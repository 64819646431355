import { bloodProduct } from "../data";

export function getNetReport(ioObj, strDateObj, endDateObj) {
  // calc the start date
  let strDate =
    strDateObj.getDate() +
    "-" +
    (strDateObj.getMonth() + 1) +
    "-" +
    strDateObj.getFullYear();
  let strtHour = strDateObj.getHours();

  // calc the now date
  let endDate =
    endDateObj.getDate() +
    "-" +
    (endDateObj.getMonth() + 1) +
    "-" +
    endDateObj.getFullYear();
  let endHour = endDateObj.getHours();

  // calc the difference in hours
  let hrAmount = (endDateObj.getTime() - strDateObj.getTime()) / 3.6e6;

  // get the day duration
  let reportArray = [];

  for (let i = 0; i <= ioObj.length - 1; i++) {
    let tempDay = JSON.parse(JSON.stringify(ioObj[i]));
    let ioDay = new Date(tempDay.dayDate);
    ioDay.setHours(0, 0, 0, 0);
    let ioDate =
      ioDay.getDate() +
      "-" +
      (ioDay.getMonth() + 1) +
      "-" +
      ioDay.getFullYear();

    strDateObj.setHours(0, 0, 0, 0);
    endDateObj.setHours(0, 0, 0, 0);

    if (
      ioDay.getTime() >= strDateObj.getTime() &&
      ioDay.getTime() <= endDateObj.getTime()
    ) {
      if (ioDate == strDate) {
        let tempSplices = [];
        tempDay.hours.forEach((hour, h) => {
          if (hour.hourName < strtHour) {
            tempSplices.push(h);
          }
        });

        for (let i = tempSplices.length - 1; i >= 0; i--) {
          tempDay.hours.splice(tempSplices[i], 1);
        }
      }
      if (ioDate == endDate) {
        let tempSplices = [];
        tempDay.hours.forEach((hour, h) => {
          if (hour.hourName > endHour) {
            tempSplices.push(h);
          }
        });

        for (let i = tempSplices.length - 1; i >= 0; i--) {
          tempDay.hours.splice(tempSplices[i], 1);
        }
      }

      reportArray.push(tempDay);
    }
  }

  let intakeReport = 0;
  let outputReport = 0;
  let netReport = 0;

  let avgUrine = 0;

  reportArray.forEach((day) => {
    day.hours.forEach((hour) => {
      intakeReport +=
        getSumOfObjs(hour.hourTotal.intake.feeds, ["breastfeed"]) +
        getSumOfObjs(hour.hourTotal.intake.bloodProducts) +
        getSumOfObjs(hour.hourTotal.intake.bolus) +
        getSumOfObjs(hour.hourTotal.intake.infusion) +
        hour.hourTotal.intake.others.val;

      outputReport +=
        hour.hourTotal.output.urine.val +
        getSumOfObjs(hour.hourTotal.output.drain) +
        getSumOfObjs(hour.hourTotal.output.procedure) +
        getSumOfObjs(hour.hourTotal.output.dialysis) +
        hour.hourTotal.output.others.val;

      if (hour.hourTotal.output && hour.hourTotal.output.urine) {
        avgUrine += hour.hourTotal.output.urine.val;
      }
    });
  });

  netReport = intakeReport - outputReport;

  return {
    inReport: intakeReport,
    outReport: outputReport,
    netReport: netReport,
    avgUrine: Math.round((avgUrine / hrAmount) * 100) / 100,
  };
}

export function getSumOfObjs(obj, exludeKeys = []) {
  let total = 0;
  for (let o in obj) {
    if (typeof obj[o] === "object") {
      const addValue = exludeKeys.includes(o) ? 0 : obj[o]["val"];
      total += addValue;
    }
  }

  return total;
}

// calculate the total of all properties
export function calcTotalProperties(ioDay, totalNeeded, props) {
  let feedsTemp = [];
  let bloodTemp = [];
  let medsTemp = [];
  let infTemp = [];
  let bolTemp = [];
  let drainsTemp = [];
  let procedureTemp = [];
  let dialysisTemp = [];

  let inTemp = [];
  let outTemp = [];

  let totalIntake = 0;
  let totalOutput = 0;

  // let blood = bloodProduct.reduce((acc, blood) => {
  //   acc[blood.propName] = {count: 0,val: 0}
  //   return acc;
  // }, {})

  // set total object for each hour
  ioDay["hours"].forEach((hr) => {
    // get total object for each hour
    hr["hourTotal"] = {
      intake: {
        feeds: {
          oral: { count: 0, val: 0 },
          breastfeed: { count: 0, val: 0 },
          tube: { count: 0, val: 0 },
          tpnPn: { count: 0, val: 0 },
          meals: { count: 0, val: 0 },
          others: { count: 0, val: 0 },
        },
        bloodProducts: bloodProduct.reduce((acc, blood) => {
          acc[blood.propName] = { count: 0, val: 0 };
          return acc;
        }, {}),
        infusion: {},
        bolus: {},
        others: { count: 0, val: 0 },
      },
      output: {
        urine: { count: 0, val: 0 },
        stool: { count: 0, val: 0 },
        emesis: { count: 0, val: 0 },
        others: { count: 0, val: 0 },
        drain: {},
        procedure: {},
        dialysis: {},
      },
    };

    // set inf and bolus props
    props.bolusProps.forEach((med) => {
      hr["hourTotal"]["intake"]["bolus"][med] = {
        count: 0,
        val: 0,
      };
    });
    props.infusionProps.forEach((med) => {
      hr["hourTotal"]["intake"]["infusion"][med] = {
        count: 0,
        val: 0,
      };
    });

    // set drains, procedure and dialysis props
    Object.keys(props.ioProps).forEach((key) => {
      props.ioProps[key].forEach((elem) => {
        let propToAdd = elem.name;
        if (elem.site) {
          propToAdd += "-" + elem.site;
        }
        if (elem.laterality) {
          propToAdd += "-" + elem.laterality;
        }
        hr["hourTotal"]["output"][key][propToAdd] = {
          count: 0,
          val: 0,
        };
      });
    });

    // set the io props for loop
    let ioProps = [
      {
        name: "intake",
        props: [
          {
            name: "feeds",
            props: Object.keys(hr["hourTotal"]["intake"]["feeds"]),
          },
          {
            name: "bloodProducts",
            props: Object.keys(hr["hourTotal"]["intake"]["bloodProducts"]),
          },
          {
            name: "infusion",
            props: props.infusionProps,
          },
          {
            name: "bolus",
            props: props.bolusProps,
          },
          {
            name: "others",
          },
        ],
      },
      {
        name: "output",
        props: [
          { name: "urine" },
          { name: "stool" },
          { name: "emesis" },
          { name: "others" },
          {
            name: "drain",
            props: props.ioProps["drain"],
          },
          {
            name: "procedure",
            props: props.ioProps["procedure"],
          },
          {
            name: "dialysis",
            props: props.ioProps["dialysis"],
          },
        ],
      },
    ];

    // create the hrTotal object
    hr.minutes.forEach((minute) => {
      ioProps.forEach((ioElem) => {
        if (minute[ioElem["name"]]) {
          ioElem["props"].forEach((prop1) => {
            if (minute[ioElem["name"]][prop1["name"]]) {
              if (prop1["props"]) {
                prop1["props"].forEach((prop2) => {
                  if (
                    prop1["name"] == "drain" ||
                    prop1["name"] == "procedure" ||
                    prop1["name"] == "dialysis"
                  ) {
                    let str = "";
                    if (prop1["name"] == "drain") {
                      str =
                        prop2["name"] +
                        "-" +
                        prop2["site"] +
                        "-" +
                        prop2["laterality"];
                      minute["output"]["drain"].forEach((drain) => {
                        if (
                          drain["name"] == prop2["name"] &&
                          drain["site"] == prop2["site"] &&
                          drain["laterality"] == prop2["laterality"]
                        ) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseInt(drain["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                    if (prop1["name"] == "procedure") {
                      str = prop2["name"] + "-" + prop2["site"];
                      minute["output"]["procedure"].forEach((procedure) => {
                        if (
                          procedure["name"] == prop2["name"] &&
                          procedure["site"] == prop2["site"]
                        ) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseInt(procedure["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                    if (prop1["name"] == "dialysis") {
                      str = prop2["name"];
                      minute["output"]["dialysis"].forEach((dialysis) => {
                        if (dialysis["name"] == prop2["name"]) {
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "val"
                          ] += parseInt(dialysis["amount"]);
                          hr["hourTotal"][ioElem["name"]][prop1["name"]][str][
                            "count"
                          ]++;
                        }
                      });
                    }
                  } else {
                    if (minute[ioElem["name"]][prop1["name"]][prop2]) {
                      hr["hourTotal"][ioElem["name"]][prop1["name"]][prop2][
                        "val"
                      ] += parseInt(
                        minute[ioElem["name"]][prop1["name"]][prop2]["amount"]
                      );
                      hr["hourTotal"][ioElem["name"]][prop1["name"]][prop2][
                        "count"
                      ]++;
                    }
                  }
                });
              } else {
                if (minute[ioElem["name"]][prop1["name"]]) {
                  hr["hourTotal"][ioElem["name"]][prop1["name"]]["val"] +=
                    parseInt(minute[ioElem["name"]][prop1["name"]]["amount"]);
                  hr["hourTotal"][ioElem["name"]][prop1["name"]]["count"]++;
                }
              }
            } else if (minute["intake"]["meds"][prop1["name"]]) {
              minute["intake"]["meds"][prop1["name"]].forEach((med) => {
                prop1["props"].forEach((propMed) => {
                  if (med.name == propMed) {
                    hr["hourTotal"]["intake"][prop1["name"]][propMed]["val"] +=
                      parseInt(med["amount"]);
                    hr["hourTotal"]["intake"][prop1["name"]][propMed][
                      "count"
                    ]++;
                  }
                });
              });
            }
          });
        }
      });
    });
  });

  // get the total
  if (totalNeeded) {
    ioDay["hours"].forEach((hour, index) => {
      feedsTemp.push(
        getSumOfObjs(hour["hourTotal"]["intake"]["feeds"], ["breastfeed"])
      );
      bloodTemp.push(
        getSumOfObjs(hour["hourTotal"]["intake"]["bloodProducts"])
      );
      infTemp.push(getSumOfObjs(hour["hourTotal"]["intake"]["infusion"]));
      bolTemp.push(getSumOfObjs(hour["hourTotal"]["intake"]["bolus"]));

      drainsTemp.push(getSumOfObjs(hour["hourTotal"]["output"]["drain"]));
      procedureTemp.push(
        getSumOfObjs(hour["hourTotal"]["output"]["procedure"])
      );
      dialysisTemp.push(getSumOfObjs(hour["hourTotal"]["output"]["dialysis"]));

      medsTemp.push(infTemp[index] + bolTemp[index]);

      inTemp.push(
        feedsTemp[index] +
          bloodTemp[index] +
          medsTemp[index] +
          hour["hourTotal"]["intake"]["others"]["val"]
      );
      outTemp.push(
        drainsTemp[index] +
          procedureTemp[index] +
          dialysisTemp[index] +
          hour["hourTotal"]["output"]["urine"]["val"] +
          hour["hourTotal"]["output"]["others"]["val"]
      );
    });

    totalIntake = (inTemp.reduce((sum, val) => sum + val) * 100) / 100;
    totalOutput =
      Math.round(outTemp.reduce((sum, val) => sum + val) * 100) / 100;
  }

  return {
    feedsTemp: feedsTemp,
    bloodTemp: bloodTemp,
    infTemp: infTemp,
    bolTemp: bolTemp,
    medsTemp: medsTemp,
    drainsTemp: drainsTemp,
    procedureTemp: procedureTemp,
    dialysisTemp: dialysisTemp,
    inTemp: inTemp,
    outTemp: outTemp,
    totalIntake: totalIntake,
    totalOutput: totalOutput,
    ioDay: ioDay,
  };
}
