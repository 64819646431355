<div class="input-popup-comp" id="inputPopup" tabindex="0">
  <div class="popup-triggers display-flex flex-column justify-content-between">
    <div>
      <div class="popup-item cp-cursor-pointer" (click)="openPopup('chat')">
        <img src="assets/icons/chat.svg" alt="" />
        <span *ngIf="showNotification" class="chat-notification"
          ><img src="assets/icons/Ellipse.svg" alt=""
        /></span>
        Chat
      </div>

      <ng-container *ngIf="!isDischargeTimeElapsed">
        <div
          class="popup-item cp-cursor-pointer"
          (click)="openPopup('notes')"
          *ngxPermissionsOnly="['create_note']"
        >
          <div class="notification-icon">
            <img src="assets/icons/notes_filled.svg" />
            <span *ngIf="(draftIndicator$ | async).length"
              ><img src="assets/icons/Ellipse.svg" alt=""
            /></span>
          </div>
          Notes
        </div>
      </ng-container>

      <ng-container *ngIf="!isDischargeTimeElapsed">
        <div
          class="popup-item cp-cursor-pointer"
          (click)="openPopup('order')"
          *ngxPermissionsOnly="['place_order']"
        >
          <img src="assets/icons/orders_filled.svg" />
          Orders
        </div>
      </ng-container>

      <div
        class="popup-item cp-cursor-pointer"
        (click)="addNewPatient()"
        *ngxPermissionsOnly="['create_patient']"
      >
        <img src="assets/icons/person_add.svg" />
        Admit patient
      </div>
    </div>
    <div>
      <div class="popup-item">
        <cp-handover-notes
          [currPatient]="currPatient"
          [showPopup]="showPopup"
          (handoverOpen)="listenToChange(false)"
          *ngxPermissionsOnly="['handover_note']"
        ></cp-handover-notes>
      </div>
    </div>
  </div>
  <div #popupBoxCont class="popup-box bg-light">
    <div class="popup-box-content">
      <div class="vertical-divider"></div>

      <div
        class="popup-header cp-cursor-pointer"
        (click)="collapsePopup('head')"
      >
        <div class="patient-name">
          {{ currPatient?.name }} {{ getPatientAge() }}
          {{ currPatient?.sex ? "," + currPatient?.sex : "" }}
        </div>

        <div
          class="collapse-icon cp-cursor-pointer"
          (click)="collapsePopup('head')"
        >
          <img src="assets/icons/collapse.svg" />
        </div>
      </div>
      <div class="content-holder">
        <mat-tab-group
          #matTabNav
          [animationDuration]="0"
          [disablePagination]="true"
          [(selectedIndex)]="selectedIndex"
          (selectedTabChange)="tabChanged($event)"
        >
          <ng-container *ngIf="!isDischargeTimeElapsed">
            <mat-tab id="orders" *ngxPermissionsOnly="['place_order']">
              <ng-template class="iris-tab" mat-tab-label>Orders</ng-template>

              <app-orders-input
                [user]="currentUser"
                [currentPatient]="currPatient"
              ></app-orders-input>
            </mat-tab>
          </ng-container>

          <mat-tab id="chat" *ngxPermissionsOnly="['get_chat']">
            <ng-template class="iris-tab" mat-tab-label>
              Chat
              <span class="tab-notification" *ngIf="showNotification"></span>
            </ng-template>
            <app-chat
              *ngIf="currPatient && currentUser"
              (inputPopup)="listenToChange($event)"
              [chatType]="'patient-chart'"
              [currPatient]="currPatient"
              [user]="currentUser"
              [chatData]="chatData"
            ></app-chat>
          </mat-tab>

          <ng-container *ngIf="!isDischargeTimeElapsed">
            <mat-tab id="notes" *ngxPermissionsOnly="['create_note']">
              <ng-template class="iris-tab" mat-tab-label>Notes</ng-template>
              <app-note-input [currPatient]="currPatient"></app-note-input>

              <!-- <app-notes-input
            [currPatient]="currPatient"
            [user]="currentUser"
            [data]="notesValue"
            [draftedNote]="draftedNewNote"
            [allDraftNote]="allDraftNotes"
          >
          </app-notes-input> -->
            </mat-tab>
          </ng-container>

          <!-- <mat-tab id="notebook" *ngxPermissionsOnly="['get_notebook']">
            <ng-template ngbTabTitle>
                Roundaᴙ
            </ng-template>
            <ng-template ngbTabContent>
              <app-notebook-popup></app-notebook-popup>
            </ng-template>
        </mat-tab> -->
        </mat-tab-group>
      </div>
    </div>
  </div>
</div>
