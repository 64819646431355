import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { consultationCharges } from "src/app/management/data/form.data";
import { ConsultationCharges } from "src/app/models/user";

@Component({
  selector: "cp-consultation-charge",
  templateUrl: "./consultation-charge.component.html",
  styleUrls: ["./consultation-charge.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: ConsultationChargeComponent,
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: ConsultationChargeComponent,
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ConsultationChargeComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  public checkDisabled(option): boolean {
    if (this.category.value != option && this.diableOptions.includes(option)) {
      return true;
    }
    return false;
  }

  @Input() showDelete: boolean = false;

  unsubscribe$: Subject<any> = new Subject<any>();

  @Output() addNew: EventEmitter<any> = new EventEmitter();

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Input("diableOptions") diableOptions: string[] = [];

  public categories: string[] = consultationCharges;

  chargesForm = new FormGroup({
    category: new FormControl(""),
    amount: new FormControl(null),
  });

  onChange = (_: any) => {};
  onTouched = () => {};
  isCalOpen: boolean = false;

  ngOnInit(): void {
    this.initializeListeners();
  }

  get category(): FormControl {
    return this.chargesForm.get("category") as FormControl;
  }

  get amount(): FormControl {
    return this.chargesForm.get("amount") as FormControl;
  }

  initializeListeners() {
    this.chargesForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((value) => {
        this.onChange(value);
      });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public addNewCharge() {
    this.addNew.emit();
  }

  public deleteCharge() {
    this.delete.emit();
  }

  writeValue(value: ConsultationCharges): void {
    this.chargesForm.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      this.chargesForm.disable();
    } else {
      this.chargesForm.enable();
    }
  }
  validate() {
    if (this.amount.invalid) return this.amount.errors;
  }
}
