<section class="crib-container input-form">
  <form
    class="iris-form crib-form"
    autocomplete="off"
    [formGroup]="cribForm"
    (ngSubmit)="submitCrib(cribForm.value)"
  >
    <div class="sub-tittle">CRIB II score</div>
    <hr />
    <div class="input-form-header">
      <div class="score">
        <div>
          {{ cribScore }}
        </div>
      </div>
    </div>

    <div class="form-fields">
      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always">
            <mat-label>Gestation weeks*:</mat-label>
            <input matInput formControlName="daysGestation" />
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always">
            <mat-label>Birth weight*:</mat-label>
            <input matInput formControlName="daysWeight" />
          </mat-form-field>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always">
            <mat-label>Gender*:</mat-label>
            <mat-select formControlName="daysGender">
              <mat-option
                *ngFor="let sexOption of sexList"
                [value]="sexOption.value"
              >
                {{ sexOption.label }}
              </mat-option>
            </mat-select>
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <mat-form-field floatLabel="always">
            <mat-label>Temperature*:</mat-label>
            <input
              matInput
              placeholder="enter in C"
              formControlName="daysTemperature"
            />
          </mat-form-field>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left iris-form-row">
          <mat-form-field floatLabel="always">
            <mat-label>Base excess*:</mat-label>
            <input
              matInput
              placeholder="mmol/l"
              formControlName="daysBaseExcess"
            />
          </mat-form-field>
        </div>
        <div class="row-right iris-form-row">
          <button
            class="button-secondary crib-generate-btn"
            (click)="calculateCribScore()"
            [disabled]="cribInputFormInvalid || calculateScoreLoading"
          >
            Generate score
            <app-loader
              [buttonLoader]="true"
              *ngIf="calculateScoreLoading"
            ></app-loader>
          </button>
        </div>
      </div>

      <!--Date/Time and Submit button-->
      <div class="date-time-row">
        <div class="warning-holder">
          <span class="iris-error" *ngIf="errorMsg">
            {{ errorMsg }}
          </span>

          <span class="iris-error" *ngIf="formState.error">
            {{ formState.error || "Server down! Please try after some time." }}
          </span>
        </div>

        <div class="content">
          <app-date-time-picker
            [showTime]="true"
            [showNowButton]="true"
            formControlName="timestamp"
            [minValidDate]="minDate"
            [maxValidDate]="maxDate"
          ></app-date-time-picker>

          <div class="b-holder">
            <button
              (click)="reset()"
              mat-flat-button
              class="button-secondary mr"
              type="button"
            >
              Reset
            </button>
            <button
              [disabled]="!cribForm.valid || formState.loading"
              mat-flat-button
              class="button-primary"
              type="submit"
            >
              Submit
              <app-loader
                [buttonLoader]="true"
                *ngIf="formState.loadType === 'crib2'"
              ></app-loader>
            </button>
          </div>
        </div>
      </div>
    </div>
  </form>
</section>
