<!-- <app-splash-screen></app-splash-screen> -->
<app-loader *ngIf="loadingData"></app-loader>
<app-sbar-alert></app-sbar-alert>

<div *ngIf="showData" class="patient-data-wrapper bg-light">
  <div class="navigation-tabs">
    <!-- Vertical navigation tabs for activities (Notes, Orders etc.)  -->
    <app-emr-nav-tabs
      [pendingCultureCount]="
        (activeCulturesCount$ | async) - (cultureDocumentCount$ | async)
      "
      [pendingCovidCount]="
        (activeCovidOrdersCount$ | async) - (covidDocumentCount$ | async)
      "
    >
    </app-emr-nav-tabs>
  </div>
  <div class="patient-info">
    <div class="patient-header">
      <app-patient-header
        (clickedNotificationUser)="setCurrentNotificationUser($event)"
        *ngIf="showHeader"
      ></app-patient-header>
    </div>

    <div class="patient-body">
      <router-outlet></router-outlet>
    </div>
  </div>

  <div class="vertical-tab">
    <app-input-popup
      [isDischargeTimeElapsed]="isDischargeTimeElapsed"
    ></app-input-popup>
  </div>
</div>
<!-- <section class="radar_policy" *ngIf="!showData">
  <h1>Due to patient safety considerations, RADAR policy is not to allow more than {{ noOfTabs }} Patient charts at the same time!</h1>
</section> -->
