import { Action, createReducer, on } from '@ngrx/store';

import * as fromAuth from '../../actions/auth.actions';
import { AuthApiActions, AuthLoginPageActions, AuthOtpPageActions } from '../../actions/auth';

export interface State {
  error: any;
  loading: boolean;
  successMsg: string | null;
}

const initialState: State = {
  error: null,
  loading: false,
  successMsg: null,
};

const authReducer = createReducer(
  initialState,
  on(
    AuthLoginPageActions.clickLoginBtn,
    AuthOtpPageActions.clickResendOtp,
    AuthOtpPageActions.clickVerifyOtp,
    (state) => ({ loading: true, error: null, successMsg: null })
  ),
  on(
    fromAuth.loginSuccess,
    AuthApiActions.basicAuthSuccess,
    AuthApiActions.verifyOtpSuccess,
    (state) => initialState
  ),
  on(
    fromAuth.loginFail,
    (state, error) => ({ loading: false, error: error, successMsg: null })
  ),
  on(
    AuthApiActions.verifyOtpFailure,
    AuthApiActions.basicAuthFailure,
    AuthApiActions.resendOtpFailure,
    (state, { error }) => ({ loading: false, error, successMsg: null })
  ),
  on(
    AuthApiActions.resendOtpSuccess,
    (state, { message }) => ({ loading: false, error: null, successMsg: message })
  )
);

export function reducer(state: State | undefined, action: Action) {
  return authReducer(state, action);
}

export const getAuthFormError = (state: State) => state.error;
export const getAuthFormLoading = (state: State) => state.loading;
export const getAuthFormSuccessMsg = (state: State) => state.successMsg;
