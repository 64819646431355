import { EMPTY, of } from "rxjs";
import { Store } from "@ngrx/store";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  concatMap,
  exhaustMap,
  withLatestFrom,
  tap,
} from "rxjs/operators";

import { PatientService } from "src/app/services/patient.service";
import { AlertInlineService } from "@iris/alert-inline/services/alert-inline.service";
import * as patientChartActions from "src/app/store/actions/patient-chart/patient-header/patient-data.actions";
import * as patientFormActions from "src/app/store/actions/patient-chart/patient-header/patient-form.actions";
import { AlertInline } from "@iris/alert-inline/models/alert-inline.model";

@Injectable()
export class PatientHeaderEffects {
  constructor(
    private actions$: Actions,
    private patientService: PatientService,
    private store$: Store<{}>,
    private router: Router,
    private _alertService: AlertInlineService
  ) {}

  getPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientChartActions.getPatient),
      switchMap(({ CPMRN, encounters, isHeader }) => {
        if (CPMRN && encounters) {
          return this.patientService
            .getPatient(CPMRN, encounters, isHeader)
            .pipe(
              mergeMap((patient) => {
                const { reason } = patient["header"]["isolation"] || {};
                let covidStatus = null;
                if (
                  reason === "COVID-19 Positive" ||
                  reason === "COVID-19 Suspected"
                ) {
                  covidStatus = reason.substring(8);
                }
                return [
                  patientChartActions.setPatient({
                    patient: patient["header"],
                  }),
                  patientFormActions.updateCovidStatus({
                    covidStatus: covidStatus,
                  }),
                  patientFormActions.updateLoaderStatus({
                    loading: false,
                    loadType: null,
                  }),
                ];
              }),
              catchError(() => EMPTY)
            );
        } else EMPTY;
      })
    )
  );

  updateSeverity$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientChartActions.updateSeverity),
      switchMap(({ patient }) =>
        this.patientService.updateSeverityLevel(patient).pipe(
          map((data) => {
            return patientChartActions.setSeverity({ severity: data.severity });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  createPatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientFormActions.createPatient),
      withLatestFrom(this.store$),
      // throttleTime(1000), // in case of multiple emits
      exhaustMap(([{ patient }, state]) =>
        this.patientService.createPatient(patient).pipe(
          mergeMap((patientData: any) => {
            const { CPMRN, encounters } = patientData["data"];
            const ifNewPatient = state["patientHeader"]["form"]["isNewPatient"];
            if (ifNewPatient) {
              return [
                patientFormActions.showSuccessScreenOne({ status: true }),
                patientChartActions.amendPatient({
                  patient: patientData["data"],
                }),
                patientFormActions.submitSuccess(),
                patientFormActions.updateUrlParams({ CPMRN, encounters }),
              ];
            } else {
              return [
                patientChartActions.amendPatient({
                  patient: patientData["data"],
                }),
                patientFormActions.submitSuccess(),
                patientFormActions.updateCurrentPage({}),
              ];
            }
          }),
          catchError((response) => {
            return of({
              type: patientFormActions.submitFail.type,
              error: response?.error?.message,
            });
          })
        )
      )
    )
  );

  updatePatient$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientFormActions.updatePatient),
      withLatestFrom(this.store$),
      concatMap(([{ payload, nextPage, isFormComplete = false }, state]) => {
        const { CPMRN, encounters, patient } = payload;
        return this.patientService.editPatient(CPMRN, encounters, patient).pipe(
          concatMap((updatedPatient) => {
            const ifNewPatient = state["patientHeader"]["form"]["isNewPatient"];

            if (isFormComplete && ifNewPatient) {
              return [
                patientChartActions.setPatient({
                  patient: updatedPatient["data"],
                }),
                patientFormActions.submitSuccess(),
                patientFormActions.showSuccessScreenTwo({ status: true }),
              ];
            }

            if (isFormComplete) {
              return [
                patientFormActions.returnToPatientDetails({
                  CPMRN,
                  encounters,
                }),
              ];
            }

            return [
              patientChartActions.setPatient({
                patient: updatedPatient["data"],
              }),
              patientFormActions.submitSuccess(),
              patientFormActions.updateCurrentPage({
                currentPage: nextPage,
              }),
            ];
          }),
          catchError((error) =>
            of({
              type: patientFormActions.submitFail.type,
              error: error.error.message,
            })
          )
        );
      })
    )
  );

  updateIninitialAssessment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(patientFormActions.updateIninitialAssessment),
      withLatestFrom(this.store$),
      concatMap(([{ payload, nextPage, isFormComplete = false }, state]) => {
        const { CPMRN, encounters, patient } = payload;

        return this.patientService
          .updateInitialAssessment(CPMRN, encounters, patient)
          .pipe(
            concatMap((updatedPatient) => {
              const ifNewPatient =
                state["patientHeader"]["form"]["isNewPatient"];

              if (isFormComplete && ifNewPatient) {
                return [
                  patientChartActions.setPatient({
                    patient: updatedPatient["data"],
                  }),
                  patientFormActions.submitSuccess(),
                  patientFormActions.showSuccessScreenTwo({ status: true }),
                ];
              }

              if (isFormComplete) {
                return [
                  patientFormActions.returnToPatientDetails({
                    CPMRN,
                    encounters,
                  }),
                ];
              }

              return [
                patientChartActions.setPatient({
                  patient: updatedPatient["data"],
                }),
                patientFormActions.submitSuccess(),
                patientFormActions.updateCurrentPage({
                  currentPage: nextPage,
                }),
              ];
            }),
            catchError((error) =>
              of({
                type: patientFormActions.submitFail.type,
                error: error.error.message,
              })
            )
          );
      })
    )
  );

  returnToPatientDetails$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientFormActions.returnToPatientDetails),
        map(({ CPMRN, encounters }) =>
          this.router.navigateByUrl(`/patient/${CPMRN}/${encounters}/summary`)
        )
      ),
    {
      dispatch: false,
    }
  );

  updateUrlParams$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientFormActions.updateUrlParams),
        map(({ CPMRN, encounters }) => {
          this.router.navigate(["admitPatient", CPMRN, encounters]);
        })
      ),
    {
      dispatch: false,
    }
  );

  submitError$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientFormActions.submitFail),
        tap(({ error }) => {
          if (!error) return;
          const errorObj: AlertInline = {
            type: "Error",
            title: "Error!",
            message: error,
          };
          this._alertService.showInlineNotification(
            errorObj,
            "center",
            "bottom",
            null
          );
        })
      ),
    {
      dispatch: false,
    }
  );

  submitSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(patientFormActions.submitSuccess),
        tap(() => {
          this._alertService.clearNotification();
        })
      ),
    {
      dispatch: false,
    }
  );
}
