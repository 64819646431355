<div class="payor-card bg-white">
  <div class="display-flex align-items-center justify-content-start">
    <app-avatar [avatarParams]="profilePic"></app-avatar>
    <div class="ml">
      <span class="cp-label-text cp-text-bold">{{ payorName || "-" }} </span>
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div class="payor-card--sections">
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">Code: </span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ payorCode || "-" }}</span>
    </div>
  </div>
</div>
