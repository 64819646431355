import { find } from "lodash-es";
// import { Socket } from 'ngx-socket-io';
import { select, Store } from "@ngrx/store";
import { filter, take } from "rxjs/operators";
import { takeUntil } from "rxjs/operators";
import { Component, OnDestroy, OnInit } from "@angular/core";

import * as fromRAlert from "../../reducers";
import { RAlertListPageActions } from "../../actions";
import {
  RAlertDevice,
  RAlertDeviceForm,
  RAlertHospital,
} from "../../../models/RAlert/device.model";
import { socket } from "../../../config/socket";
import { MatDialog } from "@angular/material/dialog";
import { Subject } from "rxjs-compat";
import { DialogService } from "@iris/confim-dialog";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-ralert-device-list-page",
  templateUrl: "./ralert-device-list-page.component.html",
  styleUrls: ["./ralert-device-list-page.component.scss"],
})
export class RalertDeviceListPageComponent implements OnInit, OnDestroy {
  public devices$ = this._store.pipe(select(fromRAlert.getRalertDevices));
  public loadingForm$ = this._store.pipe(
    select(fromRAlert.getRAlertFormLoading)
  );

  public ngDestroyed$ = new Subject();

  public hospitals: RAlertHospital[] = [];
  public editedDevice: RAlertDevice;

  constructor(
    private _store: Store<fromRAlert.State>,
    private _modalService: MatDialog,
    private _dialog: DialogService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this._store.dispatch(RAlertListPageActions.getRalertDevices());

    this.route.data.subscribe((data: { hospitals: RAlertHospital[] }) => {
      this.hospitals = data.hospitals
        ? data.hospitals.filter(
            (hospital) => hospital.units && hospital.units.length > 0
          )
        : [];
    });

    this.devices$
      .pipe(
        filter((devices) => devices.length > 0),
        take(1)
      )
      .subscribe((devices) => {
        if (devices && devices.length > 0) {
          this._store.dispatch(
            RAlertListPageActions.getAllRalertDeviceStatus({
              devices: devices.map((device) => ({ deviceId: device.deviceId })),
            })
          );
        }
      });

    this.devices$
      .pipe(filter((devices) => devices.length > 0))
      .subscribe((devices) => {
        if (devices && this.hospitals && this.hospitals.length > 0) {
          /*
           * Reformat hospital's unit to indicate whether a device is assigned to hospital.
           * */
          this.hospitals = this.hospitals.map((hospital) => {
            const units = hospital.units.map((unit) => {
              let result = { ...unit };

              if (
                find(devices, {
                  hospitalName: hospital.name,
                  unitName: unit.name,
                })
              ) {
                result = { ...result, ralertDeviceExists: true };
              } else {
                result = { ...result, ralertDeviceExists: false };
              }

              return result;
            });

            return { ...hospital, units };
          });
        }
      });

    socket.on("ralert:device:status", (data) => {
      this._store
        .pipe(
          select(fromRAlert.getRalertDeviceByDeviceId, data.deviceId),
          take(1)
        )
        .subscribe((device) => {
          this._store.dispatch(
            RAlertListPageActions.setRalertDeviceStatus({
              device,
              status: data.status,
            })
          );
        });
    });
  }

  ngOnDestroy() {
    socket.removeListener("ralert:device:status");
    this.ngDestroyed$.next();
    this.ngDestroyed$.complete();
  }

  openModal(modal) {
    this._modalService.open(modal, {
      panelClass: "dark-modal user-edit-modal",
    });
  }

  onSubmit(data: RAlertDeviceForm) {
    if (!this.editedDevice) {
      this._store.dispatch(
        RAlertListPageActions.submitNewRalertDeviceForm({ data })
      );
    } else {
      this._store.dispatch(
        RAlertListPageActions.submitEditRalertDeviceForm({
          data,
          id: this.editedDevice._id,
        })
      );
    }
  }

  onEdit(device: RAlertDevice, modal) {
    this.editedDevice = device;

    this.openModal(modal);
  }

  onDelete(id: string) {
    this._dialog
      .openConfirmDialogue({
        message: "Monitor will still be attached to patient after deleting.",
        alertType: "reject",
        headerText: "Delete Monitor",
      })
      .afterClosed()
      .pipe(takeUntil(this.ngDestroyed$))
      .subscribe((res) => {
        if (res) {
          this._store.dispatch(
            RAlertListPageActions.clickDeleteRAlertDevice({ id })
          );
        }
      });
  }
}
