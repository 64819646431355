import { Action, createReducer, on } from '@ngrx/store';
import { AuthApiActions } from '../../actions/auth';

export interface State {
  uuid: string | null;
  phone: string | null;
}

const initialState: State = {
  uuid: null,
  phone: null,
};

const authOtpReducer = createReducer(
  initialState,
  on(
    AuthApiActions.saveOtpData,
    (state, { data }) => ({ ...state, uuid: data.uuid, phone: data.phone })
  ),
  on(
    AuthApiActions.verifyOtpSuccess,
    (state) => initialState
  )
);

export function reducer(state: State | undefined, action: Action) {
  return authOtpReducer(state, action);
}

export const getAuthOtpUuid = (state: State) => state.uuid;

export const getAuthOtpPhone = (state: State) => state.phone;
