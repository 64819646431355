<div class="shift-assign-comp">
  <div class="header">Hospital/Unit list</div>

  <div class="filters-cont">
    <div class="filter-label">
      Filters
      <div
        matRipple
        class="arrow-down cp-cursor-pointer"
        (click)="hideFilters(false)"
        *ngIf="!showFilters"
      >
        <img class="filter-arrow" src="../../../assets/icons/arrow_down.svg" />
      </div>
    </div>
    <form [formGroup]="saFilters" class="filters" *ngIf="showFilters">
      <div class="hospital-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Hospital:</mat-label>
          <input
            matInput
            formControlName="hospital"
            placeholder="Filter hospitals"
            autocomplete="off"
            class="iris-input"
          />
        </mat-form-field>
      </div>
      <div class="physician-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Physician:</mat-label>
          <input
            matInput
            formControlName="physician"
            placeholder="Filter physicians"
            autocomplete="off"
            class="iris-input"
          />
        </mat-form-field>
      </div>
      <div class="nurse-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Nurse:</mat-label>
          <input
            class="iris-input"
            matInput
            formControlName="nurse"
            autocomplete="off"
            placeholder="Filter nurses"
          />
        </mat-form-field>
      </div>
      <div class="roles-filter">
        <mat-form-field [floatLabel]="'always'">
          <mat-label>Roles:</mat-label>
          <mat-select
            multiple
            class="iris-input"
            formControlName="roles"
            autocomplete="off"
            placeholder="Select roles"
            ><mat-option *ngFor="let role of roles" [value]="role">
              {{ role }}
            </mat-option></mat-select
          >
        </mat-form-field>
      </div>
      <div
        *ngIf="
          !saFilters.value.hospital &&
          !saFilters.value.physician &&
          !saFilters.value.nurse &&
          !saFilters.value.roles
        "
        class="clear-all-text clear-all-disabled"
      >
        Clear all
      </div>
      <div
        *ngIf="
          saFilters.value.hospital ||
          saFilters.value.physician ||
          saFilters.value.nurse ||
          saFilters.value.roles
        "
        (click)="clearFilters()"
        matRipple
        class="clear-all-text primary cp-cursor-pointer"
      >
        <b>Clear all</b>
      </div>
    </form>
    <div
      matRipple
      class="arrow-up cp-cursor-pointer"
      (click)="hideFilters(true)"
      *ngIf="showFilters"
    >
      <img class="filter-arrow" src="assets/icons/arrow_up.svg" />
    </div>
  </div>

  <div class="table-cont">
    <div class="table">
      <ng-container *ngFor="let workspace of workSpaceInfo">
        <div
          class="workspace-name-header"
          [hidden]="workspace.name == hiddenWorkspaceName"
        >
          {{ workspace.name }}
        </div>
        <table
          matSort
          mat-table
          (matSortChange)="onSort($event, workspace.name)"
          [dataSource]="
            workspaceList(workspace.name)
              | unitListFilter: saFilters
              | sortShiftAssign: sortParam:workspace.name
          "
        >
          <ng-container matColumnDef="hospital">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Hospital | Unit
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit?.hospitalInfo?.name }} | {{ unit?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="physician">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Physician</th>
            <td mat-cell *matCellDef="let unit">
              {{ unit?.docAssigned?.name ? unit?.docAssigned?.name : "" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="nurseNP">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Nurse(CCA)
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit?.npAssigned?.name ? unit?.npAssigned?.name : "" }}
            </td>
          </ng-container>

          <ng-container matColumnDef="nurse">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Nurse(RN)</th>
            <td mat-cell *matCellDef="let unit">
              {{ unit?.rnAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="nurseCCRN">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Nurse(CCRN)
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit?.ccrnAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="dietitian">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>Dietitian</th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.dietitianAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="specialist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Specialist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.specialistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="pharmacologist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Pharmacologist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.pharmacologistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="lab-technician">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Lab Technician
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.labTechnicianAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="documentation-specialist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Documentation Specialist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.documentationSpecialistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="physical-therapist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Physical Therapist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.physicalTherapistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="physician-associate">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Physician Associate
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.physicianAssociateAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="occupational-therapist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Occupational Therapist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.occupationalTherapistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="respiratory-therapist">
            <th mat-header-cell mat-sort-header *matHeaderCellDef>
              Respiratory Therapist
            </th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.respiratoryTherapistAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="data-entry-associate">
            <th mat-header-cell *matHeaderCellDef>Data Entry Associate</th>
            <td mat-cell *matCellDef="let unit">
              {{ unit.dataEntryAssociateAssigned?.name }}
            </td>
          </ng-container>

          <ng-container matColumnDef="phone">
            <th mat-header-cell *matHeaderCellDef>Phone no</th>
            <td
              mat-cell
              *matCellDef="let unit"
              (click)="callToPhone(i + 'phone')"
            >
              <span
                *ngIf="unit.phone"
                matTooltip="Click and press F8 to make a call"
              >
                {{ unit.phone }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="speedDial">
            <th mat-header-cell *matHeaderCellDef>Speed dial</th>
            <td
              mat-cell
              *matCellDef="let unit"
              (click)="callToPhone(i + 'speed')"
            >
              <span
                *ngIf="unit.speedDial"
                matTooltip="Click and press F8 to make a call"
              >
                {{ unit.speedDial }}
              </span>
            </td>
          </ng-container>

          <ng-container matColumnDef="anydeskId">
            <th mat-header-cell *matHeaderCellDef>Anydesk ID</th>
            <td mat-cell *matCellDef="let unit">
              <a target="_blank" *ngIf="unit.anydeskId" [href]="unit.anydeskId">
                <img
                  class="anydesk-icon"
                  matTooltip="Anydesk ID"
                  src="assets/icons/anydesk.svg"
                  alt="Anydesk ID"
              /></a>
            </td>
          </ng-container>

          <ng-container matColumnDef="pacs">
            <th mat-header-cell *matHeaderCellDef>PACS</th>
            <td mat-cell *matCellDef="let unit">
              <a
                target="_blank"
                class="primary"
                *ngIf="unit.pacsLink"
                [href]="updateLink(unit.pacsLink)"
                >PACS
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="lis">
            <th mat-header-cell *matHeaderCellDef>LIS</th>
            <td mat-cell *matCellDef="let unit">
              <a
                target="_blank"
                class="primary"
                *ngIf="unit.lisLink"
                [href]="updateLink(unit.lisLink)"
              >
                LIS
              </a>
            </td>
          </ng-container>

          <ng-container matColumnDef="meetLink">
            <th mat-header-cell *matHeaderCellDef>Meet Link</th>
            <td mat-cell *matCellDef="let unit">
              <a
                target="_blank"
                class="primary"
                *ngIf="unit.meetLink"
                [href]="updateLink(unit.meetLink)"
              >
                <img
                  matTooltip="Google meet link"
                  src="assets/icons/icons8-google-meet.svg"
                  alt="Google Meet "
              /></a>
            </td>
          </ng-container>

          <ng-container matColumnDef="assign">
            <th mat-header-cell *matHeaderCellDef>
              <div
                *ngIf="workspace.name != otherWorkspaceName"
                mat-flat-button
                class="button-primary assign-all-btn"
                (click)="
                  assignAllFlag = true;
                  openAssignAllConfirmationModal(
                    assignAll,
                    workspace._id,
                    workspace.name
                  );
                  worksSpaceName = workspace.name
                "
                [ngClass]="{
                  disabled:
                    (workspaceAssignLoaderList$ | async).includes(
                      workspace._id
                    ) ||
                    (!assignAllButtonDisabled[workspace.name] &&
                      workspace.name == hiddenWorkspaceName)
                }"
              >
                {{
                  !assignAllButtonDisabled[workspace.name] &&
                  workspace.name != hiddenWorkspaceName
                    ? "Unassign all"
                    : "Assign all"
                }}
                <app-loader
                  *ngIf="
                    (workspaceAssignLoaderList$ | async).includes(workspace._id)
                  "
                ></app-loader>
              </div>
            </th>
            <td mat-cell *matCellDef="let unit">
              <button
                mat-flat-button
                [disabled]="(unitAssignLoaderList$ | async).includes(unit._id)"
                class="assign-btn"
                [class]="[
                  unit.assignButtonText === 'Assign'
                    ? 'button-tertiary'
                    : 'button-secondary'
                ]"
                (click)="onAssign(unit, unit.assignButtonText === 'Assign')"
              >
                {{ unit.assignButtonText }}

                <app-loader
                  [buttonLoader]="true"
                  *ngIf="(unitAssignLoaderList$ | async).includes(unit._id)"
                ></app-loader>
              </button>
            </td>
          </ng-container>

          <tr
            mat-header-row
            *matHeaderRowDef="shiftAssignTableColumns; sticky: true"
          ></tr>
          <tr
            mat-row
            *matRowDef="let row; columns: shiftAssignTableColumns"
          ></tr>
        </table>
      </ng-container>
    </div>

    <!-- <mat-paginator
			#paginator
			[length]="unitsList.length"
			[hidePageSize]="true"
			[pageSize]="12"
			showFirstLastButtons
		>
		</mat-paginator> -->
  </div>

  <div class="assigned-patients-list">
    <div class="numbers">
      <div class="assigned-patients">
        <div class="count text-orange">{{ assignedPatientsCount }}</div>
        <div class="label">Assigned patients</div>
      </div>
      <div class="total-patients">
        <div class="count">{{ totalPatientsCount }}</div>
        <div class="label">Total patients</div>
      </div>
    </div>
    <div class="list">
      <mat-tab-group [selectedIndex]="0" #assignedPatList>
        <mat-tab id="physician" label="Physicians">
          <div class="count-info" *ngIf="currentUser?.role === 'Physician'">
            <div class="name primary">You</div>
            <div class="count text-orange">
              <span>
                {{ assignedPatientsCount }}
              </span>
            </div>
          </div>
          <ng-container *ngFor="let doc of patientCount.docs">
            <div
              class="count-info"
              *ngIf="
                currentUser.role !== 'Physician' ||
                doc.email !== currentUser?.email
              "
            >
              <div class="name">
                {{ doc.name }}
              </div>
              <div class="count">
                {{ doc.count }}
              </div>
            </div>
          </ng-container>
        </mat-tab>
        <mat-tab label="Nurse">
          <div class="count-info" *ngIf="currUserIsNurse">
            <div class="name primary">You</div>
            <div class="count text-orange">
              <span>{{ assignedPatientsCount }}</span>
            </div>
          </div>
          <ng-container class="count-info" *ngFor="let np of patientCount.np">
            <div
              class="count-info"
              *ngIf="
                np.email !== currentUser?.email || currentUser.role !== CCA
              "
            >
              <div class="name">{{ np.name }}</div>
              <div class="count">{{ np.count }}</div>
            </div>
          </ng-container>
          <ng-container class="count-info" *ngFor="let rn of patientCount.rn">
            <div
              class="count-info"
              *ngIf="
                currentUser.role !== 'Nurse' || rn.email !== currentUser?.email
              "
            >
              <div class="name">{{ rn.name }}</div>
              <div class="count">{{ rn.count }}</div>
            </div>
          </ng-container>
          <ng-container
            class="count-info"
            *ngFor="let ccrn of patientCount.ccrn"
          >
            <div
              class="count-info"
              *ngIf="
                currentUser.role !== CCN || ccrn.email !== currentUser?.email
              "
            >
              <div class="name">{{ ccrn.name }}</div>
              <div class="count">{{ ccrn.count }}</div>
            </div>
          </ng-container>
        </mat-tab>
      </mat-tab-group>
    </div>
  </div>
</div>

<!--<div class="d-flex">
  <div class="table-section table-responsive mt-1">
    <table class="table-sm cp-table" id="hospitalTable">
      <colgroup>
        <col class="hospitalName" />
        <col class="userName" />
        <col class="userName" />
        <col class="userName" />
        <col class="userName" />
        <col class="others" />
        <col class="others" />
        <col class="others" />
        <col class="others" />
        <col class="otherSmall" />
        <col class="otherSmall" />
        <col class="buttons" />
      </colgroup>
      <thead class="text-white">
        <tr>
          <th (click)="onSort('hospitalName')">
            Hospital
            <fa-icon [icon]="['fas', 'sort']"></fa-icon>
          </th>
          <th (click)="onSort('physician')">
            Physician
            <fa-icon [icon]="['fas', 'sort']"></fa-icon>
          </th>
          <th (click)="onSort('nurse')">
            Nurse (NP)
            <fa-icon [icon]="['fas', 'sort']"></fa-icon>
          </th>
          <th (click)="onSort('nurse')">
            Nurse (CCRN)
            <fa-icon [icon]="['fas', 'sort']"></fa-icon>
          </th>
          <th (click)="onSort('nurse')">
            Nurse (RN)
            <fa-icon [icon]="['fas', 'sort']"></fa-icon>
          </th>
          <th>Phone No.</th>
          <th>Speed Dial</th>
          <th>Anydesk Id</th>
          <th>Meet</th>
          <th>PACS</th>
          <th>LIS</th>
          <th
            class="bg-white button_holder"
            *ngxPermissionsOnly="['shift_assign']"
          >
            <button
              class="cp-btn assign_all"
              (click)="
                assignAllFlag = true; openAssignAllConfirmationModal(assignAll)
              "
              [disabled]="!assignAllButtonDisabled"
              matTooltip="Assign all"
            >
              Assign all
            </button>

             //Button to unassign all
            // <button class="cp-btn cp-hvr-red assign_all" (click)="assignAllFlag = false; openAssignAllConfirmationModal(assignAll)" [disabled]="!unassignAllButtonDisabled" matTooltip="Unassign all">
                            <img src="../../assets/icons/icons8-google-meet.svg" alt="Unassign all">
                        </button> 
          </th>
        </tr>
      </thead>
      <tbody>
        <ng-container *ngFor="let unit of unitsList; index as i">
          <tr *ngIf="unit['active']">
            <td class="pr-4"><strong></strong></td>
            <td class="pr-4"></td>
            <td class="pr-4"></td>
            <td class="pr-4"></td>
            <td class="pr-4"></td>
            <td class="pr-4 callselect">
              <span contenteditable="true" class="{{ i }}phone">{{
                unit.phone
              }}</span>
            </td>
            <td class="pr-4 callselect">
              <span contenteditable="true" class="{{ i }}speed">{{
                unit.speedDial
              }}</span>
            </td>
            <td class="pr-4"></td>
            <td class="pr-4">
              <a target="_blank" *ngIf="unit.meetLink" [href]="unit.meetLink">
                // Google Meet 
                <img
                  matTooltip="Google meet link"
                  src="../../assets/icons/icons8-google-meet.svg"
                  alt="Google-meet"
                />
              </a>
            </td>
            <td class="pr-4">
              
            </td>
            <td class="pr-4">
             
            </td>
            <td class="bg-white assign_btn_container"></td>
          </tr>
        </ng-container>
      </tbody>
    </table>
  </div>
  <div class="user-section d-flex flex-grow-1 justify-content-around">
    <div class="table-responsive mt-1 removeWidth">
      <table
        *ngIf="
          patientCount?.rn?.length ||
          patientCount?.np?.length ||
          patientCount?.ccrn?.length
        "
        class="table-sm cp-table"
        id="nurseTable"
      >
        <thead class="text-white">
          <tr class="text-center">
            <th>Nurse</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let nurse of patientCount?.np">
            <td
              class="pr-5"
              style="height: 120px; padding: 0.5rem 1rem !important"
            >
              <strong>{{ nurse?.name }} (NP)</strong>
              <ul>
                <li *ngIf="nurse.count">
                  <strong> {{ nurse.count }} </strong> (patient)
                </li>
                <li *ngIf="nurse.email && nurse.email.length">
                  {{ nurse?.email }}
                </li>
                <li *ngIf="nurse?.phone.length">
                  {{ nurse.phone }}
                </li>
              </ul>
            </td>
          </tr>
          <tr *ngFor="let nurse of patientCount?.ccrn">
            <td
              class="pr-5"
              style="height: 120px; padding: 0.5rem 1rem !important"
            >
              <strong>{{ nurse?.name }} (CCRN)</strong>
              <ul>
                <li *ngIf="nurse.count">
                  <strong> {{ nurse.count }} </strong> (patient)
                </li>
                <li *ngIf="nurse.email && nurse.email.length">
                  {{ nurse?.email }}
                </li>
                <li *ngIf="nurse?.phone.length">
                  {{ nurse.phone }}
                </li>
              </ul>
            </td>
          </tr>
          <tr *ngFor="let nurse of patientCount?.rn">
            <td
              class="pr-5"
              style="height: 120px; padding: 0.5rem 1rem !important"
            >
              <strong>{{ nurse?.name }} (RN)</strong>
              <ul>
                <li *ngIf="nurse.count">
                  <strong> {{ nurse.count }} </strong> (patient)
                </li>
                <li *ngIf="nurse.email && nurse.email.length">
                  {{ nurse?.email }}
                </li>
                <li *ngIf="nurse?.phone.length">
                  {{ nurse.phone }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <div class="table-responsive mt-1 removeWidth">
      <table
        *ngIf="patientCount?.docs?.length"
        class="table-sm cp-table"
        id="doctorTable"
      >
        <thead class="text-white">
          <tr class="text-center">
            <th>Physician</th>
          </tr>
        </thead>
        <tbody>
          <tr *ngFor="let doc of patientCount?.docs">
            <td class="pr-5">
              <strong>{{ doc?.name }}</strong>
              <ul>
                <li *ngIf="doc.count">
                  <strong> {{ doc.count }} </strong> (patient)
                </li>
                <li *ngIf="doc.email && doc.email.length">
                  {{ doc?.email }}
                </li>
                <li *ngIf="doc?.phone.length">
                  {{ doc.phone }}
                </li>
              </ul>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>-->

<ng-template #assignAll let-modal>
  <div class="dialog-container">
    <div class="dialog-title">
      <img class="warning-icon" src="assets/icons/warning_24px.svg" />
      <b>Attention</b>
      <img
        matDialogClose="cancel"
        class="dialog-close-icon"
        src="assets/icons/close.svg"
      />
    </div>
    <div class="dialog-content">{{ contentToDisplay }}</div>
    <mat-dialog-actions class="dialog-actions">
      <button
        mat-flat-button
        class="button-secondary"
        matDialogClose="cancel"
        color="primary"
      >
        Cancel
      </button>
      <button
        mat-flat-button
        class="button-primary"
        matDialogClose="yes"
        color="warn"
      >
        {{ actionToDo }}
      </button>
    </mat-dialog-actions>
  </div>
</ng-template>
