import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnInit,
  ViewChild,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { MatDialog, MatDialogRef } from "@angular/material/dialog";
import { AlertService } from "@iris/alert/services/alert.service";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileService } from "src/app/services/file.service";
import { FileTypeEnum } from "../../upload-file";
import { OpenWebcamModalComponent } from "./open-webcam-modal/open-webcam-modal.component";

@Component({
  selector: "cp-cam-photo",
  templateUrl:
    "../../upload-file/components/upload-file/upload-file.component.html",
  styleUrls: [
    "../../upload-file/components/upload-file/upload-file.component.scss",
  ],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: CamContainerComponent,
      multi: true,
    },
  ],
})
export class CamContainerComponent implements OnInit {
  uploadedFile: string | ArrayBuffer;
  constructor(
    private _fileService: FileService,
    private _alertService: AlertService,
    private _cdr: ChangeDetectorRef,
    public dialog: MatDialog
  ) {}
  ngOnInit(): void {}

  public key: string = null;

  @Input("file") file: string = null;

  @Input("fileType") fileType: "pdf" | "image" = "image";

  @Input("getFileLoader") getFileLoader: boolean = false;

  @ViewChild("fileInput") fileInput: ElementRef;

  public fileTypeEnum = FileTypeEnum;

  public isResetFile: boolean = false;

  public filePreview: string[] = [];

  get icon() {
    return "camera";
  }

  public error: boolean = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  public uploadLoader: boolean = false;

  private unsubscribe$ = new Subject<any>();

  @Input("label") label: string = "Capture from webcam";

  openWebCamDialog() {
    const dialogRef = this.dialog.open(OpenWebcamModalComponent, {
      autoFocus: false,
    });

    dialogRef
      .afterClosed()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        if (!data) return;
        this.onUpload(data);
      });
  }

  get fileSrc(): string | ArrayBuffer {
    if (this.isResetFile) return null;
    return this.uploadedFile || this.file;
  }

  writeValue(obj: any): void {
    this.key = obj;
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  public changeFile() {
    this.openWebCamDialog();
  }

  public removeFile() {
    this.isResetFile = true;
    this.onChange(null);
  }

  onUpload(file: File) {
    try {
      if (file.size > 2097152) {
        throw new Error("File size limit exceeded");
      }
      let reader = new FileReader();
      this.onTouched();
      reader.readAsDataURL(file);

      reader.onload = async (_event) => {
        this.uploadedFile = await reader.result;
        this._fileService
          .getSignedUrlPrivate(file.type)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res) => {
              this.uploadFile(file, res?.signedUrl, res?.key);
            },
            (_) => {
              this.error = true;
            }
          );
      };
    } catch (err) {
      this.uploadLoader = false;
      this._alertService.showNotification({
        type: "Error",
        message: err,
      });
    }
  }

  uploadFile(file, signerUrl: string, key: string) {
    this._fileService
      .uploadFile(file, signerUrl, file?.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          if (data?.url) {
            this.isResetFile = false;
            this.uploadLoader = false;
            this.onChange(key);
            this._cdr.detectChanges();
          }
        },
        (_) => {
          this.error = true;
        }
      );
  }
}
