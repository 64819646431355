import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";

import { BehaviorSubject, Observable, of, Subject, throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import { ApiResponse } from "src/app/models/api-response.model";
import { DocumentIntensityUpdate } from "src/app/models/document";
import { OrderableRequest } from "src/app/models/orderable/OrderableRequest.model";
import { environment } from "../../../environments/environment";
import { LabData, Task } from "../labs-scans/models/labs-scans";

@Injectable({
  providedIn: "root",
})
export class LabsScansService {
  public apiUrl = environment.apiUrl;

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  private data = {
    component: "DragDropZoneComponent",
    editLabs: false,
    sbarReferenceID: null,
  };
  private broadCastComponents = new BehaviorSubject<object>(this.data);
  broadCastComponents$ = this.broadCastComponents.asObservable();

  private updateLabsData = new BehaviorSubject<object>(null);
  private updateLoadingStatusSource = new BehaviorSubject<boolean>(null);
  updateLabs$ = this.updateLabsData.asObservable();
  loading$ = this.updateLoadingStatusSource.asObservable();

  public passComponentName({
    component,
    editLabs = false,
    sbarReferenceID = null,
  }) {
    this.broadCastComponents.next({
      ...this.data,
      component,
      editLabs,
      sbarReferenceID,
    });
  }

  public updateLab(labData: LabData) {
    this.updateLabsData.next(labData);
  }
  public loadingStatus(status: boolean) {
    this.updateLoadingStatusSource.next(status);
  }

  constructor(private http: HttpClient) {}

  getLabForms(data: string): Observable<any> {
    return this.http
      .get<any>(`${this.apiUrl}labs/search/?name=${data}`, {
        observe: "response",
      })
      .pipe(catchError(this.errorHandler));
  }

  removeLabs(CPMRN, encounters, props) {
    return this.http
      .delete(
        `${this.apiUrl}patients/documents/remove/${CPMRN}/${encounters}/${props.id}`
      )
      .pipe(catchError(this.errorHandler));
  }

  addDocument(document) {
    return this.http
      .post(`${this.apiUrl}patients/documents/new`, document)
      .pipe(catchError(this.errorHandler));
  }

  updateDocument(document) {
    return this.http
      .put(`${this.apiUrl}patients/documents/update`, document)
      .pipe(catchError(this.errorHandler));
  }

  submitLabRequest(data: OrderableRequest): Observable<any> {
    return this.http
      .post<ApiResponse>(
        `${this.apiUrl}labs/request/labs`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getLabRequest(): Observable<any> {
    return this.http
      .get<ApiResponse>(`${this.apiUrl}labs/get`, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  updateLabRequest(data: any, id: string): Observable<any> {
    return this.http
      .put<ApiResponse>(
        `${this.apiUrl}labs/update-lab-request/${id}`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  updateLabValues(data: any): Observable<any> {
    return this.http
      .put<ApiResponse>(
        `${this.apiUrl}labs/update-lab-fields/${data._id}`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  updateLabIntensityValue(
    payload: DocumentIntensityUpdate,
    docuemtnId: string
  ): Observable<any> {
    return this.http
      .patch<ApiResponse>(
        `${this.apiUrl}patients/documents/${docuemtnId}/partial`,
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  downloadImage(CPMRN, key, bucket?: string) {
    return this.http
      .post(
        this.apiUrl + "patients/" + CPMRN + "/download/",
        { key: key, bucket: bucket },
        {
          observe: "response",
          ...this.httpOptions,
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  public createVisionTask(patient, user, input = null): Task {
    return {
      id: Date.now(),
      type: 1,
      input,
      key: patient.CPMRN + ":" + patient.encounters,
      createdBy: user.email,
      output: null,
      status: "active",
      priority: 1,
      collType: "patient",
    };
  }

  filterDocuments(formData, document) {
    let value = document.filter((data) => {
      if (
        !(
          (filterData(data.name, formData.name) ||
            filterData(data?.alias?.[0], formData.name)) &&
          filterByDuration(data.reportedAt, formData.duration)
        )
      ) {
        return false;
      } else {
        return true;
      }
    });
    return value;
  }
}

export function filterData(data: string, formValue: string) {
  let returnBool = false;
  if (formValue) {
    if (data) {
      if (data.toLowerCase().includes(formValue.toLowerCase())) {
        returnBool = true;
      } else returnBool = false;
    } else returnBool = false;
  } else returnBool = true;
  return returnBool;
}

export function filterByDuration(
  date: Date,
  formDate: number | "adt"
): boolean {
  if (formDate && formDate != "adt") {
    const now = new Date();
    const documentDate = new Date(date);
    const diff = Math.abs(now.getTime() - documentDate.getTime()) / 3600000;
    if (diff < formDate) return true;
    else return false;
  } else return true;
}
