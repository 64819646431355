<div class="patient-header-comp">
  <mat-accordion>
    <mat-expansion-panel
      hideToggle
      [ngClass]="{
        'cp-clr-discharged': patientType === 'discharged',
        'cp-clr-re-admitted': patientType === 're-admitted'
      }"
    >
      <mat-expansion-panel-header>
        <div class="begin-part">
          <div class="hospital-info">
            <div class="logo">
              <img
                class="hosp-logo"
                [src]="patient.hospitalLogo"
                alt="Hospital Logo"
                onerror="this.onerror = null; this.src='assets/icons/hospital_logo.svg'"
              />
            </div>
            <div class="hosp-address">{{ patient?.hospitalName }}</div>
            <div class="hosp-address">{{ patient?.unitName }}</div>
          </div>

          <mat-divider [vertical]="true" class="divider"></mat-divider>
          <div class="id-info">
            <div class="labels">
              <span>Bed no: </span>
              <span>CPMRN: </span>
              <span>MRN: </span>
              <span>Admit Date:</span>
            </div>
            <div class="values">
              <span>{{ patient.bedNo || "-" }}</span>
              <span>{{ patient.CPMRN || "-" }}</span>
              <span>{{ patient.MRN || "-" }}</span>
              <span>{{
                patient.ICUAdmitDate
                  ? (patient.ICUAdmitDate | date: "MMM d y, HH:mm") +
                    " (" +
                    (patient.ICUAdmitDate
                      | icuDays
                        : !patient?.isCurrentlyAdmitted &&
                            patient?.ICUDischargeDate) +
                    ")"
                  : "-"
              }}</span>
            </div>
          </div>

          <mat-divider [vertical]="true" class="divider"></mat-divider>
          <div class="additional-info">
            <div class="labels">
              <span>Primary MD: </span>
              <span>Operative status:</span>
              <span>Allergies:</span>
            </div>
            <div class="values">
              <span
                >{{ patient.PCP || "-"
                }}<ng-container *ngIf="patient?.PCP && patient?.PCP_speciality"
                  >, {{ patient?.PCP_speciality }}</ng-container
                ></span
              >
              <span>{{ patient.operativeStatus || "-" }}</span>
              <span> {{ patient.allergies || "-" }}</span>
            </div>
          </div>
          <mat-divider [vertical]="true" class="divider"></mat-divider>

          <div class="badges">
            <span
              *ngIf="patient.qsofa !== null"
              class="qSofa cp-badge"
              [ngClass]="{
                'cp-clr-misc-p': patient.qsofa == 1,
                'cp-clr-danger': patient.qsofa >= 2,
                'cp-clr-misc-s': patient.qsofa < 1
              }"
            >
              <strong>qSOFA: {{ patient.qsofa }}</strong>
            </span>
            <span
              *ngIf="rox?.value"
              class="cp-badge cp-inline"
              [ngClass]="{
                'bg-red': rox.color == 'red',
                'bg-green': rox.color == 'green',
                'bg-yellow': rox.color == 'yellow'
              }"
            >
              <strong>ROX: {{ rox?.value }}</strong>
            </span>

            <span
              *ngIf="
                patient?.isolation?.precautionType &&
                patient?.isolation?.precautionType !== 'NA'
              "
              class="cp-badge isolation {{
                patient.isolation.precautionType.toLowerCase() + '-prec'
              }}"
              [matTooltip]="patient.isolation?.reason"
            >
              <b>{{ patient.isolation?.precautionType }} precautions</b>
            </span>
          </div>

          <div class="badges">
            <span
              class="patient_niv cp-badge primary-bg-light"
              *ngIf="patient.isNIV?.value"
            >
              <strong>NIV</strong>
            </span>

            <span
              class="patient_intubated cp-badge primary-bg-light"
              *ngIf="patient.isIntubated?.value"
            >
              <strong>INTUBATED</strong>
            </span>
            <span
              class="patient_trach cp-badge primary-bg-light mr-1"
              *ngIf="patient.isTrach?.value"
            >
              <strong>Trach</strong>
            </span>
          </div>

          <div class="badges">
            <div
              class="pressor_container mr-1"
              *ngIf="patient.isPressor"
              (mouseover)="showPressorInfo = true"
              (mouseout)="showPressorInfo = false"
            >
              <span class="patient_press cp-badge cp-clr-danger">
                <strong>Pressor</strong>
              </span>

              <div class="pressor_info" *ngIf="showPressorInfo">
                <div class="arrow-top"></div>
                <li *ngFor="let pressorMed of pressorMedInfo">
                  <span>{{ pressorMed.name }}</span>
                  <span>Infusion rate: {{ pressorMed.infusionRate }}</span>
                  <span>{{ pressorMed.infusionUnit }}</span>
                  <ng-container [ngSwitch]="pressorMed.dosageStatus">
                    <fa-icon
                      *ngSwitchCase="'increased'"
                      [icon]="faArrowAltCircleUp"
                    ></fa-icon>
                    <fa-icon
                      *ngSwitchCase="'decreased'"
                      [icon]="faArrowAltCircleDown"
                    ></fa-icon>
                  </ng-container>
                </li>
              </div>
            </div>

            <span
              class="patient_niv cp-badge primary-bg-light mr-1"
              *ngIf="patient?.isHFNC?.value"
            >
              <strong>HFNC</strong>
            </span>
            <span
              class="p-code p-imp-badge cp-badge cp-inline mr-3"
              [ngClass]="
                patient.code === 'Full Code'
                  ? 'cp-clr-comfort'
                  : 'cp-clr-danger'
              "
              *ngIf="patient.code"
            >
              <b>{{ patient.code }}</b>
            </span>
          </div>
        </div>

        <div class="end-part">
          <div class="edit-discharge">
            <div
              *ngxPermissionsOnly="['get_camera']"
              class="camera iris-form-row"
              [matTooltip]="
                !selUnit?.cameras.length
                  ? 'No cameras allotted to this bed'
                  : ''
              "
            >
              <mat-select
                class="select-input"
                name="cam"
                id="cam"
                placeholder="Select camera"
                (click)="$event.stopPropagation()"
                [disabled]="isDischargeTimeElapsed"
                [(ngModel)]="cameraStore"
                panelClass="cam-select"
              >
                <mat-select-trigger>
                  {{ cameraStore?.name }}
                  {{ cameraStore?.ip == patient?.camera ? "(PC)" : "" }}
                </mat-select-trigger>
                <mat-option
                  class="mat-option-cam"
                  *ngFor="let cam of selUnit?.cameras"
                  [value]="cam"
                  (click)="$event.stopPropagation()"
                >
                  <span class="cam-camera">
                    {{ cam.name }} {{ cam.ip == patient?.camera ? "(PC)" : "" }}
                  </span>
                  <span class="cam-bed">
                    Bed:
                    {{
                      cameraBedMap[cam.name] && cameraBedMap[cam.name].length
                        ? cameraBedMap[cam.name].join(", ")
                        : "NA"
                    }}
                  </span>
                </mat-option>
              </mat-select>

              <ng-template [ngxPermissionsOnly]="['get_camera']">
                <ng-container>
                  <!-- <button
                    class="button-primary"
                    mat-flat-button
                    (click)="openCamModal(content); $event.stopPropagation()"
                  > -->
                  <button
                    class="button-primary"
                    mat-flat-button
                    [disabled]="!selUnit?.cameras.length"
                    (click)="showCamBroadcast(); $event.stopPropagation()"
                  >
                    View
                    <img
                      class="icon-right"
                      src="../../../assets/icons/videocam.svg"
                    />
                  </button>
                </ng-container>
              </ng-template>
            </div>

            <button
              class="edit-patient"
              (click)="openEditModal(); $event.stopPropagation()"
              *ngxPermissionsOnly="['update_patient']"
              [disabled]="isDischargeTimeElapsed"
              matTooltip="Edit patient"
            >
              <img src="../../../assets/icons/edit.svg" />
            </button>

            <button
              class="discharge-patient"
              (click)="openDischargeModal(); $event.stopPropagation()"
              *ngxPermissionsOnly="['update_patient']"
              [disabled]="isDischargeTimeElapsed"
              matTooltip="Discharge Patient"
            >
              <img src="../../../assets/icons/exit_to_app.svg" />
            </button>

            <div class="google-meet-link">
              <a
                target="_blank"
                *ngIf="googleMeetLink && !isDischargeTimeElapsed"
                [href]="googleMeetLink"
                matTooltip="Open meet link"
              >
                <img
                  src="../../assets/icons/icons8-google-meet.svg"
                  alt="google-meet"
                />
              </a>
            </div>
          </div>

          <div class="severity mr">
            <div
              class="patient-severity iris-form-row"
              *ngxPermissionsOnly="['update_patient_severity']"
            >
              <mat-select
                class="select-input patient.severity.toLowerCase()"
                [ngClass]="severityColor(patient.severity)"
                [(ngModel)]="patient.severity"
                (selectionChange)="severityChangeFn(patient.encounters)"
                [disabled]="isDischargeTimeElapsed"
                placeholder="Select severity"
                (click)="$event.stopPropagation()"
              >
                <mat-option
                  *ngFor="let severity of severityList"
                  [value]="severity"
                  >{{ severity }}</mat-option
                >
              </mat-select>
            </div>

            <div class="sbar-create" *ngxPermissionsOnly="['create_sbar']">
              <button
                mat-flat-button
                class="button-primary"
                *ngIf="patient.isCurrentlyAdmitted"
                (click)="
                  openSbarModal(
                    patient,
                    currentUser.role !== 'Physician'
                      ? 'NonPhysician'
                      : 'Physician'
                  );
                  $event.stopPropagation()
                "
              >
                SBAR
                <mat-icon class="icon-right">add</mat-icon>
              </button>
            </div>

            <!-- <div class="roundar-toggle" *ngxPermissionsOnly="['get_notebook']">
              <mat-slide-toggle
                id="roundar"
                (click)="$event.stopPropagation()"
                (change)="onRoundarChange($event.checked)"
              >
                <small>roundaᴙ</small>
              </mat-slide-toggle>
            </div> -->
          </div>
        </div>

        <div class="arrow-up">
          <img src="assets/icons/arrow_down.svg" />
        </div>
        <div class="arrow-down">
          <img src="assets/icons/arrow_down.svg" />
        </div>
      </mat-expansion-panel-header>
      <ng-template matExpansionPanelContent>
        <div class="begin-part">
          <div class="id-info">
            <div class="labels">
              <span>Created by: </span>
              <span>Edited by: </span>
              <span>Past Medical History: </span>
            </div>
            <div class="values">
              <span>{{ patient.createdBy || "-" }}</span>
              <span>{{ patient.editedBy || "-" }}</span>
              <span>{{ patient.pastMedicalHistory || "-" }}</span>
            </div>
          </div>
          <mat-divider [vertical]="true" class="divider"></mat-divider>

          <div class="id-info">
            <div class="labels">
              <span>Chief complaints:</span>
              <span>Immunocompromised Dx:</span>
              <span>Chronic Illnesses:</span>
            </div>
            <div class="values">
              <span>
                {{
                  (patient.chiefComplaint &&
                    patient?.chiefComplaint?.join(", ")) ||
                    "-"
                }}
              </span>

              <span>
                <ng-container
                  *ngFor="let immune of patient.immune; last as last"
                >
                  {{ last ? immune : immune + ", " }}
                </ng-container>
                <ng-container *ngIf="!patient.immune.length"> - </ng-container>
              </span>
              <span>
                <ng-container
                  *ngFor="let chronic of patient.chronic; last as last"
                >
                  {{ last ? chronic : chronic + ", " }}
                </ng-container>
                <ng-container *ngIf="!patient.chronic.length"> - </ng-container>
              </span>
            </div>
          </div>

          <mat-divider
            [vertical]="true"
            class="divider"
            *ngIf="patient.apacheScore || patient.ventFreeDays"
          ></mat-divider>

          <div class="id-info">
            <div class="labels">
              <span *ngIf="patient.apacheScore">Apache Score: </span>
              <span *ngIf="patient.ventFreeDays">Vent free days:</span>
            </div>
            <div class="values">
              <span *ngIf="patient.apacheScore">{{ patient.apacheScore }}</span>
              <span *ngIf="patient.ventFreeDays">{{
                patient.ventFreeDays
              }}</span>
            </div>
          </div>
        </div>

        <div class="end-part">
          <div
            class="iris-form-row view-past-admissions"
            *ngIf="patient.admitList && patient.admitList.length > 1"
          >
            <mat-select
              class="select-input"
              [(ngModel)]="encounter"
              placeholder="View past admissions"
              (click)="$event.stopPropagation()"
              (selectionChange)="selectEncounter(encounter)"
            >
              <mat-option
                *ngFor="let pat of patient?.admitList"
                value="{{ pat.encounters }}"
                [hidden]="patient.ICUAdmitDate === pat.admitDate"
              >
                {{ pat.admitDate | date: "dd-MM-yyyy" }}
              </mat-option>
            </mat-select>
          </div>
        </div>
      </ng-template>
    </mat-expansion-panel>
  </mat-accordion>
</div>

<ng-template #content>
  <div class="modal-popup">
    <div class="header">
      <div class="title">{{ patient.hospitalName }} - All Cameras</div>
      <img
        matRipple
        class="cp-cursor-pointer"
        src="assets/icons/close.svg"
        mat-dialog-close
      />
    </div>
    <div class="body">
      <table mat-table [dataSource]="selUnit.cameras">
        <ng-container matColumnDef="slNo">
          <th mat-header-cell *matHeaderCellDef>Sl No.</th>
          <td mat-cell *matCellDef="let camera; index as i">
            {{ i + 1 }}
          </td>
        </ng-container>

        <ng-container matColumnDef="cameraName">
          <th mat-header-cell *matHeaderCellDef>Camera name</th>
          <td mat-cell *matCellDef="let camera">
            {{ camera.name }}
          </td>
        </ng-container>

        <ng-container matColumnDef="remark">
          <th mat-header-cell *matHeaderCellDef>Remarks</th>
          <td mat-cell *matCellDef="let camera">
            {{ camera.remark || "-" }}
          </td>
        </ng-container>

        <tr
          mat-header-row
          *matHeaderRowDef="cameraTableColumns; sticky: true"
        ></tr>
        <tr mat-row *matRowDef="let row; columns: cameraTableColumns"></tr>
      </table>
    </div>
  </div>
</ng-template>
