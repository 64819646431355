import {
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DatePipe } from "@angular/common";
import { MatDatepickerInputEvent } from "@angular/material/datepicker";
import {
  UntypedFormGroup,
  UntypedFormControl,
  ControlValueAccessor,
  UntypedFormBuilder,
  NG_VALUE_ACCESSOR,
  NG_VALIDATORS,
  NgModel,
} from "@angular/forms";
import { padStart } from "lodash-es";
import { Observable, Subject } from "rxjs";
import { Store, select } from "@ngrx/store";
import { getVitalClickedDay } from "src/app/vitals/store/reducers";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "app-date-time-picker",
  templateUrl: "./date-time-picker.component.html",
  styleUrls: ["./date-time-picker.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DateTimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: DateTimePickerComponent,
      multi: true,
    },
  ],
})
export class DateTimePickerComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description To observe the clicked day
   * @type {Observable}
   */
  public vitalClickedDay$ = this.store.pipe(
    select(getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  date: Date;
  hour: string;
  minute: string;
  form: UntypedFormGroup;
  range: UntypedFormGroup;

  @ViewChild("dateField") dateField: NgModel;

  disabled: boolean = false;

  onChange: any = () => {};
  onTouched: any = () => {};

  /**TODO : To display single digit hour or minute padded with '0'. */
  displayHour;
  displayMinute;

  /** To set the default value of data / time */
  @Input() defaultValue: Date;

  /** To enable the date range option */
  @Input() dateRange: boolean;

  /** To show time alongside date */
  @Input() showTime: boolean;

  /** To show Now button which sets current Date and Time */
  @Input() showNowButton: boolean;

  /** The minimum valid date */
  @Input() minValidDate: Date;

  /** The minimum valid date */
  @Input() enableValidation: boolean = false;

  /** The maximum valid date */
  @Input() maxValidDate: Date;

  /** Date Label */
  @Input() dateLabel: string;

  /** To reset the date and time. */
  @Input() reset: Observable<any>;

  /** Time Label */
  @Input() timeLabel: string;

  /** Whenever date changes this event is emitted */
  @Output() dateChange: EventEmitter<Date> = new EventEmitter<Date>();

  /** Whenever start or end date is changed this gets emitted */
  @Output() dateRangeChange: EventEmitter<string> = new EventEmitter<string>();

  /** In case of change in hour or minute this event is emitted */
  @Output() timeChange: EventEmitter<string> = new EventEmitter<string>();

  constructor(
    public datepipe: DatePipe,
    private fb: UntypedFormBuilder,
    private store: Store<any>
  ) {
    this.range = new UntypedFormGroup({
      start: new UntypedFormControl(),
      end: new UntypedFormControl(),
    });
  }

  validate() {
    if (this.dateRange || !this.dateField || !this.enableValidation)
      return null;

    if (this.date > this.maxValidDate) return { furtureDate: true };
    return this.dateField.errors;
  }

  ngOnChanges(simpleChanges: SimpleChanges) {
    if (simpleChanges?.defaultValue?.currentValue) {
      this.setDateValue(new Date(simpleChanges.defaultValue.currentValue));
    }
    this.reset?.subscribe(() => {
      this.date = null;
      this.hour = null;
      this.minute = null;
      this.range.controls.start.setValue(null);
      this.range.controls.end.setValue(null);

      this.dateChange.emit(null);
      this.timeChange.emit(null);
      if (this.dateRange) this.dateRangeChange.emit();
    });
  }

  ngOnInit(): void {
    this.vitalClickedDay$.subscribe((day: any) => {
      const disableState = day?.vitalType === "Initial" ? true : false;
      this.setDisabledState(disableState);
    });
  }

  onDateChange(event: MatDatepickerInputEvent<Date>) {
    this.dateChange.emit(event.value);

    this.setDateValue(event.value);
  }

  onDateRangeChange() {
    const dateRangeValue =
      this.range.value.start?.toLocaleString().substr(0, 10) +
      "-" +
      this.range.value.end?.toLocaleString().substr(0, 10);

    this.dateRangeChange.emit(dateRangeValue);

    this.onChange(dateRangeValue);
  }

  onHourChange() {
    this.hour = padStart(+this.hour % 24, 2, 0);
    this.minute = padStart(this.minute || 0, 2, 0);

    this.date = this.date || new Date();
    this.date.setHours(+this.hour);

    this.date.setSeconds(0);
    this.date.setMilliseconds(0);

    this.onChange(this.date.getTime());
    this.timeChange.emit(`${this.hour}:${this.minute}`);
  }

  onMinuteChange() {
    if (this.hour) {
      let hr = +this.hour;
      hr += Math.floor(+this.minute / 60);
      this.hour = padStart(hr % 24, 2, 0);
    }

    this.minute = padStart(+this.minute % 60, 2, 0);

    this.date = this.date || new Date();
    this.date.setHours(+this.hour);
    this.date.setMinutes(+this.minute);

    this.date.setSeconds(0);
    this.date.setMilliseconds(0);

    this.onChange(this.date.getTime());

    this.timeChange.emit(`${this.hour || 0}:${this.minute}`);
  }

  onNowClick() {
    const today = Date.now();
    this.setDateValue(today);

    this.dateChange.emit(this.date);
    this.timeChange.emit(`${this.hour}:${this.minute}`);
  }

  checkValidDate(d: Date): boolean {
    if (Object.prototype.toString.call(d) === "[object Date]") {
      if (isNaN(d.getTime())) {
        // d.valueOf() could also work
        // date is not valid
        return false;
      } else {
        // date is valid
        return true;
      }
    } else {
      // not a date
      return false;
    }
  }

  // every time the form control is being updated from the parent
  writeValue(value: any): void {
    if (value) {
      this.setDateValue(value);
    } else {
      this.date = null;
      this.hour = null;
      this.minute = null;
    }
  }

  // when we want to let the parent know that the value of the form control should be updated
  registerOnChange(onChange: any) {
    this.onChange = onChange;
  }

  // when we want to let the parent know that the form control has been touched
  registerOnTouched(onTouched: Function) {
    this.onTouched = onTouched;
  }

  // when the parent updates the state of the form control
  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  // to set the value of date, hour and minute

  setDateValue(value) {
    if (!value) return this.resetForm();

    this.date = new Date(value);
    this.hour = padStart(this.date.getHours(), 2, 0);
    this.minute = padStart(this.date.getMinutes(), 2, 0);

    this.date.setSeconds(0);
    this.date.setMilliseconds(0);

    this.onChange(this.date.getTime());
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  resetForm() {
    this.date = null;
    this.hour = null;
    this.minute = null;
    this.onChange(null);
  }
}
