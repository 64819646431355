import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertInlineComponent } from "@iris/alert-inline/components/alert-inline/alert-inline.component";
import { AlertInline } from "../models/alert-inline.model";

@Injectable({
  providedIn: "root",
})
export class AlertInlineService {
  constructor(private snackBar: MatSnackBar) {}

  showInlineNotification(
    displayMessage: AlertInline,
    horizontalPosition: any = "center",
    verticalPosition: any = "bottom",
    duration = 2000
  ) {
    this.snackBar.openFromComponent(AlertInlineComponent, {
      data: displayMessage,
      duration,
      horizontalPosition,
      verticalPosition,
    });
  }

  public clearNotification(): void {
    this.snackBar?.dismiss();
  }
}
