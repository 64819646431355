<mat-tab-group [selectedIndex]="currentTabIndex">
  <mat-tab label="Intake">
    <div>
      <form autocomplete="off" id="io_in_form" [formGroup]="ioIntakeForm">
        <!-- {{ ioIntakeForm.value | json }} -->
        <div class="form_container mt mr">
          <div class="f-type form_field-minWidth">
            <mat-form-field
              [hideRequiredMarker]="true"
              floatLabel="always"
              class="cp-w-100"
            >
              <mat-label>Category*</mat-label>
              <mat-select
                (selectionChange)="setInSubCat($event)"
                [value]=""
                formControlName="category"
              >
                <mat-option value="" disabled>Choose one</mat-option>
                <ng-container *ngFor="let obj of ioIntakeObj; index as i">
                  <mat-option value="{{ obj.propName }}">{{
                    obj.name
                  }}</mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="inCat.invalid && inCat.touched"
                >This field is required</mat-error
              >
            </mat-form-field>
          </div>

          <div
            class="f-sub form_field-minWidth"
            *ngIf="inCat.value != 'others' && inSubCat.length > 0"
          >
            <mat-form-field
              [hideRequiredMarker]="true"
              floatLabel="always"
              class="cp-w-100"
            >
              <mat-label>Sub category*</mat-label>
              <mat-select
                (selectionChange)="setAmtValidators($event.value)"
                [value]=""
                formControlName="subCategory"
              >
                <mat-option value="" disabled>Choose one</mat-option>
                <ng-container *ngFor="let obj of inSubCat">
                  <mat-option
                    *ngIf="
                      obj.propName == 'breastfeed'
                        ? currentPatient?.patientType == 'neonatal'
                        : true
                    "
                    value="{{ obj.propName }}"
                    >{{ obj.name }}</mat-option
                  >
                </ng-container>
              </mat-select>
              <mat-error
                *ngIf="
                  ioIntakeForm.errors &&
                  ioIntakeForm.errors.subRequired &&
                  inSub.touched
                "
                >This field is required</mat-error
              >
            </mat-form-field>
          </div>

          <div
            class="f-sub form_field-minWidth"
            *ngIf="inCat.value == 'others'"
          >
            <mat-form-field class="cp-w-100">
              <mat-label>Title</mat-label>
              <input matInput placeholder="Title" formControlName="otherSub" />
            </mat-form-field>
          </div>

          <div
            class="f-other form_field-minWidth"
            *ngIf="inSub.value == 'others'"
          >
            <mat-form-field class="cp-w-100">
              <mat-label>Others</mat-label>
              <input
                matInput
                placeholder="Others"
                formControlName="otherFeeds"
              />
            </mat-form-field>
          </div>

          <div
            class="f-medname form_field-minWidth"
            *ngIf="inCat.value == 'meds'"
          >
            <mat-form-field class="cp-w-100">
              <mat-label>Name</mat-label>
              <!-- <input matInput placeholder="Others" formControlName="medName" /> -->
              <mat-select formControlName="medName">
                <mat-option
                  [matTooltip]="med.name"
                  [value]="med.name"
                  *ngFor="let med of activeMeds"
                >
                  {{
                    med.name?.length > 40
                      ? (med.name | slice: 0:40) + "..."
                      : med.name
                  }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="f-amount form_field-maxWidth">
            <mat-form-field
              class="width--100"
              [hideRequiredMarker]="true"
              class="cp-w-100"
            >
              <mat-label>{{
                inSub.value == "breastfeed" ? "Duration*" : "Amount*"
              }}</mat-label>
              <input
                matInput
                [placeholder]="getAmtUnit()"
                formControlName="amount"
              />
              <mat-error *ngIf="inAmount.errors?.required"
                >This field is required</mat-error
              >
              <mat-error *ngIf="inAmount.errors?.max"
                >Values cant be more than 99999ml</mat-error
              >
              <mat-error *ngIf="inAmount.errors?.exceeded"
                >Hourly feed duration should be between 1 to 60</mat-error
              >
            </mat-form-field>
          </div>

          <div class="f-note form_field-maxWidth">
            <mat-form-field class="cp-w-100">
              <mat-label>Additional info</mat-label>
              <textarea
                class="cp-textarea mt form__textarea"
                matInput
                formControlName="note"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="f-note form_field-maxWidth">
            <app-date-time-picker
              class="cp-w-100"
              [minValidDate]="matMinDate"
              [maxValidDate]="matMaxDate"
              [showTime]="true"
              [showNowButton]="true"
              formControlName="date"
            ></app-date-time-picker>

            <div class="d-flex mt-2 mb-2">
              <!-- <input [disabled]="ioIntakeForm.invalid" class="cp-btn cp-hvr-secondary text-white" type="submit" value="Submit"> -->
              <button
                class="button-primary mr"
                [disabled]="ioIntakeForm.invalid || formState.loading"
                (click)="submitIoData(ioIntakeForm.value, 'intake')"
              >
                <app-loader
                  [buttonLoader]="true"
                  *ngIf="formState.loadType === 'intake'"
                ></app-loader>
                Submit
              </button>
              <div
                class="button-secondary cursor-pointer"
                (click)="resetFormUi('intake')"
              >
                Reset
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-tab>
  <mat-tab label="Output">
    <div>
      <form autocomplete="off" id="io_out_form" [formGroup]="ioOutputForm">
        <!-- {{ ioOutputForm.value | json }} -->
        <div class="form_container mt mr">
          <div class="f-cat form_field-minWidth">
            <mat-form-field
              [hideRequiredMarker]="true"
              floatLabel="always"
              class="cp-w-100"
            >
              <mat-label>Category*</mat-label>
              <mat-select
                (selectionChange)="resetIoForm('output')"
                [value]=""
                formControlName="category"
              >
                <mat-option value="" disabled>Choose one</mat-option>
                <ng-container *ngFor="let obj of ioOutputObj; index as i">
                  <mat-option value="{{ obj.propName }}">{{
                    obj.name
                  }}</mat-option>
                </ng-container>
              </mat-select>
              <mat-error *ngIf="otCat.invalid && otCat.touched"
                >This field is required</mat-error
              >
            </mat-form-field>

            <!-- <small *ngIf="otCat.invalid && otCat.touched" class="text-danger">
              This field is required
            </small> -->
          </div>

          <div
            *ngIf="otCat.value == 'others'"
            class="f-lat form_field-minWidth"
          >
            <mat-form-field class="cp-w-100">
              <mat-label>Title</mat-label>
              <input matInput placeholder="Title" formControlName="otherSub" />
            </mat-form-field>
          </div>

          <div *ngIf="otCat.value == 'drain'" class="f-lat form_field-minWidth">
            <mat-form-field floatLabel="always" class="cp-w-100">
              <mat-label>Drain type</mat-label>
              <mat-select
                (selectionChange)="opPropChanged($event, 'drain')"
                [value]=""
                formControlName="drainType"
              >
                <mat-option value="" disabled>Select</mat-option>
                <ng-container *ngFor="let drain of ioProps.drain">
                  <mat-option
                    value="{{
                      drain.name + '-' + drain.site + '-' + drain.laterality
                    }}"
                  >
                    {{ drain.name }}</mat-option
                  >
                </ng-container>
                <mat-option value="add">Add New</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="otCat.value == 'procedure'"
            class="f-lat form_field-minWidth"
          >
            <mat-form-field floatLabel="always" class="cp-w-100">
              <mat-label>Procedure type</mat-label>
              <mat-select
                id="procedureType"
                (selectionChange)="opPropChanged($event, 'procedure')"
                [value]=""
                formControlName="procedureType"
              >
                <mat-option value="" disabled>Select</mat-option>
                <ng-container *ngFor="let procedure of ioProps.procedure">
                  <mat-option
                    value="{{ procedure.name + '-' + procedure.site }}"
                  >
                    {{ procedure.name }}
                  </mat-option>
                </ng-container>
                <mat-option value="add">Add New</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div
            *ngIf="otCat.value == 'dialysis'"
            class="f-lat form_field-minWidth"
          >
            <mat-form-field floatLabel="always" class="cp-w-100">
              <mat-label>Dialysis type</mat-label>
              <mat-select
                id="dialysisType"
                (selectionChange)="opPropChanged($event, 'dialysis')"
                [value]=""
                formControlName="dialysisType"
              >
                <mat-option value="" disabled>Select</mat-option>
                <ng-container *ngFor="let dialysis of ioProps.dialysis">
                  <mat-option value="{{ dialysis.name }}">
                    {{ dialysis.name }}
                  </mat-option>
                </ng-container>
                <mat-option value="add">Add New</mat-option>
              </mat-select>
            </mat-form-field>
          </div>

          <div class="form_field-maxWidth">
            <div class="form_container">
              <div
                *ngIf="
                  otCat.value == 'drain' ||
                  otCat.value == 'procedure' ||
                  otCat.value == 'dialysis'
                "
                class="f-name form_field-minWidth"
              >
                <mat-form-field class="cp-w-100">
                  <mat-label>Name*</mat-label>
                  <input
                    [readonly]="isReadOnly"
                    matInput
                    formControlName="name"
                  />
                  <mat-error
                    *ngIf="
                      ioOutputForm.errors &&
                      ioOutputForm.errors.nameRequired &&
                      otName.touched
                    "
                    >This field is required</mat-error
                  >
                </mat-form-field>
              </div>
              <div
                *ngIf="otCat.value == 'drain' || otCat.value == 'procedure'"
                class="f-site form_field-minWidth"
              >
                <mat-form-field class="cp-w-100">
                  <mat-label>Site*</mat-label>
                  <input
                    [readonly]="isReadOnly"
                    matInput
                    formControlName="site"
                  />
                  <mat-error
                    *ngIf="
                      ioOutputForm.errors &&
                      ioOutputForm.errors.siteRequired &&
                      otSite.touched
                    "
                    >This field is required</mat-error
                  >
                </mat-form-field>
              </div>

              <div
                *ngIf="otCat.value == 'drain'"
                class="f-lat form_field-minWidth form_field-margin-bottom"
              >
                <label for="">Laterality*</label>

                <mat-button-toggle-group formControlName="laterality">
                  <mat-button-toggle value="left">Left</mat-button-toggle>
                  <mat-button-toggle value="right">Right</mat-button-toggle>
                </mat-button-toggle-group>

                <div>
                  <small
                    *ngIf="
                      ioOutputForm.errors &&
                      ioOutputForm.errors.latRequired &&
                      otLat.touched
                    "
                    class="text-danger"
                  >
                    This field is required
                  </small>
                </div>
              </div>
            </div>
          </div>

          <div class="f-amount form_field-maxWidth">
            <mat-form-field [hideRequiredMarker]="true" class="cp-w-100">
              <mat-label>Amount*</mat-label>
              <input
                [placeholder]="
                  otCat.value == 'stool' ? 'How many times?' : 'ml'
                "
                [type]="otCat.value == 'stool' ? 'text' : 'number'"
                matInput
                formControlName="amount"
              />
              <mat-error *ngIf="otAmount.errors?.required"
                >This field is required</mat-error
              >
              <mat-error *ngIf="otAmount.errors?.max"
                >Values cant be more than 99999ml</mat-error
              >
            </mat-form-field>
          </div>

          <div class="f-note form_field-maxWidth">
            <mat-form-field class="cp-w-100">
              <mat-label>Additional info</mat-label>
              <textarea
                matInput
                class="cp-textarea mt form__textarea"
                formControlName="note"
              ></textarea>
            </mat-form-field>
          </div>

          <div class="f-note form_field-maxWidth">
            <app-date-time-picker
              class="cp-w-100"
              [minValidDate]="matMinDate"
              [maxValidDate]="matMaxDate"
              [showTime]="true"
              [showNowButton]="true"
              formControlName="date"
            ></app-date-time-picker>
            <div class="d-flex mt-2 mb-2">
              <!-- <input [disabled]="ioOutputForm.invalid" class="cp-btn cp-hvr-secondary text-white" type="submit" value="Submit"> -->
              <button
                type="submit"
                (click)="submitIoData(ioOutputForm.value, 'output')"
                mat-flat-button
                class="button-primary mr"
                [disabled]="ioOutputForm.invalid || formState.loading"
              >
                <app-loader
                  [buttonLoader]="true"
                  *ngIf="formState.loadType === 'output'"
                ></app-loader>
                Submit
              </button>
              <!--edited by Jitesh on 31/7/19 changed intake to output in now and resetFormUI functions below-->
              <div
                class="button-secondary cursor-pointer"
                (click)="resetFormUi('output')"
              >
                Reset
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  </mat-tab>
</mat-tab-group>
