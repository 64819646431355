<section class="cosign">
  <div class="cosign__header">Co-signer contact details</div>
  <div class="cosign__content">
    <div class="cosign__content--title">
      You are co-signing this note with the intensivist. Please select their
      name to send a request.
    </div>
    <mat-form-field floatLabel="always">
      <mat-label></mat-label>
      <input
        matInput
        autocomplete="off"
        [formControl]="authorCosign"
        [matAutocomplete]="auto"
        placeholder="Enter name"
      />
      <mat-autocomplete
        (optionSelected)="onSelectionChanged($event)"
        #auto="matAutocomplete"
      >
        <mat-option
          *ngFor="let cosignIntensivist of cosignIntensivists"
          style="height: auto"
          (click)="getcosignIntensivistDetails(cosignIntensivist)"
          ><div class="cosign__content--option-class">
            <app-avatar
              [avatarParams]="[
                { name: cosignIntensivist.name, size: AVATAR_SIZE.L }
              ]"
            ></app-avatar>
            <div class="cosign__content--option-class__sub-sect-dropdown">
              <div>{{ cosignIntensivist.author }}</div>
              <small>{{ cosignIntensivist.email }}</small>
            </div>
          </div>
        </mat-option>
      </mat-autocomplete>
    </mat-form-field>
    <div *ngIf="!!noUserMsg" class="error-text">No user found</div>
    <ng-template [ngIf]="userInfo">
      <div class="cosign__content--option-class">
        <app-avatar
          [avatarParams]="[{ name: userInfo.name, size: AVATAR_SIZE.L }]"
        ></app-avatar>
        <div class="cosign__content--option-class__sub-sect">
          {{ userInfo.author }}
        </div>
        <div class="cosign__content--option-class__right-section-user">
          Author
        </div>
      </div>
    </ng-template>
    <ng-template [ngIf]="selectedIntensivist">
      <div class="cosign__content--option-class">
        <app-avatar
          [avatarParams]="[
            { name: selectedIntensivist.name, size: AVATAR_SIZE.L }
          ]"
        ></app-avatar>
        <div class="cosign__content--option-class__sub-sect">
          {{ selectedIntensivist.author }}
        </div>
        <div
          *ngIf="selectedIntensivist.status"
          class="cosign__content--option-class__right-section-intensivist"
        >
          {{
            selectedIntensivist.status === "pending"
              ? "Co-sign " + (selectedIntensivist.status | titlecase)
              : (selectedIntensivist.status | titlecase)
          }}
          <span
            [matTooltip]="selectedIntensivist.rejectedMsg"
            *ngIf="selectedIntensivist.rejectedMsg"
            ><mat-icon color="warn">info</mat-icon></span
          >
          <span class="cursor-pointer" (click)="remove()"
            ><mat-icon color="warn">close</mat-icon></span
          >
        </div>
      </div>
    </ng-template>
  </div>
  <mat-divider></mat-divider>
  <div class="cosign__footer" mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="button-tertiary">
      Cancel
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      class="button-primary"
      (click)="onSubmit()"
      [disabled]="!selectedIntensivist"
    >
      Submit
      <!-- <app-loader
        [buttonLoader]="true"
        *ngIf="loading$ | async"
      ></app-loader> -->
    </button>
  </div>
</section>
