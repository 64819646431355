import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  QueryList,
  ViewChild,
  ViewChildren,
} from "@angular/core";
import { SiblingService } from "src/app/services/sibling.service";
import { select, Store } from "@ngrx/store";
import * as fromRoot from "src/app/reducers/index";
import { Observable } from "rxjs";
import { ActionTypes } from "src/app/store/actions/order/index";
import { Order } from "../../../../models/Order.model";
import { OrderService } from "../../../../services/order.service";
import * as fromOrder from "../../../../store/reducers/order";
import * as fromPatientHeader from "src/app/store/reducers/patient-chart/patient-header";
import { UntypedFormControl, UntypedFormGroup } from "@angular/forms";
import { CpContextMenuService } from "../../../../services/cp-context-menu.serivce";
import { take } from "rxjs/operators";
import { DaysPassedPipe } from "../../../../shared/daysPassedPipe/daysPassed.pipe";
import { linkedOrderTooltip } from "../orders-view.component";
import { MatDialog } from "@angular/material/dialog";
import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { NgxPermissionsService } from "ngx-permissions";
import { MatAccordion } from "@angular/material/expansion";
import { NON_DRUG_CLASS_TO_TEXT_MAP } from "../order-view.data";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";
import { Patient } from "src/app/models/patient";

@Component({
  selector: "app-order-panel",
  templateUrl: "./order-panel.component.html",
  styleUrls: ["./order-panel.component.scss"],
})
export class OrderPanelComponent extends UserRolesMap implements OnInit {
  @ViewChildren("orderSearch") orderSearchElements: QueryList<ElementRef>;
  @Input() category;
  @Input() state;
  @Input() activeProtocols?: string[];
  @Input() filterControls;
  @Input()
  get highlightText(): string {
    return this._highlightText;
  }
  set highlightText(highlightText: string) {
    this._highlightText = highlightText;
    this.findSearchText();
  }
  private _highlightText: string;
  @Input() currentPatient?: Patient;
  @Output() discontinue: EventEmitter<any> = new EventEmitter<any>();
  @Output() clickViewDetails: EventEmitter<any> = new EventEmitter<any>();
  public sortMed;
  public sortblood;
  public sortlab;
  public sortdiet;
  public sortprocedure;
  public sortvent;
  public sortcommunication;
  public orders$ = this.store.pipe(select(fromOrder.getOrders));
  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData)
  );
  public ordersView;

  public editing$: Observable<boolean>;
  public getICUDischargeDate$ = this.store.pipe(
    select(fromPatientHeader.getICUDischargeDate)
  );
  public states = ["red", "yellow", "green", "completed"];

  public linkedOrderTooltip = linkedOrderTooltip;
  public selectedOrder;
  public showDiscontinue: boolean;
  public disContinueReason = [
    "Adverse effect",
    "Contraindicated",
    "Duplicate",
    "Doctor review",
    "Financial reasons",
    "In error",
    "Other",
  ];
  public disContinueReasonStatement: String;
  public selectedDisContinueReason = [];
  public discontinueForm: UntypedFormGroup;
  public isDischargeTimeElapsed = false;
  public nonDrugOrderTableColumns: string[] = [
    "type",
    "orderName",
    "brandname",
    "days",
    "dosage",
    "frequency",
    "route",
    "otherInfo",
    "startDate",
    "status",
    "viewDetails",
    "edit-discontinue",
  ];
  spanningColumns = ["type"];
  DrugSpans = [];
  NonDrugSpans = [];
  public DrugOrderTableColumns: string[] = [
    "atcClass",
    "orderName",
    "brandname",
    "days",
    "dosage",
    "frequency",
    "route",
    "otherInfo",
    "startDate",
    "status",
    "viewDetails",
    "edit-discontinue",
  ];
  public nonDrugClassToTextMap = NON_DRUG_CLASS_TO_TEXT_MAP;
  step = 0;
  constructor(
    private _siblingService: SiblingService,
    public store: Store<fromRoot.AppState>,
    public orderService: OrderService,
    private cpContextMenuService: CpContextMenuService,
    private daysPassedPipe: DaysPassedPipe,
    private dialog: MatDialog,
    private ngxPermissionsService: NgxPermissionsService,
    private cdr: ChangeDetectorRef
  ) {
    super();
    // this.cacheSpan('type', d => d.type);

    this.editing$ = store.select(fromOrder.getInputTabActive);
  }
  cacheSpanDrug(key, accessor) {
    this.DrugSpans = [];
    for (let i = 0; i < this.ordersView.length; ) {
      let currentValue = accessor(this.ordersView[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.ordersView.length; j++) {
        if (currentValue != accessor(this.ordersView[j])) {
          break;
        }

        count++;
      }

      if (!this.DrugSpans[i]) {
        this.DrugSpans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.DrugSpans[i][key] = count;
      i += count;
    }
  }
  cacheSpanNonDrug(key, accessor) {
    this.NonDrugSpans = [];
    for (let i = 0; i < this.ordersView.length; ) {
      let currentValue = accessor(this.ordersView[i]);
      let count = 1;

      // Iterate through the remaining rows to see how many match
      // the current value as retrieved through the accessor.
      for (let j = i + 1; j < this.ordersView.length; j++) {
        if (currentValue != accessor(this.ordersView[j])) {
          break;
        }

        count++;
      }

      if (!this.NonDrugSpans[i]) {
        this.NonDrugSpans[i] = {};
      }

      // Store the number of similar values that were found (the span)
      // and skip i to the next unique row.
      this.NonDrugSpans[i][key] = count;
      i += count;
    }
  }
  getRowSpanOfDrug(col, index) {
    return this.DrugSpans[index] && this.DrugSpans[index][col];
  }
  getRowSpanOfNOnDrug(col, index) {
    return this.NonDrugSpans[index] && this.NonDrugSpans[index][col];
  }
  ngOnInit() {
    this.orders$.subscribe((orders) => {
      this.mergeDiffOrderTypes(orders[this.category]);
    });

    this.showDiscontinue =
      (!!this.ngxPermissionsService.getPermission("discontinue_order") &&
        !!!this.ngxPermissionsService.getPermission(
          "mark_to_discontinue_order"
        )) ||
      this.category !== "active";

    this.discontinueForm = new UntypedFormGroup({
      reasonForDiscontinue: new UntypedFormControl(""),
    });
    this.isDischargeTimeElapsed = this.state.isDischargeTimeElapsed;
  }

  /**
   * @description finds the highlighText from all orders
   *  -> if found get the top position of element
   *  -> if top position is greater than 800 then only scroll
   * @variables_used highlightText - search text received from parent component
   *                 orderSearchElements - contains array of all elements of orders/class/brand from DOM
   * @author Rajat Saini
   * @date Jan 2, 2023
   */
  findSearchText() {
    if (!this.highlightText || !this.highlightText?.length) return;

    let foundItem = this.orderSearchElements?.find((ele) => {
      return ele.nativeElement.innerText
        ?.toLowerCase()
        .includes(this.highlightText);
    });
    if (!foundItem) return;

    const { top: currTopPosiOfEle } =
      foundItem.nativeElement.getBoundingClientRect();
    if (currTopPosiOfEle < 800) return;

    foundItem.nativeElement.scrollIntoView({
      behavior: "smooth",
      block: "start",
    });
  }

  /** 
   @description To Check if margin should be applied to status text.
   @Note Margin is given to the status text to allign it with status column in table 
  */
  get isStatusMargin(): Boolean {
    const isCommnucationControls = this.state?.communication,
      isMargin = !isCommnucationControls;
    return isMargin;
  }

  get patientName(): string {
    return this.currentPatient?.lastName
      ? this.currentPatient?.name + " " + this.currentPatient?.lastName
      : this.currentPatient?.name;
  }

  /** Merging different order types in a single array */

  mergeDiffOrderTypes(orders) {
    this.ordersView = [];
    for (const key of Object.keys(orders)) {
      if (key !== "activeProtocols" && key !== "count") {
        if (orders[key].length) {
          orders[key].forEach((order) => {
            order["last"] = false;
            switch (order.state) {
              case "red":
                order["status"] = "Not communicated";
                break;

              case "yellow":
                order["status"] = "Communicated";
                break;

              case "green":
                order["status"] = "Active";
                break;

              default:
                order["status"] = "";
            }
          });

          this.ordersView.push(...orders[key]);
        }
      }
      this.sortMed = [];
      this.sortblood = [];
      this.sortlab = [];
      this.sortdiet = [];
      this.sortprocedure = [];
      this.sortvent = [];
      this.sortcommunication = [];

      this.ordersView.forEach((order) => {
        switch (order.type) {
          case "medications":
            this.sortMed.push(order);
            break;

          case "bloods":
            this.sortblood.push(order);
            this.sortblood.sort(this.compareAtoZNames);
            break;

          case "labs":
            this.sortlab.push(order);
            this.sortlab.sort(this.compareAtoZNames);
            break;
          case "diets":
            this.sortdiet.push(order);
            this.sortdiet.sort(this.compareAtoZNames);
            break;
          case "procedures":
            this.sortprocedure.push(order);
            this.sortprocedure.sort(this.compareAtoZNames);
            break;
          case "vents":
            this.sortvent.push(order);
            this.sortvent.sort(this.compareAtoZNames);
            break;
          case "communications":
            this.sortcommunication.push(order);
            this.sortcommunication.sort(this.compareAtoZNames);
            break;
          default:
            order["sortId"] = "";
        }
      });
      if (this.sortblood.length > 0)
        this.sortblood[this.sortblood.length - 1]["last"] = true;
      if (this.sortlab.length > 0)
        this.sortlab[this.sortlab.length - 1]["last"] = true;
      if (this.sortdiet.length > 0)
        this.sortdiet[this.sortdiet.length - 1]["last"] = true;
      if (this.sortprocedure.length > 0)
        this.sortprocedure[this.sortprocedure.length - 1]["last"] = true;
      if (this.sortvent.length > 0)
        this.sortvent[this.sortvent.length - 1]["last"] = true;
      if (this.sortcommunication.length > 0)
        this.sortcommunication[this.sortcommunication.length - 1]["last"] =
          true;

      let Antimicrobials = [];
      let Inotropes_Vasopressors = [];
      let Cardiovascular_drugs = [];
      let CNS_drugs = [];
      let DVT_prophylaxis = [];
      let Analgesics_and_Sedatives = [];
      let Anti_Diabetics = [];
      let IV_fluids = [];
      let Bronchodilators = [];
      let PPI_GI_ulcer_prophylaxis = [];
      let Steroids = [];
      let others = [];
      this.sortMed.forEach((order) => {
        order["last"] = false;
        if (order.atcClass == "none" || order.atcClass == undefined) {
          order.atcClass = "Others";
        }
        switch (order.atcClass) {
          case "Antimicrobials":
            Antimicrobials.push(order);
            Antimicrobials.sort(this.compareAtoZNames);
            break;

          case "Inotropes/Vasopressors":
            Inotropes_Vasopressors.push(order);
            Inotropes_Vasopressors.sort(this.compareAtoZNames);
            break;

          case "Cardiovascular drugs":
            Cardiovascular_drugs.push(order);
            Cardiovascular_drugs.sort(this.compareAtoZNames);
            break;
          case "CNS drugs":
            CNS_drugs.push(order);
            CNS_drugs.sort(this.compareAtoZNames);
            break;
          case "DVT prophylaxis":
            DVT_prophylaxis.push(order);
            DVT_prophylaxis.sort(this.compareAtoZNames);
            break;
          case "Analgesics and Sedatives":
            Analgesics_and_Sedatives.push(order);
            Analgesics_and_Sedatives.sort(this.compareAtoZNames);
            break;
          case "Anti-Diabetics":
            Anti_Diabetics.push(order);
            Anti_Diabetics.sort(this.compareAtoZNames);
            break;
          case "IV fluids":
            IV_fluids.push(order);
            IV_fluids.sort(this.compareAtoZNames);
            break;
          case "Bronchodilators":
            Bronchodilators.push(order);
            Bronchodilators.sort(this.compareAtoZNames);
            break;
          case "PPI/GI ulcer prophylaxis":
            PPI_GI_ulcer_prophylaxis.push(order);
            PPI_GI_ulcer_prophylaxis.sort(this.compareAtoZNames);
            break;
          case "Steroids":
            Steroids.push(order);
            Steroids.sort(this.compareAtoZNames);
            break;

          default:
            others.push(order);
            others.sort(this.compareAtoZNames);
        }
      });
      if (Antimicrobials.length > 0)
        Antimicrobials[Antimicrobials.length - 1]["last"] = true;
      if (Inotropes_Vasopressors.length > 0)
        Inotropes_Vasopressors[Inotropes_Vasopressors.length - 1]["last"] =
          true;
      if (Cardiovascular_drugs.length > 0)
        Cardiovascular_drugs[Cardiovascular_drugs.length - 1]["last"] = true;
      if (CNS_drugs.length > 0) CNS_drugs[CNS_drugs.length - 1]["last"] = true;
      if (DVT_prophylaxis.length > 0)
        DVT_prophylaxis[DVT_prophylaxis.length - 1]["last"] = true;
      if (Analgesics_and_Sedatives.length > 0)
        Analgesics_and_Sedatives[Analgesics_and_Sedatives.length - 1]["last"] =
          true;
      if (Anti_Diabetics.length > 0)
        Anti_Diabetics[Anti_Diabetics.length - 1]["last"] = true;
      if (IV_fluids.length > 0) IV_fluids[IV_fluids.length - 1]["last"] = true;
      if (Bronchodilators.length > 0)
        Bronchodilators[Bronchodilators.length - 1]["last"] = true;
      if (PPI_GI_ulcer_prophylaxis.length > 0)
        PPI_GI_ulcer_prophylaxis[PPI_GI_ulcer_prophylaxis.length - 1]["last"] =
          true;
      if (Steroids.length > 0) Steroids[Steroids.length - 1]["last"] = true;
      if (others.length > 0) others[others.length - 1]["last"] = true;

      this.sortMed = [
        ...Antimicrobials,
        ...Inotropes_Vasopressors,
        ...Cardiovascular_drugs,
        ...CNS_drugs,
        ...DVT_prophylaxis,
        ...Bronchodilators,
        ...PPI_GI_ulcer_prophylaxis,
        ...Steroids,
        ...others,
      ];

      this.ordersView = [
        ...this.sortMed,
        ...this.sortblood,
        ...this.sortlab,
        ...this.sortdiet,
        ...this.sortprocedure,
        ...this.sortvent,
        ...this.sortcommunication,
      ];
    }
    this.ordersView.forEach((element) => {
      if (element.type == "medications") {
        this.cacheSpanDrug("atcClass", (d) => d.atcClass);
      } else {
        this.cacheSpanNonDrug("type", (d) => d.type);
      }
    });
  }
  compareAtoZNames(a, b) {
    if (
      (a.type == "bloods" ||
        b.type == "bloods" ||
        a.type == "communications" ||
        b.type == "communications") &&
      a.title != undefined &&
      b.title != undefined
    ) {
      const name1 = a.title.toUpperCase();
      const name2 = b.title.toUpperCase();
      if (name1 < name2) {
        return -1;
      }
      if (name1 > name2) {
        return 1;
      }
      return 0;
    } else if (a.name != undefined && b.name != undefined) {
      const name1 = a.name.toUpperCase();
      const name2 = b.name.toUpperCase();
      if (name1 < name2) {
        return -1;
      }
      if (name1 > name2) {
        return 1;
      }
      return 0;
    }
  }
  //nurse actions based on order state
  nextAction(order, content, isBedSide) {
    let actionNumber =
      this.states.findIndex((elem) => {
        return elem === order.state;
      }) + 1;
    let action = this.states[actionNumber];
    if (isBedSide) {
      action = "completed";
    }
    if (action != "completed") {
      order.state = action;
      order.action = action;
      this.store.dispatch({ type: ActionTypes.communicate, payload: order });
    } else {
      this.openDialog(content, order, 400);
    }
  }

  previousAction(order) {
    let actionNumber =
      this.states.findIndex((elem) => {
        return elem === order.state;
      }) - 1;
    let action = this.states[actionNumber];
    order.state = action;
    order.action = action;
    this.store.dispatch({ type: ActionTypes.communicate, payload: order });
  }

  completeOrder() {
    let orderProps = {
      id: this.selectedOrder._id,
      type: this.selectedOrder.type,
      fromCategory: "active",
      toCategory: "completed",
    };
    this.store.dispatch({ type: ActionTypes.move, payload: orderProps });
  }

  //modify communication order
  modify(diet) {
    //use sibiling service to notify the component to load
    this._siblingService.sendResetFlag("true");
    this._siblingService.modifyOrder(diet);
  }

  signed(diet): any {
    let orderProps = {
      id: diet._id,
      type: diet.type,
      category: "pending",
      signed: this.state.title + " " + this.state.name,
    };
    this.store.dispatch({ type: ActionTypes.sign, payload: orderProps });

    return false;
  }

  // On click  of the discontinue order-reason
  onOptionClick(name: String) {
    let index = this.selectedDisContinueReason.indexOf(name);
    if (index > -1) {
      this.selectedDisContinueReason.splice(index, 1);
    } else {
      this.selectedDisContinueReason.push(name);
    }
  }

  disContinue(): any {
    const discontinuedSelections = this.sortDicontinuedArray(
      this.selectedDisContinueReason,
      this.disContinueReason
    );

    let discontinue = {
      reasons: discontinuedSelections,
      statement: this.discontinueForm.value.reasonForDiscontinue,
    };

    if (
      this.state.type === "active" &&
      (this.state.role == this.NURSE ||
        this.state.role == this.CCA ||
        this.state.role == this.PA ||
        this.state.role == this.CCN ||
        this.state.role == this.DIETITIAN ||
        this.state.role == this.PHARMACOLOGIST)
    ) {
      this.selectedOrder["toBeDiscarded"] = true;
      this.selectedOrder["discontinue"] = discontinue;
      this.store.dispatch({
        type: ActionTypes.callUpdate,
        payload: this.selectedOrder,
      });
    } else {
      const orderProps = {
        id: this.selectedOrder._id,
        type: this.selectedOrder.type,
        category: this.selectedOrder.category,
        discontinue: discontinue,
      };
      this.discontinue.emit(orderProps);
    }
    return false;
  }

  revert(diet) {
    if (
      this.state.type == "activeD" &&
      !!this.ngxPermissionsService.getPermission("discontinue_order") &&
      !!!this.ngxPermissionsService.getPermission("mark_to_discontinue_order")
    ) {
      diet["toBeDiscarded"] = false;
      this.store.dispatch({
        type: ActionTypes.callUpdate,
        payload: diet,
      });
    }
  }

  onClose() {
    this.selectedDisContinueReason = [];
    if (!this.selectedOrder.toBeDiscarded) {
      this.selectedOrder.discontinueReasons = [];
    }
  }

  checkIfOrderExistInProtocol(diet: Order, activeProtocols: string[]): boolean {
    return this.orderService.checkIfOrderExistInProtocol(diet, activeProtocols);
  }

  public onContextMenu($event: MouseEvent, data: any): void {
    this.getICUDischargeDate$.pipe(take(1)).subscribe((dischargeDate) => {
      const dayPassed = this.daysPassedPipe.transform(
        data,
        this.state.type,
        true,
        dischargeDate
      );

      this.cpContextMenuService.onContextMenu($event, {
        content: { ...data, day: dayPassed.day },
        type: "order:diet",
      });
    });
  }

  /**
   * Method Returns sorted elements
   *
   * @desc
   *  Sorting Array based on given array
   *
   * @param selectedElements - All selected elements to be sorted
   * @param sortedArray - Order of sorted array is based on this array
   */

  public sortDicontinuedArray(
    selectedElements = [],
    sortedArray = []
  ): string[] {
    if (!Array.isArray(selectedElements) || !Array.isArray(sortedArray))
      return [];
    let selectedArray = [];
    selectedArray = selectedElements.sort(function (a, b) {
      return sortedArray.indexOf(a) - sortedArray.indexOf(b);
    });
    return selectedArray;
  }

  /*
   * NAME: reOrderOrder
   * PURPOSE: Reorder an existing order. Opens up order input window with pre-filled order.
   * DESCRIPTION:
   * PARAMS: order - order that will be reordered
   * RETURNS: void
   * USED BY: template
   * CREATED DATE: 30/07/20
   * AUTHOR: Gunjit Agrawal
   */
  reOrderOrder(order): void {
    // orderInHospital is a little hack to make sure the order is newly created.
    // orderInHospital was assigned to used for 'Order In Hospital' btn
    // currently reusing for reorder also.

    // set startTime to current date and endTime on null on reordering
    order.startTime = null;
    order.endTime = null;
    order.startNow = null;
    order.skipSchedule = [];
    this._siblingService.sendResetFlag("true");
    this._siblingService.modifyOrder({
      ...order,
      pta: false,
      orderInHospital: true,
      protocol: null,
    });
  }

  orderToHospital(med) {
    // delete med._id;
    // med.pta = false;
    this._siblingService.modifyOrder({
      ...med,
      pta: false,
      orderInHospital: true,
    });
  }

  /**
   * Fires clickViewDetails event to view order details.
   *
   * @param {Object} diet - diet order
   * @fires {self:clickViewDetails}
   */
  clickDetailsBtn(order): void {
    this.clickViewDetails.emit({
      ...order,
      orderName: order.name,
      category: this.category,
    });
  }

  openDialog(modalRef, order, width): void {
    this.selectedOrder = order;
    this.selectedDisContinueReason =
      this.selectedOrder && this.selectedOrder.discontinueReasons
        ? [...this.selectedOrder.discontinueReasons]
        : [];
    this.discontinueForm.patchValue({
      reasonForDiscontinue: this.selectedOrder?.discontinueReasonStatement
        ? this.selectedOrder.discontinueReasonStatement
        : "",
    });

    this.dialog.open(modalRef, {
      width: `${width}px`,
      autoFocus: false,
    });
  }

  isDisabled(e) {
    return e?.scrollWidth <= e?.clientWidth || false;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  setStep(index: number) {
    if (this.sortMed.length == 0) {
      this.step = 1;
    } else {
      this.step = index;
    }
  }

  nextStep() {
    this.step++;
  }

  prevStep() {
    this.step--;
  }
}
