import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AlertInlineComponent } from "./components/alert-inline/alert-inline.component";

import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";
import { MatSnackBarModule } from "@angular/material/snack-bar";
@NgModule({
  declarations: [AlertInlineComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatDialogModule,
    MatIconModule,
    MatSnackBarModule,
  ],
  exports: [AlertInlineComponent],
})
export class AlertInlineModule {}
