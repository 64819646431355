import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { LabelBadgeComponent } from "./components/label-badge/label-badge.component";

@NgModule({
  declarations: [LabelBadgeComponent],
  imports: [CommonModule],
  exports: [LabelBadgeComponent],
})
export class LabelBadgeModule {}
