import { Injectable } from "@angular/core";
import { UntypedFormGroup, ValidationErrors } from "@angular/forms";
import moment from "moment";

@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor() {}

  hasError(group: any, controlName: string, errorName: string): boolean {
    if (!!controlName) return group.controls[controlName].hasError(errorName);
    // for nested controlller inside group
    else {
      return group.errors && group.errors[errorName]; // for group errors
    }
  }

  // Validation to make sure that end date & time is always 5 mins ahead of start date & time
  endTimeValidator(group): ValidationErrors | null {
    const getDateTime = (dateTimeGroup: UntypedFormGroup): number => {
      if (!dateTimeGroup?.value) return null;
      const { date, minute, hour } = dateTimeGroup.value,
        dateTimeObj = moment(date);
      return dateTimeObj
        .set({ hour: hour, minute: minute, second: 0, millisecond: 0 })
        .valueOf();
    };
    const startDateTime = getDateTime(group.get("startTime"));
    const endDateTime = getDateTime(group.get("endTime"));
    if (endDateTime < startDateTime) {
      return { endDateLesser: true };
    }

    return null;
  }

  getDataUri(url, callback) {
    let image = new Image();
    image.src = `${url}?r=${Math.floor(Math.random() * 100000)}`;
    image.setAttribute("crossOrigin", "anonymous"); //getting images from external domain
    image.onload = function () {
      let canvas = document.createElement("canvas");
      canvas.width = image.width;
      canvas.height = image.height;

      let ctx = canvas.getContext("2d");
      ctx.clearRect(0, 0, canvas.width, canvas.height);
      ctx.drawImage(image, 0, 0);
      try {
        callback(canvas.toDataURL("image/jpeg"));
      } catch (e) {
        console.log(e);
        callback(null);
      }
    };
  }

  setdate(slot: string, date: Date) {
    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    const formatDateString = `${year}-${month}-${day} ${slot}`;
    return new Date(formatDateString).toISOString();
  }
}
