<div class="orders-input-comp pr-2 blur-bg">
  <div class="blur-bg" *ngIf="getOrderablesLoading$ | async">
    <div class="spinner">
      <mat-spinner></mat-spinner>
    </div>
  </div>

  <div class="order-search-row">
    <app-order-search
      (search)="onSearch($event)"
      type="patient"
      class="w-100"
      [enableRequest]="true"
      [userName]="user?.title ? user.title + ' ' + user.name : user.name"
    >
    </app-order-search>
  </div>
  <mat-button-toggle-group
    class="button-toggle-secondary border"
    [value]="orderableType"
  >
    <mat-button-toggle (click)="onOrderTypeSelect('diet')" [value]="'diet'">
      Diet order
    </mat-button-toggle>

    <mat-button-toggle (click)="onOrderTypeSelect('blood')" [value]="'blood'">
      Transfusion order
    </mat-button-toggle>

    <mat-button-toggle
      (click)="onOrderTypeSelect('procedure')"
      [value]="'procedure'"
    >
      Procedure order
    </mat-button-toggle>

    <mat-button-toggle (click)="onOrderTypeSelect('vents')" [value]="'vents'">
      Vent order
    </mat-button-toggle>
  </mat-button-toggle-group>

  <div
    class="orderable-title"
    [matTooltip]="orderable.name.length > 50 ? orderable.name : ''"
    matTooltipClass="cp-mat-tooltip"
    *ngIf="!showOrderableSelect() && orderable && inputSelected === 'orderable'"
  >
    {{ orderable.name }}
  </div>
  <div
    *ngIf="
      formType === 'new' &&
      inputSelected === 'orderable' &&
      orderable &&
      (selectedBrand != undefined || selectedBrand != null)
    "
  >
    <div
      class="orderable-brandName"
      [matTooltip]="selectedBrand?.length > 50 ? selectedBrand : ''"
      matTooltipClass="cp-mat-tooltip"
    >
      {{ selectedBrand | titlecase }}
    </div>
    <div><hr style="margin-bottom: 0rem" /></div>
  </div>

  <div
    class="orderable-title"
    [matTooltip]="
      value[orderFormService.getFieldRelatedToOrderableName(orderableType)]
        ?.length > 50
        ? value[orderFormService.getFieldRelatedToOrderableName(orderableType)]
        : ''
    "
    matTooltipClass="cp-mat-tooltip"
    *ngIf="
      formType === 'edit' && value && orderableType && orderableType !== 'vents'
    "
  >
    {{ value[orderFormService.getFieldRelatedToOrderableName(orderableType)] }}
  </div>
  <div
    *ngIf="
      formType === 'edit' &&
      value &&
      (value?.brandName != undefined || value?.brandName != null)
    "
  >
    <div
      class="orderable-brandName"
      [matTooltip]="selectedBrand?.length > 50 ? selectedBrand : ''"
      matTooltipClass="cp-mat-tooltip"
    >
      {{ selectedBrand | titlecase }}
    </div>
    <div><hr style="margin-bottom: 0rem" /></div>
  </div>
  <div class="select-order-preset-cont">
    <div class="select-order-cont" *ngIf="showOrderableSelect()">
      <label class="name-label">Name</label>

      <mat-select
        class="select-input order-select"
        placeholder="Choose Order"
        (selectionChange)="onOrderableSelect($event.value)"
        [value]="orderable?._id"
      >
        <!-- <option disabled [selected]="!orderable" value="">Choose Order</option> -->
        <mat-option
          *ngFor="let localOrderable of orderables"
          [value]="localOrderable._id"
          [selected]="orderable && orderable._id === localOrderable._id"
        >
          {{ localOrderable.name }}
        </mat-option>
      </mat-select>
    </div>

    <div
      class="select-preset-cont"
      *ngIf="
        inputSelected === 'orderable' &&
        orderable &&
        orderable.presets &&
        orderable.presets.length > 0
      "
    >
      <label class="order-label">Presets</label>
      <mat-select
        class="order-select"
        [value]="presetShortcut"
        (selectionChange)="onPresetSelect($event.value)"
      >
        <!-- <mat-option disabled value="">Choose Preset</mat-option> -->
        <mat-option
          *ngFor="let preset of orderable.presets"
          [value]="preset._id"
          [selected]="
            (value && value._id === preset._id) ||
            presetName === preset.presetName
          "
        >
          {{ preset.presetName ? preset.presetName : "&lt; No Name &gt;" }}
        </mat-option>
        <mat-option value="-1" [selected]="!value || !presetName"
          >No Preset</mat-option
        >
      </mat-select>
    </div>
  </div>

  <div
    class="preset-shortcut-cont"
    *ngIf="inputSelected === 'orderable' && orderable?.presets?.length > 0"
  >
    <mat-button-toggle-group
      class=""
      [(ngModel)]="presetShortcut"
      *ngIf="
        checkIfPresetsContainShortcutKey(orderable.presets);
        else elseDisplayShortcut
      "
    >
      <ng-container *ngFor="let preset of orderable.presets">
        <mat-button-toggle
          [value]="preset._id"
          (click)="onPresetSelect(preset._id)"
          *ngIf="preset.displayAsShortcut && preset.presetName"
        >
          <div>
            <span
              *ngIf="
                preset.patientType == 'pediatric' ||
                preset.patientType == 'neonatal'
              "
              ><button
                [ngClass]="[
                  preset.patientType == 'neonatal' ? 'neonatal' : 'pediatric'
                ]"
              >
                <strong
                  *ngIf="preset.patientType == 'neonatal'"
                  class="neonatalText"
                  >N</strong
                >
                <strong
                  *ngIf="preset.patientType == 'pediatric'"
                  class="pediatricText"
                  >P</strong
                >
              </button> </span
            ><span>{{
              preset.presetName ? preset.presetName : "&lt; No Name &gt;"
            }}</span>
          </div>
        </mat-button-toggle>
      </ng-container>
    </mat-button-toggle-group>
    <ng-template #elseDisplayShortcut>
      <mat-button-toggle-group [(ngModel)]="presetShortcut">
        <ng-container *ngFor="let preset of orderable.presets; let i = index">
          <mat-button-toggle
            [value]="preset._id"
            (click)="onPresetSelect(preset._id)"
            *ngIf="i < 3 && preset.presetName"
          >
            <div>
              <span
                *ngIf="
                  preset.patientType == 'pediatric' ||
                  preset.patientType == 'neonatal'
                "
                ><button
                  [ngClass]="[
                    preset.patientType == 'neonatal' ? 'neonatal' : 'pediatric'
                  ]"
                >
                  <strong
                    *ngIf="preset.patientType == 'neonatal'"
                    class="neonatalText"
                    >N</strong
                  >
                  <strong
                    *ngIf="preset.patientType == 'pediatric'"
                    class="pediatricText"
                    >P</strong
                  >
                </button> </span
              ><span> {{ preset.presetName }}</span>
            </div>
          </mat-button-toggle>
        </ng-container>
      </mat-button-toggle-group>
    </ng-template>
  </div>

  <hr
    *ngIf="
      inputSelected === 'orderable' &&
      orderable &&
      orderable.presets &&
      orderable.presets.length > 0
    "
  />

  <div
    *ngIf="
      inputSelected === 'orderable' &&
      ((formType === 'new' && orderable) || formType === 'edit') &&
      orderableType
    "
  >
    <app-order-form
      [orderableType]="orderableType"
      [orderable]="orderable"
      [user]="user"
      [formType]="formType"
      [value]="value"
      [orderInHospital]="orderInHospital"
      [currentPatient]="currentPatient"
      [loading]="orderFormLoading$ | async"
      (selectOrderable)="onSearch($event)"
      (submitted)="onSubmit($event)"
      (cancel)="open($event, cancel)"
      (formChange)="onFormChange($event)"
    >
    </app-order-form>
  </div>

  <div *ngIf="inputSelected === 'protocol' && protocol">
    <app-protocol-form
      [protocol]="protocol"
      [user]="user"
      [loading]="protocolFormLoading$ | async"
      [currentPatient]="currentPatient"
      (cancel)="open($event, cancel)"
      (submitted)="onProtocolSubmit($event)"
    >
    </app-protocol-form>
  </div>

  <ng-template #cancel let-modal>
    <div class="modal-popup">
      <div class="header">
        <img src="assets/icons/warning_24px.svg" />
        <h2 class="modal-title" id="modal-basic-title">
          <b>Attention</b>
        </h2>
      </div>
      <div class="body">
        Changes made to this order will be lost. <br />
        Do you want to continue?
      </div>
      <div class="footer">
        <button matDialogClose mat-flat-button class="button-secondary">
          Cancel
        </button>
        <button
          matDialogClose
          mat-flat-button
          (click)="resetOrder()"
          class="button-error"
        >
          Continue
        </button>
      </div>
    </div>
  </ng-template>
</div>
