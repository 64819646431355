import { createReducer, on, Action } from "@ngrx/store";

import * as notesAction from "src/app/store/actions/patient-chart/notes/notes.actions";
import * as rootActions from 'src/app/store/actions/root.actions';

export interface State {
    finalNotes: [],
    draftNotes: [],
    codeSheet: [],
    codeSheetDraft: {},
    codeSheetPdf: []
}

export const initialState: State = {
    finalNotes: [],
    draftNotes: [],
    codeSheet: [],
    codeSheetDraft: {},
    codeSheetPdf: []
}

const noteReducer = createReducer(
    initialState,
    on(
        notesAction.setNotes,
        (state, { notes }) => ({ ...state, ...notes })
    ),
    on(
        rootActions.logout,
        (state) => (initialState)
    ),
    on(
        rootActions.patientChartReset,
        (state) => (initialState)
    )
)

export function reducer(state: State | undefined, action: Action) {
    return noteReducer(state, action);
}