import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, mergeMap, catchError, switchMap, delay } from "rxjs/operators";
import { of, EMPTY, merge } from "rxjs";

import * as vitalsActions from "../actions/vitals.actions";
import { VitalsService } from "src/app/services/vitals.service";

@Injectable()
export class VitalsEffects {
  constructor(
    private actions$: Actions,
    private vitalsService: VitalsService
  ) {}

  getVitals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vitalsActions.getVitals),
      switchMap(({ CPMRN, encounters }) =>
        this.vitalsService.getVitals(CPMRN, encounters).pipe(
          map((vitals) => {
            return vitalsActions.setVitals({ vitals: vitals["data"]["days"] });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  updateVitals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(vitalsActions.updateVitals),
      switchMap(({ CPMRN, encounters, vitalData }) =>
        this.vitalsService.updateVitals(CPMRN, encounters, vitalData).pipe(
          switchMap((vitals: any) =>
            merge(
              of(vitalsActions.submitSuccess()),
              of(vitalsActions.setClickedDay({ day: vitals.vitals }))
            )
          ),
          catchError((error) => {
            console.log(error.error.message);
            return of(
              vitalsActions.submitFail({
                error: error?.error?.message ? error.error.message : error,
              })
            );
          })
        )
      )
    )
  );
}
