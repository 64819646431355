import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { IrisDesignSystemComponent } from "./iris-design-system/iris-design-system.component";
import { HammerModule } from "@angular/platform-browser";
import { AvatarModule } from "./avatar";
import { BreadcrumbModule } from "./breadcrumb";
import { BtnTagModule } from "./btn-tag";
import { ZoomPanContainerModule } from "./zoom-pan-container";
import { CarouselModule } from "./carousel";
import { ChoiceListModule } from "./choice-list";
import { ConfimDialogModule } from "./confim-dialog";
import { CounterModule } from "./counter";
import { LabelBadgeModule } from "./label-badge";
import { LoaderModule } from "./loader";
import { OverlayModule } from "./overlay";
import { PopoverModule } from "./popover";
import { ProgressBarModule } from "./progress-bar";
import { AlertModule } from "./alert";
import { DateTimePickerModule } from "./date-time-picker";
import { AlertInlineModule } from "./alert-inline";

import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatButtonModule } from "@angular/material/button";
import { MatTabsModule } from "@angular/material/tabs";
import { MatSortModule } from "@angular/material/sort";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatPaginatorModule } from "@angular/material/paginator";
import { MatRippleModule } from "@angular/material/core";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatExpansionModule } from "@angular/material/expansion";
import { MatDatepickerModule } from "@angular/material/datepicker";
import { MatButtonToggleModule } from "@angular/material/button-toggle";
import { MatTableModule } from "@angular/material/table";
import { MatDividerModule } from "@angular/material/divider";
import { MatDialogModule } from "@angular/material/dialog";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatChipsModule } from "@angular/material/chips";
import { PortalModule } from "@angular/cdk/portal";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatMenuModule } from "@angular/material/menu";
import { AppointmentCardModule } from "../shared-modules/features/appointment-card/appointment-card.module";
import { MutiselectChiplistModule } from "./mutiselect-chiplist/mutiselect-chiplist.module";
import { MedicalCardModule } from "../smart-hospital/shared/modules/medic-cards/medical-card.module.";
import { UploadWebcamImageModule } from "../shared-modules/features/upload-webcam-image/upload-webcam-image.module";
import { IrisTreeCheckboxModule } from "./iris-tree-checkbox/iris-tree-checkbox.module";
import { StatusChipModule } from "./status-chip/status-chip.module";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { SelectAndInputOthersModule } from "./select-and-input-others/select-and-input-others.module";

const CustomComponents = [
  AlertModule,
  AlertInlineModule,
  AvatarModule,
  BreadcrumbModule,
  BtnTagModule,
  CarouselModule,
  ZoomPanContainerModule,
  ChoiceListModule,
  ConfimDialogModule,
  CounterModule,
  LabelBadgeModule,
  LoaderModule,
  OverlayModule,
  PopoverModule,
  ProgressBarModule,
  DateTimePickerModule,
  AppointmentCardModule,
  MutiselectChiplistModule,
  MedicalCardModule,
  UploadWebcamImageModule,
  IrisTreeCheckboxModule,
  StatusChipModule,
  SelectAndInputOthersModule,
];

const MaterialComponents = [
  MatIconModule,
  MatInputModule,
  ReactiveFormsModule,
  MatSelectModule,
  MatFormFieldModule,
  MatButtonModule,
  MatTabsModule,
  MatSortModule,
  MatAutocompleteModule,
  MatPaginatorModule,
  MatRippleModule,
  MatTooltipModule,
  MatDatepickerModule,
  MatButtonToggleModule,
  MatTableModule,
  MatDividerModule,
  MatDialogModule,
  MatCheckboxModule,
  MatExpansionModule,
  MatChipsModule,
  PortalModule,
  MatSlideToggleModule,
  MatMenuModule,
  MatProgressSpinnerModule,
];

@NgModule({
  declarations: [IrisDesignSystemComponent],
  imports: [
    CommonModule,
    ...CustomComponents,
    ...MaterialComponents,
    HammerModule,
    FormsModule,
  ],

  exports: [CommonModule, ...MaterialComponents, ...CustomComponents],
})
export class IrisComponentsModule {}
