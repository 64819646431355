import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { IrisTreeCheckboxListComponent } from "./iris-tree-checkbox-list/iris-tree-checkbox-list.component";
import { MatTreeModule } from "@angular/material/tree";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatButtonModule } from "@angular/material/button";
import { MatButtonToggleModule } from "@angular/material/button-toggle";

@NgModule({
  declarations: [IrisTreeCheckboxListComponent],
  imports: [
    CommonModule,
    MatTreeModule,
    FormsModule,
    ReactiveFormsModule,
    MatIconModule,
    MatSelectModule,
    MatCheckboxModule,
    MatInputModule,
    MatButtonModule,
    MatButtonToggleModule,
  ],
  exports: [IrisTreeCheckboxListComponent],
})
export class IrisTreeCheckboxModule {}
