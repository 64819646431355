import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  NgZone,
  ElementRef,
  ViewChild,
  OnChanges,
  SimpleChanges,
} from "@angular/core";
import { Router } from "@angular/router";

import Compressor from "compressorjs";
import { Store, select } from "@ngrx/store";
import { ProgressBar } from "src/app/models/file/ProgressBar.model";
import { BackendError } from "src/app/models/backend-error.model";
import { DomSanitizer } from "@angular/platform-browser";
import { FileService } from "src/app/services/file.service";
import { dataURLtoFile } from "src/app/support-functions/base64ToFile";

import * as chatActions from "src/app/patient/chat/store/actions/chat.actions";
import * as fromChatIndex from "src/app/patient/chat/store/reducers";
import * as fromChatDocuments from "src/app/labs-scans-module/store/reducers";
import { ChatService } from "src/app/patient/chat/services/chat.service";
import { faImage } from "@fortawesome/free-regular-svg-icons";
import {
  faCheckCircle,
  faFilePdf,
  faFolderPlus,
  faPaperclip,
  faPaperPlane,
  faRedoAlt,
  faSearchPlus,
  faTimes,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
// import { Socket } from 'ngx-socket-io';
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { socket } from "../../../config/socket";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";
import { PatientService } from "src/app/services/patient.service";
import { OverlayComponent } from "@iris/overlay/components/overlay/overlay.component";
import { FileObj } from "@iris/carousel";
import { UserRolesMap } from "src/app/shared/accessControl/roleInterface";

const validFileSize = 20971520;
const validFileMime = ["image/jpeg", "image/png", "application/pdf"];
const fileValidationErrors = {
  type: "Invalid file type - Only pdf, png, jpeg, jpg are allowed",
  size: "File size is larger than 20MB",
};

@Component({
  selector: "app-chat",
  templateUrl: "./chat.component.html",
  styleUrls: ["./chat.component.scss"],
})
export class ChatComponent extends UserRolesMap implements OnInit, OnChanges {
  private unsubscribe$: Subject<any> = new Subject<any>();

  public chatMessagesOriginal: any = [];
  public chatMessages: any = [];

  // debounce timer
  public inDebounce;

  @Input() user;
  @Input() currPatient;
  @Input()
  set chatData(chatData) {
    if (chatData) {
      this.chatMessagesOriginal = chatData;
      this.chatMessages = this.chatMessagesOriginal;
      this.setAvatarParams();
    }
  }

  @Input()
  chatType: string = "patient-chart";

  @ViewChild("overlayComp") overlayComp: OverlayComponent;

  @Input()
  unit: any = {};

  @Output() inputPopup: EventEmitter<any> = new EventEmitter<any>();
  @Output() unitScrollEnd: EventEmitter<any> = new EventEmitter<any>();

  @ViewChild("chatEnterArea", { static: false })
  chatEnterArea: ElementRef;

  public chatStatus$ = this.store.pipe(
    select(fromChatIndex.getChatStatus),
    takeUntil(this.unsubscribe$)
  );
  public chatReciepts$ = this.store.pipe(
    select(fromChatIndex.getChatReceipts),
    takeUntil(this.unsubscribe$)
  );
  public chatDraft$ = this.store.pipe(
    select(fromChatIndex.getDraftChat),
    takeUntil(this.unsubscribe$)
  );
  public chatMinimised$ = this.store.pipe(
    select(fromChatIndex.getChatMinimised),
    takeUntil(this.unsubscribe$)
  );

  public chatNotificationStatus$ = this.store.pipe(
    select(fromChatIndex.getNotificationStatus),
    takeUntil(this.unsubscribe$)
  );
  public chatDocs$ = this.store.pipe(
    select(fromChatDocuments.getDocs),
    takeUntil(this.unsubscribe$)
  );
  public chatTabOpen$ = this.store.pipe(
    select(fromChatIndex.getChatTabOpen),
    takeUntil(this.unsubscribe$)
  );

  public chatMessage: string = null;

  public attchObj = [];
  public attachmentFiles: ProgressBar[] = [];
  public fileValidationError: string | null = null;
  public backendError: BackendError[] | null = null;
  public chatReciept: any;
  public lastReadPoint: number = 0;
  public allReadUsers: any = [];
  public showNotification: boolean;
  public showUnreadMessage: boolean;
  public chatTabOpen: boolean;
  public isDischargeTimeElapsed: boolean;

  /* ICONS */
  faImage = faImage;
  faFilePdf = faFilePdf;
  faFolderPlus = faFolderPlus;
  faSearchPlus = faSearchPlus;
  faCheckCircle = faCheckCircle;
  faPaperClip = faPaperclip;
  faPaperPlane = faPaperPlane;
  faTimes = faTimes;
  faRedoAlt = faRedoAlt;
  faTrashAlt = faTrashAlt;

  avatarParamsArr: AvatarParams[][] = [];

  constructor(
    private router: Router,
    private store: Store<{}>,
    private sanitisor: DomSanitizer,
    private _fileService: FileService,
    private ngZone: NgZone,
    private _chatService: ChatService,
    private _patientService: PatientService
  ) {
    super();
  }

  ngOnInit() {
    // when we switch to chat tab, we go to bottom of chat
    this.chatTabOpen$.subscribe((isOpen) => {
      this.chatTabOpen = isOpen;
      if (isOpen) {
        if (this.showNotification) this.setUnreadMessagePoint();
        else {
          this.showUnreadMessage = false;
          this.goToBottomOfChat();
        }
        this.store.dispatch(chatActions.hideNotification());
        this.socketUpdateLastSeen();
      }
    });

    this.chatNotificationStatus$.subscribe((showNotif) => {
      this.showNotification = showNotif;

      if (this.chatTabOpen && showNotif) {
        this.socketUpdateLastSeen();
      }
    });

    // subscribe to chat status
    this.chatStatus$.subscribe((data) => {
      if (data["chatSubmitSuccess"]) {
        this.attchObj = [];
        this.chatMessage = null;

        let attachmentField = <HTMLInputElement>(
          document.getElementById("attachmentField")
        );
        if (attachmentField) {
          attachmentField.value = "";
        }

        this.goToBottomOfChat();
      }
    });

    // get chat reciepts
    this.chatReciepts$.subscribe((data) => {
      if (data.length) {
        this.allReadUsers = data;

        // get the read reciept of current user
        this.chatReciept = data.find((reciept) => {
          return reciept.email == this.user.email;
        });
      }
    });

    // focus on the elem
    if (this.chatEnterArea) this.chatEnterArea.nativeElement.focus();

    // get draft
    this.chatDraft$.subscribe((draft) => {
      if (draft) this.chatMessage = draft;
      else this.chatMessage = null;
    });

    if (this.chatType === "unit-chat") {
      this.setUnreadMessagePoint();
      this.socketUpdateLastSeen();
    }
  }

  ngAfterViewInit() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes?.currPatient && this.chatType === "patient-chart") {
      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(this.currPatient);
    }
  }

  setAvatarParams() {
    if (this.avatarParamsArr.length === 0) {
      this.chatMessages.forEach((chat) => {
        this.avatarParamsArr.push([
          {
            name: chat.senderName,
            size: AVATAR_SIZE.L,
          },
        ]);
      });
    } else {
      this.avatarParamsArr.push([
        {
          name: this.chatMessages[this.chatMessages.length - 1].senderName,
          size: AVATAR_SIZE.L,
        },
      ]);
    }
  }

  goToMessageReplied(refMsg, index) {
    let refTime = new Date(refMsg.timestamp).getTime();
    let indexToScroll = -1;

    for (let i = index; i >= 0; i--) {
      const chat = this.chatMessages[i];
      let chatTime = new Date(chat.timestamp).getTime();

      if (chatTime == refTime) {
        indexToScroll = i;
        break;
      }
    }

    if (indexToScroll >= 0) {
      let pointer = document.getElementById("chat-no-" + indexToScroll);
      if (pointer) {
        let topVal = pointer.offsetTop;
        this.goToBottomOfChat(topVal);

        // set border
        pointer.style.background = "#9ad1d8";
        pointer.style.transitionProperty = "background";
        pointer.style.transitionDuration = "1s";

        setTimeout(() => {
          pointer.style.background = "none";
        }, 1000);
      }
    }
  }

  socketUpdateLastSeen() {
    let delay = 100;
    const lastChat = this.chatMessages[this.chatMessages.length - 1];
    clearTimeout(this.inDebounce);
    this.inDebounce = setTimeout(() => {
      let userChatReciept = this.allReadUsers.filter(
        (rec) => rec.email == this.user.email
      );

      if (this.chatMessages && this.chatMessages.length) {
        let userReciept: any;
        let emitLastSeen = true;

        if (!userChatReciept[0]) {
          userReciept = {
            name: this.user.name,
            email: this.user.email,
            lastRead: this.chatMessages[this.chatMessages.length - 1].timestamp,
          };
        } else {
          if (
            new Date(userChatReciept[0].lastRead).getTime() ==
            new Date(
              this.chatMessages[this.chatMessages.length - 1].timestamp
            ).getTime()
          ) {
            emitLastSeen = false;
          }

          userReciept = {
            ...userChatReciept[0],
            lastRead: this.chatMessages[this.chatMessages.length - 1].timestamp,
            name: this.user.name,
          };
        }

        if (emitLastSeen) {
          this.store.dispatch(
            chatActions.setLastSeen({ user: this.user, lastChat })
          );
          socket.emit("setLastSeenOfUser", {
            chatType: this.chatType,
            unit: {
              hospitalName: this.unit.hospitalInfo?.name,
              unitName: this.unit.name,
            },
            CPMRN: this.currPatient.CPMRN,
            encounters: this.currPatient.encounters,
            readReciept: userReciept,
          });
        }
      }
    }, delay);
  }

  public refMessage = null;
  replyToThisMessage(chat) {
    this.refMessage = chat;
    this.chatEnterArea.nativeElement.focus();
  }

  setUnreadMessagePoint() {
    // get the index last seen
    let foundIndex = -1;
    if (this.chatReciept && this.chatReciept.lastRead) {
      for (let i = this.chatMessages.length - 1; i >= 0; i--) {
        const chat = this.chatMessages[i];

        if (
          new Date(chat.timestamp).getTime() <=
          new Date(this.chatReciept.lastRead).getTime()
        ) {
          foundIndex = i;
          break;
        }
      }
    }

    // set the pos based on found index
    if (foundIndex >= 0) {
      this.chatMessages = JSON.parse(JSON.stringify(this.chatMessagesOriginal));
      if (foundIndex < this.chatMessages.length - 1) {
        this.chatMessages[foundIndex]["new"] = true;
        this.showUnreadMessage = true;
      }
    } else {
      this.chatMessages = JSON.parse(JSON.stringify(this.chatMessagesOriginal));
    }

    // set the scroll position
    setTimeout(() => {
      let pointer = document.getElementById("unread_message_point");
      if (pointer) {
        let topVal = pointer.offsetTop;
        this.goToBottomOfChat(topVal);
      } else {
        this.goToBottomOfChat();
      }
    }, 0);
  }

  cancelReply() {
    this.refMessage = null;
  }

  goToBottomOfChat(val = null) {
    let objDiv = document.getElementById("chat-area");

    if (objDiv) {
      let pos = val;
      if (!val) {
        pos = objDiv.scrollHeight;
      }

      objDiv.scrollTop = pos;
    }
  }

  public isRecieptOpen: Boolean = false;
  public clickedMessage: any = null;
  openReadReciepts(chat) {
    let readBy = this.allReadUsers.filter((user) => {
      return (
        new Date(user.lastRead).getTime() >= new Date(chat.timestamp).getTime()
      );
    });

    this.isRecieptOpen = true;
    this.clickedMessage = { ...chat, readBy: readBy };
  }

  addToDocs(chat) {
    // minimise the popup
    this.inputPopup.emit(false);
    // passing chat id for RPA
    const imageData = {
      _id: chat._id,
      key: chat.attachmentLink.key,
      link: chat.image,
      fileType: chat.attachmentLink.fileType,
      name: chat.attachmentLink.name,
      size: chat.attachmentLink.size,
    };

    // set the image key in store
    this.store.dispatch(chatActions.addToDoc({ imageData }));

    // navigate to scans and labs
    this.router.navigate([
      "patient",
      this.currPatient.CPMRN,
      this.currPatient.encounters,
      "labs",
    ]);
    this.chatDocs$.subscribe((data) => {
      chat.documentAdded = data.documentAdded;
    });
  }

  openDocInput() {
    this.attchObj = [];
    document.getElementById("attachmentField").click();
  }

  resetErrorMessages(): void {
    this.fileValidationError = null;
    this.backendError = null;
  }

  public attachNames: string = "";
  upload(files: File[], uploadType = "File") {
    // open the modal
    this.isViewAttchOpen = true;

    if (files.length > 5) {
      // show error
    } else {
      for (let i = 0; i < files.length; i++) {
        let doc = files[i];

        if (doc["size"] > validFileSize) {
          this.fileValidationError = fileValidationErrors.size;
        } else if (validFileMime.indexOf(doc["type"]) === -1) {
          this.fileValidationError = fileValidationErrors.type;
        } else {
          this.resetErrorMessages();
          let reader = new FileReader();
          reader.readAsDataURL(doc);

          reader.onload = async (_event) => {
            const result: any = await reader.result;

            this.attchObj.push({
              id: Date.now(),
              file: doc,
              name: doc["name"],
              type: doc["type"],
              size: doc["size"],
              fileUrl: result,
            });

            this.attachNames += `${doc["name"]} `;

            const docIndex = this.attchObj.length - 1;

            if (doc["type"].split("/")[1] === "pdf") {
              this.attchObj[docIndex]["fileUrl"] =
                this.sanitisor.bypassSecurityTrustResourceUrl(result);
            }
          };
        }
      }
    }
  }

  rotateBase64Image90deg(base64Image, isClockwise, index) {
    //create an off-screen canvas
    let offScreenCanvas = document.createElement("canvas");
    let offScreenCanvasCtx = offScreenCanvas.getContext("2d");

    // cteate Image
    var img = new Image();
    img.src = base64Image;

    // set its dimension to rotated size
    offScreenCanvas.height = img.width;
    offScreenCanvas.width = img.height;

    // rotate and draw source image into the off-screen canvas:
    if (isClockwise) {
      offScreenCanvasCtx.rotate((90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(0, -offScreenCanvas.width);
    } else {
      offScreenCanvasCtx.rotate((-90 * Math.PI) / 180);
      offScreenCanvasCtx.translate(-offScreenCanvas.height, 0);
    }
    offScreenCanvasCtx.drawImage(img, 0, 0);

    // encode image to data-uri with base64
    this.attchObj[index]["fileUrl"] = offScreenCanvas.toDataURL(
      "image/jpeg",
      100
    );
    this.attchObj[index]["rotate"] = true;
  }

  clearAttachments() {
    this.isViewAttchOpen = false;
    this.attchObj = [];

    let attachmentField = <HTMLInputElement>(
      document.getElementById("attachmentField")
    );
    if (attachmentField) {
      attachmentField.value = "";
    }
  }
  private tempPdfsLoaded = [];
  downloadImage(chat) {
    this._fileService
      .getFile(chat.attachmentLink.key)
      // this._documentService.downloadImage(this.currPatient.CPMRN, chat.attachmentLink.key)
      .subscribe((data) => {
        let fileType = chat.attachmentLink["fileType"];

        if (data.status === "success") {
          let dataToAdd = data.data;
          if (fileType == "application/pdf") {
            this.tempPdfsLoaded.push(chat);
            dataToAdd =
              this.sanitisor.bypassSecurityTrustResourceUrl(dataToAdd);
          }

          chat["image"] = dataToAdd;
          chat["imageLoaded"] = true;
          this.store.dispatch(chatActions.updateChat({ message: chat }));
        }
      });
  }

  public isViewAttchOpen: Boolean = false;

  ngOnDestroy() {
    // remove scroll event listener
    // let chatArea = document.getElementById("chat-area");
    // chatArea.removeEventListener('scroll', this.scroll, true);

    // save draft
    this.overlayComp.closeModal();
    if (this.chatType == "patient-chart") {
      if (this.chatMessage && this.chatMessage.trim() != "") {
        this.saveChatDraft(this.chatMessage.trim());
      } else {
        this.saveChatDraft("");
      }
    }

    // sanitize pdfs
    if (this.tempPdfsLoaded.length) {
      this.tempPdfsLoaded.forEach((chat) => {
        chat.imageLoaded = false;
        chat.image = null;
        this.store.dispatch(chatActions.updateChat({ message: chat }));
      });
      this.tempPdfsLoaded = [];
    }

    // unsubscribe
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  saveChatDraft(message) {
    this.store.dispatch(chatActions.saveDraftChat({ message }));
  }

  private delay;
  scroll = (event: any): void => {
    clearTimeout(this.delay);

    this.delay = setTimeout(() => {
      let windowHeight = event.target.scrollTop + event.target.offsetHeight;
      let scrollHeight = event.target.scrollHeight;

      if (windowHeight == scrollHeight) {
        this.ngZone.run(() => {
          // set last seen
          this.socketUpdateLastSeen();
        });
      }
    }, 500);
  };

  public zoomedChat: FileObj[] = [];
  openZoomedView(chat) {
    if (chat?.attachmentLink.fileType == "application/pdf") {
      this.zoomedChat = [
        {
          fileType: "pdf",
          pdfUrl: chat["image"],
          name: chat?.attachmentLink?.name,
        },
      ];
    } else {
      this.zoomedChat = [
        {
          fileType: "image",
          imgData: chat["image"],
          name: chat?.attachmentLink?.name,
        },
      ];
    }
    this.overlayComp.openModal();
  }

  public closeZoomedView(): void {
    this.overlayComp.closeModal();
  }

  detectKey(event) {
    let key = event.keyCode;

    if (key == 13) {
      this.chatType == "patient-chart"
        ? this.submitMessagePC()
        : this.submitMessageUL();
    }
  }

  // strike off message
  public isStrikeOffOpen: boolean = false;
  openStrikeOffModal(val, message = null) {
    this.isStrikeOffOpen = val;
    this.clickedMessage = message;
  }
  strikeOffMessage() {
    if (this.clickedMessage) {
      if (this.chatType == "patient-chart") {
        delete this.clickedMessage.image;
        this.clickedMessage.imageLoaded = false;
        // this.store.dispatch(chatActions.strikeOffPcChat({CPMRN:this.currPatient.CPMRN, encounters: this.currPatient.encounters, message: this.clickedMessage}))
        this._chatService
          .strikeOffPatChat(
            this.currPatient.CPMRN,
            this.currPatient.encounters,
            this.clickedMessage
          )
          .subscribe(
            (data) => {
              this.isStrikeOffOpen = false;
            },
            (err) => {
              console.log(err);
            }
          );
      } else {
        // this.store.dispatch(chatActions.strikeOffUnitChat({unit: {unitName: this.unit.name, hospitalName: this.unit.hospitalName, _id: this.unit._id}, message: this.clickedMessage}));
        this._chatService
          .strikeOffUnitChat(
            {
              unitName: this.unit.name,
              hospitalName: this.unit.hospitalInfo?.name,
              _id: this.unit._id,
            },
            this.clickedMessage
          )
          .subscribe(
            (data) => {
              this.isStrikeOffOpen = false;
            },
            (err) => {
              console.log(err);
            }
          );
      }
    }
  }

  // Patient chart codes
  submitMessagePC() {
    if (!this.chatMessage || (this.chatMessage && !this.chatMessage.trim())) {
      return false;
    }
    // set the action
    this.store.dispatch(chatActions.submitChatStart());

    let messageToSend = {
      message: this.chatMessage.trim(),
      attachmentLink: {},
      timestamp: null,
      // senderName: this.user.name,
      // senderRole: this.user.role,
      // senderEmail: this.user.email,
      device: "RADAR",
      priority: null,
      isDeleted: false,
      refMessage: null,
    };

    // check if reply to
    if (this.refMessage) {
      messageToSend.refMessage = {
        senderName: this.refMessage.senderName,
        message: this.refMessage.message,
        timestamp: this.refMessage.timestamp,
      };
    }

    if (this.attchObj.length) {
      this.attchObj.forEach(async (file, index) => {
        let compressedFile;
        file.name = file?.name;
        let fileData = file.file;

        if (file.type.toLowerCase() !== "application/pdf") {
          if (file["rotate"]) {
            fileData = dataURLtoFile(file.fileUrl, file.name);
          }

          // // image compression logic start
          compressedFile = await new Promise((resolve, reject) => {
            new Compressor(fileData, {
              quality: 0.6,
              success(result) {
                resolve(result);
              },
              error(err) {
                reject(null);
                console.log(err.message);
              },
            });
          });

          if (compressedFile) {
            fileData = new File([compressedFile], file.name, {
              type: file.type,
            });
          }
          // // image compression logic end
        }

        this._fileService
          .getSignedUrl({
            id: file.id,
            name: file.name,
            size: file.size,
            type: file.type,
          })
          .subscribe((signedData) => {
            if (signedData["status"] == "success") {
              // upload file
              this._fileService
                .uploadFile(fileData, signedData["data"]["url"], file.type)
                .subscribe((data) => {
                  if (data && data.type == 4) {
                    // attach key
                    messageToSend.attachmentLink = {
                      key: signedData["data"]["key"],
                      name: file.name,
                      size: file.size,
                      fileType: file.type,
                    };

                    // attach message for the last index
                    messageToSend.message = null;
                    if (index == this.attchObj.length - 1) {
                      messageToSend.message = this.chatMessage.trim();
                    }

                    // close modal
                    this.isViewAttchOpen = false;

                    // dispatch action
                    this.store.dispatch(
                      chatActions.sendMessage({
                        CPMRN: this.currPatient.CPMRN,
                        encounters: this.currPatient.encounters,
                        message: messageToSend,
                      })
                    );
                  }
                });
            }
          });
      });
    } else {
      // dispatch action
      this.store.dispatch(
        chatActions.sendMessage({
          CPMRN: this.currPatient.CPMRN,
          encounters: this.currPatient.encounters,
          message: messageToSend,
        })
      );
    }

    this.refMessage = null;
  }

  // Unit List codes
  submitMessageUL() {
    if (!this.chatMessage || (this.chatMessage && !this.chatMessage.trim())) {
      return false;
    }

    // set the action
    this.store.dispatch(chatActions.submitChatStart());

    let messageToSend = {
      message: this.chatMessage.trim(),
      attachmentLink: {},
      timestamp: null,
      // senderName: this.user.name,
      // senderRole: this.user.role,
      // senderEmail: this.user.email,
      device: "RADAR",
      priority: null,
      isDeleted: false,
      refMessage: null,
    };

    // check if reply to
    if (this.refMessage) {
      messageToSend.refMessage = {
        senderName: this.refMessage.senderName,
        message: this.refMessage.message,
        timestamp: this.refMessage.timestamp,
      };
    }

    this.store.dispatch(
      chatActions.unitSendMessage({ unit: this.unit, message: messageToSend })
    );

    this.refMessage = null;
  }
}
