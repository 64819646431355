export const environment = {
  production: false,
  apiUrl: "https://radar-360.cloudphysicianworld.org/api/",
  devResUrl: "https://radar-360.cloudphysicianworld.org/api/",
  ptzApiUrl: "https://radar-360.cloudphysicianworld.org/api/",
  socketUrl: "https://radar-360.cloudphysicianworld.org/",
  recaptchaKey: "6Le9MMAUAAAAAACoh3Dqm6siCb5ewnZ_svviFTbJ",
  passwordSecretKey: "CloudPhysician",
  wikiDomain: "api-public.sandbox.cloudphysicianworld.org",
  s3region: "ap-south-1",
  socketPath: "/api/CP-EMR",
  s3bucket: "emr-dev-public/radar-360/",
};
