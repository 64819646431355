import { Component, Input } from "@angular/core";
import { OrderService } from "../../../services/order.service";
import { MedPreset } from "../../../models/preset/MedPreset.model";
import { MedService } from "../../../services/order/med.service";

@Component({
  selector: "app-protocol-med-view",
  template: `
    <div class="view">
      <age-group-view
        *ngIf="med.patientType"
        [ageGroup]="med.patientType"
        [id]="med._id"
      ></age-group-view>
      <div class="name"><strong>Name: </strong> {{ med.name }}</div>
      <!-- <div class="name">
        <strong>Brand name: </strong> {{ med.brandName | titlecase }}
      </div> -->
      <div class="">
        <strong>Quantity/Unit: </strong> {{ med.quantity }} {{ med.unit }}
      </div>
      <div class="" *ngIf="med.maxDose">
        <strong>Max Dose: </strong> {{ med.maxDose }}
      </div>
      <div class=""><strong>Route: </strong> {{ med.route }}</div>
      <div class="" *ngIf="med.numberOfDoses">
        <strong>Doses: </strong>{{ med.numberOfDoses }}
      </div>
      <div class="" *ngIf="med.frequency?.fType">
        <strong>Frequency: </strong>
        <span>{{ orderService.getFrequencyDisplayName(med.frequency) }}</span>
      </div>
      <div class="" *ngIf="med.form"><strong>Form: </strong>{{ med.form }}</div>
      <div class="" *ngIf="med.urgency">
        <strong>Urgency: </strong>{{ med.urgency }}
      </div>
      <div class="" *ngIf="med.concentration">
        <strong>Concentration: </strong>
        <span
          >{{ med.concentration?.value }} {{ med.concentration?.unit }}</span
        >
      </div>
      <div class="" *ngIf="med.bodyWeight">
        <strong>Body Weight: </strong>
        <span>{{ med.bodyWeight }}</span>
      </div>
      <div
        class=""
        *ngIf="
          med?.concentration?.value && medService?.getRate(med)?.rate as rate
        "
      >
        <strong>Rate: </strong>
        <span class="mr-1">{{ rate.min }}</span>
        <span *ngIf="rate.max" class="mr-1"> - {{ rate.max }}</span>
        <span>(ml/hr)</span>
      </div>
      <div
        class=" combination"
        *ngIf="med.combination && med.combination.length > 0"
      >
        <hr />
        <strong>Combination Med</strong>
        <div class="combination__med row" *ngFor="let comb of med.combination">
          <div class="">
            <span><strong>Name: </strong>{{ comb.name }}</span>
          </div>
          <div class="">
            <span
              ><strong>Quantity/Unit: </strong>{{ comb.quantity }}
              {{ comb.unit }}</span
            >
          </div>
        </div>
        <hr />
      </div>

      <div class=" details" *ngIf="med.sosReason">
        <strong>SOS Reason</strong>:
        <em>{{ med.sosReason }}</em>
      </div>

      <div class=" details" *ngIf="med.instructions">
        <strong>Instructions</strong>:
        <em>{{ med.instructions }}</em>
      </div>

      <div class=" details" *ngIf="med.additionalInformation">
        <strong>Additional Information</strong>:
        <em>{{ med.additionalInformation }}</em>
      </div>
    </div>
  `,
  styleUrls: ["./protocol-view.scss"],
})
export class ProtocolMedViewComponent {
  @Input() med: MedPreset;

  constructor(
    public orderService: OrderService,
    public medService: MedService
  ) {}
}
