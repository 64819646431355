import { Injectable } from "@angular/core";
import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { ApiResponse } from "../models/api-response.model";
import { UserInterface } from "../models/user";

@Injectable({
  providedIn: "root",
})
export class UserService {
  env = environment;

  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  updateOnCallStatus(data): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "users/updateOnCallStatus", data)
      .pipe(catchError(this.errorHandler));
  }

  getOnCallUsers(data): Observable<any> {
    return this.http
      .get<any>(this.env.apiUrl + "users/getOnCallUsers", data)
      .pipe(catchError(this.errorHandler));
  }

  getUserByHospital(data: { roles: string[] }): Observable<UserInterface[]> {
    return this.http
      .post<ApiResponse>(this.env.apiUrl + "users/get", data)
      .pipe(
        catchError(this.errorHandler),
        map((userData) => userData.data)
      );
  }
}
