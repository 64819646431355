import { Injectable, Pipe, PipeTransform } from "@angular/core";

const day = 1.15741e-8;

@Pipe({
  name: "icuDays",
})
@Injectable({ providedIn: "root" })
export class IcuDaysPipe implements PipeTransform {
  transform(value: string | Date, dischargeDate: any = null): string {
    let admitDate = new Date(value).setHours(0, 0, 0, 0);

    let endDate = new Date().setHours(0, 0, 0, 0);
    if (dischargeDate) {
      endDate = new Date(dischargeDate).setHours(0, 0, 0, 0);
    }

    const days = Math.round((endDate - admitDate) * day) + 1;
    return days === 1 ? `${days} day` : `${days} days`;
  }
}
