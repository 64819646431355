import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import * as fromUserIndex from "src/app/store/reducers/user/index";
import * as fromHospitalIndex from "src/app/store/reducers/hospitals/index";
import * as fromUnitIndex from "src/app/store/reducers/units/index";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  Validators,
} from "@angular/forms";
import { ECallService } from "src/app/services/e-call.service";
import { AlertService } from "@iris/alert/services/alert.service";
import { MatDialogRef } from "@angular/material/dialog";
import { HospitalService } from "src/app/services/hospital.service";
import { HospitalList } from "src/app/models/hospital-list";
import { Hospital } from "src/app/models/hospital";

@Component({
  selector: "app-e-call-popup",
  templateUrl: "./e-call-popup.component.html",
  styleUrls: ["./e-call-popup.component.scss"],
})
export class ECallPopupComponent implements OnInit, OnDestroy {
  @Input() contextName: string;
  private unsubscribe$ = new Subject();

  public hospitals$ = this.store.pipe(
    select(fromHospitalIndex.getHospitals),
    takeUntil(this.unsubscribe$)
  );

  public units$ = this.store.pipe(
    select(fromUnitIndex.getUnits),
    takeUntil(this.unsubscribe$)
  );

  public isCommandCenterUser$ = this.store.pipe(
    select(fromUserIndex.isCommandCenterUser),
    takeUntil(this.unsubscribe$)
  );
  bedDeviceId: string;

  get isContentLoaded() {
    return !this.isLoader;
  }

  get displayHospitalSelection() {
    return !this.form.get(this.FORM_CONTROL_NAME.hospitalName)?.value;
  }

  get displayUnitSelection() {
    return !this.displayHospitalSelection;
  }

  get displayBedSelection() {
    return this.unitBeds.length;
  }

  get hospitalName() {
    return this.form.get(
      this.FORM_CONTROL_NAME.hospitalName
    ) as UntypedFormControl;
  }

  get unitName() {
    return this.form.get(this.FORM_CONTROL_NAME.unitName) as UntypedFormControl;
  }

  get bedNo() {
    return this.form.get(this.FORM_CONTROL_NAME.bedNo) as UntypedFormControl;
  }

  public ECallPopupContext: any = {
    ECall: "eCall",
    CodeBlue: "CodeBlue",
  };

  private ContextName = {
    eCall: "call",
    CodeBlue: "code_blue",
  };

  public FORM_CONTROL_NAME: any = {
    hospitalName: "hospitalName",
    unitName: "unitName",
    bedNo: "bedNo",
  };

  public isLoader: boolean = true;
  public form;
  public hospitals: Hospital[] = [];
  private allUnits = [];
  showSearchHosp: boolean = true;
  showSelectUnit: boolean = true;
  public hospitalUnits = [];
  public unitBeds = [];
  private deviceId: string;
  private isCCUser: boolean = true;

  constructor(
    private activeModal: MatDialogRef<ECallPopupComponent>,
    private store: Store<{}>,
    private fb: UntypedFormBuilder,
    private _eCallService: ECallService,
    private _alertService: AlertService,
    private _hospitalService: HospitalService
  ) {}

  ngOnInit() {
    this.initializeForm();

    // listening to bed form control value changes to update the device id
    this.form.controls[this.FORM_CONTROL_NAME.bedNo].valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((bed) => {
        this.bedDeviceId = bed?.deviceId;
      });

    this.isCommandCenterUser$.takeUntil(this.unsubscribe$).subscribe((flag) => {
      this.isCCUser = flag;
    });

    this.units$.takeUntil(this.unsubscribe$).subscribe((units) => {
      this.allUnits = units;
    });

    this.hospitals$.takeUntil(this.unsubscribe$).subscribe((hospData) => {
      if (!hospData?.length) return this.callHospitals();

      this.hospitals = hospData;
      this.isLoader = false;
    });
  }

  callHospitals() {
    this._hospitalService
      .getAllHospitals()
      .takeUntil(this.unsubscribe$)
      .subscribe((res: HospitalList) => {
        this.isLoader = false;
        if (!res?.count) return;

        this.hospitals = res.hospitals.sort((a, b) =>
          a.name < b.name ? -1 : 1
        );
      });
  }

  initializeForm() {
    this.form = this.fb.group({
      [this.FORM_CONTROL_NAME.hospitalName]: [null, [Validators.required]],
      [this.FORM_CONTROL_NAME.unitName]: [null, [Validators.required]],
      [this.FORM_CONTROL_NAME.bedNo]: [null],
    });
  }

  selectHospital(inputHospital) {
    this.hospitalName.setValue(inputHospital.name);
    this.hospitalUnits = this.allUnits?.length
      ? this.allUnits.filter(
          (unit) =>
            unit?.hospitalInfo.name === inputHospital.name &&
            (unit?.deviceId?.length || unit?.beds?.some((bed) => bed.deviceId))
        )
      : inputHospital?.units.filter(
          (unit) =>
            unit?.deviceId?.length || unit?.beds?.some((bed) => bed.deviceId)
        );
  }

  selectUnit(unitObj) {
    this.unitName.setValue(unitObj.name);
    this.deviceId = unitObj?.deviceId[0];
    this.unitBeds = unitObj?.beds?.length
      ? unitObj.beds.filter((bed) => !!bed.deviceId)
      : [];
  }

  handleSubmit() {
    const payload = {
      message: this.ContextName[this.contextName],
      bedNo: this.bedNo?.value?.number,
      deviceId: this.bedDeviceId ? this.bedDeviceId : this.deviceId,
    };

    this._eCallService.postEcall(payload).subscribe(
      (res) => {
        this.activeModal.close();
        this._alertService.showNotification({
          type: "Success",
          message: `Successfully raised rAlert for ${this.hospitalName?.value} - ${this.unitName?.value}`,
        });
      },
      (error) => {
        this._alertService.showNotification({
          type: "Error",
          message: `Server error!`,
        });
      }
    );
  }

  handleBack() {
    if (this.bedNo?.value) {
      this.onbedReset();
    } else if (this.unitName?.value) {
      this.unitName.setValue(null);
      this.onUnitReset();
    } else {
      this.hospitalName.setValue(null);
      this.onHospReset();
    }
  }

  onbedReset() {
    this.bedNo.reset({ emitEvent: false });
    this.bedDeviceId = null;
  }

  onUnitReset() {
    this.unitBeds = [];
    this.deviceId = null;
  }

  onHospReset() {
    this.hospitalUnits = [];
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
