import { Component, Input, OnInit } from "@angular/core";
import { AbstractControl, UntypedFormGroup, Validators } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { AlertService } from "@iris/alert/services/alert.service";
import { VentsFormService } from "./services/vents-form.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientStatusService } from "./services/patient-status.service";

@Component({
  selector: "app-vents",
  templateUrl: "./vents.component.html",
  styleUrls: ["./vents.component.scss"],
})
export class VentsComponent implements OnInit {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description pain form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  ventsForm: UntypedFormGroup = this.ventsFormService.initVentForm();

  /**
   * @description Gets the abstract control of the therapy form from the vents form
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get therapyForm(): AbstractControl {
    return this.ventsForm.get("therapyForm");
  }

  /**
   * @description Gets the abstract control of the vent mode from the therapyForm
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get ventMode(): AbstractControl {
    return this.therapyForm.get("daysVentMode");
  }

  /**
   * @description Gets the abstract control of the therapy form from the vents form
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get airwayForm(): AbstractControl {
    return this.ventsForm.get("airwayForm");
  }

  /**
   * @description To get the abstract control of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.ventsForm.get("timestamp");
  }

  get nasopharyngealAirway(): AbstractControl {
    return this.airwayForm.get("nasopharyngealAirway");
  }

  get oralAirway(): AbstractControl {
    return this.airwayForm.get("oralAirway");
  }

  get biteBlock(): AbstractControl {
    return this.airwayForm.get("biteBlock");
  }

  get supraglotticAirway(): AbstractControl {
    return this.airwayForm.get("supraglotticAirway");
  }

  get cooksAirway(): AbstractControl {
    return this.airwayForm.get("cooksAirway");
  }

  get oralEndotrachealTube(): AbstractControl {
    return this.airwayForm.get("oralEndotrachealTube");
  }

  get nasalEndotrachealTube(): AbstractControl {
    return this.airwayForm.get("nasalEndotrachealTube");
  }

  get tracheostomyTube(): AbstractControl {
    return this.airwayForm.get("tracheostomyTube");
  }

  get otherAirway(): AbstractControl {
    return this.airwayForm.get("otherAirway");
  }

  /**
   * @description Gets the abstract control of the airway values
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  get airwayValuesFromForm(): AbstractControl {
    return this.airwayForm.get("daysVentAirway");
  }

  therapyValuesPediatric: string[] = ["Oxygen hood", "Nasal Bubble CPAP"];

  therapyValuesDefault: string[] = [
    "Room air",
    "Invasive ventilation",
    "NIV",
    "Nasal cannula",
    "Face mask",
    "Venturi mask",
    "Non-rebreathing mask",
    "HFNC",
    "T piece",
    "Ambu bag",
    "Trach mask",
  ];

  /**
   * Values of therapy devices
   * @type {Array}
   */
  therapyValues: string[];

  validO2Devices: string[] = [];

  /**
   * Values of airway
   * @type {Array}
   */
  airwayValues: string[] = [
    "Nasopharyngeal airway",
    "Oral airway",
    "Bite block",
    "Supraglottic airway",
    "Cooks airway",
    "Oral endotracheal tube with cuff",
    "Nasal endotracheal tube",
    "Tracheostomy tube",
  ];

  /**
   * Values of supraglotic values
   * @type {Array}
   */
  supragloticValues: string[] = [
    "Classic LMA",
    "Igel",
    "LMA supreme",
    "Proseal",
    "Fastrach",
    "Other",
  ];

  /**
   * Values of supraglotic values
   * @type {Array}
   */
  breathSequenceValues: string[] = ["CMV", "IMV"];

  /**
   * @description to hide show the formcontrols based on the selection
   * Refer https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   */
  showO2: boolean = false;
  showFio2: boolean = false;
  showMode: boolean = false;
  showPEEP: boolean = false;
  showNasoph: boolean = false;
  showOral: boolean = false;
  showBite: boolean = false;
  showSupraglotic: boolean = false;
  showCooks: boolean = false;
  showSupraOther: boolean = false;
  showOralEndo: boolean = false;
  showNasalEndo: boolean = false;
  showTracheostomy: boolean = false;

  /**
   * @description Calculates the fio2 when fio2 DOM input is focussed
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  calculateFio2(): void {
    let fio2 = this.therapyForm.get("daysFiO2");

    let therapyDevice = this.therapyForm.get("daysTherapyDevice").value;

    if (therapyDevice.includes("Room air")) {
      fio2.setValue(21);
    }
  }

  /**
   * @description Triggers when the therapy device value is changed
   * This hides and shows the formcontrols based on https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  therapyChanged(): void {
    this.therapyForm.get("daysFiO2").setValue(null);

    let therapyVal = this.therapyForm.get("daysTherapyDevice").value || [];

    let isInvasive = therapyVal.includes("Invasive ventilation");
    let isNiv = therapyVal.includes("NIV");
    let isRoomair = therapyVal.includes("Room air");
    const isOxygenHood = therapyVal.includes("Oxygen hood");
    const isNasalCPAP = therapyVal.includes("Nasal Bubble CPAP");

    if (!therapyVal.length) {
      this.showO2 = false;
      this.showFio2 = false;
      this.showMode = false;
      this.showPEEP = false;
      this.therapyForm.get("daysOxygenFlow").reset();
      this.resetModeSettings();
      return;
    }

    this.showFio2 = true;
    this.showO2 = true;
    this.showPEEP = false;

    if (therapyVal.length === 1) {
      if (isInvasive || isNiv || isRoomair) {
        this.showO2 = false;
        this.therapyForm.get("daysOxygenFlow").reset();
      }

      if (isOxygenHood) this.showFio2 = false;
    }

    if (isInvasive || isNiv) {
      this.showMode = true;
      this.ventMode.setValue("VC");
    } else {
      if (isNasalCPAP) this.showPEEP = true;
      this.showMode = false;
      this.resetModeSettings();
    }
  }

  /**
   * @description Triggers when the therapy device value is changed
   * This hides and shows the formcontrols
   * This also adds values to the form array based on the selection
   * Refer https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  airwayChanged(): void {
    let airwayVal = this.airwayForm.get("daysVentAirway").value;

    // if (!airwayVal?.length) {
    //   return;
    // }

    // To show the Nasopharyngeal fields
    if (airwayVal?.includes("Nasopharyngeal airway")) {
      this.showNasoph = true;
    } else {
      this.showNasoph = false;
      this.nasopharyngealAirway.reset();
    }

    // To show the Oral fields
    if (airwayVal?.includes("Oral airway")) {
      this.showOral = true;
    } else {
      this.showOral = false;
      this.oralAirway.reset();
    }

    // To show the Bite fields
    if (airwayVal?.includes("Bite block")) {
      this.showBite = true;
    } else {
      this.showBite = false;
      this.biteBlock.reset();
    }

    // To show the supraglotic fields
    if (airwayVal?.includes("Supraglottic airway")) {
      this.showSupraglotic = true;
    } else {
      this.showSupraglotic = false;
      this.supraglotticAirway.reset();
    }

    // To show the cooks fields
    if (airwayVal?.includes("Cooks airway")) {
      this.showCooks = true;
    } else {
      this.showCooks = false;
      this.cooksAirway.reset();
    }

    // To check if oral is present
    if (airwayVal?.includes("Oral endotracheal tube with cuff")) {
      this.showOralEndo = true;
    } else {
      this.showOralEndo = false;
      this.oralEndotrachealTube.reset();
    }

    // To check if nasal is present
    if (airwayVal?.includes("Nasal endotracheal tube")) {
      this.showNasalEndo = true;
    } else {
      this.showNasalEndo = false;
      this.nasalEndotrachealTube.reset();
    }

    // To check if Tracheostomy is present
    if (airwayVal?.includes("Tracheostomy tube")) {
      this.showTracheostomy = true;
    } else {
      this.showTracheostomy = false;
      this.tracheostomyTube.reset();
    }
  }

  /**
   * @description Triggers when supragloctic is changed to show the other field
   * @param event
   */
  supraGloticChanged(): void {
    let supraVal = this.supraglotticAirway.value.daysSupraglotticAirway;

    if (supraVal === "Other") {
      this.showSupraOther = true;
    } else {
      this.showSupraOther = false;
    }
  }

  /**
   * @description Triggers when mode is changed to show the other field
   * @param event
   */
  modeChanged(): void {
    let modeVal = this.therapyForm.value.daysVentMode;

    if (modeVal === "VC") {
      this.breathSequenceValues = ["CMV", "IMV"];
    } else if (modeVal === "PC") {
      this.breathSequenceValues = ["CMV", "IMV", "CSV"];
    }
  }

  /**
   * @description This resets the values of the mode settings when the therapy device is changed
   * @author Suraj Shenoy
   * @date Jul 9 2021
   */
  resetModeSettings(): void {
    this.ventMode.setValue(null);
    this.therapyForm.get("daysVentVt").setValue(null);
    this.therapyForm.get("daysVentPEEP").setValue(null);
    this.therapyForm.get("daysVentRRset").setValue(null);
    this.therapyForm.get("daysVentPinsp").setValue(null);
    this.therapyForm.get("daysVentPsupport").setValue(null);
    this.therapyForm.get("daysVentPplat").setValue(null);
    this.therapyForm.get("daysVentPmean").setValue(null);
    this.therapyForm.get("daysVentPpeak").setValue(null);
    this.therapyForm.get("daysVentMV").setValue(null);
    this.therapyForm.get("daysVentI").setValue(null);
    this.therapyForm.get("daysVentE").setValue(null);
    this.therapyForm.get("daysVentBreathSequence").setValue(null);
  }

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
      // creating therapy values as per patient type
      this.addRemoveTherapyValues();
    }
  }

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    day = day ?? <Vitals>{};
    if (day) {
      this.setFormValues(day);
    }
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    const setTherapyForm = () => {
      setFormVal(
        this.therapyForm,
        "daysTherapyDevice",
        vitalData.daysTherapyDevice || null
      );

      this.therapyChanged();
      setFormVal(
        this.therapyForm,
        "daysOxygenFlow",
        vitalData.daysOxygenFlow || null
      );

      setFormVal(this.therapyForm, "daysFiO2", vitalData.daysFiO2 || null);

      setFormVal(
        this.therapyForm,
        "daysVentMode",
        vitalData.daysVentMode || null
      );

      this.modeChanged();

      setFormVal(this.therapyForm, "daysVentVt", vitalData.daysVentVt || null);

      setFormVal(
        this.therapyForm,
        "daysVentPEEP",
        vitalData.daysVentPEEP || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentRRset",
        vitalData.daysVentRRset || null
      );

      setFormVal(this.therapyForm, "daysRR", vitalData.daysRR || null);

      setFormVal(
        this.therapyForm,
        "daysVentPinsp",
        vitalData.daysVentPinsp || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPsupport",
        vitalData.daysVentPsupport || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPpeak",
        vitalData.daysVentPpeak || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPplat",
        vitalData.daysVentPplat || null
      );

      setFormVal(
        this.therapyForm,
        "daysVentPmean",
        vitalData.daysVentPmean || null
      );

      setFormVal(this.therapyForm, "daysVentMV", vitalData.daysVentMV || null);

      setFormVal(
        this.therapyForm,
        "daysVentBreathSequence",
        vitalData.daysVentBreathSequence || null
      );

      setFormVal(this.therapyForm, "daysVentI", vitalData.daysVentI || null);

      setFormVal(this.therapyForm, "daysVentE", vitalData.daysVentE || null);
    };

    const setAirwayForm = () => {
      setFormVal(
        this.airwayForm,
        "daysVentAirway",
        vitalData.daysVentAirway || null
      );
      this.airwayChanged();

      // nasal
      setFormVal(
        this.nasopharyngealAirway,
        "daysVentTubeSize",
        vitalData?.nasopharyngealAirway?.daysVentTubeSize || null
      );

      // oral
      setFormVal(
        this.oralAirway,
        "daysVentTubeSize",
        vitalData?.oralAirway?.daysVentTubeSize || null
      );

      // bite block
      setFormVal(
        this.biteBlock,
        "daysVentTubeSize",
        vitalData?.biteBlock?.daysVentTubeSize || null
      );

      // supraglotic
      setFormVal(
        this.supraglotticAirway,
        "daysVentTubeSize",
        vitalData?.supraglotticAirway?.daysVentTubeSize || null
      );
      if (
        vitalData?.supraglotticAirway?.daysSupraglotticAirway &&
        !this.supragloticValues.includes(
          vitalData?.supraglotticAirway?.daysSupraglotticAirway
        )
      ) {
        setFormVal(this.supraglotticAirway, "daysSupraglotticAirway", "Other");
        this.supraGloticChanged();
        setFormVal(
          this.supraglotticAirway,
          "supraGloticOther",
          vitalData?.supraglotticAirway?.daysSupraglotticAirway || null
        );
      } else {
        setFormVal(
          this.supraglotticAirway,
          "daysSupraglotticAirway",
          vitalData?.supraglotticAirway?.daysSupraglotticAirway || null
        );
      }

      // cooks
      setFormVal(
        this.cooksAirway,
        "daysVentTubeSize",
        vitalData?.cooksAirway?.daysVentTubeSize || null
      );

      // nasal endo
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentCuff",
        vitalData?.nasalEndotrachealTube?.daysVentCuff
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysEttTieLevel",
        vitalData?.nasalEndotrachealTube?.daysEttTieLevel || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentTubeSize",
        vitalData?.nasalEndotrachealTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "daysVentCuffPressure",
        vitalData?.nasalEndotrachealTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.nasalEndotrachealTube,
        "isClosedSuction",
        vitalData?.nasalEndotrachealTube?.isClosedSuction
      );

      // oral endo
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentCuff",
        vitalData?.oralEndotrachealTube?.daysVentCuff
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysEttTieLevel",
        vitalData?.oralEndotrachealTube?.daysEttTieLevel || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentTubeSize",
        vitalData?.oralEndotrachealTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "daysVentCuffPressure",
        vitalData?.oralEndotrachealTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.oralEndotrachealTube,
        "isClosedSuction",
        vitalData?.oralEndotrachealTube?.isClosedSuction
      );

      // trach endo
      setFormVal(
        this.tracheostomyTube,
        "daysVentCuff",
        vitalData?.tracheostomyTube?.daysVentCuff
      );
      setFormVal(
        this.tracheostomyTube,
        "daysVentTubeSize",
        vitalData?.tracheostomyTube?.daysVentTubeSize || null
      );
      setFormVal(
        this.tracheostomyTube,
        "daysVentCuffPressure",
        vitalData?.tracheostomyTube?.daysVentCuffPressure || null
      );
      setFormVal(
        this.tracheostomyTube,
        "isClosedSuction",
        vitalData?.tracheostomyTube?.isClosedSuction
      );
    };

    if (vitalData.timestamp)
      setFormVal(this.ventsForm, "timestamp", vitalData.timestamp);

    setTherapyForm();
    setAirwayForm();
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.ventsForm.reset();
    this.ventsForm.get("timestamp").setValue(null);

    this.showO2 = false;
    this.showFio2 = false;
    this.showPEEP = false;
    this.showMode = false;
    this.showNasoph = false;
    this.showOral = false;
    this.showBite = false;
    this.showSupraglotic = false;
    this.showCooks = false;
    this.showSupraOther = false;
    this.showOralEndo = false;
    this.showNasalEndo = false;
    this.showTracheostomy = false;
    this.formState.error = null;
  }

  /**
   * @description To submit the pain score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */

  constructor(
    private store: Store<{}>,
    private _alertService: AlertService,
    private ventsFormService: VentsFormService,
    private patientStatusService: PatientStatusService
  ) {}

  ngOnInit(): void {
    // creating therapy values as per patient type
    this.addRemoveTherapyValues();

    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Vents updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });

    this.resetVitalTimestamp();
  }

  /**
   * @description add therapy device value according to patient-type
   * @author Rajat Saini
   * @Date Oct 31 2022
   */
  addRemoveTherapyValues() {
    if (this.currentPatient?.patientType == "adult") {
      this.therapyValues = [...this.therapyValuesDefault];
      return;
    }

    this.therapyValues = [
      ...this.therapyValuesDefault,
      ...this.therapyValuesPediatric,
    ];
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.ventsForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  /**
   * @description To submit the vents form to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitVents(): void {
    /** Init the form loading */
    this.store.dispatch(vitalActions.submitForm({ loadType: "vents" }));

    let ventsValue = this.ventsForm.value;
    let therapyForm = ventsValue.therapyForm;
    let airwayForm = ventsValue.airwayForm;

    let vents = {
      timestamp: new Date(ventsValue.timestamp),
      ...therapyForm,
    };

    // Submit daysRR only if it exists
    if (therapyForm.daysRR) {
      vents.daysRR = therapyForm.daysRR;
    } else {
      delete vents.daysRR;
    }

    // If other, send the value of the free text as the supraglotic airway
    let supraGlocticAirwayVal = airwayForm.supraglotticAirway;
    if (supraGlocticAirwayVal.daysSupraglotticAirway === "Other") {
      supraGlocticAirwayVal.daysSupraglotticAirway =
        supraGlocticAirwayVal.supraGloticOther;
    }

    vents = {
      ...vents,
      ...airwayForm,
      supraglotticAirway: supraGlocticAirwayVal,
    };

    let patientInfo = this.patientStatusService.patientTherapyStatus(
      this.currentPatient,
      therapyForm.daysTherapyDevice,
      airwayForm.daysVentAirway,
      ventsValue.timestamp
    );

    /** Dispatch the action */
    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: vents, patientInfo },
      })
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
