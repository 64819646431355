import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  Output,
} from "@angular/core";
import {
  AbstractControl,
  ControlContainer,
  FormArray,
  FormControl,
} from "@angular/forms";

@Component({
  selector: "cp-time-schedule",
  templateUrl: "./time-schedule.component.html",
  styleUrls: ["./time-schedule.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TimeScheduleComponent {
  constructor(public controlContainer: ControlContainer) {}

  @Output() addNew: EventEmitter<any> = new EventEmitter();

  @Input("label") label: string = "";

  @Input("index") index: number = 0;

  @Output() delete: EventEmitter<any> = new EventEmitter();

  @Input() showDelete: boolean = false;

  get timeForm(): AbstractControl {
    return this.controlContainer.control;
  }

  get parentForm(): FormArray {
    return this.timeForm.parent as FormArray;
  }

  get previousTo(): AbstractControl {
    return this.parentForm?.controls[this.index - 1]?.get("to");
  }

  get nextFrom(): AbstractControl {
    return this.parentForm?.controls[this.index + 1]?.get("from");
  }

  get invalidControl(): boolean {
    if (!this.from.dirty || !this.to.dirty) return false;
    return (
      this.timeForm.hasError("fromTimeRequired") ||
      this.timeForm.hasError("toTimeRequired")
    );
  }

  get from(): FormControl {
    return this.timeForm.get("from") as FormControl;
  }

  get to(): FormControl {
    return this.timeForm.get("to") as FormControl;
  }

  public addNewScedule(): void {
    this.addNew.emit();
  }

  public deleteSchedule(): void {
    this.delete.emit();
  }
}
