import { I } from "@angular/cdk/keycodes";
import { Component, Inject, Input } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confirm-delete",
  template: `
    <div class="modal-header">
      <h5 class="modal-title" id="modal-title">
        {{ data.title ? data.title : "Delete" }}
      </h5>
      <button
        type="button"
        class="close"
        aria-describedby="modal-title"
        (click)="onNoClick('cancel click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body">
      <p>
        <strong
          >Are you sure you want to delete
          <span class="text-primary">{{
            data.name ? data.name : "this record"
          }}</span>
          ?</strong
        >
      </p>
      <p *ngIf="data.description">{{ data.description }}</p>
      <p>This operation can not be undone.</p>
      <button
        type="submit"
        class="cp-btn cp-btn--danger"
        (click)="onNoClick('delete')"
      >
        OK
      </button>
      <button
        class="cp-btn cp-hvr-misc-s ml-1"
        type="button"
        (click)="onNoClick('cancel click')"
      >
        Cancel
      </button>
    </div>
  `,
})
export class ConfirmDeleteComponent {
  constructor(
    public dialogRef: MatDialogRef<ConfirmDeleteComponent>,
    @Inject(MAT_DIALOG_DATA) public data
  ) {}
  onNoClick(message): void {
    if (message == "cancel click") this.dialogRef.close(false);
    if (message == "delete") this.dialogRef.close(true);
  }
}
