import { Injectable } from "@angular/core";
import { Patient } from "src/app/models/patient";
import moment from "moment-timezone";

@Injectable({
  providedIn: "root",
})
export class PatientStatusService {
  constructor() {}

  patientTherapyStatus(
    patient: Patient,
    therapyVal: String[] = [],
    airwayVal: String[] = [],
    timestamp: Date
  ) {
    // let time = new Date(timestamp).getTime();
    let time = moment(timestamp).valueOf();

    let patTherapyStatus: any = {};

    const patNiv = patient.isNIV || {};
    const patInt = patient.isIntubated || {};
    const patTrach = patient.isTrach || {};
    const patHFNC = patient.isHFNC || {};

    let ventNiv = false,
      ventInt = false,
      ventTrach = false,
      ventHFNC = false;

    if (therapyVal?.length) {
      therapyVal.forEach((thDevice) => {
        if (thDevice === "NIV") {
          ventNiv = true;
        } else if (thDevice === "HFNC") {
          ventHFNC = true;
        }
        // DISABLED DUE TO THE CHANGE IN WORKFLOW
        // else if (thDevice === "Invasive ventilation") {
        //   ventInt = true;
        // } else if (thDevice === "Trach mask") {
        //   ventTrach = true;
        // }
      });
    }

    if (airwayVal?.length) {
      airwayVal.forEach((airway) => {
        if (
          airway === "Oral endotracheal tube with cuff" ||
          airway === "Nasal endotracheal tube"
        ) {
          ventInt = true;
        } else if (airway === "Tracheostomy tube") {
          ventTrach = true;
        }
      });
    }

    // for niv
    if (moment(time).diff(moment(patNiv.timestamp), "minutes") >= 0) {
      patTherapyStatus.isNIV = {
        value: ventNiv,
        timestamp: time,
      };
    }

    // for int
    if (moment(time).diff(moment(patInt.timestamp), "minutes") >= 0) {
      patTherapyStatus.isIntubated = {
        value: ventInt,
        timestamp: time,
      };
    }

    // for trach
    if (moment(time).diff(moment(patTrach.timestamp), "minutes") >= 0) {
      patTherapyStatus.isTrach = {
        value: ventTrach,
        timestamp: time,
      };
    }

    // for hfnc
    if (moment(time).diff(moment(patHFNC.timestamp), "minutes") >= 0) {
      patTherapyStatus.isHFNC = {
        value: ventHFNC,
        timestamp: time,
      };
    }

    return Object.keys(patTherapyStatus).length ? patTherapyStatus : null;
  }
}
