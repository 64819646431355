import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";

import { MedRatePipe } from "./services/med-rate.pipe";
import { VentTubePipe } from "./services/vent-tube.pipe";
import { VentAirwayPipe } from "./services/vent-airway.pipe";
import { CanEditOrderPipe } from "./services/canEditOrder.pipe";
import { SearchComponent } from "./components/search/search.component";
import { OrderTimeComponent } from "./components/fields/time/order-time.component";
import { RequestComponent } from "./components/request/request.component";
import { DetailComponent } from "./components/fields/detail/detail.component";
import { MedFormComponent } from "./components/forms/med-form/med-form.component";
import { LabFormComponent } from "./components/forms/lab-form/lab-form.component";
import { ProtocolLabViewComponent } from "./components/protocol-view/lab.component";
import { ProtocolMedViewComponent } from "./components/protocol-view/med.component";
import { DietFormComponent } from "./components/forms/diet-form/diet-form.component";
import { ProtocolDietViewComponent } from "./components/protocol-view/diet.component";
import { ProtocolCommViewComponent } from "./components/protocol-view/comm.component";
import { FrequencyComponent } from "./components/fields/frequency/frequency.component";
import { OrderDetailSignedTitlePipe } from "./services/order-detail-signed-title.pipe";
import { TimePeriodOptionsPipe } from "./services/time-period-options.pipe";
import { ProtocolBloodViewComponent } from "./components/protocol-view/blood.component";
import { BloodFormComponent } from "./components/forms/blood-form/blood-form.component";
import { FormatDiscontinueReasonsPipe } from "./services/formatDiscontinueReasons.pipe";
import { DetailsModalComponent } from "./components/details-modal/details-modal.component";
import { ProtocolProcedureViewComponent } from "./components/protocol-view/procedure.component";
import { ProcedureFormComponent } from "./components/forms/procedure-form/procedure-form.component";
import { MetaFrequencyComponent } from "./components/fields/meta-frequency/meta-frequency.component";
import { MedDetailViewComponent } from "./components/details-modal/detail/med-detail-view.component";
import { VentDetailViewComponent } from "./components/details-modal/detail/vent-detail-view.component";
import { LabHistoryViewComponent } from "./components/details-modal/history/lab-history-view.component";
import { MedHistoryViewComponent } from "./components/details-modal/history/med-history-view.component";
import { DietHistoryViewComponent } from "./components/details-modal/history/diet-history-view.component";
import { CommHistoryViewComponent } from "./components/details-modal/history/comm-history-view.component";
import { VentHistoryViewComponent } from "./components/details-modal/history/vent-history-view.component";
import { BloodHistoryViewComponent } from "./components/details-modal/history/blood-history-view.component";
import { HistoryCommonViewComponent } from "./components/details-modal/history/history-common-view.component";
import { CommunicationFormComponent } from "./components/forms/communication-form/communication-form.component";
import { ProcedureHistoryViewComponent } from "./components/details-modal/history/procedure-history-view.component";
import { IrisComponentsModule } from "../iris-components/iris-components.module";

import { PatientTypeComponent } from "./components/fields/patient-type/patient-type.component";
import { AgeGroupViewComponent } from "./components/protocol-view/common/age-group-view/age-group-view.component";
import { DoseSchedulerComponent } from "./components/fields/dose-scheduler/dose-scheduler.component";
import { MatInputPreceedingZeroDirective } from "../directives/preceedingzero.directive";
import { PresetComponent } from "./components/fields/preset/preset.component";
import { EllipsisDirective } from "../directives/ellipsis.directive";
import { FilterUnitPipe } from "./components/forms/med-form/filter-unit.pipe";
import { PrintOrdersComponent } from "./components/print-orders/print-orders.component";

const components = [
  MedRatePipe,
  VentTubePipe,
  OrderTimeComponent,
  VentAirwayPipe,
  DetailComponent,
  SearchComponent,
  LabFormComponent,
  MedFormComponent,
  RequestComponent,
  CanEditOrderPipe,
  DietFormComponent,
  BloodFormComponent,
  FrequencyComponent,
  PresetComponent,
  DetailsModalComponent,
  MetaFrequencyComponent,
  MedDetailViewComponent,
  ProcedureFormComponent,
  VentDetailViewComponent,
  LabHistoryViewComponent,
  MedHistoryViewComponent,
  ProtocolLabViewComponent,
  DietHistoryViewComponent,
  ProtocolMedViewComponent,
  DietHistoryViewComponent,
  CommHistoryViewComponent,
  VentHistoryViewComponent,
  ProtocolCommViewComponent,
  BloodHistoryViewComponent,
  ProtocolDietViewComponent,
  ProtocolBloodViewComponent,
  OrderDetailSignedTitlePipe,
  TimePeriodOptionsPipe,
  CommunicationFormComponent,
  HistoryCommonViewComponent,
  FormatDiscontinueReasonsPipe,
  ProcedureHistoryViewComponent,
  ProtocolProcedureViewComponent,
  DoseSchedulerComponent,
  MatInputPreceedingZeroDirective,
  PatientTypeComponent,
  AgeGroupViewComponent,
  EllipsisDirective,
  FilterUnitPipe,
  PrintOrdersComponent,
];

@NgModule({
  declarations: components,
  exports: components,
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    IrisComponentsModule,
  ],
})
export class OrdersModule {}
