import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Injectable } from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  Validators,
} from "@angular/forms";
import { throwError } from "rxjs";
import { catchError } from "rxjs/operators";
import {
  numberValidator,
  numberWithDotValidator,
} from "src/app/admit-form/utils/validator";
import { environment } from "src/environments/environment";

@Injectable({
  providedIn: "root",
})
export class CribFormService {
  env = environment;
  constructor(private fb: FormBuilder, private http: HttpClient) {}

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  initCribForm(): FormGroup {
    return this.fb.group({
      daysScore: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(0),
          Validators.max(27),
          numberValidator,
        ]),
      ],
      daysGestation: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(22),
          Validators.max(42),
          numberValidator,
        ]),
      ],
      daysWeight: [
        null,
        Validators.compose([
          Validators.required,
          Validators.min(1),
          Validators.max(999000),
          numberValidator,
        ]),
      ],
      daysGender: [null, Validators.required],
      daysTemperature: [
        null,
        Validators.compose([
          Validators.required,
          Validators.max(100),
          numberWithDotValidator,
        ]),
      ],
      daysBaseExcess: [null, Validators.required],
      timestamp: new FormControl(
        new Date(new Date().setSeconds(0, 0)),
        Validators.required
      ),
    });
  }

  getCRIBscore(payload) {
    return this.http
      .post<any>(
        this.env.apiUrl + "v1/vitals/get/crib-2",
        payload,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
}
