import { Component, Input, OnInit } from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { MatIconRegistry } from "@angular/material/icon";

@Component({
  selector: "app-label-badge",
  templateUrl: "./label-badge.component.html",
  styleUrls: ["./label-badge.component.scss"],
})
export class LabelBadgeComponent implements OnInit {
  @Input() labelData: any;
  @Input() iris_class;

  constructor(
    private iconRegistry: MatIconRegistry,
    private sanitizer: DomSanitizer
  ) {}

  ngOnInit(): void {
    if (this.labelData?.icon)
      this.iconRegistry.addSvgIconLiteral(
        `${this.labelData.icon["iconName"]}`,
        this.sanitizer.bypassSecurityTrustHtml(this.labelData.icon["iconUrl"])
      );
  }
}
