<div class="vital-info">
  <div class="left-side">
    <div *ngIf="vitals?.length" class="day-selector">
      <button
        [disabled]="disablePrev"
        matRipple
        (click)="changeDay('prev')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_left</mat-icon
        >
      </button>

      <mat-form-field [floatLabel]="'always'">
        <mat-select
          [value]="selectedDay"
          (selectionChange)="dayChanged($event.value)"
          class="iris-input"
        >
          <mat-option *ngFor="let day of vitals | uniqueDay" [value]="day"
            >Day {{ day }}</mat-option
          >
        </mat-select>
      </mat-form-field>

      <button
        [disabled]="disableNext"
        matRipple
        (click)="changeDay('next')"
        type="button"
      >
        <mat-icon class="cp-mat-icon-overflow-initial"
          >keyboard_arrow_right</mat-icon
        >
      </button>

      <mat-form-field floatLabel="always" class="date-selector">
        <input
          class="date-picker-input"
          [matDatepicker]="dob"
          placeholder="yyyy/mm/dd"
          [value]="selectedDate"
          [matDatepickerFilter]="dateFilter"
          (dateChange)="onClickOfDate($event?.value)"
          matInput
        />
        <mat-label></mat-label>
        <mat-datepicker-toggle matSuffix [for]="dob"> </mat-datepicker-toggle>
        <mat-datepicker #dob></mat-datepicker>
      </mat-form-field>
    </div>
  </div>
  <div class="right-side">
    <div
      class="nice-chart-btn"
      *ngIf="currPatient?.patientType === patientType.Neonatal"
    >
      <button class="button-secondary" (click)="openNiceChartTrends()">
        View NICE chart
      </button>
    </div>
  </div>
</div>
