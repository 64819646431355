<div class="handover-view">
  <div
    class="handover-view__header display-flex justify-content-between align-items-center"
  >
    <span class="cp-header">Handover note</span>
    <button [mat-dialog-close]="false" mat-icon-button aria-label="Close icon">
      <mat-icon class="cp-icon">close</mat-icon>
    </button>
  </div>
  <div class="handover-view__body">
    <ng-container [formGroup]="form">
      <ng-container
        *ngFor="let noteControl of notesForm.controls; let i = index"
      >
        <div
          class="handover-view__body__heading mb"
          [ngClass]="{ 'handover-view__body__heading--margin-top': i !== 0 }"
        >
          <span class="handover-view__body__heading__name mb mr">
            By {{ noteMap.get(noteControl?.get("noteId")?.value)?.name }}
          </span>
          <span class="handover-view__body__heading__date">
            <ng-container *ngIf="i == 0"> Last updated: </ng-container>
            {{
              noteMap.get(noteControl?.get("noteId")?.value)?.updatedAt
                | date: "dd/MM/yyyy HH:mm"
            }}
          </span>
        </div>
        <div
          class="handover-view__body__note"
          [ngClass]="{ mb: i !== notesForm.controls.length - 1 }"
          [formGroup]="noteControl"
          *ngIf="isCurrentUser(noteControl?.get('noteId')?.value)"
        >
          <cp-text-editor
            formControlName="note"
            [autosave]="false"
          ></cp-text-editor>
        </div>
        <span
          *ngIf="noteControl?.get('note')?.errors?.maxlength"
          class="error-text"
          >{{ validationText }}</span
        >
        <div
          class="handover-view__body__text cp-no-margin-para"
          *ngIf="!isCurrentUser(noteControl?.get('noteId')?.value)"
          [innerHTML]="noteControl?.get('note')?.value | sanitizeHtml"
        ></div>
      </ng-container>
    </ng-container>
  </div>
  <div
    class="handover-view__buttons display-flex justify-content-end"
    *ngIf="isUpdateButton"
  >
    <button mat-flat-button class="button-secondary" [mat-dialog-close]="false">
      Cancel
    </button>
    <button
      mat-flat-button
      class="button-primary ml"
      (click)="updateNotes()"
      [disabled]="updateNoteLoader || isInvalid || !isDirty"
    >
      <app-loader *ngIf="updateNoteLoader" [buttonLoader]="true"></app-loader>
      Update
    </button>
  </div>
</div>
