export enum AuthScreenEnum {
  LoginPage = "LoginPage",
  OtpPage = "OtpPage",
  ForgotPassword = "ForgotPassword",
  ResetPassword = "ResetPassword",
}

export interface TwoFaRemember {
  email: string;
  token: string;
  timestamp: Date;
}
