import { Component, Input, OnInit } from "@angular/core";
import {
  AVATAR_SIZE,
  AvatarParams,
} from "@iris/avatar/components/avatar/avatar.component";

interface PayorCard {
  name: string;
  code: string;
  profilePic: string;
}

@Component({
  selector: "cp-payor-card",
  templateUrl: "./payor-card.component.html",
  styleUrls: ["./payor-card.component.scss"],
  host: {
    class: "bg-white",
  },
})
export class PayorCardComponent implements OnInit {
  avatarParamsIcon1: AvatarParams[] = [
    {
      size: AVATAR_SIZE.L,
    },
  ];

  @Input("payorData") payorData: PayorCard = null;

  public get payorName(): string {
    return this.payorData?.name;
  }

  public get payorCode(): string {
    return this.payorData?.code;
  }

  public get profilePic(): AvatarParams[] {
    return [
      {
        size: AVATAR_SIZE.L,
        imageUrl: this.payorData?.profilePic,
        name: this.payorData?.name,
      },
    ];
  }

  constructor() {}

  ngOnInit(): void {}
}
