<div class="counter">
  <div class="minus-icon" (click)="onMinus()">
    <img
      *ngIf="count !== 0"
      class="icon-size"
      src="../../../assets/icons/remove_circle_outline.svg"
    />
    <img
      *ngIf="count === 0"
      class="icon-size"
      src="../../../assets/icons/remove_circle_outline_disabled.svg"
    />
  </div>

  <div class="label-cont">
    <div [class]="[label ? 'primary' : '']">
      {{ count }}
    </div>
    <div *ngIf="label" class="label">
      {{ label }}
    </div>
  </div>

  <div class="plus-icon" (click)="onPlus()">
    <img class="icon-size" src="../../../assets/icons/add_circle_outline.svg" />
  </div>
</div>
