import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";

import { environment } from "../../../environments/environment";
import {
  AuthVerifyOtpApi,
  AuthResendOtpApi,
} from "../../models/auth/Otp.model";

@Injectable({ providedIn: "root" })
export class AuthOtpService {
  public apiUrl = environment.apiUrl;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private _httpClient: HttpClient) {}

  /**
   * Verify the otp
   *
   * @param {AuthVerifyOtpApi} data
   * @returns {Observable}
   */
  verifyOtp(data: AuthVerifyOtpApi): Observable<any> {
    return this._httpClient.post(
      `${this.apiUrl}auth/otp/verify`,
      data,
      this.httpOptions
    );
  }

  /**
   * Resends the otp
   *
   * @param {AuthResendOtpApi} data
   * @returns {Observable}
   */
  resendOtp(data: AuthResendOtpApi): Observable<any> {
    return this._httpClient.post(
      `${this.apiUrl}auth/otp/resend`,
      data,
      this.httpOptions
    );
  }

  /**
   * Forgot Password Send otp
   *
   * @param email
   * @returns {Observable}
   */
  forgotPasswordSendOtp(email: string): Observable<any> {
    const data = {
      email,
    };
    return this._httpClient.post(
      `${this.apiUrl}auth/forgot-password/send-otp`,
      data,
      this.httpOptions
    );
  }

  /**
   * Forgot Password Verify otp
   *
   * @param {AuthVerifyOtpApi} data
   * @returns {Observable}
   */
  forgotPasswordVerifyOtp(data: AuthVerifyOtpApi): Observable<any> {
    return this._httpClient.post(
      `${this.apiUrl}auth/forgot-password/verify-otp`,
      data,
      this.httpOptions
    );
  }

  /**
   * Forgot Password
   *
   * @param {AuthVerifyOtpApi} data
   * @returns {Observable}
   */
  forgotPassword(
    email: string,
    uuid: string,
    password: String,
    otp
  ): Observable<any> {
    const data = {
      email,
      uuid,
      password,
      otp,
    };
    return this._httpClient.post(
      `${this.apiUrl}auth/forgot-password`,
      data,
      this.httpOptions
    );
  }
}
