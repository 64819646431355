import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { UploadFileComponent } from "./components/upload-file/upload-file.component";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { DomSanitizer } from "@angular/platform-browser";
import { OverlayModule } from "@iris/overlay";
import { FilePreviewModalModule } from "@iris/file-preview-modal";

@NgModule({
  declarations: [UploadFileComponent],
  imports: [
    CommonModule,
    MatIconModule,
    MatProgressSpinnerModule,
    OverlayModule,
    FilePreviewModalModule,
  ],
  exports: [UploadFileComponent],
})
export class UploadFileModule {
  constructor(
    private matIconRegistry: MatIconRegistry,
    private domSanitizer: DomSanitizer
  ) {
    this.matIconRegistry.addSvgIcon(
      "cp-document",
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        "assets/icons/Document.svg"
      )
    );
  }
}
