import { Injectable } from "@angular/core";
import { MatDialog } from "@angular/material/dialog";
import { ConfimDialogComponent } from "../components/comfirm-dialog/confim-dialog.component";
import {
  ConfirmDialogInterface,
  DATA_CONFIG,
} from "../models/confirm-dialog.model";

@Injectable({
  providedIn: "root",
})
export class DialogService {
  constructor(private dialog: MatDialog) {}

  openConfirmDialogue({
    message = DATA_CONFIG.message,
    buttonText = DATA_CONFIG.buttonText,
    alertType = DATA_CONFIG.alertType,
    headerText = DATA_CONFIG.headerText,
    secondaryButtonText = DATA_CONFIG.secondaryButtonText,
    width = DATA_CONFIG.width,
    itemList,
  }: ConfirmDialogInterface) {
    return this.dialog.open(ConfimDialogComponent, {
      width,
      disableClose: true,
      autoFocus: false,
      data: {
        message,
        buttonText,
        alertType,
        headerText,
        secondaryButtonText,
        itemList,
      },
    });
  }
}
