// offset is given to transform it from tooltip height
const offsetTooltip = 10;
// offset to allign the tip of tooltip wrt element.
const offsetTip = 20;
import { ToolTipPositions } from "@iris/popover/models/tooltip.model";
export const positionData: ToolTipPositions = {
  "top-start": {
    originX: "center",
    originY: "top",
    overlayX: "start",
    overlayY: "bottom",
    offsetY: -1 * offsetTooltip,
    offsetX: -1 * offsetTip,
  },
  "top-center": {
    originX: "center",
    originY: "top",
    overlayX: "center",
    overlayY: "bottom",
    offsetY: -1 * offsetTooltip,
  },
  "top-end": {
    originX: "center",
    originY: "top",
    overlayX: "end",
    overlayY: "bottom",
    offsetY: -1 * offsetTooltip,
    offsetX: offsetTip,
  },
  "right-start": {
    originX: "end",
    originY: "center",
    overlayX: "start",
    overlayY: "top",
    offsetX: offsetTooltip,
    offsetY: -1 * offsetTip,
  },
  "right-center": {
    originX: "end",
    originY: "center",
    overlayX: "start",
    overlayY: "center",
    offsetX: offsetTooltip,
  },
  "right-end": {
    originX: "end",
    originY: "center",
    overlayX: "start",
    overlayY: "bottom",
    offsetX: offsetTooltip,
    offsetY: offsetTip,
  },
  "bottom-start": {
    originX: "center",
    originY: "bottom",
    overlayX: "start",
    overlayY: "top",
    offsetY: offsetTooltip,
    offsetX: -1 * offsetTip,
  },
  "bottom-center": {
    originX: "center",
    originY: "bottom",
    overlayX: "center",
    overlayY: "top",
    offsetY: offsetTooltip,
  },
  "bottom-end": {
    originX: "center",
    originY: "bottom",
    overlayX: "end",
    overlayY: "top",
    offsetY: offsetTooltip,
    offsetX: offsetTip,
  },
  "left-start": {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "top",
    offsetX: -1 * offsetTooltip,
    offsetY: -1 * offsetTip,
  },
  "left-center": {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "center",
    offsetX: -1 * offsetTooltip,
  },
  "left-end": {
    originX: "start",
    originY: "center",
    overlayX: "end",
    overlayY: "bottom",
    offsetX: -1 * offsetTooltip,
    offsetY: offsetTip,
  },
};

export const defaultPositioning = "top-start";
