import { Injectable } from "@angular/core";
import { Patient } from "@smart-hospital/shared/models/patient.model";
import { Subject } from "rxjs-compat";

import { OpdSearchInterface } from "../../patient-search/models/patientSearch.model";

@Injectable({
  providedIn: "root",
})
export class EditCardService {
  constructor() {}

  private editCardSubject = new Subject<OpdSearchInterface | Patient>();
  editCard$ = this.editCardSubject.asObservable();

  public editPatient(patient: OpdSearchInterface | Patient): void {
    this.editCardSubject.next(patient);
  }
}
