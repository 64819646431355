import { Action } from "@ngrx/store";

// load lab values

export const LOAD_LAB_DOCUMENTS = "[LAB] Load Lab Documents";
export const LOAD_LAB_DOCUMENTS_FAIL = "[LAB] Load Lab Documents  Fail";
export const LOAD_LAB_DOCUMENTS_SUCCESS = "[LAB] Load Lab Documents Success";
export const LOAD_LAB_DOCUMENTS_SOCKET_SUCCESS =
  "[LAB] Load Lab Documents Socket  Success";

export const UPDATE_LAB_DOCUMENTS = "[LAB] Update Lab Documents";
export const UPDATE_LAB_DOCUMENTS_FAIL = "[LAB] Update Lab Documents Fail";
export const UPDATE_LAB_DOCUMENTS_SUCCESS =
  "[LAB] Update Lab Documents Success";

export class LoadLabDocument implements Action {
  readonly type = LOAD_LAB_DOCUMENTS;
  constructor(
    public payload: {
      docs: any;
    }
  ) {}
}

export class UpdateLabDocument implements Action {
  readonly type = UPDATE_LAB_DOCUMENTS;
  constructor(
    public payload: {
      docs: any;
    }
  ) {}
}

export class UpdateLabDocumentFail implements Action {
  readonly type = UPDATE_LAB_DOCUMENTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadLabDocumentFail implements Action {
  readonly type = LOAD_LAB_DOCUMENTS_FAIL;
  constructor(public payload: any) {}
}

export class LoadLabDocumentSocketSuccess implements Action {
  readonly type = LOAD_LAB_DOCUMENTS_SOCKET_SUCCESS;
  constructor(public payload) {}
}

export class UpdateLabDocumentSuccess implements Action {
  readonly type = UPDATE_LAB_DOCUMENTS_SUCCESS;
  constructor() {}
}

export class LoadLabDocumentSuccess implements Action {
  readonly type = LOAD_LAB_DOCUMENTS_SUCCESS;
  constructor() {}
}

export type LabDocumentAction =
  | LoadLabDocument
  | LoadLabDocumentFail
  | LoadLabDocumentSuccess;
