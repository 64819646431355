import { of, empty, EMPTY } from "rxjs";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { NgxPermissionsService } from "ngx-permissions";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  switchMap,
  tap,
  filter,
  take,
  concatMap,
  timestamp,
} from "rxjs/operators";
import { Location } from "@angular/common";

import * as fromAuth from "../actions/auth.actions";
import * as userActions from "../actions/user.actions";
import {
  AuthApiActions,
  AuthLoginPageActions,
  AuthOtpPageActions,
} from "../actions/auth";
import { AuthService } from "src/app/services/auth.service";
import * as rootActions from "src/app/store/actions/root.actions";
import { PermissionService } from "../../shared/accessControl/permission.service";
// import { Socket } from 'ngx-socket-io';
import Cookies from "js-cookie";
import { environment } from "src/environments/environment";
import { Store } from "@ngrx/store";
import { socket, initiateSocketConnection } from "../../config/socket";
import { AuthOtpService } from "../../get-started/services/auth-otp.service";
import { AuthLocalStorageService } from "../../get-started/services/auth-localStorage.service";
import { displayErrorMessage } from "src/app/store/actions/root.actions";
import { MatDialog } from "@angular/material/dialog";
import { TwoFaRemember } from "src/app/models/auth/Auth.model";
import { PinInfo } from "src/app/models/patient";
import { PatientService } from "src/app/services/patient.service";

@Injectable()
export class AuthEffects {
  env = environment;

  constructor(
    private actions$: Actions,
    private authService: AuthService,
    private _router: Router,
    private ngxPermissionsService: NgxPermissionsService,
    private permissionService: PermissionService,
    private store: Store<{}>,
    // private socket: Socket,
    private _location: Location,
    private _authOtpService: AuthOtpService,
    public _patientService: PatientService,
    private _authLocalStorageService: AuthLocalStorageService,
    private dialogRef: MatDialog
  ) {}

  sendLogin$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthLoginPageActions.clickLoginBtn),
      switchMap(({ email, password, token, rememberUUID }) =>
        this.authService
          .loginUser({
            email,
            password: this.authService.encryptPassword(password),
            token,
            rememberUUID,
          })
          .pipe(
            switchMap((data) => {
              if (data.token) {
                return of(
                  AuthApiActions.login({
                    password,
                    data: {
                      token: data.token,
                      refreshToken: data.refreshToken,
                      userInfo: data.userInfo,
                    },
                  })
                );
              }

              return of(
                AuthApiActions.saveOtpData({
                  data: { uuid: data.data.uuid, phone: data.data.phone },
                }),
                AuthApiActions.showOtpAuthScreen(),
                AuthApiActions.basicAuthSuccess()
              );
            }),
            catchError((error) => {
              let message = "Server Error";

              if (error && error.error && error.error.message) {
                message = error.error.message;
              }

              return of(AuthApiActions.basicAuthFailure({ error: message }));
            })
          )
      )
    )
  );

  setJwt$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(fromAuth.wikiSetJwt),
        map(({ jwt, roles }): any => {
          if (!!(jwt && this.env.wikiDomain)) {
            // Cookies.set('jwt', 'fskfdjklfdjlfjd', { domain: 'localhost' , expires: 365 });
            // Cookies.set('radar_roles', roles, { domain: 'localhost' , expires: 365 });

            Cookies.set("jwt", jwt, {
              domain: "." + this.env.wikiDomain.split(".").slice(1).join("."),
              expires: 365,
            });
            Cookies.set("radar_roles", roles, {
              domain: "." + this.env.wikiDomain.split(".").slice(1).join("."),
              expires: 365,
            });
          }
        })
      ),
    {
      dispatch: false,
    }
  );

  loginSuccess$ = createEffect((): any =>
    this.actions$.pipe(
      ofType(fromAuth.loginSuccess),
      switchMap(({ user }): any => {
        // check role and redirect
        this.ngxPermissionsService.flushPermissions();
        let userRole = user.role;
        if (userRole) {
          this.ngxPermissionsService.loadPermissions(
            this.permissionService.getPermissions(userRole)
          );

          // set the socket for role change
          this.roleChangeSocket(false);
          this.roleChangeSocket(true);

          // redirect user
          this.authService.redirectUser(userRole);
        }

        return of(
          fromAuth.wikiSetJwt({ jwt: user.wikiJwt, roles: user.allowedRoles })
        );
      }),
      catchError((error) => {
        return of(error);
      })
    )
  );

  logout$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(rootActions.logout),
        tap(() => {
          this._authLocalStorageService.clearLocalStorage();
          this.ngxPermissionsService.flushPermissions();
          this._router.navigate(["/login"]);

          // close all mat dialogs
          this.dialogRef.closeAll();

          // stop socket
          this.roleChangeSocket(false);

          // remove wiki.js cookie
          if (this.env.wikiDomain) {
            Cookies.remove("radar_roles", {
              path: "",
              domain: "." + this.env.wikiDomain.split(".").slice(1).join("."),
            });
            Cookies.remove("jwt", {
              path: "",
              domain: "." + this.env.wikiDomain.split(".").slice(1).join("."),
            });
          }

          // disconnect the socket
          socket.close();
        })
      ),
    { dispatch: false }
  );

  setUser$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(userActions.setUser),
        tap(() => {
          // set the socket for role change
          this.roleChangeSocket(false);
          this.roleChangeSocket(true);
        })
      ),
    { dispatch: false }
  );

  login$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.login),
      switchMap(({ password, data }) => {
        localStorage.setItem("token", data.token);
        localStorage.setItem("refreshToken", data.refreshToken);

        // if (!socket) {
        initiateSocketConnection();
        // }

        // changes by sidharth
        const localUserData = JSON.parse(
          JSON.stringify({ ...data.userInfo, currentUserState: true })
        );
        delete localUserData.wiki;
        localStorage.setItem("currentUser", JSON.stringify(localUserData));

        this.authService.setSessionExpirationInfo(null);

        if (data && data.userInfo) {
          data.userInfo.password = password;
        }

        return of(fromAuth.loginSuccess({ user: data.userInfo }));
      }),
      catchError(() => of(displayErrorMessage({ message: "Login Failed." })))
    )
  );

  // Otp Effects
  clickVerifyOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthOtpPageActions.clickVerifyOtp),
      switchMap(({ data, password }) =>
        this._authOtpService.verifyOtp(data).pipe(
          take(1),
          switchMap((response) => {
            if (data.remember) {
              this._authLocalStorageService.save2faRemember({
                token: data.uuid,
                timestamp: response.timestamp,
                email: data.email,
              });
            } else {
              this._authLocalStorageService.remove2faRemember(data.email);
            }

            return of(
              AuthApiActions.verifyOtpSuccess(),
              AuthApiActions.login({
                password,
                data: {
                  token: response.token,
                  refreshToken: response.refreshToken,
                  userInfo: response.userInfo,
                },
              })
            );
          }),
          catchError((err) => {
            let error = "Server Error";

            if (err && err.error && err.error.message) {
              error = err.error.message;
            }

            return of(AuthApiActions.verifyOtpFailure({ error }));
          })
        )
      )
    )
  );

  clickResendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthOtpPageActions.clickResendOtp),
      switchMap(({ data }) =>
        this._authOtpService.resendOtp(data).pipe(
          take(1),
          map((response) =>
            AuthApiActions.resendOtpSuccess({ message: "Successfully Sent" })
          ),
          catchError((err) => {
            let error = "Server Error";

            if (err && err.error && err.error.message) {
              error = err.error.message;
            }

            return of(AuthApiActions.resendOtpFailure({ error }));
          })
        )
      )
    )
  );

  // Forgot password effects
  forgotPasswordSendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.forgotPasswordSendOtp),
      switchMap(({ email }) =>
        this._authOtpService.forgotPasswordSendOtp(email).pipe(
          take(1),
          concatMap((response) => {
            return [
              AuthApiActions.saveOtpData({ data: response.data }),
              AuthApiActions.forgotPasswordSendOtpSuccess(),
            ];
          }),
          catchError((err) => {
            return of(
              AuthApiActions.forgotPasswordSendOtpFailure({
                error: err.error.message,
              })
            );
          })
        )
      )
    )
  );

  forgotPasswordResendOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.forgotPasswordResendOtp),
      switchMap(({ data }) =>
        this._authOtpService.resendOtp(data).pipe(
          take(1),
          map((response) =>
            AuthApiActions.forgotPasswordResendOtpSuccess({
              message: "Successfully Sent",
            })
          ),
          catchError((err) => {
            let error = "Server Error";

            if (err && err.error && err.error.message) {
              error = err.error.message;
            }

            return of(AuthApiActions.forgotPasswordResendOtpFailure({ error }));
          })
        )
      )
    )
  );

  forgotPasswordVerifyOtp$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.forgotPasswordVerifyOtp),
      switchMap(({ data }) =>
        this._authOtpService.forgotPasswordVerifyOtp(data).pipe(
          take(1),
          map((response) => {
            return AuthApiActions.forgotPasswordVerifyOtpSuccess();
          }),
          catchError((err) => {
            return of(
              AuthApiActions.forgotPasswordVerifyOtpFailure({
                error: err.error.message,
              })
            );
          })
        )
      )
    )
  );

  forgotPassword$ = createEffect(() =>
    this.actions$.pipe(
      ofType(AuthApiActions.forgotPassword),
      switchMap(({ email, uuid, password, otp }) =>
        this._authOtpService
          .forgotPassword(
            email,
            uuid,
            this.authService.encryptPassword(password),
            otp
          )
          .pipe(
            take(1),
            map((response) => {
              return AuthApiActions.resetPasswordSuccess();
            }),
            catchError((err) => {
              return of(
                AuthApiActions.resetPasswordFailure({
                  error: err.error.message,
                })
              );
            })
          )
      )
    )
  );

  roleChangeSocket(param) {
    if (param) {
      socket?.on("user-role-change", (data) => {
        let userLS = JSON.parse(localStorage.getItem("currentUser"));
        if (userLS.email === data.email) {
          if (userLS.role === data.role) {
            let userRole = data.role;
            this.authService.redirectUser(userRole, true);
          } else {
            this.store.dispatch(rootActions.logout());
          }
        }
      });
    } else {
      if (socket) {
        socket.removeAllListeners("user-role-change");
      }
    }
  }
}
