import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { AssessmentComponent } from "./assessment/assessment.component";
import { VitalsComponent } from "./vitals/vitals.component";

const routes: Routes = [
  {
    path: "vitals",
    component: VitalsComponent,
  },
  {
    path: "assessment",
    component: AssessmentComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class VitalsRoutingModule {}
