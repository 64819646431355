<div
  class="webcam-container display-flex flex-column justify-content-around align-items-center p-2"
>
  <div class="cp-title display-flex justify-content-between">
    <div class="title" id="title">Camera</div>
    <img
      src="assets/icons/close.svg"
      type="button"
      aria-describedby="title"
      matDialogClose
    />
  </div>
  <div class="webcam-container__inner-block">
    <video
      allow="camera"
      class="cp-w-100 cp-h-100"
      #videoPlayer
      [srcObject]="stream"
      autoplay
    ></video>
    <canvas class="cp-w-100 cp-h-100" #canvasElement></canvas>
  </div>
  <mat-dialog-actions class="display-flex align-self-end gap-2">
    <ng-template [ngIf]="videoMode" [ngIfElse]="canvasCta">
      <button
        type="button"
        class="button-secondary"
        mat-dialog-close
        mat-flat-button
        mat-dialog-close
      >
        Cancel
      </button>
      <button
        type="submit"
        class="button-primary"
        mat-flat-button
        (click)="takeSnapshot()"
      >
        Take a picture
      </button>
    </ng-template>
    <ng-template #canvasCta>
      <button
        type="button"
        class="button-secondary"
        mat-flat-button
        (click)="toggleback()"
      >
        Back
      </button>
      <button
        type="submit"
        class="button-primary"
        mat-dialog-close
        (click)="saveProfile()"
      >
        Save as profile picture
      </button>
    </ng-template>
  </mat-dialog-actions>
</div>
