import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-vent-history-view',
  template: `
	  <div class="row">
	    <div class="col-3 details-modal__text">
		    <div class="details-modal__text__title">
			    Airway:
		    </div>
		    <div class="details-modal__text__detail">
			    {{ order.airway | ventAirway }}
		    </div>
	    </div>

	    <div class="col-3 details-modal__text">
		    <div class="details-modal__text__title">
          Vent type:
		    </div>
		    <div class="details-modal__text__detail">
			    {{ order.ventType === 'Mech' ? 'Mech Invasive' : order.ventType }}
		    </div>
	    </div>

	    <div class="col-3 details-modal__text" *ngIf="order.mode">
		    <div class="details-modal__text__title">
			    Mode:
		    </div>
		    <div class="details-modal__text__detail">
			    {{ order.mode }}
		    </div>
	    </div>
	  
	    <div class="col-3 details-modal__text" *ngIf="order.subMode">
		    <div class="details-modal__text__title">
			    Breath Sequence:
		    </div>
		    <div class="details-modal__text__detail">
			    {{ order.subMode }}
		    </div>
	    </div>
        
	    <div class="col-3 details-modal__text" *ngIf="order.fio2">
		    <div class="details-modal__text__title">
		        FiO<sub>2</sub>:
		    </div>
		    <div class="details-modal__text__detail">
			    {{ order.fio2 }}
		    </div>
	    </div>
        
	    <div class="col-3 details-modal__text" *ngIf="order.tubeSize || order.tube">
			  <div class="details-modal__text__title">
				  Tube Size:
			  </div>
			  <div class="details-modal__text__detail">
				  {{ order.tube | ventTube }} {{ order.tubeSize }}
			  </div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.ettTieLevel">
			  <div class="details-modal__text__title">ETT Tie Level:</div>
			  <div class="details-modal__text__detail">{{ order.ettTieLevel }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.intType">
			  <div class="details-modal__text__title">Intubation Type:</div>
			  <div class="details-modal__text__detail">{{ order.intType }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text"
		       *ngIf="order.oralAirway && order.oralAirway === 'none'">
			  <div class="details-modal__text__title">Oral Airway:</div>
			  <div class="details-modal__text__detail">{{ order.oralAirway }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.pinsp">
			  <div class="details-modal__text__title">
				  P<sub>insp</sub>/IPAP:
			  </div>
			  <div class="details-modal__text__detail">{{ order.pinsp }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.rrset">
			  <div class="details-modal__text__title">
				  RR<sub>set</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.rrset }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.peep">
			  <div class="details-modal__text__title">
				  {{ order.ventType === 'NIV' ? 'CPAP/EPAP' : 'PEEP/EPAP' }}
			  </div>
			  <div class="details-modal__text__detail">{{ order.peep }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.flowRate">
			  <div class="details-modal__text__title">Flow Rate:</div>
			  <div class="details-modal__text__detail">{{ order.flowRate }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.vt">
			  <div class="details-modal__text__title">
				  Tidal Volume (V<sub>t</sub>):
			  </div>
			  <div class="details-modal__text__detail">{{ order.vt }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.psupport">
			  <div class="details-modal__text__title">PS:</div>
			  <div class="details-modal__text__detail">{{ order.psupport }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.pplat">
			  <div class="details-modal__text__title">
				  P<sub>plat</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.pplat }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.map">
			  <div class="details-modal__text__title">MAP (Airway):</div>
			  <div class="details-modal__text__detail">{{ order.map }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.mv">
			  <div class="details-modal__text__title">MV:</div>
			  <div class="details-modal__text__detail">{{ order.mv }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.rrtot">
			  <div class="details-modal__text__title">
				  RR<sub>total</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.rrtot }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.ppeak">
			  <div class="details-modal__text__title">
				  P<sub>peak</sub>:
			  </div>
			  <div class="details-modal__text__detail">{{ order.ppeak }}</div>
		  </div>
		
		  <div class="col-3 details-modal__text" *ngIf="order.inspiratory || order.expiratory">
			  <div class="details-modal__text__title">I:E :</div>
			  <div class="details-modal__text__detail">
				  {{ order.inspiratory }}:{{ order.expiratory }}
			  </div>
		  </div>
	  </div>
  
    <div class="details-modal__text">
	    <div class="details-modal__text__title">Start Time:</div>
	    <div class="details-modal__text__content">{{ order.startTime | date: 'medium' }}</div>
    </div>
  `,
  styleUrls: ['../details-modal-common.scss']
})
export class VentHistoryViewComponent {
  @Input() order;
}

