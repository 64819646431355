import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChatComponent } from "./chat.component";
import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { NgxPermissionsModule } from "ngx-permissions";

@NgModule({
  declarations: [ChatComponent],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IrisComponentsModule,
    NgxPermissionsModule.forChild(),
  ],
  exports: [ChatComponent, IrisComponentsModule],
})
export class ChatModule {}
