import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";
import * as fromLabsForm from "./lab-forms.reducer";
import * as fromLabDocs from "./lab-documents.reducer";
import * as fromUploadFiles from "./upload-file.reducer";
import * as fromVisionTask from "./vision-task.reducer";
import * as fromLabRequest from "./lab-request.reducer";

export interface LabState {
  labForms: fromLabsForm.LabFormState;
  labDocuments: fromLabDocs.LabDocumentState;
  uploadFiles: fromUploadFiles.UploadFileState;
  visionTask: fromVisionTask.VisionTaskStatus;
  labRequest: fromLabRequest.LabRequestState;
}

export const reducers: ActionReducerMap<LabState> = {
  labForms: fromLabsForm.reducer,
  labDocuments: fromLabDocs.reducer,
  uploadFiles: fromUploadFiles.reducer,
  visionTask: fromVisionTask.reducer,
  labRequest: fromLabRequest.reducer,
};

export const getLabState = createFeatureSelector<LabState>("labs");
export const getLabsForms = createSelector(
  getLabState,
  (state: LabState) => state.labForms
);
export const getDocs = createSelector(
  getLabState,
  (state: LabState) => state.labDocuments
);

export const getFile = createSelector(
  getLabState,
  (state: LabState) => state.uploadFiles
);

export const getVision = createSelector(
  getLabState,
  (state: LabState) => state.visionTask
);

export const getLabRequest = createSelector(
  getLabState,
  (state: LabState) => state.labRequest
);

export const getAllLabForm = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormEntities
);
export const getLabFormLoaded = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormLoaded
);
export const getLabsformLoading = createSelector(
  getLabsForms,
  fromLabsForm.getLabFormLoading
);

export const getAllLabs = createSelector(getDocs, fromLabDocs.getLabEntities);

export const getAllScans = createSelector(getDocs, fromLabDocs.getScanEntities);

export const getAllLabDocument = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentEntities
);

export const getAllDocuments = createSelector(
  getDocs,
  fromLabDocs.getDocumentEntities
);
export const getLabDocumentLoaded = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentLoaded
);
export const getLabsDocumentLoading = createSelector(
  getDocs,
  fromLabDocs.getLabDocumentLoading
);

export const getAllUploadFile = createSelector(
  getFile,
  fromUploadFiles.getUploadFileEntities
);
export const getUploadFileLoaded = createSelector(
  getFile,
  fromUploadFiles.getUploadFileLoaded
);
export const getUploadFileLoading = createSelector(
  getFile,
  fromUploadFiles.getUploadFileLoading
);

export const getUploadFileGcpUploads = createSelector(
  getFile,
  fromUploadFiles.getUploadFileGcpUploads
);

export const getVisionData = createSelector(
  getVision,
  fromVisionTask.getVisionEntities
);
export const getVisionDataLoadingStatus = createSelector(
  getVision,
  fromVisionTask.getVisionLoading
);
export const getVisionDataLoadedStatus = createSelector(
  getVision,
  fromVisionTask.getVisionLoaded
);

export const getAllLabRequest = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestEntities
);
export const getAllLabRequestLoaded = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestLoaded
);
export const getAllLabRequestLoading = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestLoading
);

export const getAllLabRequestErrorMsg = createSelector(
  getLabRequest,
  fromLabRequest.getLabRequestErrorMsg
);
