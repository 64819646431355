import { Component, OnInit, ViewChild } from "@angular/core";
import {
  AvatarParams,
  AVATAR_SIZE,
  AVATAR_STATUS,
} from "@iris/avatar/components/avatar/avatar.component";
import {
  FormControl,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { Label } from "@iris/label-badge";
import { AlertService } from "@iris/alert/services/alert.service";
import {
  CpProgressBarSection,
  PROGRESS_BAR_TYPE,
} from "@iris/progress-bar/components/progress-bar.component";
import { AddUserComponent } from "src/app/management/components/add-user/add-user.component";
import { MatDialog } from "@angular/material/dialog";
import { MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";
import { Subject } from "rxjs";
import { OverlayComponent } from "@iris/overlay/components/overlay/overlay.component";
import { Alert } from "@iris/alert/models/alert.model";
import { StatusChipInterface } from "@iris/status-chip/models";
const calenderIcon = require("!!raw-loader!../../../assets/icons/calendar.svg")
  .default as string;
const calenderArrowDownIcon =
  require("!!raw-loader!../../../assets/icons/arrow_drop.svg")
    .default as string;

export interface PeriodicElement {
  name: string;
  position: number;
  weight: number;
  symbol: string;
}

const ELEMENT_DATA: PeriodicElement[] = [
  { position: 1, name: "Hydrogen", weight: 1.0079, symbol: "H" },
  { position: 2, name: "Helium", weight: 4.0026, symbol: "He" },
  { position: 3, name: "Lithium", weight: 6.941, symbol: "Li" },
  { position: 4, name: "Beryllium", weight: 9.0122, symbol: "Be" },
  { position: 5, name: "Boron", weight: 10.811, symbol: "B" },
  { position: 6, name: "Carbon", weight: 12.0107, symbol: "C" },
  { position: 7, name: "Nitrogen", weight: 14.0067, symbol: "N" },
  { position: 8, name: "Oxygen", weight: 15.9994, symbol: "O" },
  { position: 9, name: "Fluorine", weight: 18.9984, symbol: "F" },
  { position: 10, name: "Neon", weight: 20.1797, symbol: "Ne" },
];

@Component({
  selector: "app-iris-design-system",
  templateUrl: "./iris-design-system.component.html",
  styleUrls: ["./iris-design-system.component.scss"],
})
export class IrisDesignSystemComponent implements OnInit {
  resetDateTime: Subject<any> = new Subject<any>();

  @ViewChild("overlayComp1") overlayComp1: OverlayComponent;
  @ViewChild("overlayComp2") overlayComp2: OverlayComponent;
  @ViewChild("overlayComp3") overlayComp3: OverlayComponent;

  iconName: string;
  btnTagNames: string[] = ["Hyperkalemia", "COVID", "Neisseria"];
  iconLeft = "assets/icons/check_circle.svg";
  iconRight = "assets/icons/arrow_drop.svg";

  minValidDate = new Date();

  progressBarArr: CpProgressBarSection[];

  AVATAR_SIZE: typeof AVATAR_SIZE = AVATAR_SIZE;
  AVATAR_STATUS: typeof AVATAR_STATUS = AVATAR_STATUS;
  PROGRESS_BAR_TYPE: typeof PROGRESS_BAR_TYPE = PROGRESS_BAR_TYPE;

  countries = [
    "India",
    "England",
    "Netherlands",
    "India",
    "England",
    "Netherlands",
    "India",
    "England",
    "Netherlands",
    "India",
    "England",
    "Netherlands",
  ];

  highRiskHospitals = new UntypedFormControl("");

  avatarParamsIcon1: AvatarParams[];
  avatarParamsIcon2: AvatarParams[];
  avatarParamsIcon3: AvatarParams[];
  avatarParamsIcon4: AvatarParams[];

  avatarParamsImage1: AvatarParams[];
  avatarParamsImage2: AvatarParams[];
  avatarParamsImage3: AvatarParams[];
  avatarParamsImage4: AvatarParams[];

  avatarParamsInitials1: AvatarParams[];
  avatarParamsInitials2: AvatarParams[];
  avatarParamsInitials3: AvatarParams[];
  avatarParamsInitials4: AvatarParams[];

  avatarParamsGroup1: AvatarParams[];
  avatarParamsGroup2: AvatarParams[];
  avatarParamsGroup3: AvatarParams[];
  avatarParamsGroup4: AvatarParams[];

  avatarParamsGroup5: AvatarParams[];
  avatarParamsGroup6: AvatarParams[];
  avatarParamsGroup7: AvatarParams[];
  avatarParamsGroup8: AvatarParams[];

  avatarParamsActive1: AvatarParams[];
  avatarParamsActive2: AvatarParams[];
  avatarParamsActive3: AvatarParams[];
  avatarParamsActive4: AvatarParams[];

  avatarParamsAway1: AvatarParams[];
  avatarParamsAway2: AvatarParams[];
  avatarParamsAway3: AvatarParams[];
  avatarParamsAway4: AvatarParams[];

  displayedColumns: string[] = ["position", "name", "weight", "symbol"];
  dataSource = ELEMENT_DATA;

  title = "This is a title";
  content =
    "Feature discovery tooltips are best used for onboarding experiences and product tours";

  showLoader: boolean;
  showButtonLoader: boolean;
  // forms

  disabled = true;
  required = true;

  //badge
  public labelData: Label = {
    info: "Label",
    icon: {
      iconUrl: "../../assets/icons/check_circle.svg",
      iconName: "checkUsdfjsdfdsfjkCircle",
    },
  };
  form: UntypedFormGroup;

  //Date Time
  date1: string;
  date2: string;
  hour: number;
  minute: number;

  fromDate: string;
  toDate: string;

  dateGroup: UntypedFormGroup;
  defaultDate = new Date();

  mulpleCheckboxes = new UntypedFormControl(["option 1"]);

  constructor(
    private _alertService: AlertService,
    private formBuilder: UntypedFormBuilder,
    public dialog: MatDialog,
    iconRegistry: MatIconRegistry,
    sanitizer: DomSanitizer,
    public fb: UntypedFormBuilder
  ) {
    this.dateGroup = fb.group({
      dateTimeControl: [null],
      dateRangeControl: [null],
    });

    this.treeNodeForm.valueChanges.subscribe((data) => {
      console.log(data, "tree");
    });

    this.colorsControl.valueChanges.subscribe((data) => {
      console.log(data);
    });
    iconRegistry.addSvgIconLiteral(
      "cp-calender",
      sanitizer.bypassSecurityTrustHtml(calenderIcon)
    );
    iconRegistry.addSvgIconLiteral(
      "cp-arrow-down",
      sanitizer.bypassSecurityTrustHtml(calenderArrowDownIcon)
    );

    // radiobutton

    this.highRiskHospitals.valueChanges.subscribe((data) => {
      console.log(data, "NOEOWO");
    });

    //Forms
    this.form = this.formBuilder.group({
      name: new UntypedFormControl(
        { value: "Rakesh", disabled: false },
        Validators.required
      ),
      // password:new FormControl({value: '', disabled: false},Validators.required  ),
      // search:new FormControl({value: '', disabled: false},Validators.required  ),
    });
  }

  ngOnInit(): void {
    this.setAvatarParams();
    this.setProgressBarSections();

    this.dateGroup.controls.dateTimeControl.valueChanges.subscribe(
      (timeStamp) => {
        console.log(new Date(timeStamp));
      }
    );

    this.dateGroup.controls.dateRangeControl.valueChanges.subscribe((value) => {
      console.log(value);
    });

    this.selectAndInputFormControl.valueChanges.subscribe((data) => {
      console.log(data);
    });
  }

  setAvatarParams() {
    this.avatarParamsInitials1 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.L,
      },
    ];

    this.avatarParamsInitials2 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.M,
      },
    ];

    this.avatarParamsInitials3 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.S,
      },
    ];

    this.avatarParamsInitials4 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.XS,
      },
    ];

    this.avatarParamsImage1 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
    ];

    this.avatarParamsImage2 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
    ];

    this.avatarParamsImage3 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
    ];

    this.avatarParamsImage4 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
    ];

    this.avatarParamsIcon1 = [
      {
        size: AVATAR_SIZE.L,
      },
    ];

    this.avatarParamsIcon2 = [
      {
        size: AVATAR_SIZE.M,
      },
    ];

    this.avatarParamsIcon3 = [
      {
        size: AVATAR_SIZE.S,
      },
    ];

    this.avatarParamsIcon4 = [
      {
        size: AVATAR_SIZE.XS,
      },
    ];

    this.avatarParamsGroup1 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.L,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.L,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.L,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.L,
      },
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.L,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.L,
      },
    ];

    this.avatarParamsGroup2 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.M,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.M,
      },
    ];

    this.avatarParamsGroup3 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.S,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.S,
      },
    ];

    this.avatarParamsGroup4 = [
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Sapna Kumari",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Jane Sloan",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Sutton Braidy",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.XS,
      },
      {
        name: "Kat Edison",
        size: AVATAR_SIZE.XS,
      },
    ];

    this.avatarParamsGroup5 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
      },
    ];

    this.avatarParamsGroup6 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.M,
      },
    ];

    this.avatarParamsGroup7 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.S,
      },
    ];

    this.avatarParamsGroup8 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.XS,
      },
    ];

    (this.avatarParamsActive1 = [
      {
        imageUrl:
          "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
        size: AVATAR_SIZE.L,
        status: AVATAR_STATUS.ACTIVE,
      },
    ]),
      (this.avatarParamsActive2 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.M,
          status: AVATAR_STATUS.ACTIVE,
        },
      ]),
      (this.avatarParamsActive3 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.S,
          status: AVATAR_STATUS.ACTIVE,
        },
      ]),
      (this.avatarParamsActive4 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.XS,
          status: AVATAR_STATUS.ACTIVE,
        },
      ]),
      (this.avatarParamsAway1 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.L,
          status: AVATAR_STATUS.AWAY,
        },
      ]),
      (this.avatarParamsAway2 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.M,
          status: AVATAR_STATUS.AWAY,
        },
      ]),
      (this.avatarParamsAway3 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.S,
          status: AVATAR_STATUS.AWAY,
        },
      ]),
      (this.avatarParamsAway4 = [
        {
          imageUrl:
            "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSy2Vzf90CMXNaJzJ6kKGm-nGNhmYIhkDGEFV6SzvfcuEY5MlvGLFfPHFlOwxVTWuqFZm0&usqp=CAU",
          size: AVATAR_SIZE.XS,
          status: AVATAR_STATUS.AWAY,
        },
      ]);
  }

  setProgressBarSections() {
    this.progressBarArr = [
      {
        type: PROGRESS_BAR_TYPE.STOPPED,
        minVal: 0,
        maxVal: 20,
      },
      {
        type: PROGRESS_BAR_TYPE.PAUSED,
        minVal: 20,
        maxVal: 40,
        text: "Paused",
        align: "center",
      },
      {
        type: PROGRESS_BAR_TYPE.PROGRESS,
        minVal: 40,
        maxVal: 100,
        text: "100%",
        align: "right",
        popoverContent: `Scheduled at : 7:00`,
      },
    ];
  }

  // alert component
  info() {
    const displayMessage: Alert = {
      type: "Info",
      message:
        "Message Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia eaque harum explicabo ratione in nisi officia quod asperiores! Pariatur sed labore eius? Blanditiis, incidunt? Adipisci in aut explicabo voluptate asperiores",
    };

    this._alertService.showNotification(displayMessage, "left", "top");
  }

  success() {
    const displayMessage: Alert = {
      type: "Success",
      message:
        "Message Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia eaque harum explicabo ratione in nisi officia quod asperiores! Pariatur sed labore eius? Blanditiis, incidunt? Adipisci in aut explicabo voluptate asperiores",
    };

    this._alertService.showNotification(displayMessage);
  }

  warning() {
    const displayMessage: Alert = {
      type: "Warning",
      message:
        "Message Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia eaque harum explicabo ratione in nisi officia quod asperiores! Pariatur sed labore eius? Blanditiis, incidunt? Adipisci in aut explicabo voluptate asperiores",
    };

    this._alertService.showNotification(displayMessage);
  }

  error() {
    const displayMessage: Alert = {
      type: "Error",
      message:
        "Message Description Lorem ipsum dolor sit amet consectetur adipisicing elit. Quia eaque harum explicabo ratione in nisi officia quod asperiores! Pariatur sed labore eius? Blanditiis, incidunt? Adipisci in aut explicabo voluptate asperiores",
    };

    this._alertService.showNotification(displayMessage);
  }

  onIconClick(isLefticonClicked: boolean) {
    if (isLefticonClicked) this.iconName = "leftIcon";
    else this.iconName = "rightIcon";
  }

  onLoaderClick(loaderType: string) {
    switch (loaderType) {
      case "pageLoader":
        this.showLoader = true;

        setTimeout(() => {
          this.showLoader = false;
        }, 3000);

        break;

      case "buttonLoader":
        this.showButtonLoader = true;

        setTimeout(() => {
          this.showButtonLoader = false;
        }, 10000);

        break;
    }
  }

  onDateChange(date: Date, withTime: boolean) {
    if (date) {
      if (withTime) this.date2 = date.toLocaleString().slice(0, 10);
      else this.date1 = date.toLocaleString().slice(0, 10);
    } else {
      this.date2 = null;
    }
  }

  onTimeChange(time: string) {
    if (time) {
      const split = time.split(":");
      this.hour = parseInt(split[0], 10);
      this.minute = parseInt(split[1], 10);
    } else {
      this.hour = null;
      this.minute = null;
    }
  }

  onDateRangeChange(dateRange: string) {
    if (dateRange) {
      const range = dateRange.split("-");
      this.fromDate = range[0];
      this.toDate = range[1];
    } else {
      this.fromDate = null;
      this.toDate = null;
    }
  }

  onResetDateTime() {
    this.resetDateTime.next();
  }

  submit() {
    alert(JSON.stringify(this.form.value));
  }

  openDialog() {
    const dialogRef = this.dialog.open(AddUserComponent, {
      width: "699px",
      data: { userType: "Test" },
    });

    dialogRef.afterClosed().subscribe((result) => {
      console.log("The dialog was closed");
    });
  }

  openCdkOverlay() {
    this.overlayComp1.openModal();
  }

  openCdkOverlayWidth65() {
    this.overlayComp2.openModal();
  }

  openCdkOverlayWidth50() {
    this.overlayComp3.openModal();
  }

  // MAT SELECT COMPONENTS

  // toppingsControl = new FormControl([]);
  // toppingList: string[] = ['Extra cheese', 'Mushroom', 'Onion', 'Pepperoni', 'Sausage', 'Tomato'];

  // onToppingRemoved(topping: string) {
  //   const toppings = this.toppingsControl.value as string[];
  //   this.removeFirst(toppings, topping);
  //   this.toppingsControl.setValue(toppings); // To trigger change detection
  // }

  // private removeFirst<T>(array: T[], toRemove: T): void {
  //   const index = array.indexOf(toRemove);
  //   if (index !== -1) {
  //     array.splice(index, 1);
  //   }
  // }

  // MAT SELECT SECOND

  colorsControl = new UntypedFormControl([]);
  colorsList: string[] = ["red", "blue", "green", "yellow", "pink", "black"];

  onColorRemoved(color: string) {
    const colors = this.colorsControl.value as string[];
    this.removeFirst(colors, color);
    this.colorsControl.setValue(colors, { emitEvent: true }); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }

  // checkboxtree

  treeNodeForm = this.fb.group({
    node: [null],
  });

  treeNodes = [
    {
      id: 1,
      label: "Node 1",
      children: [
        {
          id: 2,
          label: "Node 1.1",
          children: [
            {
              id: 3,
              label: "Node 1.1.1",
              children: [],
              checked: false,
            },
          ],
          checked: false,
        },
        {
          id: 4,
          label: "Node 1.2",
          children: [
            {
              id: 3,
              label: "Node 1.2.1",
              children: [
                {
                  id: 3,
                  label: "Node 1.2.1",
                  children: [],
                  checked: false,
                },
                {
                  id: 8,
                  label: "Node 1.2.2",
                  children: [],
                  checked: false,
                },
                {
                  id: 9,
                  label: "Node 1.2.3",
                  children: [],
                  checked: false,
                },
                {
                  id: 7,
                  label: "Node 1.2.4",
                  children: [],
                  checked: false,
                },
              ],
              checked: false,
            },
          ],
          checked: false,
        },
      ],
      checked: false,
    },
  ];

  statusConfigPayload: StatusChipInterface = {
    buttonText: "Booked",
    isNextIconVisible: true,
    colorClass: ["class1"].filter((clrClass) => clrClass),
  };

  onButtonClick() {
    // Logic to get checked nodes and perform desired action
  }

  selectAndInputFormControl: FormControl = new FormControl("");

  selectaAndInputOothersList: string[] = [
    "Option 1",
    "Option 2",
    "Option 3",
    "Others",
  ];
}
