import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ConfimDialogComponent } from "./components/comfirm-dialog/confim-dialog.component";
import { MatIconModule } from "@angular/material/icon";
import { MatDialogModule } from "@angular/material/dialog";

@NgModule({
  declarations: [ConfimDialogComponent],
  imports: [CommonModule, MatIconModule, MatDialogModule],
  exports: [ConfimDialogComponent],
})
export class ConfimDialogModule {}
