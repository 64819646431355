import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  Inject,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, FormControl, NgControl } from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { DateInterface } from "../models/dateInterface.type";

@Component({
  selector: "cp-date-picker",
  templateUrl: "./date-picker.component.html",
  providers: [
    { provide: MatFormFieldControl, useExisting: DatePickerComponent },
  ],
  host: {
    "[class.example-floating]": "shouldLabelFloat",
    "[id]": "id",
  },
  styles: [
    `
      .cp-date-picker-comp {
        position: relative;
      }
    `,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerComponent
  implements
    ControlValueAccessor,
    OnInit,
    OnChanges,
    MatFormFieldControl<DateInterface>,
    OnDestroy
{
  static nextId = 0;
  @ViewChild("date") dateInput: HTMLInputElement;

  unsubscribe$: Subject<any> = new Subject<any>();

  datePicker = new FormControl(null);
  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = "date-picker";
  id = `date-picker-${DatePickerComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};
  isCalOpen: boolean = false;

  get empty() {
    return !this.datePicker.value;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input("min") min;
  @Input("max") max;

  @Input("aria-describedby") userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder || "yyyy/mm/dd";
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled ? this.datePicker.disable() : this.datePicker.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): DateInterface {
    return this.datePicker.value;
  }
  set value(date: DateInterface) {
    this.datePicker.setValue(date);
  }

  get errorState(): boolean {
    return this.ngControl.invalid && this.touched;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private readonly _changeDetectorRef: ChangeDetectorRef,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.min || changes.max) {
      this._changeDetectorRef.detectChanges();
    }
  }
  ngOnInit(): void {
    this.initializeListeners();
  }

  initializeListeners() {
    this.datePicker.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        let isoString = null;
        if (val) {
          isoString = new Date(val).toISOString();
        }
        this.onChange(isoString || val);
      });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(
        event.relatedTarget as Element
      ) &&
      !this.isCalOpen
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".cp-date-picker-comp"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick() {
    this._focusMonitor.focusVia(this.dateInput, "program");
  }

  writeValue(date: DateInterface): void {
    this.value = date;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }

  calendarOpen(): void {
    this.isCalOpen = true;
  }

  calendarClose(): void {
    this.isCalOpen = false;
  }
}
