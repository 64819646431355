import {
  Component,
  ElementRef,
  OnDestroy,
  OnInit,
  Renderer2,
  ViewChild,
} from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { FileService } from "src/app/services/file.service";

@Component({
  selector: "app-open-webcam-modal",
  templateUrl: "./open-webcam-modal.component.html",
  styleUrls: ["./open-webcam-modal.component.scss"],
})
export class OpenWebcamModalComponent implements OnInit, OnDestroy {
  snapshotUrl: File;

  constructor(
    private renderer: Renderer2,
    private _dialogRef: MatDialogRef<OpenWebcamModalComponent>,
    private _fileService: FileService
  ) {}

  public stream: MediaStream;
  public error: boolean = false;

  private unsubscribe$ = new Subject<any>();

  videoMode = true;

  @ViewChild("canvasElement") canvasElement: ElementRef<HTMLCanvasElement>;
  @ViewChild("videoPlayer") videoPlayer: ElementRef<HTMLVideoElement>;

  ngOnInit() {
    if (this.videoMode) {
      navigator.mediaDevices
        .getUserMedia({ video: true })
        .then((stream) => {
          this.stream = stream;
        })
        .catch((error) => {
          console.error("Error accessing webcam", error);
        });
    }
  }

  ngOnDestroy(): void {
    if (this.stream) {
      this.stream.getTracks().forEach((track) => {
        track.stop();
      });
    }
  }

  public takeSnapshot() {
    setTimeout(() => {
      const canvas = this.canvasElement.nativeElement;
      const context = canvas.getContext("2d");

      canvas.width = this.videoPlayer.nativeElement.videoWidth;
      canvas.height = this.videoPlayer.nativeElement.videoHeight;

      context.drawImage(
        this.videoPlayer.nativeElement,
        0,
        0,
        canvas.width,
        canvas.height
      );

      this.renderer.setStyle(this.videoPlayer.nativeElement, "display", "none");
      this.renderer.setStyle(canvas, "display", "block");
      // Convert canvas image to data URL
      // Convert canvas image to data URL
      const dataUrl = canvas.toDataURL("image/png");

      // Convert data URL to Blob
      const binary = atob(dataUrl.split(",")[1]);
      const array = [];
      for (let i = 0; i < binary.length; i++) {
        array.push(binary.charCodeAt(i));
      }
      const blob = new Blob([new Uint8Array(array)], { type: "image/jpeg" });

      // Create File object from Blob
      const file = new File([blob], "snapshot.png", { type: "image/jpeg" });

      // data URL
      this.snapshotUrl = file;
      this.videoMode = false; // Set display mode to canvas after taking snapshot
    }, 1000);
  }

  saveProfile() {
    this._dialogRef.close(this.snapshotUrl);
  }

  toggleback() {
    this.renderer.setStyle(this.videoPlayer.nativeElement, "display", "block");
    this.renderer.setStyle(this.canvasElement.nativeElement, "display", "none");

    this.videoMode = true; // Set display mode of video
  }
}
