import { Injectable } from "@angular/core";
import { Observable, throwError, BehaviorSubject, Subject } from "rxjs";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { catchError } from "rxjs/operators";
import { Content, Cosign, NoteForm, Notes } from "../model/notes.model";
import { environment } from "src/environments/environment";
import { Store, select } from "@ngrx/store";
import * as fromUserReducer from "src/app/store/reducers/user/index";

@Injectable({
  providedIn: "root",
})
export class NotesService {
  env = environment;
  public user;
  public author;
  public user$ = this.store.pipe(select(fromUserReducer.getCurrUser));
  private editFormDataSource = new Subject<NoteForm>();
  private cosignDataSource = new Subject<Cosign>();
  private showAddednumNoteSource = new BehaviorSubject<boolean>(false);
  private popUpCloseTriggerSource = new BehaviorSubject<boolean>(false);
  public editNote$ = this.editFormDataSource.asObservable();
  public cosign$ = this.cosignDataSource.asObservable();
  public showAddednumNote$ = this.showAddednumNoteSource.asObservable();
  public popUpCloseTrigger$ = this.popUpCloseTriggerSource.asObservable();
  public header = new Headers();

  constructor(private http: HttpClient, private store: Store<{}>) {
    this.user$.subscribe((data) => {
      if (data && data.email) {
        this.user = data;
        let author = "";

        if (data.title) {
          author += data.title + " ";
        }

        author += data.name;

        if (data["qualification"]) {
          author += " " + data["qualification"];
        }
        this.author = author;
      }
    });
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  passFormDataToNote(editFormData: NoteForm) {
    if (editFormData) {
      this.editFormDataSource.next(editFormData);
    }
  }

  passCosigneeInfo(cosign: Cosign) {
    if (cosign) {
      this.cosignDataSource.next(cosign);
    }
  }

  showAddednumNote(state: boolean) {
    this.showAddednumNoteSource.next(state);
  }

  popUpCloseTrigger(state: boolean) {
    this.popUpCloseTriggerSource.next(state);
  }

  createNote(CPMRN, encounters, notes): Observable<Notes> {
    // following the preexisting payload structure, will be refactored in future
    let data = {
      note: notes,
    };
    return this.http
      .post<Notes>(
        `${this.env.apiUrl}notes/${CPMRN}/${encounters}`,
        data,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  deletePendedNote(deletePend): Observable<any> {
    return this.http
      .put<any>(
        this.env.apiUrl + "notes/deletepended/",
        { ...deletePend },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  getNote(CPMRN, encounters, currentUser): Observable<Notes[]> {
    return this.http
      .get<Notes[]>(
        `${this.env.apiUrl}notes/${CPMRN}/${encounters}/${currentUser}`
      )
      .pipe(catchError(this.errorHandler));
  }

  getCosignIntensivist(
    commandCenterID: string,
    hospitalID: string,
    authorName: string
  ): Observable<any> {
    return this.http
      .get<Notes[]>(
        `${this.env.apiUrl}notes/cosignintesivist/${commandCenterID}/${hospitalID}/${authorName}`,
        { observe: "response" }
      )
      .pipe(catchError(this.errorHandler));
  }

  updateDraftNote(CPMRN, encounters, note): Observable<Content> {
    return this.http
      .put<Content>(
        `${this.env.apiUrl}notes/notes/updatedraft/${CPMRN}/${encounters}`,
        note,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  lockNote(data): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/locknote/", data)
      .pipe(catchError(this.errorHandler));
  }

  deleteDraft(CPMRN, encounters, refId): Observable<any> {
    return this.http
      .put<any>(
        this.env.apiUrl + "notes/deletedraft/",
        {
          CPMRN,
          encounters,
          refId,
        },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  attestNote(CPMRN, encounters, refId, cosign, addendumArray): Observable<any> {
    return this.http
      .put<any>(this.env.apiUrl + "notes/attestNote/", {
        CPMRN,
        encounters,
        refId,
        cosign,
        addendumArray,
      })
      .pipe(catchError(this.errorHandler));
  }

  updateNote(CPMRN, encounters, id, note): Observable<Content> {
    return this.http
      .put<Content>(
        this.env.apiUrl + "notes/" + CPMRN + "/" + encounters + "/" + id,
        note,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }
}
