import * as fromnotesReducer from "./notes.reducer";
import * as fromnotesViewReducer from "./notes-view.reducer";
import { AppState } from "../..";
import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

export interface notesState {
  data: fromnotesReducer.State;
  view: fromnotesViewReducer.State;
}

export interface State extends AppState {
  notes: notesState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromnotesReducer.reducer,
  view: fromnotesViewReducer.reducer,
};

/** SELECTOR setup */
export const getNotesDataState = createFeatureSelector<notesState>("notes");

export const getNotesData = createSelector(
  getNotesDataState,
  (state) => state.data
);

export const getNoteViewState = createSelector(
  getNotesDataState,
  (state) => state.view
);

export const getNoteHighlight = createSelector(
  getNoteViewState,
  (state) => state.indicator
);

// Form
// export const getVitalForm = createSelector(
//     getVitalDataState,
//     (state) => (state.form)
// )
