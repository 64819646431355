<div
  class="cp-appointment-duration-comp"
  [formGroup]="appointmentForm"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
>
  <span>
    <input
      #durationInput
      formControlName="duration"
      type="number"
      matInput
      [placeholder]="placeholder"
    />
  </span>
  <span #unitContainer>
    <mat-select formControlName="unit" #unitInput>
      <mat-option *ngFor="let unit of units" [value]="unit">
        {{ unit }}
      </mat-option>
    </mat-select>
  </span>
</div>
