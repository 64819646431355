import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OverlayComponent } from "./components/overlay/overlay.component";
import { PortalModule } from "@angular/cdk/portal";

@NgModule({
  declarations: [OverlayComponent],
  imports: [CommonModule, PortalModule],
  exports: [OverlayComponent],
})
export class OverlayModule {}
