import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { BtnTagComponent } from "./components/btn-tag/btn-tag.component";

@NgModule({
  declarations: [BtnTagComponent],
  imports: [CommonModule],
  exports: [BtnTagComponent],
})
export class BtnTagModule {}
