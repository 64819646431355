import { Injectable } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { AlertComponent } from "@iris/alert/components/alert.component";

import { Alert } from "@iris/alert/models/alert.model";

@Injectable({
  providedIn: "root",
})
export class AlertService {
  constructor(private snackBar: MatSnackBar) {}

  /**
   *
   * @param displayMessage @type {{type: string(Info,Warning,Success,Error), message: string}}
   * @param horizontalPosition @type {string}
   * @param verticalPosition @type {string}
   */
  showNotification(
    displayMessage: Alert,
    horizontalPosition: any = "center",
    verticalPosition: any = "bottom",
    duration = 1000
  ) {
    this.snackBar.openFromComponent(AlertComponent, {
      data: displayMessage,
      duration,
      horizontalPosition,
      verticalPosition,
    });
  }

  public clearNotification(): void {
    this.snackBar?.dismiss();
  }
}
