<section
  class="iris-modal-container iris-modal-container--no-overflow form-container add-user"
>
  <div class="form-title">
    {{ data?.user?._id ? "Edit" : "Register" }}
    {{ data.userType | lowercase }}
    <mat-icon
      class="form-title__close cursor-pointer cp-icon"
      [mat-dialog-close]="true"
      >close</mat-icon
    >
  </div>
  <div class="form-body form-body-overflow form-body--no-border">
    <form class="iris-form" [formGroup]="userForm" autocomplete="off">
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <cp-upload-file
              [file]="profilePic"
              [getFileLoader]="imageLoader"
              formControlName="profilePicture"
              label="Upload a profile picture. Max size 2 MB"
            ></cp-upload-file>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <cp-upload-file
              formControlName="signature"
              [file]="signature"
              [getFileLoader]="imageLoader"
              label="Upload signature. Max size 2 MB"
            ></cp-upload-file>
          </div>
        </div>
      </div>
      <div class="iris-v-row mt">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Title:</mat-label>
              <input
                class="iris-input"
                formControlName="title"
                matInput
                placeholder="Enter the title"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Name*:</mat-label>
              <input
                class="iris-input"
                formControlName="name"
                matInput
                placeholder="Enter the name"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Email*:</mat-label>
              <input
                class="iris-input"
                formControlName="email"
                matInput
                placeholder="Enter the email"
              />
              <mat-error>Enter a valid email id</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field
              [floatLabel]="'always'"
              class="cp-mobile-label"
              hideRequiredMarker="true"
            >
              <mat-label>Mobile number*:</mat-label>
              <input
                class="iris-input"
                formControlName="phone"
                matInput
                placeholder="(+91) XXXXXXXXXX"
              />
              <mat-error *ngIf="this.userForm?.get('phone')?.errors?.pattern"
                >Invalid mobile number</mat-error
              >
              <mat-error *ngIf="this.userForm?.get('phone')?.errors?.required"
                >Mandatory field</mat-error
              >
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Registration ID:</mat-label>
              <input
                class="iris-input"
                formControlName="registration_id"
                matInput
                placeholder="Enter the registration id"
              />
              <mat-error>Enter a valid registration id</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Employee Code:</mat-label>
              <input
                placeholder="Enter the employee code"
                class="iris-input"
                formControlName="employeeCode"
                matInput
              />
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Qualification:</mat-label>
              <input
                class="iris-input"
                formControlName="qualification"
                matInput
                placeholder="Enter the qualification"
              />
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div
            class="iris-form-row"
            *ngIf="!isAdmin && data?.hospitals?.length"
          >
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Hospital*:</mat-label>
              <mat-select
                (selectionChange)="setHospital()"
                placeholder="Select the hospital"
                class="iris-input"
                [formControl]="userForm.get('hospitals')"
                multiple
              >
                <mat-option
                  *ngFor="let hospital of data.hospitals"
                  [value]="hospital._id"
                  >{{ hospital.name }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
              <div
                *ngIf="
                  userForm.controls['hospitals'].touched &&
                  userForm.errors?.hospitals
                "
                class="iris-error"
              >
                Note: Add ateast one hospital!
              </div>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdmin && data?.hospitals?.length" class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Department:</mat-label>
              <mat-select
                placeholder="Select the department"
                class="iris-input"
                [formControl]="userForm.get('departments')"
                multiple
              >
                <mat-option
                  *ngFor="let department of departments"
                  [value]="department"
                >
                  {{ department }}
                </mat-option>
              </mat-select>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field
              *ngIf="selUnits?.length; else noUnitsAdded"
              [floatLabel]="'always'"
              hideRequiredMarker="true"
            >
              <mat-label>{{ unitLabel }}:</mat-label>
              <mat-select
                placeholder="Select the units"
                class="iris-input"
                [formControl]="userForm.get('units')"
                multiple
              >
                <mat-option
                  #selectAll
                  checked
                  value="selectAll"
                  (click)="selectAllUnits()"
                  >Select All</mat-option
                >
                <mat-option
                  *ngFor="let unit of selUnits"
                  [value]="
                    unit.hospitalName + '___' + unit.name + '___' + unit._id
                  "
                  >{{ unit.hospitalName }} - {{ unit.name }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdmin" class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Role*:</mat-label>
              <mat-select
                placeholder="Set the role"
                class="iris-input"
                formControlName="role"
              >
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Allowed roles:</mat-label>
              <mat-select
                multiple
                placeholder="Set the allowed role"
                class="iris-input"
                formControlName="allowedRoles"
              >
                <mat-option *ngFor="let role of roles" [value]="role">{{
                  role
                }}</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div *ngIf="!isAdmin" class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Specialty</mat-label>
              <mat-select
                placeholder="Set the specialty"
                class="iris-input"
                formControlName="speciality"
              >
                <mat-option
                  *ngFor="let spOption of specialtyOptions"
                  [value]="spOption"
                  >{{ spOption }}</mat-option
                >
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>User status*:</mat-label>
              <mat-select
                placeholder="Set the role"
                class="iris-input"
                formControlName="active"
              >
                <mat-option [value]="true">Active</mat-option>
                <mat-option [value]="false">Inactive</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field floatLabel="always" hideRequiredMarker="true">
              <mat-label>Active from*:</mat-label>
              <cp-date-picker
                formControlName="activeFrom"
                [max]="maxStartDate(userForm.get('activeTo').value)"
              ></cp-date-picker>
              <mat-error *ngIf="userForm.get('activeFrom').invalid">
                Mandatory field
              </mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <mat-form-field floatLabel="always">
              <mat-label>Active to:</mat-label>
              <cp-date-picker
                formControlName="activeTo"
                [min]="minEndDate(userForm.get('activeFrom').value) || minDate"
              ></cp-date-picker>
            </mat-form-field>
          </div>
        </div>
      </div>

      <div class="iris-v-row display-flex align-items-center">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Remarks:</mat-label>
              <input
                class="iris-input"
                formControlName="remarks"
                matInput
                placeholder="Enter the remarks"
              />
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div class="iris-form-row">
            <cp-shedule-appointment
              *ngIf="!isAdmin"
              [isEdit]="schedules?.value?.length > 0"
              [departments]="userForm.get('departments').value"
              [activeFrom]="userForm.get('activeFrom').value"
              [activeTo]="userForm.get('activeTo').value"
              formControlName="schedules"
            ></cp-shedule-appointment>
          </div>
        </div>
      </div>

      <div class="iris-v-row">
        <div class="row-left">
          <div *ngIf="isLocked" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Lockout status:</mat-label>
              <mat-select
                placeholder="User lockout status"
                class="iris-input"
                formControlName="lockedStatus"
              >
                <mat-option [value]="'locked'">Locked</mat-option>
                <mat-option [value]="'unlock'">Unlock</mat-option>
              </mat-select>
              <mat-error>Mandatory field</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right"></div>
      </div>
      <div class="iris-v-row password-section">
        <div class="row-left">
          <div *ngIf="!addUserFlag" class="iris-form-row">
            <mat-slide-toggle
              (change)="passwordToggle($event)"
              [checked]="false"
              >Update password?</mat-slide-toggle
            >
          </div>
          <div *ngIf="updatePassword" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'" hideRequiredMarker="true">
              <mat-label>Password</mat-label>
              <input
                type="password"
                class="iris-input"
                formControlName="password"
                matInput
                placeholder="Type in the password"
              />
              <mat-hint>
                <mat-icon inline="true">warning</mat-icon>
                This will reset the user's password
              </mat-hint>
              <mat-error>Enter a valid password</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div *ngIf="updatePassword" class="row-right">
          <div class="iris-form-row password-error-row">
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'minlength'
                )
              "
              >Must be at least 8 characters long</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasNumber'
                )
              "
              >Must contain at least 1 number</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasCapitalCase'
                )
              "
              >Must contain at least 1 uppercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasSmallCase'
                )
              "
              >Must contain at least 1 lowercase alphabet</mat-checkbox
            >
            <mat-checkbox
              disabled
              [checked]="
                regPassword.value &&
                !validatorService.hasPasswordError(
                  userForm,
                  'password',
                  'hasSpecialCharacters'
                )
              "
              >Must contain at least 1 special character</mat-checkbox
            >
          </div>
        </div>
      </div>
    </form>
  </div>
  <div class="form-footer">
    <div *ngIf="serverError" class="iris-error mr">
      {{ serverError }}
    </div>
    <button
      class="button-secondary"
      type="button"
      mat-flat-button
      mat-dialog-close
    >
      Cancel
    </button>
    <button
      [disabled]="!userForm.valid || formLoading"
      type="button"
      (click)="addUser()"
      class="button-primary"
      mat-flat-button
    >
      Register
      <app-loader [buttonLoader]="true" *ngIf="formLoading"></app-loader>
    </button>
  </div>
</section>

<ng-template #noUnitsAdded>
  <div class="form-info">No units added</div>
</ng-template>
