import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatDialogRef } from "@angular/material/dialog";

@Component({
  selector: "app-notes-aira-modal",
  templateUrl: "./notes-aira-modal.component.html",
  styleUrls: ["./notes-aira-modal.component.scss"],
})
export class NotesAiraModalComponent {
  constructor(private dialogRef: MatDialogRef<NotesAiraModalComponent>) {}

  @Input() summary;
  @Input() assessmentPlan;
  @Input() diagnosis;
  @Input() todo;
  @Input() medication;
  @Input() reports;
  @Output() passEntry: EventEmitter<any> = new EventEmitter();

  submitUserInfo(type) {
    if (type === "yes") {
      this.passEntry.emit(true);
      this.dialogRef.close();
    } else {
      this.passEntry.emit(false);
    }
  }
}
