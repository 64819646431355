import { Labs } from "../../labs-scans/models/labs-scans";
import * as fromLabs from "../actions/lab-documents.actions";

export interface LabDocumentState {
  entities: any[];
  loaded: boolean;
  loading: boolean;
  documentAdded: boolean;
}

export const initialState: LabDocumentState = {
  entities: [],
  loaded: false,
  loading: false,
  documentAdded: false,
};

export function reducer(state = initialState, action): LabDocumentState {
  switch (action.type) {
    case fromLabs.LOAD_LAB_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.UPDATE_LAB_DOCUMENTS: {
      return {
        ...state,
        loading: true,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_DOCUMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
        documentAdded: false,
      };
    }

    case fromLabs.UPDATE_LAB_DOCUMENTS_FAIL: {
      return {
        ...state,
        loading: false,
        loaded: false,
      };
    }

    case fromLabs.LOAD_LAB_DOCUMENTS_SOCKET_SUCCESS: {
      const entities = action.payload;
      return {
        ...state,
        loading: false,
        loaded: false,
        entities,
      };
    }

    case fromLabs.LOAD_LAB_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
        documentAdded: true,
      };
    }

    case fromLabs.UPDATE_LAB_DOCUMENTS_SUCCESS: {
      return {
        ...state,
        loading: false,
        loaded: true,
      };
    }

    case "[Root Actions] patient chart closed": {
      return initialState;
    }
  }

  return state;
}

export const getLabEntities = (state: LabDocumentState) => {
  let labs = state.entities.filter(
    (labs) => labs.category === "labs" || !labs.category
  );
  return labs.sort(
    (a, b) =>
      new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
  );
};

export const getLabDocumentEntities = (state: LabDocumentState) =>
  state.entities.sort(
    (a, b) =>
      new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
  );

export const getDocumentEntities = (state: LabDocumentState) => {
  let labs = state.entities.filter((labs) => labs?.category === "documents");
  return labs.sort(
    (a, b) =>
      new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
  );
};

export const getScanEntities = (state: LabDocumentState) => {
  let labs = state.entities.filter((labs) => labs?.category === "scans");
  return labs.sort(
    (a, b) =>
      new Date(b.reportedAt).getTime() - new Date(a.reportedAt).getTime()
  );
};

export const getLabDocumentLoading = (state: LabDocumentState) => state.loading;
export const getLabDocumentLoaded = (state: LabDocumentState) => state.loaded;
