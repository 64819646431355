import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { AppointmentComponent } from "./appointment/appointment.component";
import { MatCardModule } from "@angular/material/card";
import { MatDividerModule } from "@angular/material/divider";
import { AvatarModule } from "@iris/avatar";
import { MatIconModule, MatIconRegistry } from "@angular/material/icon";
import { DomSanitizer } from "@angular/platform-browser";

@NgModule({
  declarations: [AppointmentComponent],
  imports: [
    CommonModule,
    MatDividerModule,
    MatCardModule,
    AvatarModule,
    MatIconModule,
  ],
  exports: [AppointmentComponent],
})
export class AppointmentCardModule {
  constructor(
    private _matIconRegistry: MatIconRegistry,
    private _domSanitizer: DomSanitizer
  ) {
    {
      _matIconRegistry.addSvgIcon(
        "cp-calender",
        _domSanitizer.bypassSecurityTrustResourceUrl(
          "./assests/icons/calendar.svg"
        )
      );
      _matIconRegistry.addSvgIcon(
        "cp-time",
        _domSanitizer.bypassSecurityTrustResourceUrl("./assests/icons/time.svg")
      );
    }
  }
}
