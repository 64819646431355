<div class="upload-container">
  <ng-container [ngSwitch]="fileType">
    <ng-container
      *ngSwitchCase="fileTypeEnum.DOCUMENT"
      [ngTemplateOutlet]="document"
    ></ng-container>
    <ng-container
      *ngSwitchCase="fileTypeEnum.IMAGE"
      [ngTemplateOutlet]="image"
    ></ng-container>
  </ng-container>
</div>

<ng-template #document>
  <div
    (drop)="onContainerDrop($event)"
    [ngClass]="{ 'disable-control': isDisabled }"
  >
    <div>
      <ng-container *ngIf="!loader; else loading">
        <ng-container *ngIf="!fileSrc; else showDocument">
          <div
            [ngClass]="{ 'upload-container__inline-form': isInlineForm }"
            class="dashed-border cp-container display-flex align-items-center upload-document"
          >
            <span class="bg-light upload-document__icon">
              <mat-icon
                class="cursor-pointer"
                svgIcon="cp-document"
                aria-hidden="false"
                aria-label="document icon"
              >
              </mat-icon>
            </span>
            <span class="cp-color-normal cp-ml-2">Drop files here or</span>
            <span class="primary cursor-pointer" (click)="changeFile()"
              >&nbsp;upload</span
            >
          </div>
        </ng-container>
      </ng-container>
      <ng-template #showDocument>
        <div
          class="bg-light cp-container display-flex justify-content-between align-items-center"
        >
          <div class="display-flex align-items-center" (click)="openPdf()">
            <mat-icon
              class="cursor-pointer"
              svgIcon="cp-document"
              aria-hidden="false"
              aria-label="document icon"
            >
            </mat-icon>
            <span class="cp-ml-1">{{ fileName }}</span>
          </div>
          <mat-icon class="cp-icon cursor-pointer" (click)="removeFile()"
            >close</mat-icon
          >
        </div>
      </ng-template>
      <ng-template #loading>
        <mat-spinner [diameter]="30"></mat-spinner>
      </ng-template>
    </div>
    <ng-container
      [ngTemplateOutlet]="documentInput"
      [ngTemplateOutletContext]="{
        input: {
          accept: 'application/pdf, image/png, image/jpeg',
          hidden: true
        }
      }"
    >
    </ng-container>
  </div>
</ng-template>

<ng-template #image>
  <div class="photo-container display-flex center dashed-border">
    <div
      class="photo-container__loader display-flex center"
      *ngIf="loader; else content"
    >
      <mat-spinner [diameter]="30"></mat-spinner>
    </div>
    <ng-template #content>
      <ng-container *ngIf="!error; else errorTemplate">
        <ng-container *ngIf="fileSrc">
          <div class="display-flex center">
            <img
              [attr.src]="fileSrc"
              class="photo-container__img"
              alt="uploaded image"
            />
            <div
              class="photo-container__buttons display-flex justify-content-around align-items-center"
            >
              <button
                class="button-secondary cursor-pointer"
                (click)="changeFile()"
              >
                Change
              </button>
              <button
                class="button-primary cursor-pointer"
                (click)="removeFile()"
              >
                Remove
              </button>
            </div>
          </div>
        </ng-container>
        <div
          class="cursor-pointer display-flex align-items-center"
          [hidden]="fileSrc"
        >
          <ng-template [ngIf]="photoModal" [ngIfElse]="camModal">
            <ng-container
              [ngTemplateOutlet]="documentInput"
              [ngTemplateOutletContext]="{
                input: {
                  accept: 'image/png, image/jpeg',
                  class: 'upload-container__input'
                }
              }"
            >
            </ng-container>
            <ng-container *ngIf="!fileSrc">
              <div class="photo-container__icon">
                <mat-icon class="cp-icon">image</mat-icon>
                <span class="photo-container__icon__text">{{ label }}</span>
              </div>
            </ng-container>
          </ng-template>
        </div>
      </ng-container>
    </ng-template>
    <ng-template #errorTemplate>
      <span class="error-text">Something went wrong!</span>
    </ng-template>
  </div>
</ng-template>

<!-- for webcam -->
<ng-template #camModal>
  <ng-container *ngIf="!fileSrc">
    <div (click)="openWebCamDialog()" class="photo-container__icon">
      <mat-icon class="cp-icon">camera_alt</mat-icon>
      <span class="photo-container__icon__text">{{ label }}</span>
    </div>
  </ng-container>
</ng-template>

<ng-template #documentInput let-input="input">
  <input
    #fileInput
    title=" "
    [ngClass]="input?.class"
    type="file"
    (change)="onUpload($event?.target?.files[0])"
    [accept]="input?.accept"
    [hidden]="input?.hidden"
  />
</ng-template>

<cp-file-preview-modal [filePreview]="filePreview"></cp-file-preview-modal>
