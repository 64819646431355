import { FocusMonitor } from "@angular/cdk/a11y";
import { coerceBooleanProperty } from "@angular/cdk/coercion";
import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  Inject,
  Input,
  OnDestroy,
  OnInit,
  Optional,
  Self,
  ViewChild,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  FormGroup,
  NgControl,
} from "@angular/forms";
import {
  MAT_FORM_FIELD,
  MatFormField,
  MatFormFieldControl,
} from "@angular/material/form-field";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-appointment-duration",
  templateUrl: "./appointment-duration.component.html",
  styleUrls: ["./appointment-duration.component.scss"],
  providers: [
    { provide: MatFormFieldControl, useExisting: AppointmentDurationComponent },
  ],
  host: {
    "[class.example-floating]": "shouldLabelFloat",
    "[id]": "id",
  },
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentDurationComponent
  implements
    ControlValueAccessor,
    OnInit,
    MatFormFieldControl<number>,
    OnDestroy
{
  static nextId = 0;

  public units: string[] = ["minutes", "hours"];

  @ViewChild("unitContainer") unitContainer: ElementRef;
  @ViewChild("unitInput") unitInput: ElementRef;

  @ViewChild("durationInput") durationInput: ElementRef;

  unsubscribe$: Subject<any> = new Subject<any>();

  appointmentForm = new FormGroup({
    duration: new FormControl(""),
    unit: new FormControl(""),
  });

  get durationArr() {
    if (this.unit.value == "minutes") {
      return Array.from({ length: 59 }, (_, i) => i + 1);
    } else {
      return Array.from({ length: 23 }, (_, i) => i + 1);
    }
  }

  stateChanges = new Subject<void>();
  focused = false;
  touched = false;
  controlType = "appointment-duration";
  id = `appointment-duration-${AppointmentDurationComponent.nextId++}`;
  onChange = (_: any) => {};
  onTouched = () => {};

  get empty() {
    return !this.duration;
  }

  get shouldLabelFloat() {
    return this.focused || !this.empty;
  }

  @Input("aria-describedby") userAriaDescribedBy: string;

  @Input()
  get placeholder(): string {
    return this._placeholder || "duration";
  }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean {
    return this._required;
  }
  set required(value) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean {
    return this._disabled;
  }
  set disabled(value) {
    this._disabled = coerceBooleanProperty(value);
    this._disabled
      ? this.appointmentForm.disable()
      : this.appointmentForm.enable();
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): number {
    if (this.unit.value == "hours") {
      return this.duration.value * 60;
    } else {
      return this.duration.value;
    }
  }
  set value(duration: number) {
    if (duration > 60 && duration % 60 == 0) {
      this.duration.setValue(duration / 60);
      this.unit.setValue("hours");
    } else {
      this.duration.setValue(duration);
      this.unit.setValue("minutes");
    }
  }

  get errorState(): boolean {
    return this.ngControl.invalid && this.touched;
  }

  constructor(
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    @Optional() @Inject(MAT_FORM_FIELD) public _formField: MatFormField,
    @Optional() @Self() public ngControl: NgControl
  ) {
    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }
  }
  ngOnInit(): void {
    this.initializeListeners();
  }

  get duration(): FormControl {
    return this.appointmentForm.get("duration") as FormControl;
  }

  get unit(): FormControl {
    return this.appointmentForm.get("unit") as FormControl;
  }

  initializeListeners() {
    this.appointmentForm.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.onChange(this.value);
      });
  }

  ngOnDestroy() {
    this.stateChanges.complete();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    this._focusMonitor.stopMonitoring(this._elementRef);
  }

  onFocusIn() {
    if (!this.focused) {
      this.focused = true;
      this.stateChanges.next();
    }
  }

  onFocusOut(event: FocusEvent) {
    if (
      !this._elementRef.nativeElement.contains(event.relatedTarget as Element)
    ) {
      this.touched = true;
      this.focused = false;
      this.onTouched();
      this.stateChanges.next();
    }
  }

  setDescribedByIds(ids: string[]) {
    const controlElement = this._elementRef.nativeElement.querySelector(
      ".cp-appointment-duration-comp"
    )!;
    controlElement.setAttribute("aria-describedby", ids.join(" "));
  }

  onContainerClick(event) {
    if (this.unitContainer.nativeElement.contains(event.target)) {
      this._focusMonitor.focusVia(this.unitInput, "program");
    } else {
      this._focusMonitor.focusVia(this.durationInput, "program");
    }
  }

  writeValue(value: number): void {
    this.value = value;
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.disabled = isDisabled;
  }
}
