import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { OpenWebcamModalComponent } from "./cam-container/open-webcam-modal/open-webcam-modal.component";
import { MatDialogModule } from "@angular/material/dialog";
import { CamContainerComponent } from "./cam-container/cam-container.component";
import { MatIconModule } from "@angular/material/icon";
import { FilePreviewModalModule } from "@iris/file-preview-modal";

@NgModule({
  declarations: [OpenWebcamModalComponent, CamContainerComponent],
  imports: [
    CommonModule,
    MatDialogModule,
    MatIconModule,
    FilePreviewModalModule,
  ],
  exports: [OpenWebcamModalComponent, CamContainerComponent],
})
export class UploadWebcamImageModule {}
