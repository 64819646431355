import {
  Component,
  OnInit,
  Input,
  ChangeDetectorRef,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { Router, ActivatedRoute, Event, NavigationEnd } from "@angular/router";
import { filter, distinctUntilChanged } from "rxjs/operators";
import { Store, select } from "@ngrx/store";
import * as fromNoteStore from "../../note-module/store";

import * as fromPatientHeaderReducers from "src/app/store/reducers/patient-chart/patient-header/index";
import { PatientService } from "src/app/services/patient.service";
import {
  faBackward,
  faCircle,
  faExchangeAlt,
  faFileMedicalAlt,
  faFileUpload,
  faHeartbeat,
  faRedo,
} from "@fortawesome/free-solid-svg-icons";
import { faClipboard, faListAlt } from "@fortawesome/free-regular-svg-icons";
import { faNotesMedical } from "@fortawesome/free-solid-svg-icons";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";
import { displayAge } from "src/app/support-functions/calculateAge";
import { MatDialog } from "@angular/material/dialog";
import { FormControl, Validators } from "@angular/forms";
import { AlertService } from "@iris/alert";
import { TrendsComponent } from "src/app/shared-modules/components/trends/trends.component";
import {
  trendType,
  weightChartXAxisRanges,
  weightChartYAxisRanges,
} from "src/app/shared-modules/components/trends/trends.data";
import { FormsService } from "src/app/admit-form/services/forms.service";
import * as fromPatientFormActions from "../../store/actions/patient-chart/patient-header/patient-form.actions";
import moment from "moment-timezone";
import { Patient, PatientType } from "src/app/models/patient";
import {
  calculateCurrentWeight,
  calculateWeight,
  CALCULATE_CURRENT_WEIGHT,
  CALCULATE_WEIGHT,
} from "src/app/support-functions/calculateWeight";
@Component({
  selector: "app-emr-nav-tabs",
  templateUrl: "./emr-nav-tabs.component.html",
  styleUrls: ["./emr-nav-tabs.component.scss"],
})
export class EmrNavTabsComponent implements OnInit {
  @ViewChild("enterWeigthTemplate") enterWeigthTemplate: ElementRef;

  @Input()
  set pendingCultureCount(count: number) {
    this.cultureCount = count && count > 0 ? count : 0;
  }
  @Input()
  set pendingCovidCount(count: number) {
    this.covidCount = count && count > 0 ? count : 0;
  }

  public cultureCount = 0;
  public covidCount = 0;
  public draftNote$ = this.store.select(fromNoteStore.getAllDraftNotes);

  private unsubscribe$: Subject<any> = new Subject<any>();

  public patientHeader$ = this.store.pipe(
    select(fromPatientHeaderReducers.getPatHeaderData),
    takeUntil(this.unsubscribe$)
  );

  public CPMRN;
  public encounters;
  public selected: string;
  public notesCount = 0;
  public currentUser;
  public patientInfo: Patient;
  public patientWeightHistory: any = [];
  public patientAvatar: AvatarParams[];
  public patientType = "";
  public isDischargeTimeElapsed = false;

  /** nav tabs' icon's url */
  notesImageUrl: string;
  ordersImageUrl: string;
  vitalsImageUrl: string;
  assessmentImageUrl: string;
  marImageUrl: string;
  labsScansImageUrl: string;
  ioImageUrl: string;
  summaryImageUrl: string;
  roundarImageUrl: string;

  tabInFocus: string = "summary";

  /* ICONS */
  faBackward = faBackward;
  faHeartBeat = faHeartbeat;
  faFileMedicalAlt = faFileMedicalAlt;
  faClipboard = faClipboard;
  faCircle = faCircle;
  faExchangeAlt = faExchangeAlt;
  faFileUpload = faFileUpload;
  faListAlt = faListAlt;
  faRedo = faRedo;
  faNotesMedical = faNotesMedical;

  weight = new FormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.min(1),
      Validators.max(999000),
    ])
  );

  public get PatientType() {
    return PatientType;
  }

  constructor(
    private router: Router,
    public store: Store<{}>,
    private _patientService: PatientService,
    private changeDetRef: ChangeDetectorRef,
    private dialog: MatDialog,
    private _alertService: AlertService,
    private _formActions: FormsService
  ) {}

  backToPatList() {
    this.router.navigate(["patientlist"]);
  }

  goTo(...childRoute: string[]) {
    this.router.navigate([
      "patient",
      this.CPMRN,
      this.encounters,
      ...childRoute,
    ]);
  }

  ngOnInit() {
    this.router.events
      .pipe(
        filter((event) => event instanceof NavigationEnd),
        distinctUntilChanged()
      )
      .subscribe(() => {
        this.tabInFocus = this.router.url.split("/").reverse()[0];
        this.setNavTabsIconUrl();
      });

    /* Get Patient info */
    this.patientHeader$.subscribe((patient) => {
      this.patientInfo = JSON.parse(JSON.stringify(patient));
      this.patientWeightHistory =
        this.patientInfo?.patientType === "neonatal" &&
        this.patientInfo?.weightHistory?.length
          ? this.transformWeigthHistory(this.patientInfo.weightHistory)
          : [];
      if (patient && patient.CPMRN) {
        this.CPMRN = patient.CPMRN;
        this.encounters = patient.encounters;
      }

      this.setPatientType(patient);

      this.isDischargeTimeElapsed =
        this._patientService.checkIfDischargeTimeElapsed(patient);
    });

    /* Highlight the drafted notes */

    /** Set patient's avatar */
    this.patientAvatar = [
      {
        name: this.patientInfo?.name,
        size: AVATAR_SIZE.L,
      },
    ];

    /** set tabInFocus and icons of nav tabs */

    let currRoute = this.router.url.split("/");
    this.tabInFocus = currRoute[currRoute.length - 1];

    this.setNavTabsIconUrl();
  }

  // set patient type

  setPatientType(data) {
    if (data.isCurrentlyAdmitted !== null)
      this.patientType = data.isCurrentlyAdmitted
        ? this.encounters > 1
          ? "re-admitted"
          : "admitted"
        : "discharged";
  }

  // returns rounded value upto 2 decimals.
  getRoundedValue(value) {
    return Math.round(value * 100) / 100;
  }

  getWeight(weightObj) {
    const payload: CALCULATE_WEIGHT = {
      weightObj,
      patientType: this.patientInfo?.patientType,
      weightInGrams: true,
    };
    return calculateWeight(payload) || "-";
  }

  getCurrWeight() {
    const payload: CALCULATE_CURRENT_WEIGHT = {
      weightHistory: this.patientInfo?.weightHistory,
      weightObj: this.patientInfo?.weightObj,
      patientType: this.patientInfo?.patientType,
      weightInGrams: true,
    };
    return calculateCurrentWeight(payload);
  }

  getCorrectedGestationalAge() {
    let { weeks: gestAgeW, days: gestAgeD } = this.patientInfo?.gestationAge;
    let [patientAge, patientAgeUnit] = this.getPatientAge().split(" ");
    if (patientAgeUnit.includes("hr")) patientAge = Math.floor(patientAge / 24);

    const newDaysInGestationAge = gestAgeD + +patientAge;
    const noOfWeeks = Math.floor(newDaysInGestationAge / 7);
    const remainingDays = Math.floor(newDaysInGestationAge % 7);

    gestAgeW = gestAgeW + noOfWeeks;
    gestAgeD = remainingDays;

    return `${gestAgeW > 0 ? gestAgeW + "w " : ""}${
      gestAgeD > 0 ? gestAgeD + "d" : ""
    }`;
  }

  /** To set the icon-url of the selected and rest of the nav-tab */

  setNavTabsIconUrl() {
    this.summaryImageUrl =
      this.tabInFocus === "summary"
        ? "assets/icons/summary_white.svg"
        : "assets/icons/summary.svg";
    this.notesImageUrl =
      this.tabInFocus === "notes"
        ? "assets/icons/notes_white.svg"
        : "assets/icons/notes.svg";
    this.ordersImageUrl =
      this.tabInFocus === "orders"
        ? "assets/icons/orders_white.svg"
        : "assets/icons/orders.svg";
    this.vitalsImageUrl =
      this.tabInFocus === "vitals"
        ? "assets/icons/vitals_white.svg"
        : "assets/icons/vitals.svg";
    this.assessmentImageUrl =
      this.tabInFocus === "assessment"
        ? "assets/icons/assessment_white.svg"
        : "assets/icons/assessment.svg";
    this.marImageUrl =
      this.tabInFocus === "mar"
        ? "assets/icons/mar_white.svg"
        : "assets/icons/mar.svg";
    this.labsScansImageUrl =
      this.tabInFocus === "labs"
        ? "assets/icons/labs_scans_white.svg"
        : "assets/icons/labs_scans.svg";
    this.ioImageUrl =
      this.tabInFocus === "intake-output"
        ? "assets/icons/input_output_white.svg"
        : "assets/icons/input_output.svg";
    this.roundarImageUrl =
      this.tabInFocus === "notebook"
        ? "assets/icons/roundar_white.svg"
        : "assets/icons/roundar.svg";
  }

  getPatientAge() {
    const ageObj = this.patientInfo?.age;

    return displayAge(
      ageObj,
      true,
      this.patientInfo.patientType,
      this.patientInfo.dob
    );
  }

  openEnterWeigth(content) {
    const dialogRef = this.dialog.open(content, {
      height: "235px",
      width: "266px",
      autoFocus: false,
      disableClose: true,
    });
  }

  updateWeigth() {
    const weightObj = {
      weight: this.weight.value,
      weightUnit: "gm",
    };
    const updatedProperties = this._formActions.transformUpdateData(weightObj);
    const payload = {
      CPMRN: this.CPMRN,
      encounters: this.encounters,
      patient: updatedProperties,
    };
    this.store.dispatch(
      fromPatientFormActions.updatePatient({
        payload,
        nextPage: 0,
        isFormComplete: false,
      })
    );
    this.weight.reset();
    this._alertService.showNotification({
      type: "Success",
      message: "Weight Updated Successfully",
    });
  }

  openWeightTrends() {
    const dialogRef = this.dialog.open(TrendsComponent, {
      width: "1320px",
      height: "705px",
      autoFocus: false,
      disableClose: true,
    });
    const componentInstance = dialogRef.componentInstance;
    componentInstance.isShowDayDropdown = false;
    componentInstance.currPatient = this.patientInfo;
    componentInstance.currentVitalData = this.patientWeightHistory;
    // componentInstance.currentVitalData = weigthData.sort(
    //   (a, b) => new Date(b.date).getTime() - new Date(a.date).getTime()
    // );
    componentInstance.yAxisConfig = weightChartYAxisRanges;
    componentInstance.xAxisConfig = weightChartXAxisRanges;
    componentInstance.vitalNames = {
      show: "Progressive Weight Graph",
      val: null,
      unitName: "g",
    };
    componentInstance.vitalNormalRange = [undefined, false];
    componentInstance.trendType = trendType.weight;
    componentInstance.isHeaderToggleVisible = true;
  }

  transformWeigthHistory(wHistory) {
    return wHistory.map((wObj) => {
      const dob = this.patientInfo?.dob || moment();
      const transformedObj = {
        value: Math.floor(wObj["weightKg"] * 1000),
        date: wObj["weightMeasuredTime"],
        age: Math.floor(
          moment
            .duration(moment(wObj["weightMeasuredTime"]).diff(moment(dob)))
            .asDays()
        ),
        createdBy: wObj.createdBy,
        updatedBy: wObj.updatedBy,
      };
      return transformedObj;
    });
  }
}
