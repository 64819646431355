import { Component, Inject, OnInit } from "@angular/core";
import {
  AbstractControl,
  FormArray,
  FormControl,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { Alert } from "@iris/alert/models/alert.model";
import { AlertService } from "@iris/alert/services/alert.service";
import { merge, Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { CustomValidators } from "src/app/iris-components/validators/custom-validators";
import { Hospital, Unit } from "src/app/models/hospital";
import { AuditService } from "src/app/services/audit.service";
import { userRoles } from "src/app/shared/accessControl/roles";
import { ManagementService } from "../../services/management.service";
import { ValidatorsService } from "../../services/validators.service";
import moment from "moment";

const availableRoles = [...userRoles];
@Component({
  selector: "app-add-user",
  templateUrl: "./add-user.component.html",
  styleUrls: ["./add-user.component.scss"],
})
export class AddUserComponent implements OnInit {
  durationControl = new FormControl("");

  /**
   * User form
   */
  userForm = new UntypedFormGroup(
    {
      profilePicture: new FormControl(""),
      signature: new FormControl(""),
      title: new UntypedFormControl(""),
      name: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^(?!\s*$).+/),
      ]),
      qualification: new UntypedFormControl(""),
      hospitals: new UntypedFormControl([]),
      units: new UntypedFormControl([]),
      role: new UntypedFormControl("", Validators.required),
      speciality: new UntypedFormControl(""),
      allowedRoles: new UntypedFormControl(""),
      email: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/),
      ]),
      phone: new UntypedFormControl("", [
        Validators.required,
        Validators.pattern(
          /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[1-9]\d{9}$/
        ),
      ]),
      departments: new UntypedFormControl([]),
      active: new UntypedFormControl(true, Validators.required),
      lockedStatus: new UntypedFormControl(null),
      remarks: new UntypedFormControl(""),
      schedules: new UntypedFormControl([]),
      activeFrom: new UntypedFormControl(new Date(), Validators.required),
      activeTo: new UntypedFormControl(null),
      registration_id: new UntypedFormControl(""),
      employeeCode: new UntypedFormControl(""),
      password: new UntypedFormControl(
        "",
        Validators.compose([
          // Validators.required,
          // check whether the entered password has a number
          CustomValidators.patternValidator(/\d/, {
            hasNumber: true,
          }),
          // check whether the entered password has upper case letter
          CustomValidators.patternValidator(/[A-Z]/, {
            hasCapitalCase: true,
          }),
          // check whether the entered password has a lower case letter
          CustomValidators.patternValidator(/[a-z]/, {
            hasSmallCase: true,
          }),
          // check whether the entered password has a special character
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
            {
              hasSpecialCharacters: true,
            }
          ),
          Validators.minLength(8),
        ])
      ),
    },
    { validators: CustomValidators.checkIfAdmin }
  );
  profilePic: string = "";
  signature: string = "";
  imageLoader: boolean;

  constructor(
    public dialogRef: MatDialogRef<AddUserComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public validatorService: ValidatorsService,
    public _managementService: ManagementService,
    private auditorService: AuditService,
    private _alertService: AlertService
  ) {}

  /** Holds the list of available roles
   * @type {array}
   */
  public roles: string[] = availableRoles;

  public departmentsMap = new Map();

  public bookAppointment: boolean = false;
  public specialtyOptions: string[];

  /** To indicate data is being processed
   * @type {boolean} */
  formLoading: boolean = false;

  /** To display the server error
   * @type {string} */
  serverError: string = "";

  /** To hold the list of selected hospitals
   * @type {array}
   */
  selHospitals: Hospital[] = [];

  /** To hold the list of selected units
   * @type {array}
   */
  selUnits: Unit[] = [];

  /** Check if password update is necessary
   * @type {boolean}
   */
  updatePassword: boolean = false;

  /** To set if add or edit user
   * @type {boolean}
   */
  addUserFlag: boolean = true;

  unitLabel: string = "Units";

  ngOnInit(): void {
    if (this.data?.userType === "System Administrator") {
      this.userForm.get("role").setValue("System Administrator");
    }
    this.setDepartmentMap();
    this.specialityStatus();
    this.listenToEvents();
    if (this.data?.user?._id) {
      this.fillData();
      this.getImage();
      this.addUserFlag = false;
      this.isAllUnitsSelected = false;
    } else {
      this.updatePassword = true;
    }
    this.fetchSpecialtyList();
    this.formControlListeners();
  }

  fetchSpecialtyList() {
    this._managementService.getSpecialityList().subscribe((res: any) => {
      this.specialtyOptions = res?.data?.specialities?.length
        ? res?.data?.specialities
        : [];
    });
  }

  formControlListeners() {
    const roleValueChange = this.userForm.get("role").valueChanges;
    const allowedRolesValueChane =
      this.userForm.get("allowedRoles").valueChanges;
    merge(roleValueChange, allowedRolesValueChane).subscribe((data) => {
      const allRoles = [
        this.userForm.get("role").value,
        ...this.userForm.get("allowedRoles").value,
      ];
      if (!allRoles.includes("Physician"))
        this.userForm.patchValue({ speciality: null });
    });
  }

  listenToEvents() {
    this.userForm
      .get("activeFrom")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        this.setStart(val);
      });

    this.userForm
      .get("activeTo")
      .valueChanges.pipe(takeUntil(this.unsubscribe$))
      .subscribe((val) => {
        if (!this.userForm.get("activeFrom").value)
          this.userForm.get("activeFrom").setValue(this.minDate);
        this.setEnd(val);
      });

    this.userForm
      .get("role")
      .valueChanges.takeUntil(this.unsubscribe$)
      .subscribe(() => {
        this.specialityStatus();
      });

    this.userForm
      .get("allowedRoles")
      .valueChanges.takeUntil(this.unsubscribe$)
      .subscribe(() => {
        this.specialityStatus();
      });
  }

  setStart(activeFrom: string) {
    const activeFromDate = moment(activeFrom);
    const schedulesArr = this.schedules?.value?.reduce(
      (finalSchedules, value) => {
        const startDate = value?.start;
        const endDate = value?.end;
        if (!startDate) return;
        const endMomentDate = moment(endDate);
        const nextStartDate = moment(activeFrom).add(1, "days");
        const isDateChange = endMomentDate.diff(nextStartDate, "days") <= 0;
        const startMomentDate = moment(startDate);
        const diff = activeFromDate.diff(startMomentDate);
        if (diff > 0) {
          return [
            ...finalSchedules,
            {
              ...value,
              start: activeFrom,
              end: isDateChange ? nextStartDate.toISOString() : endDate,
            },
          ];
        }
        return [...finalSchedules, value];
      },
      []
    );
    this.schedules.setValue(schedulesArr);
  }

  setEnd(activeTo: string) {
    const activeToDate = moment(activeTo);
    const schedulesArr = this.schedules?.value?.reduce(
      (finalSchedules, value) => {
        const endDate = value?.end;
        const startDate = value?.start;
        if (!endDate) return;
        const startMomentDate = moment(startDate);
        const endMomentDate = moment(endDate);
        const previousEndDate = moment(activeTo).subtract(1, "days");
        const diff = activeToDate.diff(endMomentDate);
        const isDateChange = startMomentDate.diff(previousEndDate, "days") >= 0;
        if (diff < 0) {
          return [
            ...finalSchedules,
            {
              ...value,
              end: activeTo,
              start: isDateChange ? previousEndDate.toISOString() : startDate,
            },
          ];
        }
        return [...finalSchedules, value];
      },
      []
    );
    this.schedules.setValue(schedulesArr);
  }

  public specialityStatus() {
    const role = this.userForm.get("role").value;
    const allowedRoles = this.userForm.get("allowedRoles").value;
    if (allowedRoles.includes("Physician") || role == "Physician") {
      this.userForm.get("speciality").enable();
    } else {
      this.userForm.get("speciality").disable();
    }
  }

  get minDate(): Date {
    return new Date();
  }

  get schedules(): FormArray {
    return this.userForm.get("schedules") as FormArray;
  }

  setDepartmentMap() {
    this.data?.hospitals?.forEach((hospital) => {
      const id = hospital?._id,
        departments = hospital?.departments,
        hospitalName = hospital?.name;
      const departmentData = { departments, hospitalName };
      this.departmentsMap.set(id, departmentData);
    });
  }

  public getImage(): void {
    this.imageLoader = true;
    const email = this.data.user.email;
    this._managementService
      .getImageByEmail(email)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (val) => {
          this.imageLoader = false;
          this.profilePic = val.data?.profilePicture;
          this.signature = val.data?.signature;
        },
        (_) => {
          this.imageLoader = false;
          console.error("Image upload fail");
        }
      );
  }

  /**
   * To see the status of password update toggle
   * @param event {event}
   */
  passwordToggle(event): void {
    this.updatePassword = event.checked;
  }

  /**
   * Fills the userform if its edit
   */
  fillData(): void {
    let user = this.data.user;
    this.userForm.get("title").setValue(user.title);
    this.userForm.get("name").setValue(user.name);
    this.userForm.get("qualification").setValue(user.qualification);
    this.userForm.get("email").setValue(user.email);
    this.userForm.get("phone").setValue(user.phone);
    this.userForm.get("active").setValue(user.active);
    this.userForm.get("role").setValue(user.role);
    this.userForm.get("speciality").setValue(user.speciality);
    this.userForm.get("remarks").setValue(user.remarks);
    this.userForm.get("registration_id").setValue(user.registration_id || "");
    this.userForm.get("schedules").setValue(user.schedules || []);
    this.userForm.get("activeFrom").setValue(user.activeFrom || new Date());
    this.userForm.get("activeTo").setValue(user.activeTo);
    this.userForm.get("departments").setValue(user.departments);
    this.userForm.get("profilePicture").setValue(user.profilePicture);
    this.userForm.get("signature").setValue(user.signature);
    this.userForm.get("employeeCode").setValue(user.employeeCode);
    if (user.allowedRoles) {
      this.userForm.get("allowedRoles").setValue(user.allowedRoles);
    }

    if (user.hospitals) {
      let hospIds = user.hospitals.map((hosp) => hosp._id);

      this.userForm.get("hospitals").setValue(hospIds);
      this.setHospital();

      if (user.units) {
        let unitValues = user.units.map(
          (unit) => `${unit.hospitalName}___${unit.name}___${unit._id}`
        );

        this.userForm.get("units").setValue(unitValues);
      }
    }

    if (this.isLocked) {
      this.userForm.get("lockedStatus").setValue("locked");
    }
  }

  maxStartDate(start: Date): string | null {
    if (!start) return null;
    return moment(start).subtract(1, "days").toISOString();
  }

  minEndDate(end: Date): string | null {
    if (!end) return null;
    return moment(end).add(1, "days").toISOString();
  }

  /**
   * Does the server call to save the user in the db
   */
  addUser(): void {
    this.formLoading = true;

    const userInfo = this.userForm.value;
    userInfo.speciality = userInfo?.speciality || null;
    // Send blank allowedRoles if not present
    if (!userInfo.allowedRoles) {
      userInfo.allowedRoles = [];
    }

    if (this.data?.commandCenter?._id) {
      userInfo.commandCenterID = this.data.commandCenter._id;
    }

    if (userInfo.units?.length) {
      userInfo.units = userInfo.units
        .filter((unit) => unit !== "selectAll")
        .map((unit) => {
          let val = unit?.split("___");

          return {
            hospitalName: val[0],
            name: val[1],
            _id: val[2],
          };
        });
    }

    // Registering User in aws quicksight
    // AllowedRoles having 'Auditor' role.
    if (userInfo.allowedRoles.includes("Auditor")) {
      this.auditorService
        .registerNewUser(userInfo.email)
        .pipe(takeUntil(this.unsubscribe$))
        .subscribe(
          (data) => {
            const displayMessage: Alert = {
              type: "Success",
              message: "User successfully registered quicksight",
            };

            this._alertService.showNotification(displayMessage);
          },
          (error) => {
            const displayMessage: Alert = {
              type: "Error",
              message: "Something Went Wrong",
            };

            this._alertService.showNotification(displayMessage);
          }
        );
    }

    this.setTheApi(userInfo)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (response) => {
          this.dialogRef.close(response?.data?.user);
        },
        (err) => {
          console.log(err);
          this.serverError = err.message;
          if (err.message === "Validation Error" && err.data?.length) {
            this.serverError = "";
            err.data.forEach((e, index) => {
              this.serverError += `${e.param}: ${e.msg}`;

              if (index < err.data.length - 1) {
                this.serverError += ", ";
              }
            });
          }
          this.formLoading = false;
        }
      );
  }

  /**
   * To set the api if its create or edit
   * @param userInfo {User}
   * @returns
   */
  setTheApi(userInfo): any {
    if (this.data?.user?._id) {
      return this._managementService.editUser(userInfo, this.data?.user?._id);
    } else {
      return this._managementService.addUser(userInfo);
    }
  }

  /**
   * Set the hospital based on the select so that respective units can be filled
   */
  setHospital(): any {
    let hospIds = this.userForm.get("hospitals").value;
    this.selHospitals = [];
    this.selUnits = [];

    this.selHospitals = this.data.hospitals.filter((hosp) =>
      hospIds.includes(hosp._id)
    );

    let isCommandCenter = false;
    this.selHospitals.forEach((hosp) => {
      if (hosp.isCommandCenter) isCommandCenter = true;

      let units = hosp.units.map((unit) => ({
        ...unit,
        hospitalName: hosp.name,
      }));
      this.selUnits = [...this.selUnits, ...units];

      if (!this.data?.user?._id) {
        this.selUnits = this.selUnits.filter((unit) => unit.active);
      } else {
        let userUnits = this.data.user.units;

        this.selUnits = this.selUnits.filter((unit) => {
          let unitIndex = userUnits.findIndex(
            (uUnit) => uUnit._id === unit._id
          );

          if (unitIndex < 0 && unit.active) {
            return true;
          } else if (unitIndex > -1) {
            return true;
          } else {
            return false;
          }
        });
      }
    });

    this.setUnitValidator(isCommandCenter);

    // preselect all the units
    if (!this.data?.user?._id) {
      this.setUnitsValue(true);
    }
    this.resetDepartment();
  }

  public get departments() {
    const hospitalIds = this.userForm.get("hospitals").value;
    return hospitalIds.reduce((finalDeps, id) => {
      const deps = this.departmentsMap.get(id)?.departments;
      if (!Array.isArray(deps)) return [...finalDeps];
      const finalArr = [...finalDeps, ...deps];
      return [...new Set(finalArr)];
    }, []);
  }

  resetDepartment() {
    const transformedDep = this.userForm
      .get("departments")
      ?.value.filter((deps) => this.departments.includes(deps));
    this.userForm.get("departments").setValue(transformedDep || []);
  }

  setUnitValidator(isCommandCenter = false) {
    if (isCommandCenter) {
      this.unitLabel = "Units";
      this.userForm.get("units").clearValidators();
    } else {
      this.unitLabel = "Units*";
      this.userForm.get("units").setValidators(Validators.required);
    }

    this.userForm.get("units").updateValueAndValidity();
  }

  setUnitsValue(setAll: boolean): void {
    if (setAll) {
      let newUnitVal = this.selUnits.map(
        (unit) => unit.hospitalName + "___" + unit.name + "___" + unit._id
      );
      this.units.setValue([...newUnitVal, "selectAll"]);
    } else {
      this.units.setValue([]);
    }
  }

  /**
   * To get if the user is admin or not
   */
  get isAdmin(): boolean {
    if (this.data.userType === "System Administrator") {
      return true;
    } else return false;
  }

  /**
   * To get if the user is locked
   */
  get isLocked(): boolean {
    if (this.data?.user?.lockout?.isLocked) {
      return true;
    }
    return false;
  }

  /**
   * Extract units value from user form
   */
  get units(): AbstractControl {
    return this.userForm.get("units");
  }

  /**
   * Extract password value from user form
   */
  get regPassword(): AbstractControl {
    return this.userForm.get("password");
  }

  isAllUnitsSelected: boolean = true;
  selectAllUnits(): void {
    if (!this.isAllUnitsSelected) {
      this.setUnitsValue(true);
    } else {
      this.setUnitsValue(false);
    }
    this.isAllUnitsSelected = !this.isAllUnitsSelected;
  }

  /** Subject to eliminate the subscription. */
  unsubscribe$ = new Subject();
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
