import {
  ChangeDetectionStrategy,
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  TemplateRef,
  ViewChild,
} from "@angular/core";
import {
  AbstractControl,
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { MatDialog } from "@angular/material/dialog";
import moment from "moment";
import {
  consultationCharges,
  weekDays,
} from "src/app/management/data/form.data";
import { SchedulesInterface } from "src/app/models/user";
import { timeRangeValidators } from "../../validators/time-range.validator";

@Component({
  selector: "cp-shedule-appointment",
  templateUrl: "./shedule-appointment.component.html",
  styleUrls: ["./shedule-appointment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: SheduleAppointmentComponent,
      multi: true,
    },
  ],
})
export class SheduleAppointmentComponent
  implements ControlValueAccessor, OnChanges
{
  public weekDays: string[] = weekDays;
  schedulesData: any;

  @Input("activeFrom") activeFrom: string | null = null;
  @Input("activeTo") activeTo: string | null = null;

  @Input("departments") selectedDeps: string[] = [];

  @Input("isEdit") isEdit: boolean = false;

  maxStartDate(start: Date): string | null {
    if (!start) return null;
    return moment(start).subtract(1, "days").toISOString();
  }

  minEndDate(end: Date): string | null {
    if (!end) return null;
    return moment(end).add(1, "days").toISOString();
  }

  public weekDaysForm(days = null) {
    return this.weekDays.reduce((finalForm, day) => {
      const daysData = days ? days[day] : null;
      const dayFormData = this.getFormArr(
        daysData,
        this.timeRangeForm.bind(this)
      );
      finalForm[day] = this._fb.array(dayFormData);
      return finalForm;
    }, {});
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  public timeForm(time = null): FormControl {
    return new FormControl({
      from: time?.from || null,
      to: time?.to || null,
    });
  }

  public consultationForm(
    formValue = null,
    category = consultationCharges[0]
  ): FormControl {
    return new FormControl({
      category: formValue?.category || category,
      amount: formValue?.amount || null,
    });
  }

  getCategory(consultationArr: AbstractControl[]): string {
    const addedValues = this.getAllConsultation(consultationArr);
    return consultationCharges.reduce((final, val) => {
      if (!addedValues.includes(val) && !final) return val;
      return final;
    }, null);
  }

  public timeRangeForm(formValue = null): FormGroup {
    return this._fb.group(
      {
        from: this._fb.control(formValue?.from || null),
        to: this._fb.control(formValue?.to || null),
      },
      {
        validators: timeRangeValidators,
      }
    );
  }

  public days(daysGroup: FormGroup): FormGroup {
    return daysGroup.get("days") as FormGroup;
  }

  public dayForm(dayGroup, day): FormGroup {
    return this.days(dayGroup).get(day) as FormGroup;
  }

  public consultation(parentForm: FormGroup): FormArray {
    return parentForm.get("consultationCharges") as FormArray;
  }

  public getScheduleForm(formValue = null) {
    const sheduleFormData = this.getFormArr(
      formValue?.consultationCharges,
      this.consultationForm.bind(this)
    );
    return this._fb.group({
      department: this._fb.control(formValue?.department, Validators.required),
      duration: this._fb.control(formValue?.duration, Validators.required),
      allowMultipleAppointmentsInSameSlot: this._fb.control(
        formValue?.allowMultipleAppointmentsInSameSlot || false
      ),
      start: this._fb.control(formValue?.start, Validators.required),
      end: this._fb.control(formValue?.end, Validators.required),
      consultationCharges: this._fb.array(sheduleFormData),
      days: this._fb.group(this.weekDaysForm(formValue?.days)),
    });
  }

  public getFormArr(formData, form): FormGroup[] {
    if (!formData?.length) return [form()];
    let formArrData: FormGroup[] = [];
    formData?.forEach((formDataEl) => {
      formArrData = [...formArrData, form(formDataEl)];
    });
    return formArrData;
  }

  public scheduleForm = this.getScheduleForm();

  public scheduleFormList: FormArray = this._fb.array([this.scheduleForm]);

  constructor(public dialog: MatDialog, private _fb: FormBuilder) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.selectedDeps) {
      this.resetDeps();
    }
  }

  resetDeps() {
    this.scheduleFormList.controls.forEach((control) => {
      const department = control.get("department");
      const isReset = !this.selectedDeps.includes(department.value);
      if (isReset) department.reset();
    });
  }

  writeValue(obj: SchedulesInterface[]): void {
    this.schedulesData = obj || [];
    let formArr = [];
    this.schedulesData.forEach((formValue) => {
      formArr.push(this.getScheduleForm(formValue));
    });
    if (!formArr.length) formArr = [this.getScheduleForm()];
    this.scheduleFormList = this._fb.array(formArr);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  @ViewChild("scheduleAppointment") scheduleAppointment: TemplateRef<any>;

  public dialogRef;

  openAppointment() {
    this.dialogRef = this.dialog.open(this.scheduleAppointment, {
      disableClose: true,
      hasBackdrop: false,
    });
  }

  closeModal(): void {
    this.dialogRef.close();
  }

  public addNewTime(formArray: FormArray): void {
    formArray.push(this.timeRangeForm());
  }

  public addNewCharge(consultationGroup: FormArray): void {
    const newCategory = this.getCategory(consultationGroup.controls);
    consultationGroup.push(this.consultationForm(null, newCategory));
  }

  public delete(formArray: FormArray, index: number) {
    formArray.removeAt(index);
  }

  public addNewSchedule() {
    this.scheduleFormList.push(this.getScheduleForm());
  }

  public getForm(index: number): FormGroup {
    return this.scheduleFormList.controls[index] as FormGroup;
  }

  public saveData(): void {
    this.savingForm();
    this.closeModal();
  }

  public savingForm() {
    const filteredDays = this.getFilteredData();
    this.onTouched();
    this.onChange(filteredDays);
  }

  getFilteredData() {
    return this.scheduleFormList.value.map((formValue) => {
      this.weekDays.forEach((day) => {
        formValue.days[day] = formValue.days[day].filter(
          (dayData) => !!(dayData.from && dayData.to)
        );
      });
      formValue.consultationCharges = formValue.consultationCharges.filter(
        (consultationCharge) => !!consultationCharge.amount
      );
      return formValue;
    });
  }

  departmentDisabled(index: number, option): boolean {
    const departmentValue = this.getForm(index)?.get("department")?.value;
    return this.scheduleFormList?.controls?.reduce((final, form) => {
      const formValue = form.value?.department;
      if (formValue != departmentValue && option == formValue) return true;

      return final;
    }, false);
  }

  public getAllConsultation(controls: AbstractControl[]) {
    return controls.reduce((final, control) => {
      return [...final, control?.value?.category];
    }, []);
  }

  public addToAllSchedule(control: FormGroup) {
    const daysGroups = (control?.get("days") as FormGroup).controls;
    const firstDay = this.weekDays[0];
    const firstValue = daysGroups[firstDay]?.value;
    if (daysGroups[firstDay].invalid) return;
    const addValue = (formArr: FormArray) => {
      firstValue.forEach((val, index) => {
        if ((!val?.from || !val?.to) && index != 0) return;
        formArr.push(this.timeRangeForm(val));
      });
    };
    this.weekDays.forEach((day) => {
      if (day == firstDay) return;
      const dayGroup = daysGroups[day] as FormArray;
      dayGroup.clear();
      addValue(dayGroup);
    });
  }
}
