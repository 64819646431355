import {
  ChangeDetectionStrategy,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  forwardRef,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

export enum OtherInputPosition {
  Right = "right",
  Bottom = "bottom",
}

@Component({
  selector: "cp-select-and-input-others",
  templateUrl: "./select-and-input-others.component.html",
  styleUrls: ["./select-and-input-others.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectAndInputOthersComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: SelectAndInputOthersComponent,
      multi: true,
    },
  ],
})
export class SelectAndInputOthersComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  unsubscribe$ = new Subject();

  @Input() selectLabelName: string;
  @Input() width: number;
  @Input() gap: number;
  @Input() dropdownList: string[];
  @Input() otherInputPosition: OtherInputPosition = OtherInputPosition.Bottom;
  @Input() isRequired: boolean = false;

  selectFormControl = new FormControl(null);
  othersInput = new FormControl({ value: null, disabled: true });

  get otherInputPositionEnum() {
    return OtherInputPosition;
  }

  constructor() {}

  writeValue(obj: any): void {
    if (!obj) return;
    this.setControlValue(obj);
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  ngOnInit(): void {
    this.initListeners();
  }

  initListeners() {
    this.selectFormControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((dropdownValue) => {
        if (dropdownValue === "Others") {
          this.othersInput.enable({ emitEvent: false });
          this.onChange(null);
          return;
        }
        this.othersInput.disable({ emitEvent: false });

        this.onChange(dropdownValue);
      });

    this.othersInput.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((data) => {
        this.onChange(data);
      });
  }

  setControlValue(val) {
    if (this.dropdownList?.includes(val)) {
      this.selectFormControl.setValue(val);
      return;
    }

    this.selectFormControl.setValue("Others");
    this.othersInput.setValue(val);
  }

  validate() {
    if (!this.isRequired) return null;

    const selectValue = this.selectFormControl?.value;
    const otherValue = this.othersInput?.value;

    if (!selectValue)
      return this.selectFormControl.setErrors({ selectError: "Invalid value" });

    if (selectValue === "Others" && !(otherValue || otherValue?.length)) {
      return this.selectFormControl.setErrors({ otherError: "Invalid value" });
    }

    return this.selectFormControl.setErrors(null);
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
