import * as fromOrder from "./order";
import * as userReducer from "./user/index";
import * as authFormReducer from "./auth/index";
import * as patientReducer from "./patients/index";
import * as hospitalReducer from "./hospitals/index";
import * as orderReducer from "./order/orders.reducer";

export interface AppState {
  auth: authFormReducer.AuthState;
  user: userReducer.State;
  patients: patientReducer.patientState;
  hospitals: hospitalReducer.hospitalState;
  order: fromOrder.OrderState;
}

export const reducers = {
  // auth: as feature module
  user: userReducer.reducer,
  // patients: as feature module
  // hospitals: as feature module
  // patientChart: as feature module
  order: orderReducer.reducer,
};
