import { select, Store } from "@ngrx/store";
import { merge, Observable, of, Subject } from "rxjs";
import { faTrashAlt } from "@fortawesome/free-regular-svg-icons";
import {
  AbstractControl,
  ControlContainer,
  UntypedFormArray,
  Validators,
} from "@angular/forms";
import {
  ChangeDetectorRef,
  Component,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import {
  catchError,
  debounceTime,
  distinctUntilChanged,
  filter,
  map,
  switchMap,
  takeUntil,
  tap,
} from "rxjs/operators";

import { Orderable } from "../../../../models/Orderable.model";
import { MedRate } from "../../../../models/med/MedRate.model";
import { UtilService } from "../../../../services/util.service";
import { concentrationUnits } from "../../../../models/Med.model";
import { MedService } from "../../../../services/order/med.service";
import { MedPresetBE } from "../../../../models/preset/MedPreset.model";
import { OrderFormService } from "../../../../services/order-form.service";
import { SearchService } from "../../../../services/order-search.service";
import * as fromPatientHeader from "../../../../store/reducers/patient-chart/patient-header";
import { padStart } from "lodash-es";
import { FilterUnitPipe } from "./filter-unit.pipe";

@Component({
  selector: "app-med-form",
  templateUrl: "./med-form.component.html",
  styleUrls: ["./med-form.component.scss"],
})
export class MedFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() value?: MedPresetBE | Orderable;
  @Input() config: any;
  @Input() formType: string;
  @Input() orderInHospital: boolean;
  @Input() medSchedule?: Date[];
  @Input() orderableName?: string | null;
  @Input() showBedsideForm = true;

  @Input()
  set rate(rate: MedRate) {
    this.concentrationRate = rate;
  }

  filteredOptions: Observable<string[]>;

  public totalCombination = 0;
  public maxCombination = 5;
  public routes = null;
  public units = null;
  public subUnit: any = null;
  public orderDataError: boolean = false;
  public concentrationUnits = concentrationUnits;
  public concentrationRate: MedRate;

  public quantityValue$;
  public unitValue$;
  public concentrationValue$;
  public bodyWeightValue$;
  public maxDose$;
  public frequency$;
  private destroy$ = new Subject();

  faTrashAlt = faTrashAlt;

  medCombSearching = false;
  medCombSearchFailed = false;
  private patientHospitalName = "";
  selectedurgency: any;
  clickedItem: any = [];

  constructor(
    public utilService: UtilService,
    public controlContainer: ControlContainer,
    private orderFormService: OrderFormService,
    private medService: MedService,
    private _orderSearchService: SearchService,
    private _store: Store<any>,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.initConcentrationCalculation();
    this.loadOrderItems();
    this._store
      .pipe(select(fromPatientHeader.getPatientHospitalName))
      .subscribe((hospitalName) => (this.patientHospitalName = hospitalName));
    this.combination.value.forEach((element, i) => {
      this.clickedItem[i] = element.name;
    });
  }

  public loadOrderItems() {
    this.orderFormService
      .getOrderItems()
      .pipe(takeUntil(this.destroy$))
      .subscribe(
        (orderData) => {
          const med = orderData.order.med;
          this.units = med.unit;
          this.subUnit = med.unit;
          this.routes = med.route;
        },
        () => {
          this.orderDataError = true;
        }
      );
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.form.value.combination.forEach((element, i) => {
      this.clickedItem[i] = element.name;
    });
    this.selectedurgency = this.form.get("urgency").value;
    if (
      changes.medSchedule &&
      changes.medSchedule.currentValue &&
      changes.medSchedule.currentValue.length > 0 &&
      this.form
    ) {
      this.setEndTime();
    }

    if (changes.value && !changes.value.firstChange) {
      this.initConcentrationCalculation();
    }
  }

  ngOnDestroy(): void {
    this.destroy$.next();
  }

  /*
   * NAME: initConcentrationCalculation
   * PURPOSE: starts the concentarion calculation
   * DESCRIPTION: subscribes to med form value, when new value comes rate is calculated again
   * PARAMS: void
   * RETURNS: void
   * USED BY: ngOnChanges(), ngOnInit()
   * CREATED DATE: 14 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  initConcentrationCalculation(): void {
    this.quantityValue$ = this.form.get("quantity").valueChanges;
    this.unitValue$ = this.form.get("unit").valueChanges;
    this.concentrationValue$ = this.form.get("concentration").valueChanges;
    this.bodyWeightValue$ = this.form.get("bodyWeight").valueChanges;
    this.maxDose$ = this.form.get("maxDose").valueChanges;
    this.frequency$ = this.form.get("frequency").valueChanges;

    merge(
      this.quantityValue$,
      this.unitValue$,
      this.concentrationValue$,
      this.bodyWeightValue$,
      this.maxDose$,
      this.frequency$
    )
      .pipe(debounceTime(500), takeUntil(this.destroy$))
      .subscribe((_) => {
        this.concentrationRate = this.medService.getRate(this.form.value);
      });

    this.concentrationRate = this.medService.getRate(this.form.value);
  }

  /**
   * Get accessor parent form control.
   *
   * @returns {AbstractControl}
   */
  get form(): AbstractControl {
    return this.controlContainer.control;
  }

  /**
   * Get accessor for combination field.
   *
   * @returns {FormArray}
   */
  get combination(): UntypedFormArray {
    return this.form.get("combination") as UntypedFormArray;
  }

  /**
   * Get accessor for name field.
   *
   * @returns {AbstractControl}
   */
  get name(): AbstractControl {
    return this.form.get("name");
  }

  /**
   * Get accessor for quantity field.
   *
   * @returns {AbstractControl}
   */
  get quantity(): AbstractControl {
    return this.form.get("quantity");
  }

  /**
   * Get accessor for unit field.
   *
   * @returns {AbstractControl}
   */
  get unit(): AbstractControl {
    return this.form.get("unit");
  }

  /**
   * Get accessor for route field.
   *
   * @returns {AbstractControl}
   */
  get route(): AbstractControl {
    return this.form.get("route");
  }

  /**
   * Get accessor for urgency field.
   *
   * @returns {AbstractControl}
   */
  get urgency(): AbstractControl {
    return this.form.get("urgency");
  }

  /**
   * Get accessor for numberOfDoses field.
   *
   * @returns {AbstractControl}
   */
  get numberOfDoses(): AbstractControl {
    return this.form.get("numberOfDoses");
  }

  /**
   * Get accessor for frequency field.
   *
   * @returns {AbstractControl}
   */
  get frequency(): AbstractControl {
    return this.form.get("frequency");
  }

  /**
   * Get accessor for fType field.
   *
   * @returns {AbstractControl}
   */
  get fType(): AbstractControl {
    return this.form.get("frequency.fType");
  }

  /**
   * Get accessor for startTime field.
   *
   * @returns {AbstractControl}
   */
  get startTime(): AbstractControl {
    return this.form.get("startTime");
  }

  /**
   * Get accessor for endTime field.
   *
   * @returns {AbstractControl}
   */
  get endTime(): AbstractControl {
    return this.form.get("endTime");
  }

  /**
   * Get accessor for doseRange field.
   *
   * @returns {AbstractControl}
   */
  get doseRange(): AbstractControl {
    return this.form.get("doseRange");
  }

  /**
   * Get accessor for maxDose field.
   *
   * @returns {AbstractControl}
   */
  get maxDose(): AbstractControl {
    return this.form.get("maxDose");
  }

  /**
   * Get accessor for bodyWeight field.
   *
   * @returns {AbstractControl}
   */
  get bodyWeight(): AbstractControl {
    return this.form.get("bodyWeight");
  }

  /**
   * Get accessor for concentration.value field.
   *
   * @returns {AbstractControl}
   */
  get concentrationValue(): AbstractControl {
    return this.form.get("concentration.value");
  }

  /**
   * Get accessor for concentration.unit field.
   *
   * @returns {AbstractControl}
   */
  get concentrationUnit(): AbstractControl {
    return this.form.get("concentration.unit");
  }

  /*
   * NAME: doseRangeChange
   * PURPOSE: updates max dose value and validation
   * DESCRIPTION: if range is checked then set required validator
   *   - if not then clear validator and set dose to null
   *   - calls updateValueAndValidity() angular method to update validation
   * PARAMS: checked:boolean - if range is checked
   * RETURNS: void
   * USED BY: med-form.component.html
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  doseRangeChange(checked: boolean): void {
    if (checked) {
      this.maxDose.setValidators([Validators.required]);
    } else {
      this.maxDose.clearValidators();
      this.maxDose.reset();
    }

    this.maxDose.updateValueAndValidity();
  }

  /**
   * Adds new combination med form to combination field.
   * Then increment the totalCombination value.
   *
   * @param {event} event
   */
  addCombination(event): void {
    event.preventDefault();

    this.combination.push(this.orderFormService.newCombinationFormGroup());
    this.combination.controls.forEach((c) => c.updateValueAndValidity());
    this.totalCombination++;
  }

  /*
   * NAME: setEndTime
   * PURPOSE: Updates endTime form value
   * DESCRIPTION: calculates endTime from calculateEndTime method and then updates it.
   * PARAMS: void
   * RETURNS: void
   * USED BY: ngOnChanges
   * CREATED DATE: 17 October 2019
   * AUTHOR: Gunjit Agrawal
   */
  setEndTime(): void {
    const frequency = this.frequency.value;
    const startTime = this.startTime.value;
    const numberOfDoses = this.numberOfDoses.value;

    const endTime = this.orderFormService.calculateEndTime(
      frequency,
      startTime,
      numberOfDoses
    );

    if (endTime) {
      this.endTime.patchValue({
        date: new Date(endTime),
        hour: padStart(endTime.getHours(), 2, 0),
        minute: padStart(endTime.getMinutes(), 2, 0),
      });
    } else {
      this.endTime.patchValue({ date: null, hour: null, minute: null });
    }
  }

  /**
   * Removes the combination form from combination field.
   * Then decrement the totalCombination value.
   *
   * @param {number} index
   */
  removeCombination(index: number): void {
    this.combination.removeAt(index);
    this.totalCombination--;
    this.clickedItem.splice(index, 1);
  }

  /**
   * Get a particular combination field from passed controlName and index.
   *
   * @param {string} controlName - name | quantity | unit
   * @param {number} index
   * @returns {AbstractControl}
   */
  getCombItemField(controlName: string, index: number): AbstractControl {
    return this.form.get(`combination.${index}.${controlName}`);
  }

  /**
   * Checks if the given field has a specific error.
   *
   * @param {string} controlName
   * @param {string} errorName
   * @param {number} index
   * @returns {boolean}
   */
  hasCombError(controlName: string, errorName: string, index: number): boolean {
    return this.getCombItemField(controlName, index).hasError(errorName);
  }

  /**
   * If bedside is checked then resets home med checkbox.
   */
  onBedsideChange() {
    this.form.get("pta").patchValue(false);
    this.form
      .get("bedsideOrder")
      .patchValue(!!!this.form.get("bedsideOrder").value);
  }

  /**
   * If home med is checked then resets bedside checkbox.
   */
  onHomeMedChange(event) {
    this.form.get("bedsideOrder").patchValue(false);
    this.form.get("pta").patchValue(!!!this.form.get("pta").value);
  }

  /*
   * NAME: onUnitChange
   * PURPOSE: updates concentration fields
   * DESCRIPTION: if unit is not of concentration category, then clears concentration value
   *   - calls updateConcentrationValidation to update concentration valdation
   * PARAMS: unit:string - med unit
   * RETURNS:  void
   * USED BY:
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  onUnitChange(unit: string): void {
    const pipe = new FilterUnitPipe();
    this.subUnit = pipe.transform(this.units, unit);
    if (!this.isConcentrationUnit(unit)) {
      this.clearConcentrationValue();
    }
  }

  /*
   * NAME: clearConcentrationValidation
   * PURPOSE: clears concentration validation
   * DESCRIPTION:  clears required validation on unit and value
   * PARAMS: void
   * RETURNS: void
   * USED BY: updateConcentrationValidation()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  clearConcentrationValidation(): void {
    this.concentrationUnit.clearValidators();
    this.concentrationValue.clearValidators();
    this.updateValueAndValidityOfConcentration();
  }

  /*
   * NAME: clearConcentrationValue
   * PURPOSE: set conc. unit, conc. value and bodyWeight to null
   * DESCRIPTION:
   * PARAMS: null
   * RETURNS: void
   * USED BY: onUnitChange()
   * CREATED DATE: 6 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  clearConcentrationValue(): void {
    this.concentrationUnit.setValue(null);
    this.concentrationValue.setValue(null);
    // this.bodyWeight.setValue(null);
  }

  /*
   * NAME: updateValueAndValidityOfConcentration
   * PURPOSE: update value and validity for concentration
   * DESCRIPTION: calls updateValueAndValidity() angular function to update
   *   - it is required when validation is set dynamically
   * PARAMS: null
   * RETURNS: void
   * USED BY: setConcentrationValidation(), clearConcentrationValidation()
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  updateValueAndValidityOfConcentration(): void {
    this.concentrationUnit.updateValueAndValidity();
    this.concentrationValue.updateValueAndValidity();
  }

  /*
   * NAME: isConcentrationUnit
   * PURPOSE: checks wether med unit is of concentration type.
   * DESCRIPTION:  calls isConcentrationUnit of med service to check
   * PARAMS: unit:string - med unit
   * RETURNS: boolean - true if it is a unit of concentration
   * USED BY: updateConcentrationValidation(), onUnitChange()
   * CREATED DATE: 5 Novmeber 2019
   * AUTHOR: Gunjit Agrawal
   */
  isConcentrationUnit(unit: string): boolean {
    return this.medService.isConcentrationUnit(unit);
  }

  /*
   * NAME: hasConcentrationError
   * PURPOSE: checks if concentration field has required error
   * DESCRIPTION:
   * PARAMS: field:string - unit or value
   * RETURNS: boolean - true if error is present
   * USED BY: med-form.component.html
   * CREATED DATE: 5 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  hasConcentrationError(field: string): boolean {
    if (field === "unit") {
      return this.concentrationUnit.hasError("required");
    } else {
      return this.concentrationValue.hasError("required");
    }
  }

  getFirstCombName(name: string) {
    if (!name) {
      return null;
    }

    const nameArray = name.split("/");

    return nameArray.length > 1 ? nameArray[0] : null;
  }

  /*
   * NAME: onDaysChange
   * PURPOSE: set endTime and number of doses.
   * DESCRIPTION:
   *   if frequency is every then number of doses is calculated and updated.
   *   if frequency is continuous then endTime is calculated and updated.
   * PARAMS: days:number
   * RETURNS: void
   * USED BY:
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDaysChange(days: number): void {
    const frequency = this.form.value["frequency"];
    const startTime = this.form.value["startTime"];

    if (frequency && frequency.fType === "every") {
      const dose = this.medService.calculateNumberOfDoses(frequency, days);

      if (this.form.value["noOfDays"] == "0") {
        this.form.get("numberOfDoses").reset();
        this.form.get("noOfDays").reset();
      }

      if (this.form.value["noOfDays"]) {
        this.form.get("numberOfDoses").setValue(dose);
      } else {
        this.form.get("numberOfDoses").reset();
      }
    }

    if (
      frequency &&
      frequency.fType === "continuous" &&
      startTime &&
      startTime.date
    ) {
      const endTime = this.orderFormService.calculateEndTime(
        frequency,
        startTime,
        null,
        days
      );

      if (endTime && this.form.get("endTime")) {
        this.form.get("endTime").patchValue({
          date: new Date(endTime),
          hour: padStart(endTime.getHours(), 2, 0),
          minute: padStart(endTime.getMinutes(), 2, 0),
        });
      }
    }
  }

  /*
   * NAME: onDoseChange
   * PURPOSE: set number of days
   * DESCRIPTION:
   *   if frequency is every then number of days is calculated and updated.
   * PARAMS: days:number
   * RETURNS: void
   * USED BY:
   * CREATED DATE: 26/08/20
   * AUTHOR: Gunjit Agrawal
   */
  onDoseChange(dose: number) {
    const frequency = this.form.value["frequency"];

    if (frequency && frequency.fType === "every") {
      const days = this.medService.calculateNumberOfDays(frequency, dose);

      if (this.form.value["numberOfDoses"] == "0") {
        this.form.get("numberOfDoses").reset();
        this.form.get("noOfDays").reset();
      }

      if (this.form.value["numberOfDoses"]) {
        this.form.get("noOfDays").setValue(days);
      } else {
        this.form.get("noOfDays").reset();
      }
    }
  }
  inputHandle(e) {
    const name = this.combination.controls.find((x) => x.get("name").value);
    name
      .get("name")
      .valueChanges.pipe(
        filter((term) => term != undefined && term.length > 2),
        debounceTime(300),
        distinctUntilChanged()
      )
      .subscribe((val) => {
        this.filteredOptions = this._orderSearchService
          .search(val, "patient", "med", this.patientHospitalName)
          .pipe(
            map((data) => {
              if (data && data["med"]) {
                return data["med"].map((med) => med.name);
              }

              return [];
            })
          );
      });
  }

  isDisabled(e) {
    return e?.scrollWidth <= e?.clientWidth || false;
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  selectedItem(item, i) {
    this.clickedItem[i] = item;
  }

  assignUnit(unit) {
    const pipe = new FilterUnitPipe();
    this.subUnit = pipe.transform(this.units, unit);
  }
}
