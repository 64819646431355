export function processRichEditInWebWorker(_function) {

    // Create worker for background processing.  Client app may not need this if they not procesing final text much.
    // But better to keep this, to de-couple UI thread and background thread.
    var workerURL = URL.createObjectURL(new Blob([_function.toString(),
    ';this.onmessage =  function (eee) {' + _function.name + '(eee.data);}'
    ], {
            type: 'application/javascript'
        }));
  
    var worker: any = new Worker(workerURL);
    worker.workerURL = workerURL;
    return worker;
  }
  
export function ProcessResponseData(ActionRecipe) {
          
    // Data post method of worker.
    self.postMessage(ActionRecipe, '');
  }