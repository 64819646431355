import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpHeaders,
  HttpErrorResponse,
} from "@angular/common/http";
import { Router } from "@angular/router";
import { Store } from "@ngrx/store";
import { environment } from "src/environments/environment";
import { BehaviorSubject, throwError, fromEvent } from "rxjs";
import { catchError } from "rxjs/operators";
import * as userRoles from "../shared/accessControl/roles";
import * as rootActions from "src/app/store/actions/root.actions";
import * as crypto from "crypto-js";
import { Location } from "@angular/common";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  env = environment;
  private _registerUrl = this.env.apiUrl + "users/signup";
  private _loginUrl = this.env.apiUrl + "users/login";
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  tokenExpiredBehavior: BehaviorSubject<any> = new BehaviorSubject<any>(false);
  tokenExpiredAction = this.tokenExpiredBehavior.asObservable();

  constructor(
    private http: HttpClient,
    private _router: Router,
    private store: Store<{}>,
    private _location: Location
  ) {
    this.syncMethod();
  }

  syncMethod() {
    const source$ = fromEvent(window, "storage");
    source$.pipe().subscribe((storage) => {
      if (!localStorage.getItem("token")) {
        this._router.navigate(["/login"]);
        this.store.dispatch(rootActions.logout());
      }
    });
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error.message || "Server error");
  }

  registerUser(user) {
    return this.http.post<any>(this._registerUrl, user, {
      headers: new HttpHeaders(),
    });
  }

  loginUser(user) {
    return this.http.post<any>(this._loginUrl, user);
  }

  changeRole(user) {
    return this.http.post<any>(this.env.apiUrl + "users/change_role", user);
  }

  encryptPassword(value: String): String {
    return crypto.AES.encrypt(value, environment.passwordSecretKey).toString();
  }

  logoutUser(socketID?) {
    // This is done from effects

    return this.http.post<any>(`${this.env.apiUrl}users/logout`, {
      socketID: socketID || null,
    });
    // this.store.dispatch({ type: "logout", payload: {} });
  }

  isLoggedIn() {
    return !!localStorage.getItem("token");
  }

  getToken() {
    return localStorage.getItem("token");
  }

  changePassword(user) {
    return this.http.post<any>(this.env.apiUrl + "users/change_password", user);
    // .pipe(catchError(this.errorHandler));
  }

  checkAdmin() {
    let currUser = JSON.parse(localStorage.getItem("currentUser"));
    if (
      currUser.role == "System Administrator" ||
      currUser.role == "Medical Administrator" ||
      currUser.role == "Billing Administrator" ||
      currUser.role == "Super Billing Administrator"
    ) {
      this.logoutUser();
    }
  }

  checkIfnotAdmin() {
    let currUser = JSON.parse(localStorage.getItem("currentUser"));

    if (
      currUser.role != "System Administrator" &&
      currUser.role != "Medical Administrator" &&
      currUser.role != "Billing Administrator" &&
      currUser.role == "Super Billing Administrator"
    ) {
      this.logoutUser();
    }
  }

  checkIfnotAudit() {
    let currUser = JSON.parse(localStorage.getItem("currentUser"));

    if (currUser.role != "Auditor") {
      this.logoutUser();
    }
  }

  checkIfAccessibleByRole(path): boolean {
    let user = JSON.parse(localStorage.getItem("currentUser"));
    let role = user.role;
    let roleGroupToCheck = "";
    let pathFound = path.split("/")[0];

    switch (pathFound) {
      case "management":
        roleGroupToCheck = "management";
        break;

      case "patientlist":
      case "patient":
        roleGroupToCheck = "patientAccess";
        break;

      case "med-admin":
        roleGroupToCheck = "medAdmin";
        break;

      case "admitPatient":
        roleGroupToCheck = "patientAccess";
        break;

      case "tv":
        roleGroupToCheck = "tv";
        break;

      case "audit":
        roleGroupToCheck = "auditor";
        break;

      case "billing":
        roleGroupToCheck = "billingAdmin";
        break;

      default:
        roleGroupToCheck = "";
        break;
    }

    if (roleGroupToCheck && userRoles[roleGroupToCheck].includes(role)) {
      return true;
    } else {
      return false;
    }
  }

  redirectUser(role: string, changeRole: boolean = false): void {
    function getSwitchUrl(roleType: string): string {
      switch (roleType) {
        case "Super Administrator":
          return "/management/super-admin";

        case "System Administrator":
          return "/management/system-admin";

        case "Medical Administrator":
          return "/med-admin";

        case "Billing Administrator":
        case "Super Billing Administrator":
          return "/billing";

        case "Tv":
          return "tv/home";

        case "Tv-Covid":
          return "tv/covid";

        case "Auditor":
          return "/audit";

        case "R-Alert":
          return "/r-alert";

        default:
          return "/patientlist";
      }
    }

    let switchUrl: any = getSwitchUrl(role);

    if (changeRole) {
      this._location.go(switchUrl);

      setTimeout(() => {
        location.reload();
      }, 0);
    } else {
      this._router.navigate([switchUrl]);
    }
  }

  /**
   * Setting session expired info
   */
  private identification: any = null; // adding in constructor due to typescript error

  private sessionExpirationInfo = new BehaviorSubject(this.identification);
  notifySessionExpirationInfo = this.sessionExpirationInfo.asObservable();

  setSessionExpirationInfo(value) {
    this.sessionExpirationInfo.next(value);
  }

  getAccessToken() {
    return this.http
      .get<any>(
        `${
          this.env.apiUrl
        }users/requestAccessToken?x-refresh-token=${localStorage.getItem(
          "refreshToken"
        )}`
      )
      .toPromise();
  }

  initiateTokenExpired(value) {
    this.tokenExpiredBehavior.next(value);
  }

  /**
   * @description This is used to trigger the server to broadcast the socket message regarding role change for other tabs
   * @author Suraj Shenoy
   * @date 14 Feb 2022
   */
  triggerRoleChange() {
    return this.http
      .get(`${this.env.apiUrl}users/roleChangeTrigger`)
      .pipe(catchError(this.errorHandler));
  }
}
