import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  displayErrorMessage,
  displaySuccessMessage,
} from "../actions/root.actions";
import { tap } from "rxjs/operators";
import { AlertService } from "@iris/alert/services/alert.service";

@Injectable()
export class MessageEffects {
  constructor(private actions$: Actions, private alertService: AlertService) {}

  displaySuccessMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displaySuccessMessage),
        tap(({ message, description = null }) =>
          this.alertService.showNotification({
            type: "Success",
            message,
          })
        )
      ),
    { dispatch: false }
  );

  displayErrorMessage$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(displayErrorMessage),
        tap(({ message, description = null }) =>
          this.alertService.showNotification({
            type: "Error",
            message,
          })
        )
      ),
    { dispatch: false }
  );
}
