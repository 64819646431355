import { Component, Inject, OnInit } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";

@Component({
  selector: "app-confim-dialog",
  templateUrl: "./confim-dialog.component.html",
  styleUrls: ["./confim-dialog.component.scss"],
})
export class ConfimDialogComponent implements OnInit {
  constructor(
    @Inject(MAT_DIALOG_DATA) public data,
    public dialogRef: MatDialogRef<ConfimDialogComponent>
  ) {}

  ngOnInit(): void {}

  get statusData(): { class: string; text: string } {
    let returnObj = {
      class: "button-primary",
      text: "Accept",
    };
    if (this.data.alertType == "reject") {
      returnObj = {
        class: "button-error",
        text: "Reject",
      };
    }
    return returnObj;
  }

  public get itemList() {
    return this.data?.itemList;
  }

  closeDialog() {
    this.dialogRef.close(false);
  }
}
