<div class="cp-card">
  <div class="header" #cardheader>
    <div class="header__title">
      <app-avatar [avatarParams]="avatarParamsIcon1"></app-avatar>
      <ng-content select="[userInfo]"></ng-content>
    </div>
  </div>
  <div class="body"><ng-content select="[card-body]"></ng-content></div>
  <mat-divider class="mt-4"></mat-divider>
  <div class="footer">
    <ng-content select="[details]"></ng-content>
  </div>
</div>
