<div
  class="cp-consultation-charge-comp display-flex align-items-center"
  [formGroup]="chargesForm"
>
  <div class="cp-consultation-charge-comp__category">
    <mat-select
      formControlName="category"
      class="bg-light cp-form-input cp-form-input--custom-field combined-input__left"
      placeholder="Visit Category"
    >
      <mat-option
        *ngFor="let category of categories"
        [disabled]="checkDisabled(category)"
        [value]="category"
      >
        {{ category }}
      </mat-option>
    </mat-select>
  </div>
  <div class="cp-consultation-charge-comp__amount">
    <input
      class="cp-form-input cp-form-input--custom-field combined-input__right"
      type="number"
      formControlName="amount"
      matInput
      placeholder="enter amount"
    />
  </div>
  <div class="ml mt cursor-pointer" (click)="addNewCharge()">
    <mat-icon
      matDatepickerToggleIcon
      svgIcon="cp-add"
      aria-hidden="false"
      aria-label="add icon"
    >
    </mat-icon>
  </div>
  <div
    (click)="deleteCharge()"
    class="cursor-pointer display-flex mt"
    *ngIf="showDelete"
  >
    <mat-icon class="cp-icon">delete</mat-icon>
  </div>
</div>
