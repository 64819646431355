<div class="main-container iris-design-system-comp cp-light-theme">
  <div class="avatar-cont">
    <div class="component-name">Avatars</div>

    <div class="comp-align">
      <app-avatar [avatarParams]="avatarParamsIcon1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsIcon2"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsIcon3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsIcon4"></app-avatar>

      <app-avatar [avatarParams]="avatarParamsInitials1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsInitials2"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsInitials3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsInitials4"></app-avatar>

      <app-avatar [avatarParams]="avatarParamsImage1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsImage2"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsImage3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsImage4"></app-avatar>
    </div>

    <div class="comp-align">
      <app-avatar [avatarParams]="avatarParamsGroup1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsGroup2"></app-avatar>

      <app-avatar [avatarParams]="avatarParamsGroup3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsGroup4"></app-avatar>
    </div>

    <div class="comp-align">
      <app-avatar [avatarParams]="avatarParamsGroup5"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsGroup6"></app-avatar>

      <app-avatar [avatarParams]="avatarParamsGroup7"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsGroup8"></app-avatar>
    </div>

    <div class="comp-align">
      <app-avatar [avatarParams]="avatarParamsActive1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsActive2"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsActive3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsActive4"></app-avatar>

      <app-avatar [avatarParams]="avatarParamsAway1"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsAway2"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsAway3"></app-avatar>
      <app-avatar [avatarParams]="avatarParamsAway4"></app-avatar>
    </div>
  </div>

  <div class="alert-cont">
    <div class="component-name">Alert</div>
    <div style="display: flex">
      <button
        style="margin-right: 4px !important"
        mat-flat-button
        class="button-primary"
        (click)="info()"
      >
        Info Button
      </button>
      <button
        style="margin-right: 4px !important"
        mat-flat-button
        class="button-primary"
        (click)="success()"
      >
        Success Button
      </button>
      <button
        style="margin-right: 4px !important"
        mat-flat-button
        class="button-primary"
        (click)="warning()"
      >
        Warning Button
      </button>
      <button
        style="margin-right: 4px !important"
        mat-flat-button
        class="button-primary"
        (click)="error()"
      >
        Error Button
      </button>
    </div>
  </div>

  <div class="badge-cont">
    <div class="component-name">Badge/Labels</div>
    <div style="display: flex">
      <div>
        <app-label-badge
          [iris_class]="'iris-label-outline label-blue'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-red'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-green'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-grey'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-orange'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small cp-blue'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default medium cp-blue'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default large cp-blue'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small cp-grey'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default medium cp-grey'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default large cp-grey'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small label-blue'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-red'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-green'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-grey'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-orange'"
          [labelData]="labelData"
        >
        </app-label-badge>
      </div>

      <div>
        <app-label-badge
          [iris_class]="'iris-label-outline label-blue label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-red label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-green label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-grey label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-outline label-orange label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small cp-blue label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default medium cp-blue label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default large cp-blue label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small cp-grey label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default medium cp-grey label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default large cp-grey label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <br />
        <app-label-badge
          [iris_class]="'iris-label-default small label-blue label-left'"
          [labelData]="labelData"
        ></app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-red label-left'"
          [labelData]="labelData"
        >
        </app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-green label-left'"
          [labelData]="labelData"
        ></app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-grey label-left'"
          [labelData]="labelData"
        ></app-label-badge>
        <app-label-badge
          [iris_class]="'iris-label-default small label-orange label-left'"
          [labelData]="labelData"
        ></app-label-badge>
      </div>
    </div>
  </div>

  <div class="button-cont">
    <div class="component-name">Buttons</div>

    <div class="comp-align">
      <button mat-flat-button class="button-primary">Primary Button</button>
      <button mat-flat-button class="button-primary" disabled>
        Primary Button
      </button>

      <button mat-flat-button class="button-secondary">Tertiary Button</button>
      <button mat-flat-button class="button-secondary" disabled>
        Tertiary Button
      </button>

      <button mat-flat-button class="button-error">Delete Button</button>
      <button mat-flat-button class="button-error" disabled>
        Delete Button
      </button>
    </div>

    <div class="comp-align">
      <button mat-flat-button class="button-primary">
        <mat-icon class="icon-left">bookmark</mat-icon>
        Save
      </button>
      <button mat-flat-button class="button-primary">
        Save
        <mat-icon class="icon-right">bookmark</mat-icon>
      </button>

      <button mat-flat-button class="button-secondary">
        <img
          class="icon-left"
          src="../../../assets/icons/add_circle_outline.svg"
        />
        Add
      </button>
      <button mat-flat-button class="button-secondary">
        Remove
        <mat-icon class="icon-right">remove</mat-icon>
      </button>

      <button mat-flat-button class="button-error">
        <mat-icon class="icon-left">delete</mat-icon>
        Delete
      </button>
      <button mat-flat-button class="button-error">
        Delete
        <mat-icon class="icon-right">delete</mat-icon>
      </button>
    </div>

    <div class="comp-align bg-dark dark-theme">
      <button mat-flat-button class="button-transparent-dark">
        Transparent Button
      </button>
      <button mat-flat-button class="button-transparent-dark" disabled>
        Transparent Button
      </button>
    </div>

    <div class="btn-group">
      <div class="button-group-vertical">
        <button mat-flat-button class="button-primary">Top</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Bottom</button>
      </div>

      <div class="button-group-horizontal">
        <button mat-flat-button class="button-primary">Top</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Middle</button>
        <button mat-flat-button class="button-primary">Bottom</button>
      </div>
    </div>

    <div class="fab-icon-cont">
      <div class="comp-align">
        <button mat-fab class="fab-icon">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab class="fab-icon outline">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab class="fab-icon no-outline">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab disabled class="fab-icon">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab disabled class="fab-icon outline">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab disabled class="fab-icon no-outline">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab class="fab-icon small">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab class="fab-icon small outline">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab class="fab-icon small no-outline">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small outline">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small no-outline">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>
      </div>

      <div class="comp-align">
        <button mat-fab class="fab-icon square">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab class="fab-icon outline square">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab class="fab-icon no-outline square">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab disabled class="fab-icon square">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab disabled class="fab-icon outline square">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab disabled class="fab-icon no-outline square">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab class="fab-icon small square">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab class="fab-icon small outline square">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab class="fab-icon small no-outline square">
          <img src="../../../assets/icons/heart.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small square">
          <img src="../../../assets/icons/heart-white.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small outline square">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>

        <button mat-fab disabled class="fab-icon small no-outline square">
          <img src="../../../assets/icons/heart-disabled.svg" />
        </button>
      </div>
    </div>
  </div>

  <div class="link-cont">
    <div class="component-name">Link</div>
  </div>

  <div class="loader-cont">
    <div class="component-name">Loader</div>

    <button
      (click)="onLoaderClick('pageLoader')"
      mat-raised-btn
      class="button-secondary"
    >
      Show Loader
    </button>
    <button
      (click)="onLoaderClick('buttonLoader')"
      mat-raised-btn
      class="button-secondary"
    >
      Button Loader
      <app-loader [buttonLoader]="true" *ngIf="showButtonLoader"></app-loader>
    </button>
    <app-loader *ngIf="showLoader"></app-loader>
  </div>

  <div class="progress-bar-cont">
    <div class="component-name">Progress Bar</div>

    <app-progress-bar [progressBarArr]="progressBarArr"></app-progress-bar>
  </div>

  <div class="counter-cont">
    <div class="component-name">Controls : Counter</div>

    <div class="comp-align">
      <app-counter count="4" label="Room"></app-counter>
    </div>
  </div>

  <div class="popover-cont">
    <div class="component-name">Popover</div>

    <div class="comp-align">
      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="top-start"
        [popoverAutoclose]="false"
      >
        Top Start
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="top-center"
        [popoverAutoclose]="false"
      >
        Top Center
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="top-end"
      >
        Top End
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="click"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="bottom-start"
      >
        Bottom Start
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="bottom-center"
        [popoverAutoclose]="false"
      >
        Bottom Center
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="bottom-end"
      >
        Bottom End
      </button>
    </div>

    <div class="comp-align">
      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="right-start"
      >
        Right Start
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="right-center"
      >
        Right Center
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="right-end"
        [popoverAutoclose]="false"
      >
        Right End
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="left-start"
      >
        Left Start
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="click"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="left-center"
        [popoverAutoclose]="false"
      >
        Left Center
      </button>

      <button
        mat-flat-btn
        class="button-primary"
        popoverTrigger="hover"
        cpPopover="{{ content }}"
        popoverTitle="{{ title }}"
        position="left-end"
      >
        Left End
      </button>
    </div>
  </div>

  <div class="btn-tag-cont">
    <div class="component-name">Button : Tag</div>

    <div class="comp-align">
      <app-btn-tag
        [tagName]="btnTagNames[0]"
        [iconLeft]="iconLeft"
        [iconRight]="iconRight"
        (iconClick)="onIconClick($event)"
      ></app-btn-tag>
      <app-btn-tag
        [tagName]="btnTagNames[1]"
        [iconLeft]="iconLeft"
        [iconRight]="iconRight"
        (iconClick)="onIconClick($event)"
      ></app-btn-tag>
      <app-btn-tag
        [tagName]="btnTagNames[2]"
        [iconLeft]="iconLeft"
        [iconRight]="iconRight"
        (iconClick)="onIconClick($event)"
      ></app-btn-tag>
      <app-btn-tag
        [disabled]="true"
        [tagName]="btnTagNames[0]"
        [iconLeft]="iconLeft"
        [iconRight]="iconRight"
      ></app-btn-tag>
    </div>

    <div class="icon-name">{{ iconName }}</div>
  </div>

  <!-- <div class="date-time-picker-cont">
    <div class="component-name">Date Time Picker</div>

    <div class="date-pickers" [formGroup]="dateGroup">
      <app-date-time-picker
        (dateChange)="onDateChange($event, false)"
        [minValidDate]="minValidDate"
        (timeChange)="onTimeChange($event)"
      ></app-date-time-picker>

      <app-date-time-picker
        formControlName="dateTimeControl"
        [showTime]="true"
        [showNowButton]="true"
        [defaultValue]="defaultDate"
        (dateChange)="onDateChange($event, true)"
        (timeChange)="onTimeChange($event)"
      ></app-date-time-picker>

      <button mat-flat-button class="button-error" (click)="onResetDateTime()">
        Reset
      </button>

      <app-date-time-picker
        formControlName="dateRangeControl"
        [dateRange]="true"
        [maxValidDate]="minValidDate"
        (dateRangeChange)="onDateRangeChange($event)"
      ></app-date-time-picker>
    </div>

    <div class="date-time-output">
      <div class="date">
        <span><b>Date :</b> {{ date1 }} </span>
      </div>

      <div class="date-and-time">
        <span><b>Date :</b> {{ date2 }} </span>
        <span><b>Hour :</b> {{ hour }} </span>
        <span><b>Minute :</b> {{ minute }}</span>
      </div>

      <div class="date-range">
        <span><b>From Date :</b> {{ fromDate }} </span>
        <span><b>To Date :</b> {{ toDate }} </span>
      </div>
    </div>
  </div> -->

  <div class="table-cont">
    <div class="component-name">Table</div>

    <table mat-table [dataSource]="dataSource" class="table-width">
      <!--- Note that these columns can be defined in any order.
						The actual rendered columns are set as a property on the row definition" -->

      <!-- Position Column -->
      <ng-container matColumnDef="position">
        <th mat-header-cell *matHeaderCellDef>No.</th>
        <td mat-cell *matCellDef="let element">{{ element.position }}</td>
      </ng-container>

      <!-- Name Column -->
      <ng-container matColumnDef="name">
        <th mat-header-cell *matHeaderCellDef>Name</th>
        <td mat-cell *matCellDef="let element">{{ element.name }}</td>
      </ng-container>

      <!-- Weight Column -->
      <ng-container matColumnDef="weight">
        <th mat-header-cell *matHeaderCellDef>Weight</th>
        <td mat-cell *matCellDef="let element">{{ element.weight }}</td>
      </ng-container>

      <!-- Symbol Column -->
      <ng-container matColumnDef="symbol">
        <th mat-header-cell *matHeaderCellDef>Symbol</th>
        <td mat-cell *matCellDef="let element">{{ element.symbol }}</td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns"></tr>
    </table>
  </div>

  <!-- <div class="form-cont">
		<form [formGroup]="form">
			<app-iris-input [irisFormType]="'Text'" [formControlName]="'name'" [placeholder]="'Name'"></app-iris-input>

			<button (click)="submit()"> Submit</button>
		</form>
	</div>  -->

  <div class="irisForm-group">
    <mat-label class="iris-label">Label:</mat-label>
    <mat-form-field class="iris-form" appearance="outline">
      <input matInput />
    </mat-form-field>
  </div>

  <div class="dialog-display">
    <button mat-raised-button (click)="openDialog()">Display dialog</button>
  </div>

  <div>
    <mat-form-field class="date-icon" floatLabel="always">
      <mat-label>Date</mat-label>
      <input matInput [matDatepicker]="picker" />
      <mat-datepicker-toggle matPrefix [for]="picker">
        <mat-icon
          matDatepickerToggleIcon
          svgIcon="cp-calender"
          aria-hidden="false"
          aria-label="calender icon"
        >
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker-toggle matSuffix [for]="picker">
        <mat-icon
          matDatepickerToggleIcon
          svgIcon="cp-arrow-down"
          aria-hidden="false"
          aria-label="open arrow icon"
        >
        </mat-icon>
      </mat-datepicker-toggle>
      <mat-datepicker #picker></mat-datepicker>
    </mat-form-field>
  </div>
  <div class="overlaycontainer">
    <div class="component-name">CDK overlay</div>
    <div class="display-flex">
      <div>
        <label><b>Width 100%</b></label>
        <button
          mat-flat-button
          class="button-primary"
          (click)="openCdkOverlay()"
        >
          Open
        </button>
      </div>
      <div class="ml">
        <label><b>Width 65%</b></label>
        <button
          mat-flat-button
          class="button-primary"
          (click)="openCdkOverlayWidth65()"
        >
          Open
        </button>
      </div>
      <div class="ml">
        <label><b>Width 50%</b></label>
        <button
          mat-flat-button
          class="button-primary"
          (click)="openCdkOverlayWidth50()"
        >
          Open
        </button>
      </div>
    </div>
    <br />
    <b class="mt"
      >Note: Please Update the style for overlay in
      './iris-styles/components/modified/_overlay.scss'</b
    >

    <app-overlay #overlayComp1>
      <div class="overlayContainer--content">
        <h2>Panel Width 100%</h2>
      </div>
    </app-overlay>

    <app-overlay
      #overlayComp2
      [backdropClass]="'backdrop-width-65'"
      [panelClass]="'panel-width-65'"
    >
      <div class="overlayContainer--content">
        <h2>Panel Width 65%</h2>
      </div>
    </app-overlay>

    <app-overlay
      #overlayComp3
      [backdropClass]="'backdrop-width-50'"
      [panelClass]="'panel-width-50'"
    >
      <div class="overlayContainer--content">
        <h2>Panel Width 50%</h2>
      </div>
    </app-overlay>
  </div>

  <div class="matCheckboxContainer">
    <div class="component-name">Custom checkbox</div>
    <div class="display-flex">
      <label class="primary-checkbox">
        <input class="primary-checkbox_checkbox" type="checkbox" />
        <span class="primary-checkbox_checkmark"></span>
        <span class="primary-checkbox_label">Primary Checkbox</span>
      </label>
      <label class="primary-checkbox red ml">
        <input class="primary-checkbox_checkbox" type="checkbox" />
        <span class="primary-checkbox_checkmark"></span>
        <span class="primary-checkbox_label">Red Checkbox</span>
      </label>
    </div>
    <div class="display-flex">
      <cp-choice-list
        label="Multiple checkbox"
        [formControl]="mulpleCheckboxes"
        [options]="['option 1', 'option 2']"
      ></cp-choice-list>
    </div>
  </div>
</div>

<!-- <div>
  <app-appointment>
    <ng-container userInfo>
      <app-avatar [avatarParams]="avatarParamsIcon1"></app-avatar>
      <span class="cp-title-primary">Dr. Lindsey Saris</span>
    </ng-container>
    <ng-container specialisation>
      <div class="cp-sub-header">Cardiologist</div>
    </ng-container>
    <ng-container details>
      <div>
        <mat-icon svgIcon="cp-calender"></mat-icon>
        <span class="cp-sub-header">2/1/2023</span>
      </div>
      <div>
        <img src="../../../assets/icons/access-time.svg" alt="" />
        <span class="cp-sub-header">16:30</span>
      </div>
    </ng-container>
  </app-appointment> -->

<!-- <app-radio-mat-buttons-array
    [selectionList]="countries"
    [formControl]="highRiskHospitals"
  ></app-radio-mat-buttons-array> -->
<!-- </div> -->

<div class="m-3">
  <h4>Angular Material Multi Select with Chips Example</h4>
  <app-multiselect-chiplist
    [selectionList]="colorsList"
    [formControl]="colorsControl"
    ><span class="label">Doctor:</span>
  </app-multiselect-chiplist>
</div>

<!-- <mat-form-field>
  <mat-label>Toppings</mat-label>
  <mat-select [formControl]="toppingsControl" multiple>

    <mat-select-trigger>
      <mat-chip-list>
        <mat-chip *ngFor="let topping of toppingsControl.value"
          [removable]="true" (removed)="onToppingRemoved(topping)">
          {{ topping }}
          <mat-icon matChipRemove>cancel</mat-icon>
        </mat-chip>
      </mat-chip-list>
    </mat-select-trigger>

    <mat-option *ngFor="let topping of toppingList" [value]="topping">{{topping}}</mat-option>

  </mat-select>
</mat-form-field> -->

<!-- <mat-form-field>
  <mat-label>Choose Colors</mat-label>
  <mat-select [formControl]="colorsControl" multiple>
    <mat-option *ngFor="let color of colorsList" [value]="color"
      >{{color}}</mat-option
    >
  </mat-select>
</mat-form-field>
<div>
  <mat-chip-list>
    <mat-chip
      *ngFor="let color of colorsControl.value"
      [removable]="true"
      (removed)="onColorRemoved(color)"
      (keydown.enter)="onColorRemoved(color)"
    >
      {{ color }}
      <mat-icon matChipRemove>cancel</mat-icon>
    </mat-chip>
  </mat-chip-list>
</div> -->

<app-patient-card> </app-patient-card>

<cp-cam-photo></cp-cam-photo>

<h6>Checkbox Tree Example</h6>

<ng-container [formGroup]="treeNodeForm">
  <app-iris-tree-checkbox-list
    formControlName="node"
    [nodes]="treeNodes"
  ></app-iris-tree-checkbox-list>
</ng-container>

<pre>{{ treeNodeForm.value | json }}</pre>

<h6>Status chip Example</h6>

<cp-status-chip [statusConfig]="statusConfigPayload"></cp-status-chip>

<cp-select-and-input-others
  [selectLabelName]="'Reason'"
  [width]="5"
  [gap]="0"
  [dropdownList]="selectaAndInputOothersList"
  [otherInputPosition]="'right'"
  [formControl]="selectAndInputFormControl"
></cp-select-and-input-others>
