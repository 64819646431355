import {
  Component,
  EventEmitter,
  Input,
  Output,
  ViewEncapsulation,
  forwardRef,
} from "@angular/core";
import { TreeNode } from "../model/node.model";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

@Component({
  selector: "app-iris-tree-checkbox-list",
  templateUrl: "./iris-tree-checkbox-list.component.html",
  styleUrls: ["./iris-tree-checkbox-list.component.scss"],
  encapsulation: ViewEncapsulation.None,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => IrisTreeCheckboxListComponent),
      multi: true,
    },
  ],
})
export class IrisTreeCheckboxListComponent implements ControlValueAccessor {
  @Input() nodes: TreeNode[];

  private _parentNode: TreeNode[];

  @Output() updateParentNode: EventEmitter<TreeNode> =
    new EventEmitter<TreeNode>();

  showChildren: boolean = true;

  toggleChildren() {
    this.showChildren = !this.showChildren;
  }

  onNodeChange(node: TreeNode) {
    this.updateChildren(node);
    this.updateParentNode.emit(node);
    this._parentNode = [node];
    if (this.onChanged) {
      this.onChanged(this._parentNode);
    }
  }

  updateChildren(node: TreeNode) {
    if (!node.children) return;
    node.children.forEach((child) => {
      child.checked = node.checked;
      this.updateChildren(child);
    });
  }

  /**
   *
   * @param childNode
   * @param parentNode
   * @description -> triggers when a child node has been changed
   *              -> update parentNode's property if all the child are checked
   *              -> trigger recursively for super parent node ( consider current parent Node as child and trigger for it's parent node)
   */
  handleChildNodeChange(parentNode: TreeNode) {
    const isAllChildChecked = parentNode?.children?.every((chN) => chN.checked);
    parentNode.checked = isAllChildChecked || false;
    this.updateParentNode.emit(parentNode);
    this._parentNode = [parentNode];
    if (this.onChanged) {
      this.onChanged(this._parentNode);
    }
  }

  isParentNode(node: TreeNode): boolean {
    return !!node.children.length;
  }

  private onChanged: (value: any) => void;
  private onTouched: () => void;

  writeValue(value: TreeNode[]): void {
    this._parentNode = value;
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
}
