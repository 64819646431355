import { Component, Input } from "@angular/core";
import { DatePipe, TitleCasePipe } from "@angular/common";

import * as moment from "moment";
import { Patient } from "src/app/models/patient";

declare const require: any;
const jsPDF = require("jspdf");
require("jspdf-autotable");
import { jsPDFDocument } from "jspdf-autotable";
import { UtilService } from "src/app/services/util.service";
import { displayAge } from "src/app/support-functions/calculateAge";
import { IcuDaysPipe } from "src/app/pipes/icu-days.pipe";

@Component({
  selector: "app-io-print",
  templateUrl: "./io-print.component.html",
  styleUrls: ["./io-print.component.scss"],
})
export class IoPrintComponent {
  imgData = null;
  titlePipe = new TitleCasePipe();
  datepipe = new DatePipe("en-US");
  currentDate = this.datepipe.transform(new Date(), "dd-MM-yyyy");

  _currentPatient: Patient;
  @Input() set currentPatient(value: Patient) {
    this._currentPatient = value;
    if (value?.hospitalLogo) {
      this.utils.getDataUri(value?.hospitalLogo, (info) => {
        this.imgData = info;
      });
    }
  }
  get currentPatient(): Patient {
    return this._currentPatient;
  }

  @Input() toDate?: string;
  @Input() fromDate?: string;
  @Input() disabled?: boolean;
  @Input() dateRangeValue?: string;
  @Input() report?: {
    avgUrine?: number;
    netReport?: number;
    intakeReport?: number;
    outputReport?: number;
  };

  constructor(private icuDaysPipe: IcuDaysPipe, private utils: UtilService) {}

  setPdfFileNameAndHeader(doc: jsPDFDocument): jsPDFDocument {
    // PDF file name
    doc.setProperties({
      title: `${this.currentPatient.name} ${this.currentPatient.lastName}_${this.currentPatient.MRN}_${this.currentDate}`,
      author: "Cloudphysician",
      creator: "Cloudphysician",
    });

    // File header
    doc.autoTable({
      body: [
        [
          {
            content: "I/O Report",
            styles: {
              halign: "center",
              fontSize: 14,
              fontStyle: "bold",
              textColor: "#2B2A3A",
              fillColor: [255, 255, 255],
            },
          },
        ],
      ],
    });

    return doc;
  }

  setPatientInfo(doc: jsPDFDocument, topY: number): jsPDFDocument {
    // Patient Info Table
    function getRoundedValue(value) {
      return Math.round(value * 100) / 100;
    }

    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [["Patient information"]],
      styles: { fontSize: 10 },
    });

    const noOfIcuDays = this.currentPatient.ICUAdmitDate
      ? this.icuDaysPipe.transform(
          this.currentPatient.ICUAdmitDate,
          !this.currentPatient?.isCurrentlyAdmitted &&
            this.currentPatient?.ICUDischargeDate
        )
      : null;
    doc.autoTable({
      styles: { fontSize: 10 },
      startY: topY + 8,
      columnStyles: {
        0: { cellWidth: 33 },
        1: { cellWidth: 58 },
        2: { cellWidth: 33 },
        3: { cellWidth: 58 },
      },
      body: [
        [
          "Name: ",
          this.currentPatient["lastName"]
            ? `${this.currentPatient["name"]} ${this.currentPatient["lastName"]}`
            : `${this.currentPatient["name"]}`,
          "MRN: ",
          `${this.currentPatient["MRN"] || "-"}`,
        ],
        [
          "Age / Gender:",
          `${this.getPatientAge()} / ${this.currentPatient["sex"] || "-"}`,
          "Unit / Bed no: ",
          `${this.currentPatient["unitName"] || "-"} / ${
            this.currentPatient["bedNo"] || "-"
          }`,
        ],
        [
          "Height / Weight: ",
          `${
            this.currentPatient["height"]
              ? getRoundedValue(this.currentPatient.height) + "cm"
              : "-"
          } / ${
            this.currentPatient.weight
              ? getRoundedValue(this.currentPatient.weight) + "kg"
              : "-"
          }`,
          "Admit date: ",
          `${
            this.currentPatient["ICUAdmitDate"]
              ? this.datepipe.transform(
                  new Date(this.currentPatient["ICUAdmitDate"]),
                  "MMM d y, HH:mm"
                )
              : "-"
          }`,
        ],
        [
          "Blood group: ",
          `${this.currentPatient["bloodGroup"] || "-"}`,
          "BMI: ",
          `${
            this.currentPatient?.BMI > 0
              ? this.currentPatient?.BMI?.toFixed(2)
              : "-"
          }`,
        ],
        [
          "Allergies: ",
          `${
            (this.currentPatient["allergies"] &&
              this.currentPatient["allergies"].join(", ")) ||
            "-"
          }`,
          "No of days in ICU: ",
          `${noOfIcuDays || "-"}`,
        ],
      ],
    });

    if (this.imgData) {
      doc.addImage(this.imgData, "JPEG", 15, 5, 20, 20);
    }

    return doc;
  }

  setReportHeader(doc: jsPDFDocument, topY: number): jsPDFDocument {
    doc.autoTable({
      startY: topY,
      headStyles: {
        fillColor: "#E2E8F4",
        textColor: [0, 0, 0],
      },
      head: [["Start/end time"]],
      styles: { fontSize: 10 },
    });

    const timeFormate = "DD-MM-yyyy HH:mm";

    function getDurationForDateRange(toDate, fromDate) {
      const hour = moment(new Date(toDate))
        .diff(moment(new Date(fromDate)), "hours", true)
        .toFixed(2);
      return `${hour}hrs`;
    }

    doc.autoTable({
      styles: { fontSize: 10 },
      startY: topY + 8,
      columnStyles:
        this.dateRangeValue == "date_range"
          ? {
              0: { cellWidth: 33 },
              1: { cellWidth: 58 },
              2: { cellWidth: 33 },
              3: { cellWidth: 58 },
            }
          : {
              0: { cellWidth: 33 },
            },
      body:
        this.dateRangeValue == "date_range"
          ? [
              [
                "From: ",
                `${moment(new Date(this.fromDate)).format(timeFormate)}hrs`,
                "To: ",
                `${moment(new Date(this.toDate)).format(timeFormate)}hrs`,
              ],
              [
                "Duration: ",
                getDurationForDateRange(this.toDate, this.fromDate),
              ],
            ]
          : [
              [
                "Since: ",
                `${moment()
                  .subtract(this.dateRangeValue, "h")
                  .format(timeFormate)}hrs`,
              ],
              [
                "Duration: ",
                `Past ${this.dateRangeValue.padStart(2, "0")}:00hrs`,
              ],
            ],
    });

    return doc;
  }

  setReportDetails(doc: jsPDFDocument, topY: number): jsPDFDocument {
    doc.autoTable({
      startY: topY,
      tableWidth: 61,
      rowStyles: {
        0: { fillColor: [242, 245, 241] },
        1: { fillColor: [255, 255, 255] },
      },
      columnStyles: {
        0: {
          valign: "middle",
          halign: "center",
        },
      },
      styles: {
        minCellHeight: 4,
        fontSize: 10,
        lineWidth: 0.1,
      },
      body: [
        ["Average urine"],
        [
          this.report?.avgUrine === 0
            ? "0ml/hr"
            : `${this.report?.avgUrine}ml/hr`,
        ],
      ],
    });

    topY = doc.lastAutoTable.finalY + 6;

    doc.autoTable({
      startY: topY,
      columnStyles: {
        0: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
        1: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
        2: {
          minCellWidth: 20,
          maxCellWidth: 20,
          valign: "middle",
          halign: "center",
        },
      },
      rowStyles: {
        0: { fillColor: [242, 245, 241] },
        1: { fillColor: [255, 255, 255] },
      },
      styles: {
        minCellHeight: 4,
        fontSize: 10,
        lineWidth: 0.1,
      },
      body: [
        ["Total intake", "Total output", "Net"],
        [
          this.report?.intakeReport === 0
            ? "0ml"
            : `+${this.report?.intakeReport}ml`,
          this.report?.outputReport === 0
            ? "0ml"
            : `-${this.report?.outputReport}ml`,
          this.report?.netReport === 0 ? "0ml" : `${this.report?.netReport}ml`,
        ],
      ],
    });

    return doc;
  }

  setPdfFileFooter(doc: jsPDFDocument): jsPDFDocument {
    // set footer
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 1; i <= pageCount; i++) {
      doc.setPage(i);
      doc.line(8, 323 - 34, 202, 323 - 34);

      doc.setFontSize(6);
      doc.text(
        `Page ${String(i)} of ${String(pageCount)}, Report created on RADAR`,
        10,
        323 - 31
      );
      doc.text(
        `Printed on: ${this.datepipe.transform(new Date(), "dd-MM-yyyy H:mm")}`,
        210 - 37.5,
        323 - 31
      );
    }

    return doc;
  }

  launchPdfViewer(doc: jsPDFDocument): void {
    const pdfWindow = window.open("", "", "width=700,height=900");
    pdfWindow.document.write(
      "<iframe style='width: 100%; height: 70rem' src='" +
        doc.output("datauristring") +
        "' frameborder='0'></iframe>"
    );
    pdfWindow.document.close();
  }

  print() {
    let doc: jsPDFDocument = new jsPDF();
    doc = this.setPdfFileNameAndHeader(doc);

    let topY = doc.lastAutoTable.finalY + 5;
    doc = this.setPatientInfo(doc, topY);

    topY = doc.lastAutoTable.finalY + 6;
    doc = this.setReportHeader(doc, topY);

    topY = doc.lastAutoTable.finalY + 6;
    doc = this.setReportDetails(doc, topY);

    doc = this.setPdfFileFooter(doc);
    this.launchPdfViewer(doc);
  }

  getPatientAge() {
    const ageObj = this._currentPatient?.age;

    return displayAge(
      ageObj,
      false,
      this._currentPatient.patientType,
      this._currentPatient.dob
    );
  }
}
