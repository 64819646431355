import { ConnectedPosition } from "@angular/cdk/overlay";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  Output,
  SimpleChanges,
} from "@angular/core";
import { select, Store } from "@ngrx/store";
import { Patient } from "src/app/models/patient";

import * as fromUserIndex from "src/app/store/reducers/user/index";

@Component({
  selector: "cp-handover-notes",
  templateUrl: "./handover-notes.component.html",
  styleUrls: ["./handover-notes.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class HandoverNotesComponent implements OnChanges {
  constructor(private _cdr: ChangeDetectorRef, private _store: Store<any>) {}
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.showPopup && this.showPopup) {
      this.closeHandover();
    }
  }
  public handOverOpen: boolean = false;
  @Input("currPatient") currPatient: Patient;

  @Input("showPopup") showPopup: boolean = false;

  @Output() handoverOpen: EventEmitter<any> = new EventEmitter<any>();

  public isPopupZindex: boolean = false;

  public isCommandCenterUser$ = this._store.pipe(
    select(fromUserIndex.isCommandCenterUser)
  );

  public positions: ConnectedPosition[] = [
    {
      originX: "start",
      originY: "bottom",
      overlayX: "end",
      overlayY: "bottom",
      offsetX: -22,
    },
  ];

  public changeZindex() {
    this.isPopupZindex = true;
    this._cdr.detectChanges();
  }

  public openHandover() {
    this.isPopupZindex = false;
    this.handOverOpen = true;
    this.handoverOpen.emit();
  }

  public closeHandover() {
    this.handOverOpen = false;
    this._cdr.detectChanges();
  }
}
