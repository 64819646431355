import { Subscription } from 'rxjs';
import { Component, EventEmitter, Input, OnDestroy, OnInit, Output } from '@angular/core';

import { ClockService } from '../../../shared/clock/clock.service';
import { RAlertMessage } from '../../../models/RAlert/message.model';

@Component({
  selector: 'app-ralert-message-list',
  templateUrl: './ralert-message-list.component.html',
  styleUrls: [ './ralert-message-list.component.scss' ]
})
export class RalertMessageListComponent implements OnInit, OnDestroy {
  @Input() messages: RAlertMessage[];
  @Output() acknowledge: EventEmitter<RAlertMessage> = new EventEmitter<RAlertMessage>();

  public alertTime = 300000; // in ms
  private clock$: Subscription;
  public time: any;

  constructor(public clockService: ClockService) {
    this.clock$ = this.clockService.getClock().subscribe(date => {
      this.time = date;
    });
  }

  ngOnInit() {
  }

  ngOnDestroy() {
    this.clock$.unsubscribe();
  }

  onAcknowledge(id: RAlertMessage) {
    this.acknowledge.emit(id);
  }
}
