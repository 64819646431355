import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { MatSelectChange } from "@angular/material/select";

@Component({
  selector: "app-input-side-chip",
  templateUrl: "./input-side-chip.component.html",
  styleUrls: ["./input-side-chip.component.scss"],
})
export class InputSideChipComponent implements OnInit {
  @Input() labelText: string = "";
  @Input() config: {
    width: string;
    height: string;
    skipRightBorder: boolean;
    isMultiSelect: boolean;
    selectOptions: any;
  };

  @Output() labelChange: EventEmitter<string> = new EventEmitter<string>();
  constructor() {}

  ngOnInit(): void {}

  onChange(event: MatSelectChange) {
    this.labelChange.emit(event.value);
  }
}
