import { combineLatest, merge } from "rxjs";
import { select, Store } from "@ngrx/store";
import { omit, isEqual } from "lodash-es";
import { debounceTime, take } from "rxjs/operators";
import { UntypedFormArray, UntypedFormGroup, Validators } from "@angular/forms";
import {
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnChanges,
  OnDestroy,
  OnInit,
  Output,
  SimpleChanges,
} from "@angular/core";

import * as moment from "moment";
import { MedRate } from "../../../../models/med/MedRate.model";
import { Orderable } from "../../../../models/Orderable.model";
import { OrderService } from "../../../../services/order.service";
import { MedService } from "../../../../services/order/med.service";
import { VentForm } from "../../../../models/vital/vent/Vent.model";
import * as fromHospitals from "../../../../store/reducers/hospitals";
import { LabOrderBE, LabOrderFE } from "../../../../models/Lab.model";
import { MedOrderBE, MedOrderFE } from "../../../../models/Med.model";
import { DietOrderBE, DietOrderFE } from "../../../../models/Diet.model";
import { OrderFormService } from "../../../../services/order-form.service";
import { BloodOrderBE, BloodOrderFE } from "../../../../models/Blood.model";
import { VentFormService } from "../../../../vital/services/vent-form.service";
import { SearchOrder } from "../../../../orders/components/search/search.component";
import {
  ProcedureOrderBE,
  ProcedureOrderFE,
} from "../../../../models/Procedure.model";
import * as fromPatientHeader from "../../../../store/reducers/patient-chart/patient-header";
import {
  CommunicationOrderBE,
  CommunicationOrderFE,
} from "../../../../models/Communication.model";
import {
  bloodConfig,
  commConfig,
  dietConfig,
  labConfig,
  medConfig,
  procedureConfig,
  ventConfig,
} from "../../form.config";

@Component({
  selector: "app-order-form",
  templateUrl: "./order-form.component.html",
  styleUrls: ["./order-form.component.scss"],
})
export class OrderFormComponent implements OnInit, OnChanges, OnDestroy {
  @Input() orderable: Orderable | null | any;
  @Input() orderableType: string;
  @Input() user: any;
  @Input() formType: string;
  @Input() value: any;
  @Input() orderInHospital?: boolean;
  @Input() currentPatient?: any;
  @Input() loading?: boolean;
  @Output()
  selectOrderable: EventEmitter<SearchOrder> = new EventEmitter<SearchOrder>();
  @Output() submitted: EventEmitter<
    | BloodOrderBE
    | CommunicationOrderBE
    | DietOrderBE
    | LabOrderBE
    | MedOrderBE
    | ProcedureOrderBE
  > = new EventEmitter<
    | BloodOrderBE
    | CommunicationOrderBE
    | DietOrderBE
    | LabOrderBE
    | MedOrderBE
    | ProcedureOrderBE
  >();
  @Output() cancel: EventEmitter<string> = new EventEmitter<string>();
  @Output() formChange: EventEmitter<boolean> = new EventEmitter<boolean>();

  public config: any;
  public form: UntypedFormGroup;
  public medSchedule = [];

  public quantityValue$;
  public unitValue$;
  public concentrationValue$;
  public bodyWeightValue$;
  public maxDose$;
  public noOfDays$;
  public concentrationRate: MedRate;
  public originalFormValue;
  public formValueSubscription$;
  public showBedsideForm = true;
  public startTime;

  public patientHospitalUnitName$ = this.store.pipe(
    select(fromPatientHeader.getPatientHospitalAndUnitName)
  );
  public hospitals$ = this.store.pipe(select(fromHospitals.getHospitals));

  public canPlaceBedsideOrder$ = this.store.pipe(
    select(fromPatientHeader.canPlaceBedsideOrder)
  );

  formConfig = new Map([
    ["blood", { config: bloodConfig }],
    ["comm", { config: commConfig }],
    ["diet", { config: dietConfig }],
    ["lab", { config: labConfig }],
    ["med", { config: medConfig }],
    ["procedure", { config: procedureConfig }],
    ["vents", { config: ventConfig }],
  ]);

  constructor(
    public orderFormService: OrderFormService,
    private orderService: OrderService,
    private medService: MedService,
    private ventFormService: VentFormService,
    private store: Store<any>,
    private cdRef: ChangeDetectorRef
  ) {}

  ngOnInit() {
    this.unsubscribeFormValueSubscriptions();
    this.initForm();

    // same code present in protocol-form.component.ts, refactor to one function
    if (this.patientHospitalUnitName$ && this.hospitals$) {
      combineLatest(this.patientHospitalUnitName$, this.hospitals$)
        .pipe(take(1))
        .subscribe((data) => {
          const patientData = data[0];
          const hospitals = data[1];

          const hospital = hospitals.filter(
            (hospital) => hospital.name === patientData.hospitalName
          ) as any;

          if (
            hospital.length > 0 &&
            hospital[0].units &&
            hospital[0].units.length > 0
          ) {
            const unit = hospital[0].units.filter(
              (unit) => unit.name === patientData.unitName
            );
            this.showBedsideForm =
              unit[0] && unit[0].cpManaged ? !unit[0].cpManaged : true;
          }
        });
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.orderable && !changes.orderable.firstChange) {
      this.unsubscribeFormValueSubscriptions();
      this.initForm();
    }

    if (changes.orderableType && !changes.orderableType.firstChange) {
      this.unsubscribeFormValueSubscriptions();
      this.initForm();
    }

    if (changes.value) {
      this.unsubscribeFormValueSubscriptions();
      this.initForm();
    }
  }

  ngOnDestroy(): void {
    if (
      this.quantityValue$ &&
      this.unitValue$ &&
      this.concentrationValue$ &&
      this.bodyWeightValue$ &&
      this.maxDose$
    ) {
      this.quantityValue$.unsubscribe();
      this.unitValue$.unsubscribe();
      this.concentrationValue$.unsubscribe();
      this.bodyWeightValue$.unsubscribe();
      this.maxDose$.unsubscribe();
    }

    if (this.noOfDays$) {
      this.noOfDays$.unsubscribe();
    }

    this.unsubscribeFormValueSubscriptions();
  }

  ngAfterViewInit() {
    this.cdRef.detectChanges();
  }

  /**
   * Initializes the form
   */
  initForm(): void {
    this.loadConfig(this.orderableType);

    /*
     * Changing the preset shouldn't change homeMed and bedsideOrder checkbox.
     * To make sure that it remains same, we copy the value before changing, and assign it after the form value changes.
     * */
    let bedsideOrder = null;
    let homeMed = null;

    bedsideOrder = this.value?.bedsideOrder;
    homeMed = this.value?.pta;

    this.form = this.getOrderableForm(this.orderableType, this.value, "new");
    this.value = { ...this.value };
    this.startTime = this.value.startTime;

    if (bedsideOrder) {
      this.form.patchValue({ bedsideOrder });
    }

    if (homeMed) {
      this.form.patchValue({ pta: homeMed });
    }

    if (this.formType === "edit" && this.value?.startTime) {
      this.form.get("startTime").disable();
      this.form.get("startNow").disable();
    }

    // On reorder, populate the enddate
    if (
      this.orderInHospital &&
      !this.value.endTime &&
      this.value.noOfDays &&
      this.value.numberOfDoses &&
      this.form.get("startTime").value
    ) {
      this.generateMedSchedule(
        this.form.get("startTime").value,
        this.value.frequency,
        this.value.numberOfDoses
      );
    }

    if (this.orderableType === "vents" && this.value) {
      // this is there to avoid expression has changed after it was checked error.
      this.cdRef.detectChanges();
    }

    if (this.orderableType === "med" && !this.value) {
      const combNameArray = this.value.name.split("/");
      const combFormArray = this.form.get("combination") as UntypedFormArray;
      combNameArray.shift();

      if (combNameArray.length > 0) {
        for (const combName of combNameArray) {
          combFormArray.push(
            this.orderFormService.newCombinationFormGroup(combName)
          );
        }
      }
    }

    if (
      this.orderableType === "diet" &&
      this.value &&
      this.value.frequency &&
      this.value.frequency.fType === "continuous"
    ) {
      this.form.get("rate.value").setValidators([Validators.required]);
      this.form.get("rate.value").updateValueAndValidity();
    }

    if (this.orderableType === "med") {
      this.quantityValue$ = this.form.get("quantity").valueChanges;
      this.unitValue$ = this.form.get("unit").valueChanges;
      this.concentrationValue$ = this.form.get("concentration").valueChanges;
      this.bodyWeightValue$ = this.form.get("bodyWeight").valueChanges;
      this.maxDose$ = this.form.get("maxDose").valueChanges;
      this.noOfDays$ = this.form.get("noOfDays").valueChanges;

      merge(
        this.quantityValue$,
        this.unitValue$,
        this.concentrationValue$,
        this.bodyWeightValue$,
        this.maxDose$
      )
        .pipe(debounceTime(200))
        .subscribe((_) => {
          this.concentrationRate = this.medService.getRate(this.form.value);
        });

      this.initializeMedShedule();

      this.form
        .get("numberOfDoses")
        .valueChanges.pipe(debounceTime(200))
        .subscribe((dose) => {
          const frequency = this.form.value["frequency"];
          const startTime = this.form.get("startTime").value;
          this.generateMedSchedule(startTime, frequency, dose);
        });

      this.form
        .get("frequency")
        .valueChanges.pipe(debounceTime(200))
        .subscribe((frequency) => {
          const dose = this.form.value["numberOfDoses"];
          const startTime = this.form.get("startTime").value;
          const days = this.form.value["noOfDays"];
          this.generateMedSchedule(startTime, frequency, dose);
          this.concentrationRate = this.medService.getRate(this.form.value);

          if (frequency && frequency.fType === "every" && days) {
            const newDose = this.medService.calculateNumberOfDoses(
              frequency,
              this.form.value["noOfDays"]
            );
            this.form.get("numberOfDoses").setValue(newDose);
          } else if (frequency && frequency.fType === "every" && dose) {
            const newDays = this.medService.calculateNumberOfDays(
              frequency,
              dose
            );
            this.form.get("noOfDays").setValue(newDays);
          }

          if (
            frequency &&
            frequency.fType === "continuous" &&
            startTime &&
            startTime.date
          ) {
            this.form.get("numberOfDoses").setValue(null);

            const endTime = this.orderFormService.calculateEndTime(
              frequency,
              startTime,
              null,
              days
            );

            if (endTime) {
              this.form.get("endTime").patchValue({
                date: new Date(endTime),
                time: new Date(endTime).toTimeString(),
              });
            }
          }

          if (frequency && frequency.fType !== "continuous") {
            this.resetConcentration();
          }
        });

      this.form
        .get("startTime")
        .valueChanges.pipe(debounceTime(200))
        .subscribe((startTime) => {
          const frequency = this.form.value["frequency"];
          const dose = this.form.value["numberOfDoses"];
          const days = this.form.value["noOfDays"];
          this.generateMedSchedule(startTime, frequency, dose);

          if (
            frequency &&
            frequency.fType === "continuous" &&
            startTime &&
            startTime.date
          ) {
            const endTime = this.orderFormService.calculateEndTime(
              frequency,
              startTime,
              null,
              days
            );

            if (endTime) {
              this.form.get("endTime").patchValue({
                date: new Date(endTime),
                time: new Date(endTime).toTimeString(),
              });
            }
          }
        });

      this.form
        .get("route")
        .valueChanges.pipe(debounceTime(200))
        .subscribe((_) => {
          this.form.get("instructions").reset();
          this.form.get("additionalInformation").reset();
        });

      this.concentrationRate = this.medService.getRate(this.form.value);
    }

    /*
      to check if a form value changes, then we can reset the preset
    */
    this.originalFormValue = omit(this.form.value, [
      "startTime",
      "endTime",
      "startNow",
      "pta",
    ]);

    if (this.orderableType === "vents" && this.value) {
      this.originalFormValue = omit(this.value, [
        "default",
        "protocol",
        "startTime",
        "startNow",
        "displayAsShortcut",
        "protocols",
        "presetName",
        "_id",
      ]);
    }

    this.formValueSubscription$ = this.form.valueChanges;

    this.formValueSubscription$.subscribe((value) => {
      let omissionValues = [];

      if (this.orderableType !== "vents") {
        omissionValues = ["startTime", "endTime", "startNow", "pta"];
      } else {
        omissionValues = [
          "startTime",
          "endTime",
          "startNow",
          "protocol",
          "pta",
        ];
      }

      const result = isEqual(
        this.originalFormValue,
        omit(value, omissionValues)
      );

      if (!result && this.orderableType !== "vents") {
        this.value = null;
      }

      this.formChange.emit(!result);
      this.cdRef.detectChanges(); // to detect changes in form.invalid value binded in DOM
    });
  }

  /**
   * Return the form based on preset type.
   *
   * @param {string} orderableType
   * @param [value=null] - Value of the searched order (med, lab, etc.)
   * @param {string} formType - edit or new
   * @returns {FormGroup}
   */
  getOrderableForm(
    orderableType: string,
    value = null,
    formType: string
  ): UntypedFormGroup {
    if (orderableType === "blood") {
      return this.orderFormService.newBloodForm(value, this.config);
    } else if (orderableType === "comm") {
      return this.orderFormService.newCommForm(value, this.config);
    } else if (orderableType === "diet") {
      return this.orderFormService.newDietForm(value, this.config);
    } else if (orderableType === "lab") {
      return this.orderFormService.newLabForm(value, this.config);
    } else if (orderableType === "med") {
      return this.orderFormService.newMedForm(value, this.config);
    } else if (orderableType === "procedure") {
      return this.orderFormService.newProcedureForm(value, this.config);
    } else if (orderableType === "vents") {
      return this.ventFormService.initForm(this.config);
    }
  }

  /**
   * Loads the config needed for form.
   * Loads it from formData map basis on orderableType
   *
   * @param {string} type
   */
  loadConfig(type: string): void {
    this.config = this.formConfig.get(this.orderableType).config;
  }

  /**
   * Resets the form.
   */
  onReset(): void {
    if (this.orderableType === "vents") {
      this.form.reset();
    } else {
      const field = this.orderFormService.getFieldRelatedToOrderableName(
        this.orderableType
      );
      const value = this.form.get(field).value;
      this.form.reset({
        ...this.orderFormService.getFieldObjectRelatedToOrderableName(
          this.orderableType,
          value
        ),
        protocol: this.form.value.protocol,
      });

      (this.form.get("skipSchedule") as UntypedFormArray).clear();
    }
  }

  /**
   * Cancels the order.
   *
   * @param {string} type
   * @fires :cancel
   */
  onCancel(type: string): void {
    this.cancel.emit(type);
  }

  /**
   * Updates the field and fire submitted event when submit button is clicked.
   *
   * @fires :submitted
   */
  onSubmit(): void {
    let formValue = this.form.getRawValue(),
      nameField = this.orderFormService.getFieldRelatedToOrderableName(
        this.orderableType
      ),
      name = this.orderable ? this.orderable.name : this.form.value[nameField];

    let finalValue;

    if (this.orderableType !== "vents") {
      formValue = {
        ...this.form.getRawValue(),
        ...this.orderFormService.getFieldObjectRelatedToOrderableName(
          this.orderableType,
          name
        ),
      };
    }

    if (this.orderableType === "vents") {
      const allowedFields =
        this.ventFormService.getAllowedFieldsInVent(formValue);
      formValue = this.ventFormService.filterAllowedValues(
        formValue,
        allowedFields
      ) as any;
      formValue = this.ventFormService.transformVentFormToVentOrder(formValue);
    }

    const updatedOrderValue = this.attachValues(formValue);
    finalValue = this.detachValues(updatedOrderValue);

    if (this.orderable && this.orderable.snomed && this.orderable.snomed.code) {
      finalValue = { ...finalValue, snomedCode: this.orderable.snomed.code };
    }

    if (this.value && this.value.replaceOrderId) {
      finalValue.replaceOrderId = this.value.replaceOrderId;
    }

    if (this.formType == "new" && finalValue.startNow) {
      const secounds = moment()
        .startOf("m")
        .tz("Asia/Kolkata")
        .diff(
          moment(finalValue.startTime).startOf("m").tz("Asia/Kolkata"),
          "s",
          true
        );
      const now = moment().tz("Asia/Kolkata");

      if (finalValue?.skipSchedule?.length) {
        finalValue.skipSchedule[0].timeStamp = now.toDate();
      }

      finalValue.startTime = now.toDate();

      if (finalValue.endTime) {
        finalValue.endTime = moment(finalValue.endTime)
          .tz("Asia/Kolkata")
          .add(secounds, "s")
          .toDate();
      }
    }

    this.submitted.emit(finalValue);
  }

  private attachValues(
    value:
      | BloodOrderFE
      | CommunicationOrderFE
      | DietOrderFE
      | LabOrderFE
      | MedOrderFE
      | ProcedureOrderFE
      | VentForm
  ) {
    let bedsideOrder = value.bedsideOrder;

    let startTime = this.startTime;
    let endTime = null;
    if (value.startTime?.date) {
      startTime = this.orderFormService.transformTime(
        value.startTime.date,
        value.startTime.hour,
        value.startTime.minute
      );
    }
    if (value.endTime?.date) {
      endTime = this.orderFormService.transformTime(
        value.endTime.date,
        value.endTime.hour,
        value.endTime.minute
      );
    }

    const defaultValues = {
      type: this.orderService.convertNewTypeToOldType(this.orderableType),
      createdBy: this.orderFormService.getOrderSigned(
        this.user.name,
        this.user.title
      ),
      state: "red",
      category: this.orderFormService.getOrderCategory(this.user.role, value),
      ...this.orderFormService.getScheduleChekboxes(this.form),
    };

    return { ...value, startTime, endTime, ...defaultValues, bedsideOrder };
  }

  private detachValues(value) {
    const conditionalKeys = ["endTime"];
    const removalKeys = ["everyFreRadio"];

    let newValue = { ...value };
    Object.keys(newValue).forEach((key) => {
      if (conditionalKeys.includes(key) && newValue[key] == null) {
        delete newValue[key];
      }

      if (removalKeys.includes(key)) {
        delete newValue[key];
      }
    });

    return newValue;
  }

  private initializeMedShedule(): void {
    const frequency = this.form.get("frequency").value;
    const dose = this.form.get("numberOfDoses").value;
    const startTime = this.form.get("startTime").value;
    this.generateMedSchedule(startTime, frequency, dose);
  }

  private generateMedSchedule(startTime, frequency, dose): void {
    let result = null;
    if (
      frequency?.fType === "every" &&
      this.orderFormService.validStartTime(startTime) &&
      this.orderFormService.validEveryFrequency(frequency)
    ) {
      result = this.orderFormService.generateMedSchedule(
        startTime,
        frequency,
        dose
      );
    } else if (
      frequency?.fType === "once" &&
      this.orderFormService.validStartTime(startTime)
    ) {
      result = this.orderFormService.generateMedSchedule(startTime, null, 1);
    }
    this.medSchedule = result;
  }

  /*
   * NAME: resetConcentration
   * PURPOSE: Resets concentration value and bodyWeight
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: void
   * USED BY: initForm()
   * CREATED DATE: 4 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  private resetConcentration(): void {
    this.form.get("concentration").reset();
    // this.form.get("bodyWeight").reset();
  }

  /*
   * NAME: unsubscribeFormValueSubscriptions
   * PURPOSE: Unsubscribes form value subscription
   * DESCRIPTION:
   * PARAMS: void
   * RETURNS: void
   * USED BY:
   * CREATED DATE: 7 January 2020
   * AUTHOR: Gunjit Agrawal
   */
  private unsubscribeFormValueSubscriptions(): void {
    if (this.formValueSubscription$) {
      this.formValueSubscription$.unsubscribe();
    }
  }
}
