import { Injectable } from "@angular/core";
import { Actions, ofType, createEffect } from "@ngrx/effects";
import { map, mergeMap, catchError, switchMap } from "rxjs/operators";
import { of, EMPTY } from "rxjs";

import * as patientListActions from "../actions/patients/patient-list.action";
import * as sbarListActions from "../actions/patients/sbar-data.action";
import * as patientErrorActions from "../actions/patients/patient-error.action";
import * as hospitalActions from "../actions/hospitals.actions";
import * as unitActions from "../actions/unit.actions";
import { HospitalService } from "src/app/services/hospital.service";
import { ProtocolResolverActions } from "../../protocol/actions";

@Injectable()
export class HospitalEffects {
  constructor(
    private actions$: Actions,
    private hospitalService: HospitalService
  ) {}

  getHospitalsAndPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hospitalActions.getHospitalsPatients),
      switchMap(({ queryParams }) =>
        this.hospitalService.getHospitalsAndPatients(queryParams).pipe(
          switchMap((data) => {
            return of(
              hospitalActions.setHospitals({
                hospitals: data.hospitals["hospitals"],
              }),
              patientListActions.setPatients({
                patients: data["patients"]["patients"],
              }),
              sbarListActions.setSbarList({
                sbarList: data["patients"]["sbarData"],
              })
            );
          }),
          catchError((e) => {
            return of(
              patientErrorActions.patError({
                status: e.status,
                message:
                  e.error && e.error.message ? e.error.message : e.message,
              })
            );
          })
        )
      )
    )
  );

  getNewHospitalsAndPatients$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hospitalActions.getNewHospitalsPatients),
      switchMap(({ queryParams }) =>
        this.hospitalService.getNewHospitalsAndPatients(queryParams).pipe(
          switchMap((data) => {
            return of(
              hospitalActions.setHospitals({ hospitals: data.hospitals }),
              unitActions.setUnits({
                units: data.units,
              }),
              sbarListActions.setSbarList({
                sbarList: data.sbarData,
              }),
              patientListActions.setPatients({
                patients: data.patients,
              })
            );
          }),
          catchError((e) => {
            return of(
              patientErrorActions.patError({
                status: e.status,
                message:
                  e.error && e.error.message ? e.error.message : e.message,
              })
            );
          })
        )
      )
    )
  );

  getHosptials$ = createEffect(() =>
    this.actions$.pipe(
      ofType(hospitalActions.getHospitals),
      switchMap(() =>
        this.hospitalService.getAllHospitals().pipe(
          map((hospitals) => {
            return hospitalActions.setHospitals({
              hospitals: hospitals.hospitals,
            });
          }),
          catchError(() => EMPTY)
        )
      )
    )
  );

  getPublicHospitals$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolResolverActions.getPublicHospitals),
      switchMap(() =>
        this.hospitalService.getPublic().pipe(
          map((hospitals) => hospitalActions.setPublicHospitals({ hospitals })),
          catchError(() => EMPTY)
        )
      )
    )
  );
}
