import { Component, Input } from "@angular/core";

import { PatientCPMRNEncounters } from "../../models/patient";
import { AiraApiService } from "../services/aira-api.service";
import { AiraNoteService } from "../services/aira-note.serivce";
import { MatDialog } from "@angular/material/dialog";
import { AiraNote } from "../model/AiraNote.model";
import { NotesAiraModalComponent } from "src/app/note-module/note-input/notes-aira-modal/notes-aira-modal.component";
import { disableAiraScope } from "../aira-scope";

// This will be rermoved once aira response is made uniform

const newAira = ["NICU Template", "Nursing Short Note"];

@Component({
  selector: "app-aira-btn",
  templateUrl: "./aira-btn.component.html",
  styleUrls: ["./aira-btn.component.scss"],
})
export class AiraBtnComponent {
  private _patient: PatientCPMRNEncounters;

  @Input()
  set patient(patient: PatientCPMRNEncounters) {
    this._patient = patient;
    if (patient) {
      this.disableBtn = !!disableAiraScope(patient.note.noteType)(
        patient.note.noteSubType
      )
        ? false
        : true;
    }
  }

  get patient() {
    return this._patient;
  }

  constructor(
    private _airaApiService: AiraApiService,
    private _modalService: MatDialog,
    private _airaNoteService: AiraNoteService
  ) {}

  public disableBtn = false;
  public enableLoader = false;

  /*
   * NAME: get
   * PURPOSE: fetches data from aira
   * DESCRIPTION:
   *   calls aira api service to fetch aira data
   *   incoming data is parsed and converted into three categories:
   *     - secondaryTextUpdated (plans in roundar)
   *     - diagnosisTextUpdated (diagnosis in roundar)
   *     - todoTextUpdated (to-dos in roundar)
   *
   *  a modal is opened to preview the data,
   *   on acceptance data is passed to input popup subject (to open note input popup)
   *     and data is passed to note subject (to fill the notes form)
   *
   * PARAMS: void
   * RETURNS: void
   * USED BY: template on click event
   * CREATED DATE: 05/06/20
   * AUTHOR: Gunjit Agrawal
   */
  get(): void {
    this.disableBtn = true;
    this.enableLoader = true;

    this._airaApiService
      .get(this.patient.cpmrn, this.patient.encounters, this.patient.note)
      .subscribe((data) => {
        this.disableBtn = false;
        this.enableLoader = false;

        let lambdaData = JSON.parse(data);
        if (lambdaData.statusCode !== 200) {
          console.log(lambdaData);
          return;
        }
        let finalData = JSON.parse(lambdaData.body);
        let newAiraCheck = newAira.includes(finalData.notesType.noteSubType);
        if (newAiraCheck) {
          this.newAiraNoteTemplate(finalData);
          return;
        }
        this.noteTemplate(finalData);
      });
  }

  newAiraNoteTemplate(finalData) {
    const airaNoteData: AiraNote = {
      summary: finalData.summary,
      diagnosis: finalData.diagnosis,
      plan: finalData.assessmentPlan,
      todo: finalData.todo,
      medication: finalData.medication,
      reports: finalData.reports,
      noteType: finalData.notesType.noteType,
      // note subtemplate for covid check
      noteSubTemplate: finalData.notesType.noteSubType,
    };
    this.openAiraPopup(airaNoteData);
  }

  noteTemplate(finalData) {
    //summary :The check condition will be removed in following commits
    //summary :The check condition will be removed in following commits
    let summaryTextUpdated;
    if (finalData.summary) {
      const noteType = finalData.notesType.noteType;
      const summaryHeading =
        noteType === "Discharge" ? "Discharge summary" : "Summary";
      summaryTextUpdated = `<p><strong><u>${summaryHeading}</u></strong>:&nbsp;</p>${finalData.summary}`;
    } else {
      summaryTextUpdated = null;
    }

    //assessment plan
    let secondaryTextUpdated = null;
    if (finalData.assessmentPlan.length) {
      secondaryTextUpdated =
        "<p><strong><u>Assessment and Plan</u></strong>:&nbsp;</p><ul>";
      finalData.assessmentPlan.forEach((data) => {
        secondaryTextUpdated =
          secondaryTextUpdated +
          "<li><strong>" +
          data.name +
          "</strong>:&nbsp;" +
          data.value +
          "</li>";
      });
    }

    //aira diagnosis
    let diagnosisTextUpdated = finalData.diagnosis
      ? "<p><strong><u>Diagnosis</u></strong>:&nbsp;" +
        finalData.diagnosis +
        "</p>"
      : null;

    //aira todo list
    let todoTextUpdated = null;
    if (finalData.todo.length) {
      todoTextUpdated = "<p><strong><u>To-Do List</u></strong>:&nbsp;</p><ul>";
      finalData.todo.forEach((data) => {
        todoTextUpdated = todoTextUpdated + "<li>" + data + "</li>";
      });
      todoTextUpdated = todoTextUpdated + "</ul>";
    }

    //aira medication
    let medication = null;
    if (finalData.medication.length) {
      medication = "<p><strong><u>Medication</u></strong>:&nbsp;</p><ol>";
      finalData.medication.forEach((element) => {
        medication = medication + "<li>" + element + "</li>";
      });
      medication = medication + "</ol>";
    }

    //aira reports
    let reports = "";
    finalData.reports.forEach((data) => {
      reports =
        reports +
        "<p><strong><u>" +
        data.name +
        "</u></strong>:&nbsp;" +
        data.value +
        "</p><p><br></p><p><br></p>";
    });

    const airaNoteData: AiraNote = {
      summary: summaryTextUpdated,
      diagnosis: diagnosisTextUpdated,
      plan: secondaryTextUpdated,
      todo: todoTextUpdated,
      medication,
      reports,
      noteType: finalData.notesType.noteType,
      // note subtemplate for covid check
      noteSubTemplate: finalData.notesType.noteSubType,
    };

    this.openAiraPopup(airaNoteData);
  }

  openAiraPopup(airaNoteData: AiraNote) {
    const aira = this._modalService.open(NotesAiraModalComponent, {
      autoFocus: false,
    });
    aira.componentInstance.summary = airaNoteData.summary;
    aira.componentInstance.assessmentPlan = airaNoteData.plan;
    aira.componentInstance.diagnosis = airaNoteData.diagnosis;
    aira.componentInstance.todo = airaNoteData.todo;
    aira.componentInstance.medication = airaNoteData.medication;
    aira.componentInstance.reports = airaNoteData.reports;
    aira.componentInstance.passEntry.subscribe((_) => {
      this._airaNoteService.passAiraDataToNote(airaNoteData);
    });
  }
}
