<div class="confirm-dialog">
  <div class="confirm-dialog__header">
    <div>
      <mat-icon class="confirm-dialog__header__et-icon">warning</mat-icon>
      <div class="confirm-dialog__header__text">
        {{ data.headerText || "Attention" }}
      </div>
    </div>
    <mat-icon class="confirm-dialog__header__c-icon" (click)="closeDialog()"
      >close</mat-icon
    >
  </div>

  <div class="confirm-dialog__body" [innerHtml]="data.message"></div>

  <ng-container *ngIf="itemList?.length">
    <div *ngFor="let item of itemList" class="display-flex cp-mb-1">
      <span class="cp-color-normal cp-text-bold">{{ item?.label }}:</span>
      <span class="cp-ml-1 cp-color-normal">{{ item?.value }}</span>
    </div>
  </ng-container>

  <div class="confirm-dialog__footer">
    <button
      class="button-secondary"
      type="button"
      mat-flat-button
      [mat-dialog-close]="false"
    >
      {{ data.secondaryButtonText || "Cancel" }}
    </button>

    <button
      mat-flat-button
      [ngClass]="statusData.class"
      [mat-dialog-close]="true"
    >
      {{ data?.buttonText || statusData.text }}
    </button>
  </div>
</div>
