import { Component, OnInit, Input } from "@angular/core";
import { UntypedFormGroup } from "@angular/forms";

import * as fromVitalReducer from "../../../store/reducers";
import * as vitalActions from "../../../store/actions/vitals.actions";
import { Store, select } from "@ngrx/store";
import { AlertService } from "@iris/alert/services/alert.service";
import { PainFormService } from "./pain-form.service";
import { Vitals } from "src/app/vitals/models/Vital";
import { setFormVal } from "src/app/vitals/support/vitals.support";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
@Component({
  selector: "app-pain",
  templateUrl: "./pain.component.html",
  styleUrls: ["./pain.component.scss"],
})
export class PainComponent implements OnInit {
  /** To cancel all subscriptions */
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description pain form
   * @type {FormGroup}
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  public rPainForm: UntypedFormGroup = this._painFormService.initPainForm();

  /**
   * @description To store the current patient
   * @type {Object}
   */
  public currentPatient;

  /**
   * @description To store the form state
   * @type {Observable}
   */
  public vitalForm$ = this.store.pipe(
    select(fromVitalReducer.getVitalForm),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description to listen to timestamp changes
   */
  public resetVitalTime$ = this.store.pipe(
    select(fromVitalReducer.resetTime),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description Stores the current form state
   * @type {Object}
   */
  public formState = {
    loading: false,
    loadType: null,
    error: null,
    showSuccess: false,
  };

  /**
   * @description Input value of the current patient
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  @Input()
  set currPatient(currPatient) {
    if (currPatient && currPatient.CPMRN) {
      this.currentPatient = currPatient;
    }
  }

  @Input() minDate;
  @Input() maxDate;

  /**
   * Input prop which holds the clicked day info
   */
  @Input()
  set clickedDay(day: Vitals | null) {
    if (day) {
      this.setFormValues(day);
    }
  }

  // passing from initial-assessment form [ admit form ]
  @Input() admitFormIsParent: boolean = false;

  constructor(
    private store: Store<{}>,
    private _alertService: AlertService,
    private _painFormService: PainFormService
  ) {}

  /**
   * @description To set the value of the form
   * @param {Vitals} vitalData
   * @author Suraj Shenoy
   * @date Jun 23 2021
   */
  setFormValues(vitalData: Vitals | null): void {
    setFormVal(this.rPainForm, "painScore", vitalData.daysPain);

    if (vitalData.timestamp)
      setFormVal(this.rPainForm, "timestamp", vitalData.timestamp);
  }

  /**
   * @description Resets the form
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  reset() {
    /** Reset the store */
    this.store.dispatch(vitalActions.setClickedDay({ day: null }));

    this.rPainForm.reset();
    this.rPainForm.get("painScore").setValue(null);
    this.rPainForm.get("timestamp").setValue(null);
    this.formState.error = null;
  }

  /**
   * @description To get the value of timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get formDate() {
    return this.rPainForm.get("timestamp");
  }

  /**
   * @description To get the value of pain score
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  get painScore() {
    return this.rPainForm.get("painScore").value;
  }

  /**
   * @description To submit the pain score to the server
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  submitPain(patDataFromForm): void {
    this.store.dispatch(vitalActions.submitForm({ loadType: "pain" }));

    const painProps = {
      timestamp: new Date(patDataFromForm.timestamp),
      daysPain: patDataFromForm.painScore,
    };

    this.store.dispatch(
      vitalActions.updateVitals({
        CPMRN: this.currentPatient.CPMRN,
        encounters: this.currentPatient.encounters,
        vitalData: { vitals: painProps },
      })
    );
  }

  ngOnInit(): void {
    // listen to loading props
    this.vitalForm$.subscribe((data) => {
      this.formState = data;

      if (this.formState.showSuccess) {
        // this.reset();

        this._alertService.showNotification({
          type: "Success",
          message: "Pain updated!",
        });

        this.store.dispatch(vitalActions.hideSuccess());
      }
    });
    this.resetVitalTimestamp();
  }

  /**
   * @description To reset the timestamp
   * @author Suraj Shenoy
   * @date June 22 2021
   */
  resetVitalTimestamp(): void {
    this.resetVitalTime$.subscribe(
      (date) => {
        if (date) {
          this.rPainForm.get("timestamp").setValue(date);
        }
      },
      (err) => {
        console.log(err);
      }
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
