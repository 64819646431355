import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ChoiceListComponent } from "./components/choice-list/choice-list.component";

@NgModule({
  declarations: [ChoiceListComponent],
  imports: [CommonModule],
  exports: [ChoiceListComponent],
})
export class ChoiceListModule {}
