import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { MultiselectChiplistComponent } from "./multiselect-chiplist/multiselect-chiplist.component";
import { MatSelectModule } from "@angular/material/select";
import { MatChipsModule } from "@angular/material/chips";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatTooltipModule } from "@angular/material/tooltip";

@NgModule({
  declarations: [MultiselectChiplistComponent],
  imports: [
    CommonModule,
    MatSelectModule,
    FormsModule,
    MatFormFieldModule,
    ReactiveFormsModule,
    MatChipsModule,
    MatIconModule,
    MatAutocompleteModule,
    MatTooltipModule,
  ],
  exports: [MultiselectChiplistComponent],
})
export class MutiselectChiplistModule {}
