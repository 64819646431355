import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import { FormControl, Validators } from "@angular/forms";
import { MatTabChangeEvent } from "@angular/material/tabs";
import * as d3 from "d3";
import * as moment from "moment-timezone";
import { AlertService } from "@iris/alert";
import { Patient } from "src/app/models/patient";
import {
  PHOTO_THERAPY_ICONS,
  PHOTO_THERAPY_INTENSITY_MAP,
  PhotoTherapyValue,
  trendType,
  DIRECT_ANTI_GLOBULIN_LAB_NAME,
  GRAPH_MARGINS,
  GRAPH_MARGINS_NICE_CHART,
  getPTherapyLine1Coords,
} from "./trends.data";
import * as fromPatientFormActions from "../../../store/actions/patient-chart/patient-header/patient-form.actions";
import { FormsService } from "src/app/admit-form/services/forms.service";
import { select, Store } from "@ngrx/store";
import { getProperUnitName } from "src/app/support-functions/calculateAge";
import { MatMenuTrigger } from "@angular/material/menu";
import { DocumentIntensityUpdate } from "src/app/models/document";
import { LabsScansService } from "src/app/labs-scans-module/services/labs-scans.service";
import { getAllLabs } from "src/app/labs-scans-module/store/reducers";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientType } from "src/app/models/patient";
import { IcuDaysPipe } from "src/app/pipes/icu-days.pipe";
import { DialogService } from "@iris/confim-dialog";
import { AlertInline, AlertInlineService } from "@iris/alert-inline";

const temperatureVitalName = "daysTemperature";
const temperatureUnitVitalName = "daysTemperatureUnit";
const bpVitalName = "daysBP";

@Component({
  selector: "app-trends",
  templateUrl: "./trends.component.html",
  styleUrls: ["./trends.component.scss"],
  encapsulation: ViewEncapsulation.None,
})
export class TrendsComponent implements OnInit, AfterViewInit, OnDestroy {
  private unsubscribe$ = new Subject();

  @ViewChild(MatMenuTrigger) menuTrigger: MatMenuTrigger;
  @ViewChild("canvas", { static: false })
  public _canvas: ElementRef;

  /**
   * @description selected day to display the vitals of that day
   * @author Rajat Saini
   * @date Aug 18, 2022
   */
  @Input() selectedDay: number;

  /**
   * @description specific vital data grouped by days
   * @author Rajat Saini
   * @date Aug 18, 2022
   */
  @Input()
  set currentVitalData(currentVitalData) {
    if (this.isShowDayDropdown) {
      this.days = Object.keys(currentVitalData);
    }
    this.vitalData = currentVitalData;
    this.vitalsToDisplay = this.isShowDayDropdown
      ? this.vitalData[this.selectedDay]
      : this.vitalData;
    this.isVitalDataVisible = this.vitalsToDisplay ? true : false;

    if (this.isVitalDataVisible) this.getRecentVitalData();

    // if graph intialized for the first time then on data change [socket update] the graph will be re - initialize
    if (this.isInitComplete) {
      this.plotDataOnGraph();
    }
  }

  @Input() vitalNames;

  /**
   * @description - contains Y-axis data
   * @prototype - [[90, 110], 9, 'weight (gms)'] => [90, 110] range of y-axis
   *                              => 9 number of ticks
   *                              => y axis label
   */
  @Input() yAxisConfig;

  /**
   * @description - contains X-axis config data
   * @prototype - [true, data, 'age (days )'] => 1st , whether to dynamic x-axis data
   *                              2nd , from here we need to calculate
   *                              3rd , x axis label
   */
  @Input() xAxisConfig;

  /**
   * contains null || [rangeToBeDisplayedOnGraph, isShownOnGraph, rangeToBeDisplayedOnHeader, range2ForBPOnly]
   */
  @Input() vitalNormalRange;

  @Input() currPatient: Patient;

  @Input() isShowDayDropdown: boolean = false;

  @Input()
  set trendType(type: trendType) {
    this._trendType = type;
    this.setGraphHeightWidth();
  }
  get trendType() {
    return this._trendType;
  }
  _trendType: trendType;

  @Input() isHeaderToggleVisible: boolean = false;

  public getAllLabs$ = this.store.pipe(
    select(getAllLabs),
    takeUntil(this.unsubscribe$)
  );

  isTrendActive: boolean = true;
  days: string[];
  vitalData: {};
  vitalsToDisplay: [{ date: Date; value: any; age: any }];
  isVitalDataVisible: boolean = false;
  todayDayNum: any;

  /**** graph variables ****/

  private margin;
  private width: number;
  private height: number;
  private graphWidth: number;
  private graphHeight: number;
  private x: any;
  private y: any;
  private svg: any;
  private line: d3.Line<[number, number]>;
  private xDomainData = [];
  private yDomainData = [];
  private currentDay: Date;
  public currentUnit: string;
  public recentVitalData: any;

  /***** Tabular variables */
  public tabularTableColumns: string[] = [
    "age",
    "weight",
    "date",
    "time",
    "info",
  ];
  public tooltipStore: any;
  public showEditWeightIndex: number = null;
  public inputWeightIndex: number = null;
  public inputWeigthTimestamp: string = null;
  public weightInput: FormControl = new FormControl(
    "",
    Validators.compose([
      Validators.required,
      Validators.min(1),
      Validators.max(999000),
    ])
  );

  get TrendType() {
    return trendType;
  }

  get photoTherapyIntensityMap() {
    return PHOTO_THERAPY_INTENSITY_MAP;
  }

  isTrendType(checkTrendType: trendType) {
    return checkTrendType === this.trendType;
  }

  createIntensityXCoord;
  createIntensityYCoord;
  createIntensityLabId: string;
  directAntiGlobulinValue;

  constructor(
    private _formActions: FormsService,
    private store: Store<{}>,
    private _icuDaysPipe: IcuDaysPipe,
    private alerService: AlertService,
    private alertInlineService: AlertInlineService,
    private _labScansService: LabsScansService,
    private _dialogService: DialogService
  ) {
    this.setGraphHeightWidth();
  }

  setGraphHeightWidth() {
    this.width = 1200;
    this.height = 515;
    this.margin = this.isTrendType(trendType.niceChart)
      ? GRAPH_MARGINS_NICE_CHART
      : GRAPH_MARGINS;

    this.graphWidth = this.width - this.margin.right - this.margin.left;
    this.graphHeight = this.height - this.margin.top - this.margin.bottom;
  }

  ngOnInit(): void {
    if (this.isShowDayDropdown)
      this.todayDayNum = this._icuDaysPipe
        .transform(this.currPatient.ICUAdmitDate)
        .split(" ")[0];

    if (
      this.isTrendType(trendType.niceChart) &&
      this.currPatient?.patientType === PatientType.Neonatal
    ) {
      this.getAllLabs$.subscribe((labsData) => {
        const foundRecentAntiGlobulinLab = labsData?.find((lab) =>
          lab.name?.includes(DIRECT_ANTI_GLOBULIN_LAB_NAME)
        );
        this.directAntiGlobulinValue = foundRecentAntiGlobulinLab
          ? foundRecentAntiGlobulinLab?.attributes?.value?.value
          : null;
      });
    }
  }

  ngAfterViewInit(): void {
    this.initialGraph();
    setTimeout(() => {
      this.plotDataOnGraph();
    }, 200);
  }

  isInitComplete: boolean = false;
  initialGraph() {
    this.buildSvg();
    this.makeTooltip();

    // plotting data
    this.plotDataOnGraph();
    this.isInitComplete = true;
  }

  clearGraph() {
    this.svg.selectAll(".axis").remove();
    this.svg.selectAll(".normal-range").remove();
    this.svg.selectAll(".phototherapy-line").remove();
    this.svg.selectAll(".externalTransfusion-line").remove();
    this.svg.selectAll(".phototherapyintensity-images").remove();
    this.svg.selectAll(".now-line").remove();
    this.svg.selectAll(".max-min-text").remove();
    this.svg.selectAll(".scatter-dots").remove();
    this.svg.selectAll("path").remove();
  }

  removeGraph() {
    this.svg.selectAll("*").remove();
  }

  plotDataOnGraph() {
    this.clearGraph();

    this.makeXDomainData();
    this.addXAxis();

    this.makeYDomainData();
    this.addYAxis();

    if (this.vitalNormalRange && this.vitalNormalRange[1])
      this.plotNormalRanges();

    // plotting now-line if we are showing graph for today
    if (this.isNowLineActive) this.nowLine();
    if (this.isVitalDataVisible) {
      this.makeMaxMinText();
      this.scatterDots();
      this.drawLineAndPath();

      if (this.vitalNames?.val === bpVitalName) {
        this.plotAnotherLineAndDots();
      }

      // plotting photo-therapy intesity data and inputs for NICE chart
      this.isTrendType(trendType.niceChart) &&
        this.plotNICEChartPhotoTherapyInput();
    }

    // NICE - CHART specific plotting
    if (this.isTrendType(trendType.niceChart)) this.plotNICEChartTwoLines();
  }

  shuffleVitalDay(event) {
    this.vitalsToDisplay = this.vitalData[event.value];
    this.isVitalDataVisible = this.vitalsToDisplay ? true : false;
    this.isVitalDataVisible && this.getRecentVitalData();
    // plotting graph again
    this.plotDataOnGraph();
  }

  getRecentVitalData() {
    this.recentVitalData = this.vitalsToDisplay[0];
  }

  tooltip: any;
  makeTooltip() {
    this.tooltip = d3
      .select(this._canvas.nativeElement)
      .append("div")
      .attr("class", "tooltip")
      .style("opacity", 0)
      .style("background-color", "white")
      .style("box-shadow", "rgb(0 0 0 / 20%) 0px 0px 5px")
      .style("padding", "12px 16px")
      .style("border-radius", "5px");
  }

  makeXDomainData() {
    let minX, maxX;
    switch (this.trendType) {
      case trendType.vital:
        // static x-axis of current day [ for vitals ]
        let timestamp;
        timestamp =
          this.vitalsToDisplay && this.vitalsToDisplay.length
            ? this.vitalsToDisplay[0]["date"]
            : undefined;
        this.currentDay = timestamp ? new Date(timestamp) : new Date();
        this.xDomainData = [
          this.currentDay.setHours(0, 0),
          this.currentDay.setHours(23, 0),
        ];
        return;
      case trendType.weight:
        [minX, maxX] = this.xAxisConfig[1];

        if (!this.vitalsToDisplay?.length) {
          this.xDomainData = [minX, maxX];
          return;
        }

        const vitalValues = this.vitalsToDisplay.reduce((arr, valObj) => {
          return [...arr, valObj["age"]];
        }, []);
        let [min, max] = [Math.min(...vitalValues), Math.max(...vitalValues)];
        // [minX, maxX] = [
        //   vitalValues?.length == 1 ? 0 : min || 0,
        //   this.getNoOfDaysOfAge(),
        // ];
        const noOfDaysOfAge = this.getNoOfDaysOfAge();
        [minX, maxX] = [
          min < minX ? min : minX,
          noOfDaysOfAge > maxX ? noOfDaysOfAge : maxX,
        ];
        this.xDomainData = [minX, maxX];
        return;
      case trendType.niceChart:
        [minX, maxX] = this.xAxisConfig[1];
        this.xDomainData = [minX, maxX];
    }
  }

  makeYDomainData() {
    let minY, maxY, min, max;
    switch (this.trendType) {
      case trendType.niceChart:
        [minY, maxY] = this.yAxisConfig[0];
        this.yDomainData = [minY, maxY];
        break;
      default:
        [minY, maxY] = this.yAxisConfig[0];
        const vitalValues = this.vitalsToDisplay.reduce((arr, vit) => {
          if (vit["value2"] != null)
            return [...arr, vit["value"], vit["value2"]];
          return [...arr, vit["value"]];
        }, []);
        [min, max] = [Math.min(...vitalValues), Math.max(...vitalValues)];

        if (min < minY) minY = min;
        if (max > maxY) maxY = max;

        this.yDomainData = [minY, maxY];
    }
  }

  buildSvg() {
    this.svg = d3
      .select(this._canvas.nativeElement)
      .append("svg")
      .attr("width", this.width)
      .attr("height", this.height)
      .append("g")
      .attr("class", "plot-area")
      .attr("width", this.graphWidth)
      .attr("height", this.graphHeight)
      .attr(
        "transform",
        "translate(" + this.margin.left + "," + this.margin.top + ")"
      );
  }

  addXAxis() {
    switch (this.trendType) {
      case trendType.weight:
        this.x = d3
          .scaleLinear()
          .domain(this.xDomainData)
          .range([0, this.graphWidth])
          .nice();

        this.svg
          .append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + this.graphHeight + ")")
          .call(
            d3
              .axisBottom(this.x)
              .ticks(20)
              .tickFormat(d3.format("d"))
              .tickPadding(15)
              .tickSize(-this.graphHeight)
          )
          .call((g) => g.select(".domain").remove());
        break;
      case trendType.niceChart:
        this.x = d3
          .scaleLinear()
          .domain(this.xDomainData)
          .range([0, this.graphWidth])
          .nice();

        this.svg
          .append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + this.graphHeight + ")")
          .call(
            d3
              .axisBottom(this.x)
              .ticks(14)
              .tickFormat(d3.format("d"))
              .tickPadding(15)
              .tickSize(-this.graphHeight)
          )
          .call((g) => g.select(".domain").remove());
        break;
      case trendType.vital:
        this.x = d3
          .scaleTime()
          .domain(this.xDomainData)
          .range([0, this.graphWidth])
          .nice();
        this.svg
          .append("g")
          .attr("class", "axis axis--x")
          .attr("transform", "translate(0," + this.graphHeight + ")")
          .call(
            d3
              .axisBottom(this.x)
              .ticks(d3.timeHour)
              .tickFormat(d3.timeFormat("%H:%M"))
              .tickPadding(15)
              .tickSize(-this.graphHeight)
          )
          .call((g) => g.select(".domain").remove());
        break;
      default:
    }
  }

  callTicks() {
    console.log("ticks");
  }

  addYAxis() {
    this.y = d3.scaleLinear().range([this.graphHeight, 0]);
    this.y.domain(this.yDomainData).nice();

    this.svg
      .append("g")
      .attr("class", "axis axis--y")
      .call(
        d3
          .axisLeft(this.y)
          .tickFormat(d3.format("d"))
          .tickPadding(15)
          .tickSize(-this.graphWidth)
      )
      .call((g) => g.select(".domain").remove());
  }

  makeMaxMinText(forBPValue2 = false) {
    // return if array contains only 1 elemnt
    if (this.vitalsToDisplay.length < 2) return;

    const vitalValue = forBPValue2 ? "value2" : "value";
    const [xkey] = this.xAndYKey;

    // maxMin = [max{date, time}, min{date, time}]
    const maxMin = this.getMaxMinValues(vitalValue);
    const maxMinText = this.svg.append("g").attr("class", "max-min-text");
    const [maxValue, minValue] = maxMin;
    let yMaxPoint = this.y(maxValue[vitalValue]);
    yMaxPoint = yMaxPoint <= 15 ? yMaxPoint + 15 : yMaxPoint - 6;

    let xMaxPoint = this.x(maxValue[xkey]);
    // aligning max text so that it won't go outside from graph
    xMaxPoint = this.alignElements("x-axis", xMaxPoint, 25, 25);
    maxMinText
      .append("text")
      .attr("class", "max-text")
      .attr("x", xMaxPoint)
      .attr("y", yMaxPoint)
      .attr("dy", "0rem")
      .style("text-anchor", "middle")
      .text(`Max: ${maxValue[vitalValue]}`);

    if (minValue != undefined) {
      let yMinPoint = this.y(minValue[vitalValue]);
      yMinPoint =
        yMinPoint >= this.graphHeight - 20 ? yMinPoint - 10 : yMinPoint + 20;

      let xMinPoint = this.x(minValue[xkey]);
      // aligning min text so that it won't go outside from graph
      xMinPoint = this.alignElements("x-axis", xMinPoint, 25, 25);
      maxMinText
        .append("text")
        .attr("class", "min-text")
        .attr("x", xMinPoint)
        .attr("y", yMinPoint)
        .attr("dy", "0rem")
        .style("text-anchor", "middle")
        .text(`Min: ${minValue[vitalValue]}`);
    }
  }

  scatterDots() {
    const [xkey, ykey] = this.xAndYKey;
    this.svg
      .append("g")
      .attr("class", "scatter-dots")
      .selectAll("dot")
      .data(this.vitalsToDisplay)
      .enter()
      .append("circle")
      .attr("cx", (d: any) => this.x(d[xkey]))
      .attr("cy", (d: any) => this.y(d[ykey]))
      .attr("r", 4)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "#34A2B1")
      .on("mouseover", this.showPopover.bind(this))
      .on("mouseleave", this.hidePopover.bind(this));
  }

  drawLineAndPath() {
    const [xkey, ykey] = this.xAndYKey;
    this.line = d3
      .line()
      .x((d: any) => this.x(d[xkey]))
      .y((d: any) => this.y(d[ykey]));

    // Configuring line path
    this.svg
      .append("path")
      .datum(this.vitalsToDisplay)
      .attr("class", "line")
      .attr("d", this.line)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "none")
      .style("stroke", "#34A2B1")
      .style("stroke-width", "2");
  }

  // For BP vital only
  plotAnotherLineAndDots() {
    // making max-min text for 2nd time [ BP ]
    this.makeMaxMinText(true);

    this.svg
      .append("g")
      .attr("class", "scatter-dots")
      .selectAll("dot")
      .data(this.vitalsToDisplay)
      .enter()
      .append("circle")
      .attr("cx", (d: any) => this.x(d.date))
      .attr("cy", (d: any) => this.y(d.value2))
      .attr("r", 4)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "#FAC02E")
      .on("mouseover", this.showPopover2.bind(this))
      .on("mouseleave", this.hidePopover.bind(this));

    const line2Func = d3
      .line()
      .x((d: any) => this.x(d.date))
      .y((d: any) => this.y(d.value2));

    // Configuring line path
    this.svg
      .append("path")
      .datum(this.vitalsToDisplay)
      .attr("class", "line")
      .attr("d", line2Func)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "none")
      .style("stroke", "#FAC02E")
      .style("stroke-width", "2");
  }

  showPopover(event, data) {
    let htmlData = this.getDotPopoverData(event, data);
    this.translatePopup(htmlData, event);
  }

  getDotPopoverData(event, data) {
    const [xKey] = this.xAndYKey;
    let htmlData = `${data.value} ${this.vitalNames["unitName"]} <br/>`;
    switch (this.trendType) {
      case trendType.vital:
        return htmlData + moment(data[xKey]).format("HH:mm");
      case trendType.weight:
        return htmlData + `${getProperUnitName(data[xKey], "day", true)}`;
      case trendType.niceChart:
        return htmlData + moment(data["date"]).format("MMM DD, HH:mm");
    }
  }

  showPopover2(event, data) {
    let htmlData = `${data.value2} ${this.vitalNames["unitName"]} <br/>
      ${moment(data.date).format("HH:mm")}
    `;
    this.translatePopup(htmlData, event);
  }

  translatePopup(htmlData, event) {
    this.tooltip
      .html(htmlData)
      .style("left", event.pageX - 45 + "px")
      .style("top", event.pageY - 75 + "px")
      .transition()
      .duration(100) // ms
      .style("opacity", "1");
  }

  // getUnitName(event) {
  //   return this.vitalNames?.val === temperatureVitalName
  //     ? event["unitName"]
  //     : this.vitalNames["unitName"];
  // }

  hidePopover() {
    this.tooltip.transition().duration(100).style("opacity", "0");
  }

  nowLine() {
    let xCoordinate;
    switch (this.trendType) {
      case trendType.vital:
        const [nowHour, nowMin] = [
          new Date().getHours(),
          new Date().getMinutes(),
        ];
        this.currentDay.setHours(nowHour, nowMin);
        xCoordinate = this.currentDay;
        break;
      case trendType.weight:
        xCoordinate = this.getNoOfDaysOfAge();
        break;
    }

    const nowLine = this.svg.append("g").attr("class", "now-line");
    nowLine
      .append("line")
      .attr("x1", this.x(xCoordinate))
      .attr("x2", this.x(xCoordinate))
      .attr("y1", 0)
      .attr("y2", this.graphHeight)
      .style("stroke-dasharray", "5,5")
      .style("stroke", "#488AF8");

    nowLine
      .append("rect")
      .attr("width", 37)
      .attr("height", 16)
      .attr("fill", "#3271DA")
      .attr("rx", "2")
      .attr("x", this.x(xCoordinate) - 18)
      .attr("y", -20);

    nowLine
      .append("text")
      .attr("class", "now-text")
      .attr("x", this.x(xCoordinate))
      .attr("y", -8)
      .attr("dy", "0rem")
      .style("text-anchor", "middle")
      .text("Now");
  }

  plotNormalRanges() {
    const normalRange = this.svg.append("g").attr("class", "normal-range");

    const [lowerRange, upperRange] = this.vitalNormalRange[0];

    const normalRangeHeight =
      this.vitalNames.val == temperatureVitalName
        ? this.y(lowerRange) - this.y(upperRange)
        : this.graphHeight - this.y(upperRange - lowerRange);

    normalRange
      .append("rect")
      .attr("width", this.graphWidth)
      .attr("height", normalRangeHeight)
      .attr("fill", "#F4F7FE")
      .attr("x", 0)
      .attr("y", this.y(upperRange));

    let textYPoint = (this.y(upperRange) + this.y(lowerRange)) / 2;
    textYPoint = this.alignElements("y-axis", textYPoint, 30, 40);

    normalRange
      .append("text")
      .attr("class", "normal-range-text")
      .attr("x", this.graphWidth)
      .attr("y", textYPoint)
      .attr(
        "transform",
        `rotate(-90, ${this.graphWidth + 30}, ${textYPoint + 10})`
      )
      .text("Normal range");
  }

  plotNICEChartTwoLines() {
    const [xkey, ykey] = this.xAndYKey;
    const phototherapyLineFunc = d3
      .line()
      .x((d: any) => this.x(d[xkey]))
      .y((d: any) => this.y(d[ykey]));

    const pTherapyLine1Coords = getPTherapyLine1Coords(
      this.getPatientGestationAge(),
      "line_1"
    );
    const pTherapyLine2Coords = getPTherapyLine1Coords(
      this.getPatientGestationAge(),
      "line_2"
    );

    const pTherapyTextYPoint = this.alignElements(
      "y-axis",
      this.y(Math.max(...pTherapyLine1Coords.map((x) => x.value))),
      30,
      40
    );
    const eTranfusionTextYPoint = this.alignElements(
      "y-axis",
      this.y(Math.max(...pTherapyLine2Coords.map((x) => x.value))),
      30,
      40
    );

    const photoTherapyLine = this.svg
      .append("g")
      .attr("class", "phototherapy-line");
    const externalTranfusionLine = this.svg
      .append("g")
      .attr("class", "externalTransfusion-line");

    photoTherapyLine
      .append("path")
      .datum(pTherapyLine1Coords)
      .attr("d", phototherapyLineFunc)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "none")
      .style("stroke", "#B5D2FE")
      .style("stroke-width", "2");
    photoTherapyLine
      .append("text")
      .attr("class", "normal-range-text")
      .attr("x", this.graphWidth)
      .attr("y", pTherapyTextYPoint)
      .attr(
        "transform",
        `rotate(-90, ${this.graphWidth + 30}, ${pTherapyTextYPoint + 10})`
      )
      .text("Phototherapy");

    externalTranfusionLine
      .append("path")
      .datum(pTherapyLine2Coords)
      .attr("d", phototherapyLineFunc)
      .attr("transform", "translate(" + 0 + "," + 0 + ")")
      .style("fill", "none")
      .style("stroke", "#FFBDBE")
      .style("stroke-width", "2");
    externalTranfusionLine
      .append("text")
      .attr("class", "normal-range-text")
      .attr("x", this.graphWidth + 15)
      .attr("y", eTranfusionTextYPoint)
      .attr(
        "transform",
        `rotate(-90, ${this.graphWidth + 30}, ${eTranfusionTextYPoint + 10})`
      )
      .text("Exchange ");
    externalTranfusionLine
      .append("text")
      .attr("class", "normal-range-text")
      .attr("x", this.graphWidth + 10)
      .attr("y", eTranfusionTextYPoint + 20)
      .attr(
        "transform",
        `rotate(-90, ${this.graphWidth + 30}, ${eTranfusionTextYPoint + 10})`
      )
      .text("Transfusion");
  }

  plotNICEChartPhotoTherapyInput() {
    const [xkey, ykey] = this.xAndYKey;

    const photoTherapyIntensityImages = this.svg
      .append("g")
      .attr("class", "phototherapyintensity-images");

    photoTherapyIntensityImages
      .selectAll("phototherapyintensity")
      .data(this.vitalsToDisplay)
      .enter()
      .append("svg")
      .attr("x", (d: any) => this.x(d[xkey]) - 12)
      .attr("y", -35)
      .attr("width", 24)
      .attr("height", 24)
      .html((d) => {
        if (!d["phototherapyIntensity"]) {
          if (!this.isElementRecentInData(this.vitalsToDisplay, d)) return;

          return PHOTO_THERAPY_ICONS.add;
        }

        return d["phototherapyIntensity"] === "single"
          ? PHOTO_THERAPY_ICONS.single
          : PHOTO_THERAPY_ICONS.multiple;
      })
      .on("click", this.intensityClick.bind(this))
      .on("mouseover", this.showIntensityPopover.bind(this))
      .on("mouseleave", this.hidePopover.bind(this));
  }

  showIntensityPopover(event, data) {
    let contextName = null;
    if (!data["phototherapyIntensity"]) {
      if (!this.isElementRecentInData(this.vitalsToDisplay, data))
        return this.hidePopover();

      contextName = "Select phototherapy intensity";
    }

    let htmlData;
    if (!contextName) {
      htmlData = `${
        data["phototherapyIntensity"][0].toUpperCase() +
        data["phototherapyIntensity"].slice(1)
      } phototherapy`;
    } else {
      htmlData = `${contextName}`;
    }
    this.translatePopup(htmlData, event);
  }

  intensityClick(event, data) {
    if (
      !this.isElementRecentInData(this.vitalsToDisplay, data) ||
      data["phototherapyIntensity"]
    )
      return;

    this.createIntensityLabId = data._id;
    this.openMenu(event);
  }

  openMenu(event) {
    const offsetY = 10;
    this.createIntensityXCoord = event.pageX;
    this.createIntensityYCoord = event.pageY + offsetY;
    this.menuTrigger.openMenu();
  }

  openConfirmationDialog(intensityValue: PhotoTherapyValue) {
    if (intensityValue === PhotoTherapyValue.Single)
      return this.assignPhotoTherapyIntensity(PhotoTherapyValue.Single);

    let strToPass = `You have selected ‘Multiple phototherapy’. Please confirm your selection.`;
    strToPass +=
      `\n` + `Once confirmed, your selection can’t be changed later.`;
    this._dialogService
      .openConfirmDialogue({
        message: strToPass,
        buttonText: "Confirm",
        headerText: "Confirm selection",
      })
      .afterClosed()
      .subscribe((response) => {
        if (!response) return;

        this.assignPhotoTherapyIntensity(PhotoTherapyValue.Multiple);
      });
  }

  assignPhotoTherapyIntensity(intensityValue: PhotoTherapyValue) {
    let payload: DocumentIntensityUpdate = {
      document: {
        phototherapyIntensity: intensityValue,
      },
      patient: {
        CPMRN: this.currPatient.CPMRN,
        encounters: this.currPatient.encounters,
      },
    };

    this._labScansService
      .updateLabIntensityValue(payload, this.createIntensityLabId)
      .subscribe(
        (data) => {
          this.alerService.showNotification({
            type: "Success",
            message: "Value updated!",
          });
        },
        ({ error }) => {
          console.log(error);
          const erroObj: AlertInline = {
            type: "Error",
            title: "Error!",
            message: error.message,
          };
          this.alertInlineService.showInlineNotification(
            erroObj,
            "center",
            "bottom",
            null
          );
        }
      );
  }

  isElementRecentInData(data, element) {
    const isRecentElement = data.findIndex((ele) => ele["_id"] === element._id);
    return isRecentElement !== 0 ? false : true;
  }

  getMaxMinValues(vitalValue = "value") {
    return this.vitalsToDisplay.reduce((arr, currVital) => {
      if (!arr.length) return [currVital];

      let iterArray = arr;
      const maxValue = iterArray[0];
      if (currVital[vitalValue] > +maxValue[vitalValue]) {
        arr = arr.length == 1 ? [currVital, maxValue] : [currVital, arr[1]];
      } else {
        if (arr.length == 1) arr.push(currVital);
        else {
          const minValue = iterArray[1];
          if (currVital[vitalValue] < +minValue[vitalValue]) arr[1] = currVital;
        }
      }
      return arr;
    }, []);
  }

  alignElements(axis: string, value, minVal, defaultVal) {
    switch (axis) {
      case "x-axis":
        return value < minVal
          ? defaultVal
          : value > this.graphWidth - minVal
          ? this.graphWidth - defaultVal
          : value;
      case "y-axis":
        return value < minVal
          ? defaultVal
          : value > this.graphHeight - minVal
          ? this.graphHeight - defaultVal
          : value;
      default:
        return;
    }
  }

  get xAndYKey() {
    let xkey, ykey;
    switch (this.trendType) {
      case trendType.vital:
        xkey = "date";
        ykey = "value";
        break;
      case trendType.weight:
        xkey = "age";
        ykey = "value";
        break;
      case trendType.niceChart:
        xkey = "age";
        ykey = "value";
        break;
    }
    return [xkey, ykey];
  }

  get isNowLineActive() {
    switch (this.trendType) {
      case trendType.vital:
        return this.selectedDay == this.todayDayNum;
      case trendType.weight:
        return true;
      case trendType.niceChart:
        return false;
    }
  }

  tabChanged(event: MatTabChangeEvent) {
    this.isTrendActive = event.index ? false : true;

    // re-init the graph if tab changed to trends
    if (this.isTrendActive) this.plotDataOnGraph();
  }

  closeWeightInput() {
    this.weightInput.reset();
    this.inputWeightIndex = null;
    this.showEditWeightIndex = null;
    this.inputWeigthTimestamp = null;
  }

  updateWeigth() {
    const weightObj = {
      weight: this.weightInput.value,
      weightUnit: "gm",
      weightMeasuredTime: this.inputWeigthTimestamp,
    };
    const updatedProperties = this._formActions.transformUpdateData(weightObj);
    const payload = {
      CPMRN: this.currPatient.CPMRN,
      encounters: this.currPatient.encounters,
      patient: updatedProperties,
    };
    this.store.dispatch(
      fromPatientFormActions.updatePatient({
        payload,
        nextPage: 0,
        isFormComplete: false,
      })
    );

    // chaning the weight in variable
    this.vitalsToDisplay[this.inputWeightIndex]["value"] =
      this.weightInput.value;

    this.alerService.showNotification({
      type: "Success",
      message: "Weight edited successfully",
    });
    this.closeWeightInput();
  }

  getNoOfDaysOfAge() {
    const duration = moment.duration(moment().diff(this.currPatient.dob));
    return Math.floor(duration.asDays());
  }

  getPatientGestationAge() {
    return Math.ceil(
      this.currPatient?.gestationAge?.weeks +
        this.currPatient?.gestationAge?.days / 7
    );
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
