<div class="cp-progress-bar">
  <div class="cp-progress-container">
    <ng-container *ngFor="let bar of progressBarArr">
      <div
        #bar
        class="{{ bar.type }} {{ bar.align }}"
        popoverTrigger="hover"
        cpPopover="{{ bar.popoverContent }}"
        position="top-center"
      >
        <div class="text">{{ bar.text }}</div>
      </div>
    </ng-container>
  </div>
</div>
