<app-overlay #overlayComp [backdropCloseOnClick]="false">
  <section class="cp-carousel-wrapper">
    <div class="title">
      <span>Attached files</span>
      <span>
        <img
          class="cp-cursor-pointer"
          src="assets/icons/close.svg"
          alt="close"
          (click)="closeAttachmentOverlay()"
        />
      </span>
    </div>

    <div class="content">
      <app-carousel [slides]="filePreviewList"></app-carousel>
    </div>
  </section>
</app-overlay>
