import { Component, Input, OnInit } from "@angular/core";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";
import { AddressInterface } from "@smart-hospital/shared/models/patient.model";
import { OpdSearchInterface } from "../../patient-search/models/patientSearch.model";
import { PatientInfoSection } from "../patient-model";
import { EditCardService } from "../services/edit-card.service";

@Component({
  selector: "app-patient-card",
  templateUrl: "./patient-card.component.html",
  styleUrls: ["./patient-card.component.scss"],
  host: {
    class: "bg-white",
  },
})
export class PatientCardComponent implements OnInit {
  avatarParamsIcon1: AvatarParams[] = [
    {
      size: AVATAR_SIZE.L,
    },
  ];

  PatientInfoSection = PatientInfoSection;

  @Input("patientData") patientData: OpdSearchInterface = null;
  @Input() sectionToShow: PatientInfoSection[] = [
    PatientInfoSection.Patient,
    PatientInfoSection.Visit,
    PatientInfoSection.PatientContact,
  ];

  isSectionVisible(section: PatientInfoSection): boolean {
    return this.sectionToShow.includes(section);
  }

  @Input("isEdit") isEdit: boolean = false;

  public get cpmrn(): string {
    return this.patientData?.cpmrn;
  }

  public get mrn(): string {
    return this.patientData?.mrn;
  }

  public get name(): string {
    return this.patientData?.name;
  }

  public get age(): string {
    return this.patientData?.age?.year;
  }

  public get sex() {
    return this.patientData?.sex;
  }

  public get address(): AddressInterface {
    const address = this.patientData?.address
      ? this.patientData.address[0]
      : null;
    if (!address) return null;
    return address;
  }

  public get addressEmptyString(): string {
    const address = this.patientData?.address
      ? this.patientData.address[0]
      : null;
    if (!address) return "-";
    let isFound;
    for (let addr in address) {
      if (address[addr]) isFound = true;
    }
    if (isFound) return "";
    else return "-";
  }

  public get phone(): string {
    return this.patientData?.mobileNumber;
  }

  public get profilePic(): AvatarParams[] {
    return [
      {
        size: AVATAR_SIZE.L,
        imageUrl: this.patientData?.profilePic,
        name: this.patientData?.name,
      },
    ];
  }

  public edit() {
    this._editCardService.editPatient(this.patientData);
  }

  constructor(private _editCardService: EditCardService) {}

  ngOnInit(): void {}
}
