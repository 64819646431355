import { COMMA, ENTER } from "@angular/cdk/keycodes";
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  forwardRef,
  Input,
  OnInit,
  Output,
  ViewChild,
  ViewEncapsulation,
} from "@angular/core";
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NG_VALUE_ACCESSOR,
} from "@angular/forms";
import {
  MatAutocompleteSelectedEvent,
  MatAutocompleteTrigger,
} from "@angular/material/autocomplete";
import { MatChipInputEvent } from "@angular/material/chips";
import { Observable, of } from "rxjs";
import { map, startWith } from "rxjs/operators";

interface Select {
  name: string;
  [key: string]: string;
}

@Component({
  selector: "app-multiselect-chiplist",
  templateUrl: "./multiselect-chiplist.component.html",
  styleUrls: ["./multiselect-chiplist.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => MultiselectChiplistComponent),
      multi: true,
    },
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None,
})
export class MultiselectChiplistComponent implements OnInit {
  public selectionForm: FormGroup = this._fb.group({
    selectionListControl: new FormControl([]),
    selectionInput: new FormControl(""),
  });
  constructor(private _cdrf: ChangeDetectorRef, private _fb: FormBuilder) {}
  @Input() inputHeight = null;
  @Input() toolTip_length = null;
  @ViewChild(MatAutocompleteTrigger) autocomplete: MatAutocompleteTrigger;

  @Output() selectionChangeValue: EventEmitter<Select[]> = new EventEmitter<
    Select[]
  >();

  private _selectionList: Select[];
  @Input() set selectionList(selection: Select[]) {
    this._selectionList = selection;
    this.filteredSelection$ = this.selectionForm
      .get("selectionInput")
      .valueChanges.pipe(
        startWith(""),
        map((value) => this.selectFilter(value))
      );
  }

  @Input() DISABLENUMBER = null;

  get selectionList() {
    return this._selectionList;
  }

  public resetInput() {
    this.selectionForm.get("selectionListControl").reset();
    this.matChipListSelectedList = [];
  }

  openDropDown() {
    this.autocomplete.openPanel();
  }

  @Input() placeHolder: string = "Default";
  public filteredSelection$: Observable<Select[]>;

  matChipListSelectedList: Select[] = [];

  readonly separatorKeysCodes: number[] = [ENTER, COMMA];

  ngOnInit(): void {
    this.selectionForm
      .get("selectionListControl")
      .valueChanges.subscribe((value: Select[]) => {
        this.selectionControl(value);
        this.selectionChangeValue.emit(value);
      });
  }

  selectFilter(value: string | Select): Select[] {
    const filterValue =
      value === null || value instanceof Object ? "" : value.toLowerCase();
    const matches = this.selectionList?.filter((item) =>
      !!filterValue ? item.name.toLowerCase().includes(filterValue) : item
    );
    const formValue: Select[] = this.selectionForm.get(
      "selectionListControl"
    ).value;
    return formValue === null
      ? matches
      : matches?.filter(
          (x: Select) => !formValue.find((y: Select) => y.name === x.name)
        );
  }

  selectionControl(code: Select[]) {
    this.onTouched(); // <-- mark as touched
    this.selected = code;
    this.onChanged(code); // <-- call function to let know of a change
  }

  ngOnDestroy(): void {}

  onSelectionRemoved(selection: Select) {
    const index = this.matChipListSelectedList.indexOf(selection);
    if (index < 0) return;
    this.matChipListSelectedList.splice(index, 1);
    this.selectionForm
      .get("selectionListControl")
      .setValue(this.matChipListSelectedList); // To trigger change detection
    this.selectionForm.get("selectionInput").setValue("");
  }

  addSelection(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value;
    if (value.trim()) {
      const matches = this.selectionList.filter(
        (selection) => selection.name.toLowerCase() === value
      );
      const formValue: Select[] = this.selectionForm.get(
        "selectionListControl"
      ).value;
      const matchesNotYetSelected =
        formValue === null
          ? matches
          : matches.filter((x) => !formValue.find((y) => y.name === x.name));
      if (matchesNotYetSelected.length === 1) {
        this.matChipListSelectedList.push(matchesNotYetSelected[0]);
        this.selectionForm
          .get("selectionListControl")
          .setValue(this.matChipListSelectedList);
        this.selectionForm.get("selectionInput").setValue("");
      }
    }
    // Reset the input value
    if (input) {
      input.value = "";
    }
  }

  public selectItem(event: MatAutocompleteSelectedEvent): void {
    if (!event.option) {
      return;
    }
    const value: Select = event.option.value;
    if (
      value &&
      value instanceof Object &&
      !this.matChipListSelectedList?.includes(value)
    ) {
      this.matChipListSelectedList.push(value);
      this.selectionForm
        .get("selectionListControl")
        .setValue(this.matChipListSelectedList);
      this.selectionForm.get("selectionInput").setValue("");
    }
  }

  selected!: Select[];
  disabled = false;
  private onTouched!: Function;
  private onChanged!: Function;

  writeValue(value: Select[]): void {
    this.selected = value ?? null;
    this._cdrf.detectChanges();
  }
  registerOnChange(fn: any): void {
    this.onChanged = fn;
    this._cdrf.detectChanges();
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
    this._cdrf.detectChanges();
  }
  setDisabledState(isDisabled: boolean) {
    this.disabled = isDisabled;
  }

  get disableAutoComplete() {
    if (this.DISABLENUMBER)
      return this.matChipListSelectedList.length >= this.DISABLENUMBER
        ? true
        : false;
    return false;
  }
}
