import { Component, Input, Output, EventEmitter } from "@angular/core";

@Component({
  selector: "app-counter",
  templateUrl: "./counter.component.html",
  styleUrls: ["./counter.component.scss"],
})
export class CounterComponent {
  /** Any label to display along with count. */

  @Input("label") label: string;

  /** To display the count. */

  @Input("count") count: number = 0;

  /** Sends a callback to the parent whenever count value changes. */

  @Output() onCountChange = new EventEmitter<number>();

  constructor() {}

  /** Called when '+' icon is clicked */
  onPlus() {
    this.count++;
    this.onCountChange.emit(this.count);
  }

  /** Called when '-' icon is clicked */
  onMinus() {
    if (this.count) {
      this.count--;
      this.onCountChange.emit(this.count);
    }
  }
}
