import { of } from "rxjs";
import { Router } from "@angular/router";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  take,
  tap,
  withLatestFrom,
} from "rxjs/operators";

import { ProtocolService } from "../services/protocol.service";
import { ProtocolApiActionTypes } from "../actions/protocol-api.actions";
import * as OrderActions from "../../store/actions/order/order-main.actions";
import { OrderPaneActions, OrderViewActions } from "../../store/actions/order";
import {
  CreateProtocolPageActions,
  EditProtocolPageActions,
  ListProtocolPageActions,
  ProtocolApiActions,
} from "../actions";
import { AlertService } from "@iris/alert/services/alert.service";
import {
  State as AppState,
  getPatientCPMRNEncounters,
} from "src/app/store/reducers/patient-chart/patient-header";
import { Store } from "@ngrx/store";
@Injectable()
export class ProtocolEffects {
  constructor(
    private protocolService: ProtocolService,
    private alertService: AlertService,
    private router: Router,
    private actions$: Actions,
    private store: Store<AppState>
  ) {}

  /**
   * Executes on submitCreateProtocolForm action.
   * If file is present, then getSignedUrl and saveFormData action is fired.
   * else, createProtocol is fired.
   */
  submitCreateProtocolForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(CreateProtocolPageActions.submitCreateProtocolForm),
      switchMap(({ form, file, category }) => {
        if (file) {
          return of(
            ProtocolApiActions.getSignedUrl(),
            ProtocolApiActions.saveFormData({ form, file, category })
          );
        } else {
          return of(ProtocolApiActions.createProtocol({ protocol: form }));
        }
      })
    )
  );

  submitEditProtocolForm$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProtocolPageActions.submitEditProtocolForm),
      switchMap(({ form, file, category, id }) => {
        if (file && !form.file.key) {
          return of(
            ProtocolApiActions.getSignedUrl(),
            ProtocolApiActions.saveFormData({ form, file, category, id })
          );
        } else {
          return of(ProtocolApiActions.updateProtocol({ id, protocol: form }));
        }
      })
    )
  );

  /**
   * Creates a protocol
   *
   * Executes on createProtocol action.
   * Calls protocol service's create method,
   * On success fires a createProtocolSuccess action with protocol
   * On Failure fires a createProtocolFailure action with error.
   */
  createProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolApiActions.createProtocol),
      switchMap(({ protocol }) =>
        this.protocolService.create(protocol).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.createProtocolSuccess({ protocol: data.data })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.createProtocolFailure({
                error: error.error.data,
              })
            )
          )
        )
      )
    )
  );

  updateProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ProtocolApiActions.updateProtocol),
      switchMap(({ id, protocol }) =>
        this.protocolService.edit(id, protocol).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.updateProtocolSuccess({
              protocol: { id, changes: data.data },
            })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.updateProtocolFailure({
                error: error.error.data,
              })
            )
          )
        )
      )
    )
  );

  /**
   * Deletes a protocol by given id.
   *
   * Executes on deleteProtocol action.
   * Issues a delete request through protocolService.
   * On success fires a deleteProtocolSuccess action with id as param
   * On Failure fires a deleteProtocolsFailure action with error.
   */
  deleteProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(EditProtocolPageActions.deleteProtocol),
      switchMap(({ id }) =>
        this.protocolService.delete(id).pipe(
          take(1),
          map((_) => ProtocolApiActions.deleteProtocolSuccess({ id })),
          catchError((error: any) => {
            let msg = "Server Error";

            if (error.error.message !== "Server Error") {
              msg = "Invalid Id";
            }

            return of(
              ProtocolApiActions.deleteProtocolsFailure({ error: msg })
            );
          })
        )
      )
    )
  );

  /**
   * Fetches all protocol
   *
   * Executes on loadProtocols action.
   * Issues a get request through protocolService.
   * On success fires a loadProtocolsSuccess action with protocols
   * On Failure fires a loadProtocolsFailure action with error.
   */
  loadProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListProtocolPageActions.loadProtocols),
      switchMap(() =>
        this.protocolService.get().pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.loadProtocolsSuccess({ protocols: data.data })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.loadProtocolsFailure({
                error: error?.error?.message || "Server error",
              })
            )
          )
        )
      )
    )
  );

  getProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.getProtocol),
      switchMap(({ id }) =>
        this.protocolService.getProtocol(id).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.getProtocolSuccess({ protocol: data.data })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.getProtocolFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  placeProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderPaneActions.placeProtocol),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ value }, patientData]) =>
        this.protocolService.placeProtocol(value, patientData).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.placeProtocolSuccess({
              message: "Protocol Placed Successfully",
            })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.placeProtocolFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  discontinueProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderViewActions.discontinueProtocol),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ orders }, patientData]) =>
        this.protocolService.discontinueProtocol(orders, patientData).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.discontinueProtocolSuccess({
              message: "Protocol Discontinued",
            })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.discontinueProtocolFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  signProtocol$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderViewActions.signProtocol),
      withLatestFrom(this.store.select(getPatientCPMRNEncounters)),
      switchMap(([{ orders, user }, patientData]) =>
        this.protocolService.signProtocol(orders, user, patientData).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.signProtocolSuccess({
              message: "Protocol Signed",
            })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.signProtocolFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );

  protocolSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProtocolApiActions.createProtocolSuccess,
          ProtocolApiActions.updateProtocolSuccess
        ),
        tap(({ protocol, type }) => {
          let message = "Successfully Created";

          if (type === ProtocolApiActionTypes.UpdateProtocolSuccess) {
            message = "Successfully Updated";
          }
          this.router.navigate(["med-admin/protocol"]);

          this.alertService.showNotification({ type: "Success", message });
        })
      ),
    { dispatch: false }
  );

  protocolFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProtocolApiActions.createProtocolFailure,
          ProtocolApiActions.updateProtocolFailure
        ),
        tap(({ error }) => {
          let message = "Server Error";

          if (error && error instanceof Array) {
            message = "Validation Error";
          }

          this.alertService.showNotification({ type: "Error", message });
        })
      ),
    { dispatch: false }
  );

  /**
   * On successful protocol delete shows a message and redirects to protocol page.
   *
   * Executes on deleteProtocolSuccess action.
   * Issues a message through alertService.
   * Navigates to main protocol page.
   */
  deleteProtocolSuccess$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolApiActions.deleteProtocolSuccess),
        tap(({ id }) => {
          this.alertService.showNotification({
            type: "Success",
            message: "Successfully Deleted",
          });
          this.router.navigate(["med-admin/protocol"]);
        })
      ),
    { dispatch: false }
  );

  /**
   * On unsuccessful protocol delete shows a error message.
   *
   * Executes on deleteProtocolsFailure action.
   * Issues a error message through alertService.
   */
  deleteProtocolFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(ProtocolApiActions.deleteProtocolsFailure),
        tap(({ error }) => {
          this.alertService.showNotification({
            type: "Error",
            message: error,
          });
        })
      ),
    { dispatch: false }
  );

  placeProtocolSuccess$ = createEffect(() =>
    this.actions$.pipe(
      ofType(
        ProtocolApiActions.placeProtocolSuccess,
        ProtocolApiActions.discontinueProtocolSuccess
      ),
      map(({ message }) => {
        this.alertService.showNotification({ type: "Success", message });
        return new OrderActions.CloseInputTab();
      })
    )
  );

  placeProtocolFailure$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(
          ProtocolApiActions.placeProtocolFailure,
          ProtocolApiActions.discontinueProtocolFailure
        ),
        tap(({ error }) => {
          let message = error;

          if (!error) {
            message = "Server Error";
          }

          this.alertService.showNotification({ type: "Error", message });
        })
      ),
    { dispatch: false }
  );

  /*
   * NAME: getProtocolFile$
   * PURPOSE: Calls getProtocolFile() to fetch the file of protocol
   * DESCRIPTION: Works on OrderViewActions.getProtocolFile action
   *   - if http call is success then fires ProtocolApiActions.getProtocolFileSuccess action
   *   - it there is a error then fires ProtocolApiActions.getProtocolFileFailure action
   * PARAMS: void
   * RETURNS: Action
   * USED BY:
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getProtocolFile$ = createEffect(() =>
    this.actions$.pipe(
      ofType(OrderViewActions.getProtocolFile),
      mergeMap(({ protocol }) =>
        this.protocolService.getProtocolFile(protocol).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.getProtocolFileSuccess({ data: data.data })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.getProtocolFileFailure({
                error: "Server Error",
              })
            )
          )
        )
      )
    )
  );

  /**
   * Search protocols
   *
   * Executes on searchProtocols action.
   * Issues a get request through protocolService.
   * On success fires a searchProtocolsSuccess action with protocols
   * On Failure fires a searchProtocolsFailure action with error.
   */
  searchProtocols$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ListProtocolPageActions.searchProtocols),
      switchMap(({ text }) =>
        this.protocolService.get(text).pipe(
          take(1),
          map((data: any) =>
            ProtocolApiActions.searchProtocolsSuccess({ protocols: data.data })
          ),
          catchError((error: any) =>
            of(
              ProtocolApiActions.searchProtocolsFailure({
                error: error.error.message,
              })
            )
          )
        )
      )
    )
  );
}
