import { Component, Input, OnDestroy, OnInit } from "@angular/core";
import { select, Store } from "@ngrx/store";
import { NgxPermissionsService } from "ngx-permissions";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";
import { PatientType } from "src/app/models/patient";
import { Patient } from "src/app/models/patient";
import { Vitals } from "../../models/Vital";
import { setClickedDay } from "../../store/actions/vitals.actions";
import { getVitalClickedDay } from "../../store/reducers";
import { ACTIVE_ASSESSMENT } from "../../support/vitals.support";
@Component({
  selector: "app-assessment-view",
  templateUrl: "./assessment-view.component.html",
  styleUrls: ["./assessment-view.component.scss"],
})
export class AssessmentViewComponent implements OnInit, OnDestroy {
  private unsubscribe$: Subject<any> = new Subject<any>();

  /**
   * @description To observe the clicked day
   */
  public vitalClickedDay$ = this.store.pipe(
    select(getVitalClickedDay),
    takeUntil(this.unsubscribe$)
  );

  /**
   * @description To store the current selected day
   * @type {number}
   * @author Suraj Shenoy
   * @date Jul 27th 2022
   */
  @Input() selectedDay: number = 0;

  /**
   * @description Determines if date is shown on top of table column
   * @type {boolean}
   * @author Suraj Shenoy
   * @date Jul 27th 2022
   */
  @Input() showDateOnCol: boolean = false;

  /**
   * @description To store the vitals
   * @type {Array}
   * @author Suraj Shenoy
   * @date Jul 27th 2022
   */
  @Input() vitals: Vitals[] = [];

  @Input() currPatient: Patient;

  public defaultAssessmentKeys: string[] = [
    "daysPain",
    "daysRass",
    "daysRassCAMICU",
    "daysFalls",
    "daysBraden",
  ];

  public neonatalAssessmentKeys: string[] = ["daysCrib", "apgar"];

  public assessmentKeys: string[] = [];

  public rassAssessmentProps: { val: string; show: string }[] = [
    { val: "daysRass", show: "RASS" },
    { val: "daysRassCAMICU", show: "CAM-ICU" },
  ];

  public canEditAssessment: boolean = false;
  public activeAssessment: string;

  get PatientType() {
    return PatientType;
  }

  get ActiveAssessment() {
    return ACTIVE_ASSESSMENT;
  }

  constructor(
    private store: Store<any>,
    private ngxPermissionService: NgxPermissionsService
  ) {}

  ngOnInit(): void {
    this.assessmentKeys =
      this.currPatient.patientType === PatientType.Neonatal
        ? this.neonatalAssessmentKeys
        : this.defaultAssessmentKeys;
    this.getAssessmentEntryPermission();
  }

  getAssessmentEntryPermission() {
    this.canEditAssessment = this.ngxPermissionService.getPermission(
      "create_vital"
    )
      ? true
      : false;
  }

  highlightAssessment(day: Vitals, activeAssessment: ACTIVE_ASSESSMENT) {
    this.activeAssessment = activeAssessment;
    this.store.dispatch(setClickedDay({ day, activeAssessment }));
  }

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}
