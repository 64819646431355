<section class="vents_container input-form">
  <form
    class="iris-form vent-form"
    autocomplete="off"
    [formGroup]="ventsForm"
    (ngSubmit)="submitVents()"
  >
    <div formGroupName="therapyForm" class="therapy_form">
      <div class="sub-header">
        <h2>Oxygen therapy</h2>
      </div>

      <!-- Therapy and o2 flow -->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Therapy/Device*</mat-label>
              <mat-select
                (selectionChange)="therapyChanged()"
                multiple
                formControlName="daysTherapyDevice"
              >
                <mat-option
                  *ngFor="let therapy of therapyValues"
                  [value]="therapy"
                  >{{ therapy }}</mat-option
                >
              </mat-select>
              <mat-error>{{
                therapyForm.get("daysTherapyDevice").errors?.therapyDeviceError
              }}</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div *ngIf="showO2" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>O2 Flow</mat-label>
              <input
                class="iris-input"
                formControlName="daysOxygenFlow"
                inputmode="numeric"
                pattern="[+-]?([0-9]*[.])?[0-9]+"
                digitOnly
                matInput
              />
              <mat-error>Invalid value</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <!-- Fio2 & Mode -->
      <div class="iris-v-row">
        <div class="row-left">
          <div *ngIf="showFio2" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>FiO<sub>2</sub>% </mat-label>
              <input
                (focus)="calculateFio2()"
                placeholder="%"
                class="iris-input"
                formControlName="daysFiO2"
                matInput
              />
              <mat-error>Invalid FiO2</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right">
          <div *ngIf="showMode" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Mode*</mat-label>
              <mat-select
                (selectionChange)="modeChanged()"
                formControlName="daysVentMode"
              >
                <mat-option [value]="'VC'">VC</mat-option>
                <mat-option [value]="'PC'">PC</mat-option>
              </mat-select>
              <mat-error>Invalid mode</mat-error>
            </mat-form-field>
          </div>
          <div *ngIf="showPEEP" class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>PEEP</mat-label>
              <input
                type="number"
                class="iris-input"
                formControlName="daysVentPEEP"
                placeholder="cm H2O"
                matInput
              />
              <mat-error>Invalid value</mat-error>
            </mat-form-field>
          </div>
        </div>
      </div>

      <ng-container *ngIf="ventMode.value">
        <!-- daysVentVt & daysVentPEEP-->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>TV</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentVt"
                  placeholder="ml"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>{{
                  ventMode.value === "VC" ? "PEEP" : "PEEP/EPAP"
                }}</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPEEP"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- RRset & RRmeasured -->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>RR<sub>set</sub></mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentRRset"
                  placeholder="Breaths / min"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>RRmeasured</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  placeholder="Breaths / min"
                  formControlName="daysRR"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- daysVentPinsp & daysVentPsupport-->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Pinsp/IPAP</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPinsp"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>PS</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPsupport"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- daysVentPplat & daysVentPmean-->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>P<sub>plat</sub></mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPplat"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>P<sub>mean</sub></mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPmean"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- daysVentPpeak & daysVentMV-->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>P<sub>peak</sub></mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentPpeak"
                  placeholder="cm H2O"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>MV</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentMV"
                  placeholder="l / min"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <!-- daysVentI & daysVentE-->
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>I</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentI"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>E</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentE"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="iris-v-row">
          <div class="row-left">
            <div *ngIf="showMode" class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Breath sequence</mat-label>
                <mat-select formControlName="daysVentBreathSequence">
                  <mat-option
                    *ngFor="let val of breathSequenceValues"
                    [value]="val"
                  >
                    {{ val }}
                  </mat-option>
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right"></div>
        </div>
      </ng-container>
    </div>

    <div formGroupName="airwayForm" class="airway_form">
      <div class="sub-header">
        <h2>Airway</h2>
      </div>

      <!-- daysVentAirway & airwayOthers -->
      <div class="iris-v-row">
        <div class="row-left">
          <div class="iris-form-row">
            <mat-form-field [floatLabel]="'always'">
              <mat-label>Airway*</mat-label>
              <mat-select
                multiple
                (selectionChange)="airwayChanged()"
                formControlName="daysVentAirway"
              >
                <mat-option
                  *ngFor="let airway of airwayValues"
                  [value]="airway"
                  >{{ airway }}</mat-option
                >
              </mat-select>
              <mat-error>This field is required</mat-error>
            </mat-form-field>
          </div>
        </div>
        <div class="row-right"></div>
      </div>

      <!-- Nasoph settings -->
      <div formGroupName="nasopharyngealAirway" *ngIf="showNasoph">
        <h5>Nasopharyngeal airway</h5>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Oral settings -->
      <div formGroupName="oralAirway" *ngIf="showOral">
        <h5>Oral airway</h5>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Cooks settings -->
      <div formGroupName="biteBlock" *ngIf="showBite">
        <h5>Bite block</h5>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- SupraglotticAirway settings -->
      <div formGroupName="supraglotticAirway" *ngIf="showSupraglotic">
        <h5>Supraglottic airway</h5>
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Type</mat-label>
                <mat-select
                  (selectionChange)="supraGloticChanged()"
                  formControlName="daysSupraglotticAirway"
                >
                  <mat-option
                    *ngFor="let supra of supragloticValues"
                    [value]="supra"
                    >{{ supra }}</mat-option
                  >
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div *ngIf="showSupraOther" class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Other</mat-label>
                <input
                  class="iris-input"
                  formControlName="supraGloticOther"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Cooks settings -->
      <div formGroupName="cooksAirway" *ngIf="showCooks">
        <h5>Cooks airway</h5>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Show oral endo cuff settings -->
      <div formGroupName="oralEndotrachealTube" *ngIf="showOralEndo">
        <h5>Oral endotracheal tube with cuff</h5>

        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuffed</mat-label>
                <mat-select formControlName="daysVentCuff">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Ett tie level</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysEttTieLevel"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>

        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-middle">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuff pressure</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentCuffPressure"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Is closed suction</mat-label>
                <mat-select formControlName="isClosedSuction">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Show nasal endo cuff settings -->
      <div formGroupName="nasalEndotrachealTube" *ngIf="showNasalEndo">
        <h5>Nasal endotracheal tube</h5>
        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuffed</mat-label>
                <mat-select formControlName="daysVentCuff">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Ett tie level</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysEttTieLevel"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-middle">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuff pressure</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentCuffPressure"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Is closed suction</mat-label>
                <mat-select formControlName="isClosedSuction">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>

      <!-- Show tracheostomy cuff settings -->
      <div formGroupName="tracheostomyTube" *ngIf="showTracheostomy">
        <h5>Tracheostomy tube</h5>

        <div class="iris-v-row">
          <div class="row-left">
            <div class="iris-form-row">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuffed</mat-label>
                <mat-select formControlName="daysVentCuff">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>This field is required</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right"></div>
        </div>
        <div class="iris-v-row v-row-three">
          <div class="row-left">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Size</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentTubeSize"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-middle">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Cuff pressure</mat-label>
                <input
                  type="number"
                  class="iris-input"
                  formControlName="daysVentCuffPressure"
                  matInput
                />
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
          <div class="row-right">
            <div class="iris-v-form">
              <mat-form-field [floatLabel]="'always'">
                <mat-label>Is closed suction</mat-label>
                <mat-select formControlName="isClosedSuction">
                  <mat-option [value]="true">Yes</mat-option>
                  <mat-option [value]="false">No</mat-option>
                </mat-select>
                <mat-error>Invalid value</mat-error>
              </mat-form-field>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Date/Time and Submit button-->
    <div class="date-time-row">
      <div class="warning-holder">
        <span class="iris-error" *ngIf="formState.error">
          {{ formState.error || "Server down! Please try after some time." }}
        </span>
      </div>

      <div class="content">
        <app-date-time-picker
          [showTime]="true"
          [showNowButton]="true"
          formControlName="timestamp"
          [minValidDate]="minDate"
          [maxValidDate]="maxDate"
        ></app-date-time-picker>

        <div class="b-holder">
          <button
            (click)="reset()"
            mat-flat-button
            class="button-secondary mr"
            type="button"
          >
            Reset
          </button>
          <button
            [disabled]="!ventsForm.valid || formState.loading"
            mat-flat-button
            class="button-primary"
            type="submit"
          >
            Submit
            <app-loader
              [buttonLoader]="true"
              *ngIf="formState.loadType === 'vents'"
            ></app-loader>
          </button>
        </div>
      </div>
    </div>
  </form>
</section>
