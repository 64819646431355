import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PatientCardComponent } from "./patient-card/patient-card.component";
import { AvatarModule } from "@iris/avatar";
import { MatDividerModule } from "@angular/material/divider";
import { PayorCardComponent } from "./payor-card/payor-card.component";

@NgModule({
  declarations: [PatientCardComponent, PayorCardComponent],
  imports: [CommonModule, AvatarModule, MatDividerModule],
  exports: [PatientCardComponent, PayorCardComponent],
})
export class MedicalCardModule {}
