import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { VitalsRoutingModule } from "./vitals-routing.module";
import { VitalViewComponent } from "./vitals/vital-view/vital-view.component";
import { VitalInputComponent } from "./vitals/vital-input/vital-input.component";
import { VitalsComponent } from "./vitals/vitals.component";
import { VitalDayPipe } from "./pipes/vital-day.pipe";
import { UniqueDayPipe } from "./pipes/unique-day.pipe";
import { FallRiskComponent } from "./vitals/vital-input/fall-risk/fall-risk.component";
import { BradenComponent } from "./vitals/vital-input/braden/braden.component";
// import { ComaComponent } from "./vitals/vital-input/coma/coma.component";
import { PainComponent } from "./vitals/vital-input/pain/pain.component";
import { RassComponent } from "./vitals/vital-input/rass/rass.component";
import { VitalsFormComponent } from "./vitals/vital-input/vitals-form/vitals-form.component";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { FontAwesomeModule } from "@fortawesome/angular-fontawesome";
import { VitalModule } from "../vital/vital.module";
import { IrisComponentsModule } from "../iris-components/iris-components.module";
import { SharedModule } from "../shared/shared.module";
import { VentsComponent } from "./vitals/vital-input/vents/vents.component";
import { AssessmentComponent } from "./assessment/assessment.component";
import { DaySelectorComponent } from "./vitals/day-selector/day-selector.component";
import { AssessmentViewComponent } from "./assessment/assessment-view/assessment-view.component";
import { RemoveBlankEntriesPipeModule } from "../shared-modules/pipes/remove-blank-entries/remove-blank-entries-pipe.module";
import { TrendsModule } from "../shared-modules/components/trends/trends.module";
import { CribComponent } from "./vitals/vital-input/crib/crib.component";
import { ApgarScoreComponent } from "./vitals/vital-input/apgar-score/apgar-score.component";

@NgModule({
  declarations: [
    VitalViewComponent,
    VitalInputComponent,
    VitalsComponent,
    VitalDayPipe,
    UniqueDayPipe,
    FallRiskComponent,
    BradenComponent,
    // ComaComponent,
    PainComponent,
    RassComponent,
    VitalsFormComponent,
    VentsComponent,
    AssessmentComponent,
    DaySelectorComponent,
    AssessmentViewComponent,
    CribComponent,
    ApgarScoreComponent,
  ],
  imports: [
    CommonModule,
    SharedModule,
    IrisComponentsModule,
    VitalsRoutingModule,
    FormsModule,
    ReactiveFormsModule,
    FontAwesomeModule,
    VitalModule,
    RemoveBlankEntriesPipeModule,
    TrendsModule,
  ],
  exports: [
    VitalViewComponent,
    VitalInputComponent,
    VitalsComponent,
    VitalDayPipe,
    UniqueDayPipe,
    FallRiskComponent,
    BradenComponent,
    PainComponent,
    RassComponent,
    VitalsFormComponent,
    VentsComponent,
    AssessmentComponent,
    DaySelectorComponent,
    AssessmentViewComponent,
  ],
})
export class VitalsModule {}
