import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";

import { ZoomPanContainerComponent } from "./components/zoom-pan-container.component";
import { ZoomTransformService } from "./services/zoom-transform.service";

@NgModule({
  declarations: [ZoomPanContainerComponent],
  imports: [CommonModule],
  exports: [ZoomPanContainerComponent],
  providers: [ZoomTransformService],
})
export class ZoomPanContainerModule {}
