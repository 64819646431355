import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { SelectAndInputOthersComponent } from "./components/select-and-input-others/select-and-input-others.component";
import { ReactiveFormsModule } from "@angular/forms";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";

@NgModule({
  declarations: [SelectAndInputOthersComponent],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
  ],
  exports: [SelectAndInputOthersComponent],
})
export class SelectAndInputOthersModule {}
