export interface SearchPatientsPagination {
  page: number;
  size: number;
  total: number;
  count: number;
  patientSearch: "all";
  discharged;
  patients: SearchPatients[];
}

export interface SearchPatients {
  name: string;
  CPMRN: string;
  ICUDischargeDisposition: string;
  MRN: string;
  hospitalName: string;
  ICUDischargeDate: Date;
  encounters: number;
  isCurrentlyAdmitted: boolean;
  age: number;
  sex: string;
  isReAdmitted: boolean;
}
