import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { PopoverComponent } from "./components/popover/popover.component";
import { PopoverDirective } from "./directives/popover.directive";

@NgModule({
  declarations: [PopoverComponent, PopoverDirective],
  imports: [CommonModule],
  exports: [PopoverComponent, PopoverDirective],
})
export class PopoverModule {}
