import { createAction, props } from '@ngrx/store';
import { AuthResendOtpApi, AuthVerifyOtpApi } from '../../../models/auth/Otp.model';

export const clickVerifyOtp = createAction(
  '[Otp Page] Click Verify Otp',
  props<{ data: AuthVerifyOtpApi, password: string }>()
);

export const clickResendOtp = createAction(
  '[Otp Page] Click Resend Otp',
  props<{ data: AuthResendOtpApi }>()
);
