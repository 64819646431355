import { Injectable } from "@angular/core";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Observable, throwError } from "rxjs";
import { catchError, map } from "rxjs/operators";
import { environment } from "src/environments/environment";
import { HospitalList } from "../models/hospital-list";
import { Hospital } from "../models/hospital";
import { WorkSpaces } from "../models/workspace.model";
import { logsPayload } from "../models/patient-list";

@Injectable({
  providedIn: "root",
})
export class HospitalService {
  env = environment;
  constructor(private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  /**
   * @deprecated - Use getNewHospitalsAndPatients instead
   * @param queryParams
   * @returns
   */
  getHospitalsAndPatients(queryParams?: string): Observable<any> {
    return this.http
      .get<HospitalList>(`${this.env.apiUrl}hospitals/hosp_and_patients`, {
        params: {
          orders: queryParams,
        },
      })
      .pipe(catchError(this.errorHandler));
  }

  /**
   * @description This is used to get hospitals and patients in the patient list page
   * @param queryParams
   * @returns
   */
  getNewHospitalsAndPatients(queryParams?: string): Observable<any> {
    return this.http
      .get<HospitalList>(
        `${this.env.devResUrl}hospitals/hosp_and_patients_new`,
        {
          params: {
            orders: queryParams,
          },
        }
      )
      .pipe(catchError(this.errorHandler));
  }

  /**
   * @description This is used to get workspace and it's units
   */
  getAllWorkspaces(): Observable<WorkSpaces> {
    return this.http
      .get<WorkSpaces>(`${this.env.apiUrl}workspace`)
      .pipe(catchError(this.errorHandler));
  }

  getAllHospitals(): Observable<HospitalList> {
    return this.http
      .get<HospitalList>(this.env.apiUrl + "hospitals/")
      .pipe(catchError(this.errorHandler));
  }

  getPublic(): Observable<any> {
    return this.http.get<any>(`${this.env.apiUrl}hospitals`).pipe(
      map((data) => data.hospitals),
      catchError(this.errorHandler)
    );
  }

  // get units
  getAllUnits() {
    return this.http
      .get(this.env.apiUrl + "units/")
      .pipe(catchError(this.errorHandler));
  }

  logAPI = "https://log-api.newrelic.com/log/v1";
  logs(payload: logsPayload) {
    if (!this.env.production) return;

    const logHttpOptions = {
      headers: new HttpHeaders({
        "Api-Key": this.env["logApiKey"],
      }),
    };
    return this.http.post(this.logAPI, payload, logHttpOptions);
  }
}
