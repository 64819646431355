import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { CarouselComponent } from "./components/carousel/carousel.component";
import { ZoomPanContainerModule } from "../zoom-pan-container/zoom-pan-container.module";

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, ZoomPanContainerModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
