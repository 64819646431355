import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
import { catchError } from "rxjs/operators";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
} from "@angular/common/http";
import { Subject, throwError } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class VitalsService {
  env = environment;

  public netraImage = new Subject<any>();
  triggerNetraImage(day) {
    this.netraImage.next(day);
  }

  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private _http: HttpClient) {}

  getVitals(CPMRN, encounters) {
    return this._http
      .get(this.env.apiUrl + "vitals/" + CPMRN + "/" + encounters)
      .pipe(catchError(this.errorHandler));
  }

  updateVitals(CPMRN, encounters, vitals) {
    return this._http
      .patch(
        this.env.apiUrl + "v1/vitals/" + CPMRN + "/" + encounters,
        vitals,
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  getVitalImage(key: string) {
    return this._http.post(
      `${this.env.apiUrl}vitals/get_vital_image`,
      { fileName: key },
      this.httpOptions
    );
  }
}
