import {
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  OnInit,
  QueryList,
  ViewChildren,
} from "@angular/core";

/** To set the type of a section of progress bar*/

export const PROGRESS_BAR_TYPE = {
  PROGRESS: "cp-progress",
  PAUSED: "paused",
  STOPPED: "stopped",
};

export interface CpProgressBarSection {
  /** Type could be either Progress, Paused or Stopped.
   *   Type value should be set from the constant PROGRESS_BAR_TYPE
   */

  type: string;

  /** The starting point value of a section of progress bar */

  minVal: number;

  /** The end point value of a section of progress bar */

  maxVal: number;

  /** Any additional text to display  */

  text?: string;

  /** Alignment of text. Its value could be 'right', 'center' or 'left' */

  align?: string;

  /** Content to display in the popover. */

  popoverContent?: string;
}

@Component({
  selector: "app-progress-bar",
  templateUrl: "./progress-bar.component.html",
  styleUrls: ["./progress-bar.component.scss"],
})
export class ProgressBarComponent implements OnInit, AfterViewInit {
  @ViewChildren("bar") bar: QueryList<ElementRef>;

  @Input() progressBarArr: CpProgressBarSection[];

  private totalVal: number;

  constructor() {}

  ngOnInit(): void {
    this.findTotalVal();
  }

  ngAfterViewInit() {
    this.setWidthRangeOfBars();
  }

  /** To find the total length of progress bar */

  findTotalVal() {
    const minvalue = this.progressBarArr?.reduce(
      (min, pb) => (pb.minVal < min ? pb.minVal : min),
      this.progressBarArr[0].minVal
    );
    const maxValue = this.progressBarArr?.reduce(
      (max, pb) => (pb.maxVal > max ? pb.maxVal : max),
      this.progressBarArr[0].maxVal
    );

    this.totalVal = maxValue - minvalue;
  }

  /** To set the width of each section of progress bar */

  setWidthRangeOfBars() {
    const barArr = this.bar.toArray();

    barArr[0].nativeElement.style.borderRadius = "5px 0 0 5px";
    barArr[barArr.length - 1].nativeElement.style.borderRadius = "0 5px 5px 0";

    this.progressBarArr?.forEach((bar, index) => {
      const width = ((bar.maxVal - bar.minVal) / this.totalVal) * 100;
      barArr[index].nativeElement.style.width = width + "%";
    });
  }
}
