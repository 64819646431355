import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  ViewChild,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";
import { DomSanitizer } from "@angular/platform-browser";
import { AlertService } from "@iris/alert";
import { FileObj } from "@iris/carousel";
import {
  FilePreviewInterface,
  FilePreviewModalService,
} from "@iris/file-preview-modal";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";
import { FileService } from "src/app/services/file.service";
import { FileType, UploadDataInterface } from "../../models/upload-file.model";
import { FileTypeEnum } from "../../utils/fileType.util";

@Component({
  selector: "cp-upload-file",
  templateUrl: "./upload-file.component.html",
  styleUrls: ["./upload-file.component.scss"],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: UploadFileComponent,
      multi: true,
    },
    FilePreviewModalService,
  ],
})
export class UploadFileComponent implements OnDestroy, ControlValueAccessor {
  filePreview: FilePreviewInterface[] = [];
  uploadedFile: string | ArrayBuffer;
  fileName: string = "";
  isDisabled: boolean;
  constructor(
    private _fileService: FileService,
    private _alertService: AlertService,
    private _cdr: ChangeDetectorRef,
    private _filePreviewModalService: FilePreviewModalService,
    private sanitisor: DomSanitizer
  ) {}

  public key: string = null;

  public photoModal = true;

  @Input("file") file: string = null;

  @Input("getFileLoader") getFileLoader: boolean = false;

  @Input("withFileName") withFileName: boolean = false;

  @Input("isInlineForm") isInlineForm: boolean = false;

  public fileTypeEnum = FileTypeEnum;

  @Input("fileType") fileType: FileType = this.fileTypeEnum.IMAGE;

  @ViewChild("fileInput") fileInput: ElementRef;

  public isResetFile: boolean = false;

  get fileSrc(): string | ArrayBuffer {
    if (this.isResetFile) return null;
    return this.uploadedFile || this.file;
  }

  get loader(): boolean {
    return this.getFileLoader || this.uploadLoader;
  }

  public error: boolean = false;

  onChange = (_: any) => {};
  onTouched = () => {};

  public uploadLoader: boolean = false;

  writeValue(obj: UploadDataInterface | string): void {
    if (!obj) {
      this.uploadedFile = null;
    }
    if (!obj && this.fileSrc && !this.file) {
      this.removeFile();
    } else {
      this.isResetFile = false;
    }
    if (this.withFileName) {
      this.key = (obj as UploadDataInterface)?.key;
      this.fileName = (obj as UploadDataInterface)?.fileName;
    } else {
      this.key = obj as string;
    }
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState(isDisabled: boolean) {
    this.isDisabled = isDisabled;
  }
  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  private unsubscribe$ = new Subject<any>();

  @Input("label") label: string = "Upload an file. Max size 2 MB";

  public onContainerDrop(event) {
    const file = event?.dataTransfer?.files[0];
    if (!file) return;
    this.onUpload(file);
  }

  onUpload(file) {
    if (!file) return;
    try {
      this.uploadLoader = true;
      if (file.size > 2097152) {
        throw new Error("File size limit exceeded");
      }
      let reader = new FileReader();
      this.onTouched();
      reader.readAsDataURL(file);

      reader.onload = async (_event) => {
        this.uploadedFile = await reader.result;

        this._fileService
          .getSignedUrlPrivate(file.type)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            (res) => {
              this.uploadFile(file, res?.signedUrl, res?.key);
            },
            (_) => {
              this.error = true;
            }
          );
      };
    } catch (err) {
      this.uploadLoader = false;
      const nativeElement = this.fileInput.nativeElement;
      nativeElement.value = null;
      this._alertService.showNotification({
        type: "Error",
        message: err,
      });
    }
  }

  public changeFile() {
    this.fileInput.nativeElement.click();
  }

  public removeFile() {
    this.fileInput.nativeElement.value = null;
    this.isResetFile = true;
    this.onChange(null);
  }

  uploadFile(file, signerUrl: string, key: string) {
    this._fileService
      .uploadFile(file, signerUrl, file?.type)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        (data) => {
          if (data?.url) {
            this.isResetFile = false;
            this.uploadLoader = false;
            this.fileName = file.name;
            const emitData: UploadDataInterface | string = this.withFileName
              ? {
                  key,
                  fileName: this.fileName,
                }
              : key;
            this.onChange(emitData);
            this._cdr.detectChanges();
          }
        },
        (_) => {
          this.error = true;
        }
      );
  }

  openPdf(): void {
    let previewObj: FilePreviewInterface = {
      fileName: this.fileName,
      url: this.fileSrc,
    };
    this.filePreview = [previewObj];
    this._filePreviewModalService.openFile();
  }

  // missing listener which detects that something was dragged into window area
  @HostListener("window:dragenter", ["$event"])
  onWindowDragEnter(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("dragover", ["$event"])
  onDragOver(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("dragleave", ["$event"])
  public onDragLeave(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }

  @HostListener("drop", ["$event"])
  public onDrop(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
