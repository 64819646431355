<button
  mat-flat-button
  type="button"
  class="button-secondary"
  (click)="openAppointment()"
>
  {{ isEdit ? "Edit schedule" : "Add schedule" }}
</button>

<ng-template #scheduleAppointment>
  <section
    class="iris-modal-container iris-modal-container--no-overflow form-container"
  >
    <div class="iris-form">
      <div class="form-title">
        New appointment schedule
        <mat-icon
          class="form-title__close cursor-pointer cp-icon"
          (click)="closeModal()"
          >close</mat-icon
        >
      </div>
      <div class="form-body form-body-overflow form-body--no-border">
        <ng-container
          *ngFor="
            let scheduleControl of scheduleFormList.controls;
            let i = index
          "
        >
          <ng-container [formGroup]="scheduleControl">
            <div class="iris-v-row">
              <div class="row-left">
                <div class="iris-form-row">
                  <mat-form-field [floatLabel]="'always'">
                    <mat-label>Department*:</mat-label>
                    <mat-select formControlName="department">
                      <ng-container *ngFor="let department of selectedDeps">
                        <mat-option
                          [disabled]="departmentDisabled(i, department)"
                          [value]="department"
                        >
                          {{ department }}
                        </mat-option>
                      </ng-container>
                    </mat-select>
                  </mat-form-field>
                </div>
              </div>
              <div class="row-right">
                <div class="iris-form-row">
                  <mat-form-field [floatLabel]="'always'">
                    <mat-label>Appointment duration*:</mat-label>
                    <cp-appointment-duration
                      formControlName="duration"
                    ></cp-appointment-duration>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <div class="form-body__checkbox cp-mb-2">
              <mat-checkbox
                formControlName="allowMultipleAppointmentsInSameSlot"
                >Allow multiple bookings in the same slot</mat-checkbox
              >
            </div>
            <mat-divider></mat-divider>
            <div class="iris-v-row cp-mt-2">
              <div class="row-left">
                <div class="iris-form-row">
                  <div class="form-body__margin">
                    <span>Availability:</span>
                    <ng-container
                      *ngFor="let day of weekDays; let dayIndex = index"
                    >
                      <div>
                        <ng-container
                          *ngFor="
                            let daycontrol of dayForm(scheduleControl, day)
                              .controls;
                            let i = index
                          "
                        >
                          <div class="display-flex justify-content-between">
                            <cp-time-schedule
                              (addNew)="
                                addNewTime(dayForm(scheduleControl, day))
                              "
                              [showDelete]="i > 0"
                              [index]="i"
                              (delete)="
                                delete(dayForm(scheduleControl, day), i)
                              "
                              [label]="(i == 0 ? day : '') | titlecase"
                              [formGroup]="daycontrol"
                            ></cp-time-schedule>
                            <ng-container *ngIf="i == 0 && dayIndex == 0">
                              <button
                                mat-button
                                class="cp-mt-1 display-flex align-items-center"
                                (click)="addToAllSchedule(scheduleControl)"
                              >
                                <mat-icon class="primary"
                                  >content_copy</mat-icon
                                >
                                <span class="cp-ml-1 primary cp-text-normal"
                                  >Copy to all</span
                                >
                              </button>
                            </ng-container>
                          </div>
                        </ng-container>
                      </div>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="iris-v-row cp-mt-2">
              <div class="row-left">
                <div class="iris-form-row">
                  <mat-form-field floatLabel="always">
                    <mat-label>Start date*:</mat-label>
                    <cp-date-picker
                      formControlName="start"
                      [min]="activeFrom"
                      [max]="
                        maxStartDate(scheduleControl.get('end').value) ||
                        maxStartDate(activeTo)
                      "
                    ></cp-date-picker>
                    <mat-error>Mandatory field</mat-error>
                  </mat-form-field>
                </div>
              </div>
              <div class="row-right">
                <div class="iris-form-row">
                  <mat-form-field floatLabel="always">
                    <mat-label>End date*:</mat-label>
                    <cp-date-picker
                      formControlName="end"
                      [min]="
                        minEndDate(scheduleControl.get('start').value) ||
                        minEndDate(activeFrom)
                      "
                      [max]="activeTo"
                    ></cp-date-picker>
                    <mat-error>Mandatory field</mat-error>
                  </mat-form-field>
                </div>
              </div>
            </div>
            <mat-divider></mat-divider>
            <div class="iris-v-row cp-mt-2">
              <div class="row-left">
                <div class="iris-form-row">
                  <div>
                    <span>Consultation charge:</span>
                    <ng-container
                      *ngFor="
                        let consultationControl of consultation(scheduleControl)
                          .controls;
                        let i = index
                      "
                    >
                      <cp-consultation-charge
                        [diableOptions]="
                          getAllConsultation(
                            consultation(scheduleControl).controls
                          )
                        "
                        class="cp-form-field"
                        (addNew)="addNewCharge(consultation(scheduleControl))"
                        [showDelete]="i > 0"
                        (delete)="delete(consultation(scheduleControl), i)"
                        [formControl]="consultationControl"
                      ></cp-consultation-charge>
                    </ng-container>
                  </div>
                </div>
              </div>
            </div>
            <div class="form-body__margin">
              <div
                class="display-flex justify-content-between align-items-center"
              >
                <span
                  class="primary cp-text-normal cursor-pointer mt"
                  (click)="addNewSchedule()"
                >
                  <ng-container
                    *ngIf="i == scheduleFormList.controls?.length - 1"
                  >
                    Add another schedule
                  </ng-container>
                </span>
                <mat-icon
                  *ngIf="scheduleFormList.controls?.length > 1"
                  class="cp-icon cursor-pointer"
                  (click)="delete(scheduleFormList, i)"
                  >delete</mat-icon
                >
              </div>
            </div>
          </ng-container>
          <mat-divider
            *ngIf="
              scheduleFormList.controls?.length > 1 &&
              i != scheduleFormList.controls?.length - 1
            "
            class="cp-form-divider form-body__margin"
          ></mat-divider>
        </ng-container>
      </div>
    </div>
    <div class="form-footer">
      <button
        class="button-secondary"
        type="button"
        mat-flat-button
        (click)="closeModal()"
      >
        Cancel
      </button>
      <button
        type="submit"
        class="button-primary"
        [disabled]="scheduleFormList.invalid"
        (click)="saveData()"
        mat-flat-button
      >
        Save
      </button>
    </div>
  </section>
</ng-template>
