import { Component, OnDestroy, OnInit } from "@angular/core";
import {
  UntypedFormBuilder,
  Validators,
  UntypedFormGroup,
  AbstractControl,
} from "@angular/forms";
import { AuthService } from "../services/auth.service";
import { CustomValidators } from "../iris-components/validators/custom-validators";
import {
  faCheckCircle,
  faEye,
  faEyeSlash,
  faTimesCircle,
} from "@fortawesome/free-solid-svg-icons";
import * as fromAuth from "src/app/store/actions/auth.actions";
import { Store } from "@ngrx/store";
import { ValidatorsService } from "../management/services/validators.service";
import { AlertService } from "@iris/alert/services/alert.service";
import { MatDialogRef } from "@angular/material/dialog";
import { logout } from "src/app/store/actions/root.actions";

@Component({
  selector: "app-change-password",
  templateUrl: "./change-password.component.html",
  styleUrls: ["./change-password.component.scss"],
})
export class ChangePasswordComponent implements OnInit, OnDestroy {
  public changePasswordForm: UntypedFormGroup;
  public errorFound: string;
  public showPasswordC = false;
  public showPasswordN = false;
  public showPasswordU = false;

  formLoading: boolean = false;

  /* ICONS */
  faEye = faEye;
  faEyeSlash = faEyeSlash;
  faTimesCircle = faTimesCircle;
  faCheckCircle = faCheckCircle;

  constructor(
    public dialogRef: MatDialogRef<ChangePasswordComponent>,
    private _fb: UntypedFormBuilder,
    private _auth: AuthService,
    private store: Store<{}>,
    public validatorService: ValidatorsService,
    private alertService: AlertService
  ) {
    this.changePasswordForm = this.createPassword();
  }

  valueChange$;
  ngOnInit(): void {
    this.valueChange$ = this.changePasswordForm.valueChanges.subscribe((x) => {
      if (this.errorFound) {
        this.errorFound = null;
      }
    });
  }

  get newPass(): AbstractControl {
    return this.changePasswordForm.get("newPass") as AbstractControl;
  }

  createPassword(): UntypedFormGroup {
    return this._fb.group(
      {
        currPass: [null, Validators.required],
        newPass: [
          null,
          Validators.compose([
            Validators.required,
            // check whether the entered password has a number
            CustomValidators.patternValidator(/\d/, {
              hasNumber: true,
            }),
            // check whether the entered password has upper case letter
            CustomValidators.patternValidator(/[A-Z]/, {
              hasCapitalCase: true,
            }),
            // check whether the entered password has a lower case letter
            CustomValidators.patternValidator(/[a-z]/, {
              hasSmallCase: true,
            }),
            // check whether the entered password has a special character
            CustomValidators.patternValidator(
              /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/,
              {
                hasSpecialCharacters: true,
              }
            ),
            Validators.minLength(8),
          ]),
        ],
        confNewPass: [null, Validators.required],
      },
      {
        // check whether our password and confirm password match
        validator: [
          CustomValidators.isOldPassword,
          CustomValidators.passwordMatchValidator,
        ],
      }
    );
  }

  submitChange(passwordInfo) {
    this.formLoading = true;

    let email = JSON.parse(localStorage.getItem("currentUser"))["email"];

    let dataToSend = {
      email: email,
      currPass: this._auth.encryptPassword(passwordInfo.currPass),
      newPass: this._auth.encryptPassword(passwordInfo.newPass),
      confNewPass: this._auth.encryptPassword(passwordInfo.confNewPass),
    };

    this._auth.changePassword(dataToSend).subscribe(
      (data) => {
        this.formLoading = false;

        if (data?.data?.wikiJwt) {
          this.store.dispatch(
            fromAuth.wikiSetJwt({
              jwt: data?.data?.wikiJwt,
              roles: data?.data?.allowedRoles,
            })
          );
        }

        this.dialogRef.close();
        this.alertService.showNotification({
          type: "Success",
          message: "Password updated!",
        });

        // logout user
        setTimeout(() => {
          this.store.dispatch(logout());
        }, 500);
      },
      (err) => {
        this.formLoading = false;
        this.errorFound = err?.error?.message || "Server Error";
      }
    );
  }

  ngOnDestroy(): void {
    this.valueChange$.unsubscribe();
  }
}
