<div class="patient-card bg-white">
  <div class="display-flex align-items-center justify-content-start">
    <app-avatar [avatarParams]="profilePic"></app-avatar>
    <div class="ml">
      <span class="cp-label-text cp-text-bold">{{ name || "-" }} </span>
      <div>
        <ng-container *ngIf="age">{{ age }}, </ng-container>
        {{ sex }}
      </div>
      <span *ngIf="isEdit" class="primary-text cursor-pointer" (click)="edit()"
        >Edit</span
      >
    </div>
  </div>
  <mat-divider class="inline-flex" [vertical]="true"></mat-divider>
  <div
    *ngIf="isSectionVisible(PatientInfoSection.Patient)"
    class="patient-card--sections"
  >
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">MRN:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ mrn || "-" }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">CPMRN:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ cpmrn || "-" }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Admit date:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ "-" }}</span>
    </div>
  </div>
  <mat-divider
    *ngIf="isSectionVisible(PatientInfoSection.Visit)"
    class="inline-flex"
    [vertical]="true"
  ></mat-divider>
  <div
    *ngIf="isSectionVisible(PatientInfoSection.Visit)"
    class="patient-card--sections"
  >
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal">Visit:</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ "-" }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Visit ID:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ "-" }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Visit date/time:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ "-" }}</span>
    </div>
    <div>
      <span class="cp-small-font cp-color-normal cp-text-normal"
        >Care provider:</span
      >
    </div>
    <div>
      <span class="cp-small-font cp-color-normal">{{ "-" }}</span>
    </div>
  </div>
  <mat-divider
    *ngIf="isSectionVisible(PatientInfoSection.PatientContact)"
    class="inline-flex"
    [vertical]="true"
  ></mat-divider>
  <div
    *ngIf="isSectionVisible(PatientInfoSection.PatientContact)"
    class="patient-card--sections"
  >
    <div class="cp-small-font cp-color-normal cp-text-normal">Address</div>
    <div
      class="cp-small-font cp-color-normal cp-text-overflow cp-text-overflow--line-2"
    >
      {{ address?.line1 ? address?.line1 + ", " : "" }}
      {{ address?.line2 ? address?.line2 + ", " : "" }}
      {{ address?.pincode ? address?.pincode + ", " : "" }}
      {{ address?.city ? address?.city + ", " : "" }}
      {{ address?.type ? address?.type : "" }}
      {{ addressEmptyString }}
    </div>
    <div class="cp-small-font cp-color-normal cp-text-normal">Phone number</div>
    <div class="cp-small-font cp-color-normal">{{ phone || "-" }}</div>
  </div>
</div>
