<input
  type="text"
  placeholder="hh:mm"
  aria-label="Number"
  (click)="$event.target.select()"
  (input)="changeInput()"
  (focus)="inputFocus = true; inputTouched()"
  matInput
  class="cp-form-input"
  [ngClass]="{ 'cp-form-input--custom-field': isCustom }"
  [matAutocomplete]="time"
  [formControl]="timeControl"
/>
<mat-autocomplete autoActiveFirstOption #time="matAutocomplete">
  <ng-container *ngIf="inputFocus">
    <mat-option
      *ngFor="let option of filterTime | async"
      [value]="option"
      (click)="changeInput()"
    >
      {{ option }}
    </mat-option>
  </ng-container>
</mat-autocomplete>
