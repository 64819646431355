import { NgModule } from "@angular/core";
import { RouterModule, Routes } from "@angular/router";

import { AuthGuard } from "./auth.guard";
import { AuditComponent } from "./audit/audit.component";
import { PatientComponent } from "./patient/patient.component";
import { OrdersComponent } from "./patient/orders/orders.component";
import { SummaryComponent } from "./patient/summary/summary.component";
import { PatientsListComponent } from "./patient-list/patients-list/patients-list.component";
import { PageNotFoundComponent } from "./page-not-found/page-not-found.component";
import { IntakeOutputComponent } from "./patient/intake-output/intake-output.component";
// import { NurseDataComponent } from "./auditCharts/nurse-data/nurse-data.component";
// import { HospitalAuditComponent } from "./auditCharts/hospital-audit/hospital-audit.component";
import { NotificationResolver } from "src/app/resolvers/notification.resolver";
import { AdmitFormResolver } from "./admit-form/resolvers/admit-form.resolver";
import { IrisDesignSystemComponent } from "./iris-components/iris-design-system/iris-design-system.component";
import { ShiftComponent } from "./patient-list/shift/shift.component";

const routes: Routes = [
  {
    path: "",
    redirectTo: "/login",
    pathMatch: "full",
  },
  {
    path: "login",
    loadChildren: () =>
      import("./get-started/get-started.module").then(
        (mod) => mod.GetStartedModule
      ),
  },
  // { path: '', redirectTo: '/login', pathMatch: 'full' },
  // { path: 'login', component: LoginComponent },
  // It is commented since system admin should and only create a user.
  // { path: 'register', component: RegisterComponent, resolve: { hospitals: RegisterResolverService } },
  {
    path: "patient/:patientCPMRN/:patientEncounters",
    component: PatientComponent,
    canActivate: [AuthGuard],
    children: [
      { path: "", redirectTo: "summary", pathMatch: "full" },
      {
        path: "entry",
        loadChildren: () =>
          import("./vitals/vitals.module").then((mod) => mod.VitalsModule),
      },
      //TODO - DO NOT DELETE THE BELOW ROUTE AS ITS FUNCTIONLAITY IS UNKNOWN, DISABLING IT FOR NOW
      // {
      //   path: "vitals-monitor-vision",
      //   component: VitalsVisionMonitorComponent,
      //   resolve: { notifications: NotificationResolver },
      // },
      { path: "orders", component: OrdersComponent },
      {
        path: "mar",
        loadChildren: () =>
          import("./mar/mar.module").then((mod) => mod.MarModule),
      },
      { path: "intake-output", component: IntakeOutputComponent },
      // { path: 'documents', component: DocumentsComponent },
      {
        path: "notes",
        loadChildren: () =>
          import("./note-module/note.module").then((mod) => mod.NoteModule),
      },
      {
        path: "labs",
        loadChildren: () =>
          import("./labs-scans-module/labs-scans.module").then(
            (mod) => mod.LabsScansModule
          ),
      },
      { path: "summary", component: SummaryComponent },
      // { path: "notebook", component: NotebookPageComponent },
    ],
  },
  {
    path: "patientlist",
    canActivate: [AuthGuard],
    resolve: { notifications: NotificationResolver },
    loadChildren: () =>
      import("./patient-list/patient-list.module").then(
        (mod) => mod.PatientListModule
      ),
  },
  {
    path: "shiftAssign",
    component: ShiftComponent,
    canActivate: [AuthGuard],
    resolve: { notifications: NotificationResolver },
  },
  {
    path: "admitPatient",
    canActivate: [AuthGuard],
    resolve: { hospitals: AdmitFormResolver },
    loadChildren: () =>
      import("./admit-form/admit-form.module").then(
        (mod) => mod.AdmitFormModule
      ),
  },
  // {
  //   path: 'hospital-admin',
  //   component: HospAdminComponent,
  //   canActivate: [AuthGuard],
  //   resolve: { hospitals: ManageUsersResolverService },
  // },
  {
    path: "audit",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./audit/audit.module").then((mod) => mod.AuditModule),
  },
  // {
  //   path: "audit/nurse-audit",
  //   component: NurseDataComponent,
  //   canActivate: [AuthGuard],
  // },
  // {
  //   path: "audit/hospital-audit",
  //   component: HospitalAuditComponent,
  //   canActivate: [AuthGuard],
  // },
  {
    path: "iris-design-system",
    component: IrisDesignSystemComponent,
  },
  {
    path: "management",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./management/management.module").then(
        (mod) => mod.ManagementModule
      ),
  },
  {
    path: "billing",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./billing-admin/billing-admin.module").then(
        (mod) => mod.BillingAdminModule
      ),
  },
  {
    path: "tv",
    canActivate: [AuthGuard],
    loadChildren: () => import("./tv/tv.module").then((mod) => mod.TvModule),
  },
  {
    path: "med-admin",
    canActivate: [AuthGuard],
    loadChildren: () =>
      import("./med-admin/med-admin.module").then((mod) => mod.MedAdminModule),
  },
  {
    path: "smart-hospital",
    loadChildren: () =>
      import("./smart-hospital/smart-hospital.module").then(
        (mod) => mod.SmartHospitalModule
      ),
  },
  { path: "**", component: PageNotFoundComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}

export const routingComponents = [
  PatientComponent,
  OrdersComponent,
  // PatientsListComponent,
  PageNotFoundComponent,
  IntakeOutputComponent,
  AuditComponent,
];
