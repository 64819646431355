import { Injectable } from "@angular/core";
import { Subject } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class FilePreviewModalService {
  constructor() {}

  public openFileSubject = new Subject();
  public openFile$ = this.openFileSubject.asObservable();

  public openFile() {
    this.openFileSubject.next();
  }
}
