import { createReducer, on, Action } from "@ngrx/store";

import * as notesAction from "src/app/store/actions/patient-chart/notes/notes.actions";
import * as rootActions from 'src/app/store/actions/root.actions';

export interface State {
    indicator: string;
    noteType: string;
}

export const initialState: State = {
    indicator: null,
    noteType: null
}

const noteViewReducer = createReducer(
    initialState,
    on(
        notesAction.setHighlight,
        (state, {indicator, noteType}) => ({...state, indicator: indicator, noteType: noteType})
    ),
    on(
        notesAction.resetHighlight,
        (state) => (initialState)
    ),
    on(
        rootActions.logout,
        (state) => (initialState)
    ),
    on(
        rootActions.patientChartReset,
        (state) => (initialState)
    )
)

export function reducer(state: State | undefined, action: Action) {
    return noteViewReducer(state, action);
}