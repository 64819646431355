import { createReducer, on, Action } from "@ngrx/store";
import * as rootActions from "src/app/store/actions/root.actions";
import { PatientMonitorSocketActions } from "../../../../patient-monitor/actions";
import * as fromPatChartActions from "src/app/store/actions/patient-chart/patient-header/patient-data.actions";
import {
  GestationAgeObj,
  PatientType,
  PatientWeightHistory,
  WeightObj,
} from "src/app/models/patient";

export interface State {
  BMI: null;
  CPMRN: null;
  IBW: null;
  ICUAdmitDate: null;
  MRN: null;
  PCP: null;
  admitList: [];
  age: null;
  allergies: null;
  bedNo: null;
  bloodGroup: null;
  camera: null;
  chronic: [];
  code: null;
  diagnoses: null;
  dob: null;
  encounters: null;
  height: null;
  heightUnit: null;
  hospitalInfo: {};
  hospitalLogo: null;
  hospitalName: null;
  unitName: null;
  immune: [];
  isCurrentlyAdmitted: null;
  markedToWriteNotes: false;
  isGcs: null;
  isIntubated: {};
  isNIV: {};
  isPressor: null;
  isTrach: {};
  name: null;
  operativeStatus: null;
  pastMedicalHistory: null;
  patientImage: null;
  phone: null;
  severity: null;
  covid: string | null;
  sex: null;
  weight: null;
  weightUnit: null;
  weightObj: WeightObj;
  birthWeightObj: WeightObj;
  qsofa: number;
  _id: null;
  isolation?: {
    precautionType?: string | null;
    reason?: string | null;
  };
  ICUDischargeDate: Date | null;
  rox: {};
  patientMonitorId: null | string;
  createdBy: string;
  editedBy: string;
  patientType: PatientType;
  weightHistory: [PatientWeightHistory];
  gestationAge: GestationAgeObj;
}

export const initialState: State = {
  BMI: null,
  CPMRN: null,
  IBW: null,
  ICUAdmitDate: null,
  MRN: null,
  PCP: null,
  admitList: [],
  age: null,
  allergies: null,
  bedNo: null,
  bloodGroup: null,
  camera: null,
  chronic: [],
  code: null,
  diagnoses: null,
  dob: null,
  encounters: null,
  height: null,
  heightUnit: null,
  hospitalInfo: {},
  hospitalLogo: null,
  hospitalName: null,
  unitName: null,
  immune: [],
  isCurrentlyAdmitted: null,
  markedToWriteNotes: false,
  isGcs: null,
  isIntubated: {},
  isNIV: {},
  isPressor: null,
  isTrach: {},
  name: null,
  operativeStatus: null,
  pastMedicalHistory: null,
  patientImage: null,
  phone: null,
  severity: null,
  covid: null,
  sex: null,
  weight: null,
  weightUnit: null,
  qsofa: null,
  _id: null,
  ICUDischargeDate: null,
  rox: {},
  isolation: {
    precautionType: null,
    reason: null,
  },
  patientMonitorId: null,
  createdBy: null,
  editedBy: null,
  patientType: null,
  weightObj: null,
  birthWeightObj: null,
  weightHistory: null,
  gestationAge: null,
};

const patHeaderReducer = createReducer(
  initialState,
  on(fromPatChartActions.setPatient, (state, { patient }) => ({
    ...state,
    ...patient,
  })),
  on(fromPatChartActions.amendPatient, (state, { patient }) => ({
    ...initialState,
    ...patient,
  })),
  on(fromPatChartActions.resetPatient, (state) => initialState),
  on(fromPatChartActions.setSeverity, (state, { severity }) => ({
    ...state,
    severity: severity,
  })),
  on(fromPatChartActions.setCovid, (state, { covid }) => ({ ...state, covid })),
  on(rootActions.logout, (state) => initialState),
  on(rootActions.patientChartReset, (state) => initialState),
  on(PatientMonitorSocketActions.deletePatientMonitor, (state, { data }) => ({
    ...state,
    patientMonitorId: null,
  }))
);

export function reducer(state: State | undefined, action: Action) {
  return patHeaderReducer(state, action);
}

export const getICUDischargeDate = (state: State) => state.ICUDischargeDate;
