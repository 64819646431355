export const timeRangeValidator = (fromTime, toTime) => {
  const [fromHour, fromMinute] = fromTime.split(":");
  const [toHour, toMinute] = toTime.split(":");
  const fromHourInt = parseInt(fromHour),
    fromMinuteInt = parseInt(fromMinute),
    toHourInt = parseInt(toHour),
    toMinuteInt = parseInt(toMinute);
  const isInvalid = !(fromHour && fromMinute && toHour && toMinute);
  if (isInvalid) return;
  return fromHourInt * 60 + fromMinuteInt > toHourInt * 60 + toMinuteInt;
};
