<div
  class="cp-date-picker-comp"
  (focusout)="onFocusOut($event)"
  (focusin)="onFocusIn()"
>
  <input
    matInput
    #date
    class="date-field"
    readonly
    [min]="min"
    [max]="max"
    [formControl]="datePicker"
    [matDatepicker]="fromPicker"
    [placeholder]="placeholder"
  />
  <div class="cp-date-picker-comp__button">
    <mat-datepicker-toggle class="calenderIcon" matPrefix [for]="fromPicker">
      <mat-icon matDatepickerToggleIcon class="cp-icon"
        >calendar_today</mat-icon
      >
    </mat-datepicker-toggle>
  </div>
  <mat-datepicker
    #fromPicker
    (opened)="calendarOpen()"
    (closed)="calendarClose()"
  ></mat-datepicker>
</div>
