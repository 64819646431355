<div class="cp-time-schedule-comp">
  <div class="display-flex align-items-center">
    <ng-container [formGroup]="timeForm">
      <span class="cp-time-schedule-comp__label mt">{{ label }}</span>
      <cp-time-picker
        class="cp-form-field"
        [ngClass]="{ 'cp-invalid-input': invalidControl }"
        formControlName="from"
        [min]="previousTo?.value"
        [max]="timeForm?.get('to')?.value"
      ></cp-time-picker>
      <span class="ml mr mt">-</span>
      <cp-time-picker
        class="cp-form-field"
        [ngClass]="{ 'cp-invalid-input': invalidControl }"
        formControlName="to"
        [min]="timeForm?.get('from')?.value"
        [max]="nextFrom?.value"
      ></cp-time-picker>
    </ng-container>
    <div
      class="cp-time-schedule-comp__button cursor-pointer mt"
      #addButton
      (click)="addNewScedule()"
    >
      <mat-icon
        matDatepickerToggleIcon
        svgIcon="cp-add"
        aria-hidden="false"
        aria-label="add icon"
      >
      </mat-icon>
    </div>
    <div
      (click)="deleteSchedule()"
      class="cursor-pointer display-flex mt"
      *ngIf="showDelete"
    >
      <mat-icon class="cp-icon">delete</mat-icon>
    </div>
  </div>
</div>
