<div class="iris-v-row">
  <div class="row-left iris-form-row">
    <mat-form-field>
      <mat-label>{{ selectLabelName }}:</mat-label>
      <mat-select [formControl]="selectFormControl">
        <mat-option [value]="option" *ngFor="let option of dropdownList">
          {{ option }}
        </mat-option>
      </mat-select>
      <span
        class="error-message"
        *ngIf="
          selectFormControl?.touched &&
          selectFormControl?.hasError('selectError')
        "
      >
        {{ selectFormControl?.getError("selectError") }}
      </span>
    </mat-form-field>
  </div>
  <div
    class="row-right iris-form-row"
    *ngIf="otherInputPosition === otherInputPositionEnum.Right"
  >
    <ng-container [ngTemplateOutlet]="othersInputBlock"></ng-container>
  </div>
</div>

<div
  class="iris-v-row iris-form-row"
  *ngIf="otherInputPosition === otherInputPositionEnum.Bottom"
>
  <ng-container [ngTemplateOutlet]="othersInputBlock"></ng-container>
</div>

<ng-template #othersInputBlock>
  <mat-form-field *ngIf="othersInput.enabled">
    <mat-label>Others:</mat-label>
    <input type="text" matInput [formControl]="othersInput" />
    <span
      class="error-message"
      *ngIf="othersInput?.touched && selectFormControl?.hasError('otherError')"
    >
      {{ selectFormControl?.getError("otherError") }}
    </span>
  </mat-form-field>
</ng-template>
