/**
 * @description Used to set the value of a form field based on the inputs
 * @param {AbstractControl} formType - Defines which form to use
 * @param {string} controlName - Defines the control name to edit
 * @param {any} value - Defines the value to set
 * @author Suraj Shenoy
 * @date June 23 2021
 */
export const setFormVal = (formType: any, controlName: string, value: any) => {
  formType.get(controlName).setValue(value);
};

/**
 * @description Checks if the passed value is undefined or null
 * @param {any} val
 * @returns boolean
 * @author Suraj Shenoy
 * @date Jul 16 2021
 */
export const valExists = (val: any): boolean => {
  if (typeof val === "undefined" || val === null) {
    return false;
  }
  return true;
};

/**
 * @description used to build vital data from vital-form, pain, fall-risk, braden form to be used in admit form [ initial-assessment ]
 * @param fourFormData - contains vitals/ pain/ fall-risk/ braden form values
 * @author Rajat Saini
 * @date Aug 11 2022
 */
export const buildCompleteVitalForm = (fourFormData, currentPatient) => {
  const {
    vitalGCSForm: vitalForm,
    painForm: painFormData,
    fallsForm: fallFormData,
    bradenForm: bradenFormData,
  } = fourFormData;
  // building viatals - form

  /** Get the form values */
  let {
    vitalsForm: patDataFromForm,
    pupillaryForm,
    timestamp,
    gcsForm: patGcsForm,
  } = vitalForm;
  let patientInfo: any = {};

  /* Don't save temperature unit unless temperature entered */
  let tempUnit = patDataFromForm.daysTemperatureUnit;
  if (!patDataFromForm.daysTemperature) {
    tempUnit = null;
  }

  /** Set the patient position if Other is selected */
  let patientPosition =
    patDataFromForm.daysPatPosition === "Other"
      ? patDataFromForm.daysPatPositionOther
      : patDataFromForm.daysPatPosition;

  // deleting un-necessary vital - form data for admit form
  delete patDataFromForm["daysAVPU"];
  delete patDataFromForm["daysReasonBP"];
  delete patDataFromForm["daysPatPositionOther"];

  /** Set the vital obj */
  let vitals = {
    ...patDataFromForm,
    daysTemperatureUnit: tempUnit,
    daysPatPosition: patientPosition,
  };

  // Calculate GCS score
  const gcsScore = addGCSScore(patGcsForm);
  if (gcsScore) {
    vitals = {
      ...vitals,
      ...patGcsForm,
      daysGCS: gcsScore,
    };
  }

  /** Update the props if Pupillary is entered */
  if (
    !anyFieldMissing(
      pupillaryForm.daysLeftPupilaryCondition,
      pupillaryForm.daysRightPupilaryCondition,
      pupillaryForm.daysLeftPupillarySize,
      pupillaryForm.daysRightPupillarySize
    )
  ) {
    vitals = {
      ...vitals,
      ...pupillaryForm,
    };
  }

  /** Update the props if severity needs to be set to unstable */
  if (patDataFromForm.daysFiO2 > 80 && currentPatient.severity !== "Unstable") {
    patientInfo.severity = "Unstable";
  }

  // End building viatals - form

  // Building Pain - form

  const painProps = {
    daysPain: painFormData.painScore,
  };

  // End building pain - form

  // Building Fall - Risk form
  let fallsProps = addFallsForm(fallFormData);

  // End building Fall-risk form

  // Building Braden form
  let bradenProps = addBradenForm(bradenFormData);

  // End building Braen form

  // merge data of all forms

  return {
    vitals: {
      ...vitals,
      ...painProps,
      ...fallsProps,
      ...bradenProps,
    },
  };
};

const anyFieldMissing = (...fieldArray) => {
  return fieldArray.some((value) => value === null);
};

const addGCSScore = (patGcsForm) => {
  if (
    anyFieldMissing(
      patGcsForm.daysGCSeyes,
      patGcsForm.daysGCSverbal,
      patGcsForm.daysGCSmotor
    )
  )
    return null;
  let eye =
    patGcsForm.daysGCSeyes === null ||
    typeof patGcsForm.daysGCSeyes === "undefined"
      ? 0
      : parseInt(patGcsForm.daysGCSeyes);
  let verbal =
    patGcsForm.daysGCSverbal === null ||
    typeof patGcsForm.daysGCSverbal === "undefined"
      ? 0
      : parseInt(patGcsForm.daysGCSverbal);
  let motor =
    patGcsForm.daysGCSmotor === null ||
    typeof patGcsForm.daysGCSmotor === "undefined"
      ? 0
      : parseInt(patGcsForm.daysGCSmotor);

  return eye + verbal + motor;
};

const addFallsForm = (fallFormData) => {
  if (
    anyFieldMissing(
      fallFormData.fallHistory,
      fallFormData.secDiagnoses,
      fallFormData.ambAssist,
      fallFormData.ivTherapy,
      fallFormData.gait,
      fallFormData.mentalStatus
    )
  )
    return null;

  const fallScore =
    fallFormData.fallHistory +
    fallFormData.secDiagnoses +
    fallFormData.ambAssist +
    fallFormData.ivTherapy +
    fallFormData.gait +
    fallFormData.mentalStatus;

  return {
    daysFalls: fallScore,
    daysFallsFallHistory: fallFormData.fallHistory,
    daysFallsSecDiagnoses: fallFormData.secDiagnoses,
    daysFallsAmbAssist: fallFormData.ambAssist,
    daysFallsivTherapy: fallFormData.ivTherapy,
    daysFallsGait: fallFormData.gait,
    daysFallsMentalStatus: fallFormData.mentalStatus,
  };
};

const addBradenForm = (bradenFormData) => {
  if (
    anyFieldMissing(
      bradenFormData.sensory,
      bradenFormData.moisture,
      bradenFormData.activity,
      bradenFormData.mobility,
      bradenFormData.nutrition,
      bradenFormData.friction
    )
  )
    return null;

  // calculate braden score
  const bradenScore =
    bradenFormData.sensory +
    bradenFormData.moisture +
    bradenFormData.activity +
    bradenFormData.mobility +
    bradenFormData.nutrition +
    bradenFormData.friction;

  return {
    daysBraden: bradenScore,
    daysBradenSensory: bradenFormData.sensory,
    daysBradenMoisture: bradenFormData.moisture,
    daysBradenActivity: bradenFormData.activity,
    daysBradenMobility: bradenFormData.mobility,
    daysBradenNutrition: bradenFormData.nutrition,
    daysBradenFriction: bradenFormData.friction,
  };
};

export enum ACTIVE_ASSESSMENT {
  CRIB = "crib",
  APGAR = "apgar",
  PAIN = "pain",
  RASS = "rass",
  FALL = "fall",
  BRADEN = "braden",
}

export let sexList = [
  {
    label: "Male",
    value: "M",
  },
  {
    label: "Female",
    value: "F",
  },
];
