import { createReducer, on } from "@ngrx/store";
import { Action } from "@ngrx/store";

import * as fromPatientData from "src/app/store/actions/patients/patient-list.action";
import * as hospitalActions from "src/app/store/actions/hospitals.actions";
import * as rootActions from "src/app/store/actions/root.actions";

export interface State {
  loading: boolean;
}

export const initialState: State = {
  loading: false,
};

const patientViewReducer = createReducer(
  initialState,
  // on(fromPatientData.getPatients, (state) => ({...state, loading: true})),
  on(hospitalActions.getNewHospitalsPatients, (state) => ({
    ...state,
    loading: true,
  })),
  on(fromPatientData.setPatients, (state) => ({ ...state, loading: false })),
  on(rootActions.logout, (state) => initialState)
);

export function reducer(state: State | undefined, action: Action) {
  return patientViewReducer(state, action);
}
