<label
  class="side-label"
  [ngStyle]="{
    width: config?.width || '2.5rem',
    height: config?.height || '3rem',
    'border-right': config?.skipRightBorder ? '0' : null
  }"
>
  <ng-container *ngIf="config?.isMultiSelect; else singleLabel">
    <mat-select [(value)]="labelText" (selectionChange)="onChange($event)">
      <mat-option
        *ngFor="let option of config?.selectOptions"
        [value]="option.value"
      >
        {{ option.label }}
      </mat-option>
    </mat-select>
  </ng-container>
  <ng-template #singleLabel>
    {{ labelText || "" }}
  </ng-template>
</label>
