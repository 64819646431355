<ng-container *ngIf="isCommandCenterUser$ | async">
  <div
    cdkOverlayOrigin
    #trigger="cdkOverlayOrigin"
    (click)="openHandover()"
    class="cp-cursor-pointer"
  >
    <mat-icon
      [svgIcon]="handOverOpen ? 'cp-handover-active' : 'cp-handover'"
      aria-hidden="false"
      aria-label="handover icon"
    ></mat-icon>
    <span
      class="handover-text"
      [ngClass]="{ 'handover-text--inactive': !handOverOpen }"
      >Hand... Draft</span
    >
  </div>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayOrigin]="trigger"
    [cdkConnectedOverlayPositions]="positions"
    [cdkConnectedOverlayOpen]="handOverOpen"
  >
    <cp-note-popup
      (close)="closeHandover()"
      [currPatient]="currPatient"
    ></cp-note-popup>
  </ng-template>
</ng-container>
