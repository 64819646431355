<section class="iris-modal-container cc-status-wrapper">
  <div mat-dialog-title class="title">
    <mat-icon>warning</mat-icon>
    Are you sure you want to replace the note ?
  </div>
  <mat-dialog-content class="content">
    <div class="modal-body">
      <div [innerHtml]="summary | sanitizeHtml" *ngIf="summary"></div>
      <div [innerHtml]="diagnosis | sanitizeHtml" *ngIf="diagnosis"></div>
      <div
        [innerHtml]="assessmentPlan | sanitizeHtml"
        *ngIf="assessmentPlan"
      ></div>
      <div [innerHtml]="medication | sanitizeHtml" *ngIf="medication"></div>
      <div [innerHtml]="reports | sanitizeHtml" *ngIf="reports"></div>
      <div [innerHtml]="todo | sanitizeHtml" *ngIf="todo"></div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-flat-button mat-dialog-close class="button-secondary">
      Cancel
    </button>
    <button
      mat-flat-button
      mat-dialog-close
      class="button-primary"
      (click)="submitUserInfo('yes')"
    >
      Yes, Submit
    </button>
  </mat-dialog-actions>
</section>
