import { FormControl, FormGroup } from "@angular/forms";

// Validation of date range
export const timeRangeValidators = (group: FormGroup) => {
  const fromTime = group.get("from").value;
  const toTime = group.get("to").value;
  if (!fromTime && !toTime) return null;
  if (!fromTime && toTime) {
    return { fromTimeRequired: true };
  }
  if (fromTime && !toTime) {
    return { toTimeRequired: true };
  }
};
