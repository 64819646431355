import { Injectable } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import {
  checkBlankGcs,
  checkBlankVitals,
  checkPupillaryBlank,
  vitalFormValidator,
} from "../custom-validators";

@Injectable({
  providedIn: "root",
})
export class VitalsFormService {
  constructor(private fb: UntypedFormBuilder) {}

  initVitalsGcsForm(): UntypedFormGroup {
    return this.fb.group(
      {
        vitalsForm: this.initVitalsForm(),
        gcsForm: this.initGcsForm(),
        pupillaryForm: this.initPupillaryForm(),
        timestamp: this.initTimeForm(),
      },
      { validator: checkBlankVitals }
    );
  }

  initVitalsForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        daysTemperature: new UntypedFormControl(
          null,
          Validators.compose([Validators.min(0), Validators.max(125)])
        ),
        daysTemperatureUnit: new UntypedFormControl("F"),
        daysAVPU: new UntypedFormControl(null),
        daysPatPosition: new UntypedFormControl(null),
        daysPatPositionOther: new UntypedFormControl(
          null,
          Validators.pattern(/^(?!\s*$).+/)
        ),
        daysHR: new UntypedFormControl(
          null,
          Validators.compose([Validators.min(0), Validators.max(350)])
        ),
        daysRR: new UntypedFormControl(
          null,
          Validators.compose([Validators.min(0), Validators.max(200)])
        ),
        daysBP: new UntypedFormControl(
          null,
          Validators.pattern(/^\d{1,3}\/\d{1,3}$/)
        ),
        daysReasonBP: new UntypedFormControl(
          null,
          Validators.pattern(/^(?!\s*$).+/)
        ), // To check for spaces
        daysMAP: new UntypedFormControl(
          null,
          Validators.compose([
            Validators.nullValidator,
            Validators.min(0),
            Validators.max(500),
          ])
        ),
        daysCVP: new UntypedFormControl(
          null,
          Validators.compose([
            Validators.nullValidator,
            Validators.min(-50),
            Validators.max(150),
          ])
        ),
        daysSpO2: new UntypedFormControl(
          null,
          Validators.compose([Validators.min(0), Validators.max(100)])
        ),
        daysFiO2: new UntypedFormControl(
          null,
          Validators.compose([Validators.min(21), Validators.max(100)])
        ),
      },
      [vitalFormValidator]
    );
  }

  initGcsForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        daysGCSeyes: new UntypedFormControl(null),
        daysGCSverbal: new UntypedFormControl(null),
        daysGCSmotor: new UntypedFormControl(null),
      },
      checkBlankGcs
    );
  }

  initPupillaryForm(): UntypedFormGroup {
    return new UntypedFormGroup(
      {
        daysLeftPupillarySize: new UntypedFormControl(null),
        daysRightPupillarySize: new UntypedFormControl(null),
        daysLeftPupilaryCondition: new UntypedFormControl(null),
        daysRightPupilaryCondition: new UntypedFormControl(null),
      },
      checkPupillaryBlank
    );
  }

  initTimeForm(config: any = {}): UntypedFormControl {
    return new UntypedFormControl(
      config.timestamp
        ? config.timestamp
        : new Date(new Date().setSeconds(0, 0)),
      Validators.required
    );
  }
}
