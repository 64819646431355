import { Injectable } from "@angular/core";
import { catchError, map } from "rxjs/operators";
import { Observable, throwError } from "rxjs";
import { DomSanitizer, SafeResourceUrl } from "@angular/platform-browser";
import {
  HttpClient,
  HttpErrorResponse,
  HttpHeaders,
  HttpRequest,
} from "@angular/common/http";

import { FetchFile, FileSigned } from "../models/file/File.model";
import { environment } from "../../environments/environment";
import { ApiResponse } from "../models/api-response.model";

@Injectable({ providedIn: "root" })
export class FileService {
  public env = environment;
  public httpOptions = {
    headers: new HttpHeaders({
      "Content-Type": "application/json",
    }),
  };

  constructor(private sanitizer: DomSanitizer, private http: HttpClient) {}

  errorHandler(error: HttpErrorResponse) {
    return throwError(error || "Server error");
  }

  /*
   * NAME: getFileData
   * PURPOSE: converts file data into data suitable to display in html
   * DESCRIPTION:
   * PARAMS: data:string - file data
   * RETURNS: SafeResourceUrl - bypasses data of file
   * USED BY: protocol-form.component.ts, orders-view.component.ts
   * CREATED DATE: 12 November 2019
   * AUTHOR: Gunjit Agrawal
   */
  getFileData(data: string): SafeResourceUrl {
    return this.sanitizer.bypassSecurityTrustResourceUrl(data);
  }

  getSignedUrl(file: FileSigned): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/getSignedUrl`, file, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  // Signer url for private bucket
  getSignedUrlPrivate(type) {
    return this.http
      .post<ApiResponse>(this.env.apiUrl + "v1/file/image", {
        type: type,
      })
      .pipe(
        catchError(this.errorHandler),
        map((data) => {
          return data.data;
        })
      );
  }
  // Signer url for private bucket
  getSignedUrlPreview(key) {
    return this.http
      .post<ApiResponse>(this.env.apiUrl + "v1/file/image/get", {
        key: key,
      })
      .pipe(
        catchError(this.errorHandler),
        map((data) => {
          return data.data;
        })
      );
  }

  public download(url, filename) {
    fetch(url).then(function (t) {
      return t.blob().then((b) => {
        var a = document.createElement("a");
        a.href = URL.createObjectURL(b);
        a.setAttribute("download", filename);
        a.click();
      });
    });
  }

  getFile(key: string): Observable<any> {
    return this.http
      .post<any>(`${this.env.apiUrl}file/getFile`, { key }, this.httpOptions)
      .pipe(catchError(this.errorHandler));
  }

  getFiles(data: FetchFile[]): Observable<any> {
    return this.http
      .post<any>(
        `${this.env.apiUrl}file/getFile`,
        { key: data },
        this.httpOptions
      )
      .pipe(catchError(this.errorHandler));
  }

  uploadFile(file: File, url: string, type: string): Observable<any> {
    const headers = new HttpHeaders({
      "Content-Type": type,
      DoNotIntercept: "true",
    });

    const req = new HttpRequest("PUT", url, file, {
      headers,
      reportProgress: true,
    });

    return this.http.request(req).pipe(catchError(this.errorHandler));
  }
}
