import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { TrendsComponent } from "./trends.component";
import { MatSelectModule } from "@angular/material/select";
import { MatDialogModule } from "@angular/material/dialog";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatIconModule } from "@angular/material/icon";
import { IrisComponentsModule } from "src/app/iris-components/iris-components.module";

@NgModule({
  declarations: [TrendsComponent],
  imports: [
    CommonModule,
    // MatSelectModule,
    // MatDialogModule,
    // MatTabsModule,
    // MatTableModule,
    // MatIconModule,
    IrisComponentsModule,

    // popover-module
  ],
  exports: [TrendsComponent],
})
export class TrendsModule {}
