import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { FilePreviewModalComponent } from "./components/file-preview-modal/file-preview-modal.component";
import { OverlayModule } from "@iris/overlay";
import { CarouselModule } from "@iris/carousel";

@NgModule({
  declarations: [FilePreviewModalComponent],
  imports: [CommonModule, OverlayModule, CarouselModule],
  exports: [FilePreviewModalComponent],
})
export class FilePreviewModalModule {}
