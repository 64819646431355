import { ChangeDetectionStrategy, Component, OnInit } from "@angular/core";
import {
  AvatarParams,
  AVATAR_SIZE,
} from "@iris/avatar/components/avatar/avatar.component";

@Component({
  selector: "app-appointment",
  templateUrl: "./appointment.component.html",
  styleUrls: ["./appointment.component.scss"],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class AppointmentComponent implements OnInit {
  constructor() {}

  avatarParamsIcon1: AvatarParams[] = [
    {
      size: AVATAR_SIZE.L,
    },
  ];

  ngOnInit(): void {}
}
