import { Observable } from "rxjs";
import { map } from "rxjs/operators";
import { Injectable } from "@angular/core";
import { HttpClient, HttpParams } from "@angular/common/http";

import { environment } from "../../environments/environment";

@Injectable({ providedIn: "root" })
export class SearchService {
  public apiUrl = environment.apiUrl;

  constructor(private http: HttpClient) {}

  search(
    term: string,
    type = "order",
    otype = null,
    hospitalName: string = null,
    filterInput: any = []
  ): Observable<any> {
    return this.http
      .get<any>(this.apiUrl + "orderable", {
        params: new HttpParams()
          .set("search", term)
          .set("type", type)
          .set("otype", otype)
          .set("hospital", hospitalName)
          .set("filterInput", filterInput),
      })
      .pipe(map((data) => data.data));
  }
}
