import {
  ComponentRef,
  Directive,
  ElementRef,
  Input,
  OnInit,
  TemplateRef,
} from "@angular/core";
import { PopoverComponent } from "@iris/popover/components/popover/popover.component";
import { ToolTipPos } from "../models/tooltip.model";
import {
  positionData,
  defaultPositioning,
} from "@iris/popover/data/popover.data";
import {
  ConnectedPosition,
  Overlay,
  OverlayConfig,
  OverlayPositionBuilder,
  OverlayRef,
} from "@angular/cdk/overlay";
import { ComponentPortal } from "@angular/cdk/portal";
import { Subject } from "rxjs-compat";
import { takeUntil } from "rxjs/operators";

@Directive({
  selector: "[cpPopover]",
})
export class PopoverDirective implements OnInit {
  private overlayRef: OverlayRef;
  private unsubscribe$: Subject<any> = new Subject<any>();
  private tooltiptimer: any = null;
  tooltipRef: ComponentRef<PopoverComponent> = null;
  /**
   * The string content to be displayed in the popover.
   *
   * If the content is falsy, the popover won't open.
   */
  @Input("cpPopover") content: string | TemplateRef<any> = null;

  /**  The title of the popover. */

  @Input("popoverTitle") title = "";

  /**  Delay before opening the tooltip */

  @Input("delay") delay: number = 0;

  /**
   * The preferred placement of the popover.
   *
   * Possible values are `"top-start"`, `"top-center"`, `"top-end"`, `"bottom-start"`, `"bottom-center"`,
   * `"bottom-end"`, `"left-start"`, `"left-center"`, `"left-end"`, `"right-start"`, `"right-center"`,
   * `"right-end"`
   *
   * Accepts a single string with any one of the above values.
   * Default value "top-start"
   */
  @Input() position: ToolTipPos = defaultPositioning;

  /**
   * Specifies events that should trigger the popover.
   *
   * Its value could be either "hover" or "click"
   *
   */
  @Input("popoverTrigger") popTrigger: "hover" | "click" = "hover";

  /**
   * Specifies whether popover should be closed on its own or it should be closed manually.
   *
   * Its default value is true.
   */
  @Input() popoverAutoclose: boolean = true;

  constructor(
    private overlayPositionBuilder: OverlayPositionBuilder,
    private elementRef: ElementRef,
    private overlay: Overlay
  ) {}

  get getPositionData(): ConnectedPosition {
    const defaultPositionObj = positionData[defaultPositioning],
      inputPositionObj = positionData[this.position],
      positionObj = inputPositionObj || defaultPositionObj;
    return positionObj;
  }

  ngOnInit() {
    this.toolTipEventListners();
  }

  private toolTipEventListners(): void {
    const htmlEl = this.elementRef.nativeElement;
    switch (this.popTrigger) {
      case "hover":
        htmlEl.addEventListener("mouseenter", this.onMouseInteract.bind(this));
        htmlEl.addEventListener("mouseleave", this.onMouseLeave.bind(this));
        break;
      case "click":
        htmlEl.addEventListener("click", this.onMouseInteract.bind(this));
        break;
    }
  }

  onMouseLeave(): void {
    if (this.popoverAutoclose) this.removeToolTip();
    if (this.tooltiptimer) this.removeTimer();
  }

  removeTimer(): void {
    clearTimeout(this.tooltiptimer);
    this.tooltiptimer = null;
  }

  onMouseInteract() {
    if (this.delay) {
      this.tooltiptimer = setTimeout(this.timedToolTip.bind(this), this.delay);
      return;
    }

    this.showTooltip();
  }

  private timedToolTip(): void {
    if (!this.tooltiptimer) return;
    this.showTooltip();
  }

  showTooltip() {
    // Create tooltip portal
    if (this.tooltipRef) return;
    this.createToolTip();
    this.attachToolTipComponent();
    if (this.popoverAutoclose) this.onClickOutside();
  }

  private createToolTip(): void {
    // Default position is top-center.
    const toolTipPosition: ConnectedPosition = this.getPositionData;
    const positionStrategy = this.overlayPositionBuilder
      // Create position attached to the elementRef
      .flexibleConnectedTo(this.elementRef)
      // Describe how to connect overlay to the elementRef
      // Means, attach overlay's center bottom point to the
      // top center point of the elementRef.
      .withPositions([toolTipPosition]);
    const overlayConfig = new OverlayConfig({
      //Making the tooltip to reposition on scroll
      scrollStrategy: this.overlay.scrollStrategies.reposition(),
      // Position the tooltip wrt the element
      positionStrategy,
    });

    this.overlayRef = this.overlay.create(overlayConfig);
  }

  private attachToolTipComponent(): void {
    const tooltipPortal = new ComponentPortal(PopoverComponent);

    // Attach tooltip portal to overlay
    this.tooltipRef = this.overlayRef.attach(tooltipPortal);
    const tooltipInstance = this.tooltipRef?.instance;
    // Pass content to tooltip component instance
    tooltipInstance.content = this.content;
    tooltipInstance.position = this.position;
    tooltipInstance.title = this.title;
    tooltipInstance.showCloseButton = !this.popoverAutoclose;

    this.tooltipRef.instance
      .onToolTipClose()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.removeToolTip();
      });
  }

  private onClickOutside(): void {
    this.overlayRef
      ?.outsidePointerEvents()
      ?.pipe(takeUntil(this.unsubscribe$))
      ?.subscribe(() => {
        this.removeToolTip();
      });
  }

  removeToolTip() {
    if (!this.overlayRef) return;
    this.tooltipRef = null;
    this.overlayRef.detach();
  }

  ngOnDestroy() {
    this.unsubscribe$.unsubscribe();
    this.removeToolTip();
  }
}
