import { Injectable } from "@angular/core";
import {
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { checkBlankVents, checkTherapyDevice } from "../../custom-validators";

@Injectable({
  providedIn: "root",
})
export class VentsFormService {
  constructor(private fb: UntypedFormBuilder) {}

  /**
   * @description Returns the vents form
   * @author Suraj Shenoy
   * @date Jul 9 2021
   * @returns FormGroup
   */
  initVentForm(): UntypedFormGroup {
    return this.fb.group(
      {
        therapyForm: this.initTherapyForm(),
        airwayForm: this.airwayForm(),
        timestamp: new UntypedFormControl(
          new Date(new Date().setSeconds(0, 0)),
          Validators.required
        ),
      },
      { validator: checkBlankVents }
    );
  }

  /**
   * @description Formgroup of Therapy devices form
   * Refer the link https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   * @returns FormGroup
   */
  initTherapyForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      daysTherapyDevice: new UntypedFormControl(null, [checkTherapyDevice]),
      daysOxygenFlow: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      daysFiO2: new UntypedFormControl(null, [
        Validators.min(21),
        Validators.max(100),
      ]),
      daysVentMode: new UntypedFormControl(null),
      daysVentVt: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(2000),
      ]),
      daysVentPEEP: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(30),
      ]),
      daysVentRRset: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(200),
      ]),
      daysRR: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(200),
      ]),
      daysVentPinsp: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      daysVentPsupport: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      daysVentPpeak: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(200),
      ]),
      daysVentPplat: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(80),
      ]),
      daysVentPmean: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      daysVentMV: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(100),
      ]),
      daysVentBreathSequence: new UntypedFormControl(null),
      daysVentI: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(10),
      ]),
      daysVentE: new UntypedFormControl(null, [
        Validators.min(0),
        Validators.max(10),
      ]),
    });
  }

  /**
   * @description Formgroup of airway form
   * Refer the link https://docs.google.com/spreadsheets/d/1LsBSm5NAKgLojpFHeVT6akPiKDki1ajetyt_eifWe_k/edit#gid=644604615
   * @author Suraj Shenoy
   * @date Jul 9 2021
   * @returns FormGroup
   */
  airwayForm(): UntypedFormGroup {
    return new UntypedFormGroup({
      daysVentAirway: new UntypedFormControl(null),
      nasopharyngealAirway: new UntypedFormGroup({
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
      }),
      oralAirway: new UntypedFormGroup({
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
      }),
      biteBlock: new UntypedFormGroup({
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
      }),
      supraglotticAirway: new UntypedFormGroup({
        daysSupraglotticAirway: new UntypedFormControl(null),
        supraGloticOther: new UntypedFormControl(
          null,
          Validators.pattern(/^(\d*\.)?\d+$/)
        ),
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
      }),
      cooksAirway: new UntypedFormGroup({
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
      }),
      oralEndotrachealTube: new UntypedFormGroup({
        daysVentCuff: new UntypedFormControl(null),
        daysEttTieLevel: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(50),
        ]),
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
        daysVentCuffPressure: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(100),
        ]),
        isClosedSuction: new UntypedFormControl(null),
      }),
      nasalEndotrachealTube: new UntypedFormGroup({
        daysVentCuff: new UntypedFormControl(null),
        daysEttTieLevel: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(50),
        ]),
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
        daysVentCuffPressure: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(100),
        ]),
        isClosedSuction: new UntypedFormControl(null),
      }),
      tracheostomyTube: new UntypedFormGroup({
        daysVentCuff: new UntypedFormControl(null),
        daysVentTubeSize: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(10),
        ]),
        daysVentCuffPressure: new UntypedFormControl(null, [
          Validators.min(0),
          Validators.max(100),
        ]),
        isClosedSuction: new UntypedFormControl(null),
      }),
    });
  }
}
