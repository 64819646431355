<ul class="checkbox-list">
  <li *ngFor="let node of nodes" class="checkbox-item">
    <mat-checkbox
      [ngClass]="
        !!isParentNode(node)
          ? 'checkbox-item-container-large'
          : 'checkbox-item-container-small'
      "
      [(ngModel)]="node.checked"
      (change)="onNodeChange(node)"
      [indeterminate]="node.indeterminate"
    ></mat-checkbox>
    <span [ngClass]="!!isParentNode(node) ? 'text-title' : 'text-subtitle'">{{
      node.label
    }}</span>
    <button
      mat-icon-button
      *ngIf="!!isParentNode(node)"
      (click)="toggleChildren()"
    >
      <mat-icon *ngIf="showChildren">keyboard_arrow_down</mat-icon>
      <mat-icon *ngIf="!showChildren">keyboard_arrow_up</mat-icon>
    </button>
    <app-iris-tree-checkbox-list
      *ngIf="showChildren && node.children"
      [nodes]="node.children"
      (updateParentNode)="handleChildNodeChange(node)"
      #childTree
    ></app-iris-tree-checkbox-list>
  </li>
</ul>
