import { Component, EventEmitter, Input, OnInit, Output } from "@angular/core";
import { StatusChipInterface } from "@iris/status-chip/models";

@Component({
  selector: "cp-status-chip",
  templateUrl: "./status-chip.component.html",
  styleUrls: ["./status-chip.component.scss"],
})
export class StatusChipComponent implements OnInit {
  @Input() statusConfig: StatusChipInterface;

  @Output() updateStatus = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}

  changeStatus() {
    this.updateStatus.emit();
  }
}
