import {
  Component,
  forwardRef,
  Input,
  OnDestroy,
  OnInit,
  Output,
  EventEmitter,
} from "@angular/core";
import {
  ControlValueAccessor,
  FormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  Validators,
} from "@angular/forms";
import { timeRangeValidator } from "@iris/time-picker/utils/range-validator.utils";
import { Observable, Subject } from "rxjs-compat";
import { map, startWith, takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-time-picker",
  templateUrl: "./time-picker.component.html",
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TimePickerComponent),
      multi: true,
    },
    {
      provide: NG_VALIDATORS,
      useExisting: TimePickerComponent,
      multi: true,
    },
  ],
})
export class TimePickerComponent
  implements OnInit, ControlValueAccessor, OnDestroy
{
  private unsubscribe$: Subject<any> = new Subject<any>();

  @Input("isCustom") isCustom: boolean = true;

  @Input("max") max: string = "";
  @Input("min") min: string = "";

  @Input("normalValue") normalValue: boolean = false;

  @Output() inputChange: EventEmitter<any> = new EventEmitter<any>();

  ngOnDestroy(): void {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }

  onChange = (_: any) => {};
  onTouched = () => {};

  writeValue(obj: string): void {
    this.setTime(obj);
  }
  setTime(obj) {
    if (!obj) return;
    const [hours, minutes] = obj.split(":");
    if (!hours || !minutes) return;
    const transformedMin = minutes.length > 1 ? minutes : `0${minutes}`;
    const finalTime = `${hours}:${transformedMin}`;
    this.timeControl.setValue(finalTime);
  }
  registerOnChange(fn: any): void {
    this.onChange = fn;
  }
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }
  setDisabledState?(isDisabled: boolean): void {
    if (isDisabled) this.timeControl.disable();
    else this.timeControl.enable();
  }
  filterTime: Observable<string[]>;

  public timeControl: FormControl = new FormControl("", [
    Validators.pattern("^([0-1]?[0-9]|2[0-3]):[0-5][0-9]$"),
  ]);

  public inputFocus: boolean = false;

  public inputTouched(): void {
    this.onTouched();
  }

  public get options(): string[] {
    let finalTime: string[] = [];
    for (let hour = 0; hour < 24; hour++) {
      let minute = 0;
      while (minute <= 45) {
        let time = `${hour}:`;
        const fullTime = (time += minute ? minute : `0${minute}`);
        finalTime.push(fullTime);
        minute += 15;
      }
    }
    return finalTime;
  }
  //for getting continuous 24 hr time @sheethalkhan  23-05-2023
  public get normalOptions(): string[] {
    let finalTime: string[] = [];
    for (let hour = 0; hour <= 24; hour++) {
      let minute = 0;
      while (minute <= 59) {
        let time = `${hour}:`;
        if (hour === 24 && minute == 1) {
          break;
        }
        const fullTime = (time += minute ? minute : `0${minute}`);
        let parts = fullTime.split(":");

        let formattedTime =
          parts[0].padStart(2, "0") + ":" + parts[1].padStart(2, "0");

        finalTime.push(formattedTime);
        minute += 1;
      }
    }
    return finalTime;
  }

  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();
    if (this.normalValue === false) {
      return this.options.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    } else {
      return this.normalOptions.filter((option) =>
        option.toLowerCase().includes(filterValue)
      );
    }
  }

  public changeInput() {
    this.inputChange.emit();
  }

  ngOnInit(): void {
    this.initializeListeners();
  }

  initializeListeners() {
    this.filterTime = this.timeControl.valueChanges.pipe(
      startWith(""),
      map((value) => this._filter(value || ""))
    );

    this.timeControl.valueChanges
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe((val: string) => {
        this.onChange(val);
      });
  }

  validate() {
    if (this.max && timeRangeValidator(this.timeControl.value, this.max)) {
      return { maxTime: true };
    }
    if (this.min && timeRangeValidator(this.min, this.timeControl.value)) {
      return { minTime: true };
    }
    return this.timeControl.errors;
  }
}
