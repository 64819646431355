import { createAction, props } from '@ngrx/store';

export const setUser = createAction(
    '[User] Set User',
    props<{user: any}>()
)

export const setRoundarPreference = createAction(
  '[Patient Header] Set Roundar Preference',
  props<{ data: boolean }>()
);

export const updateOnCallStatus = createAction(
  '[User] Update On Call Status',
  props<{user: any}>()
)

export const updateUserStore = createAction(
	'[User] Upadate User Store',
	props<{ user : any }>()
)