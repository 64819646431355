import {
  ActionReducerMap,
  createFeatureSelector,
  createSelector,
} from "@ngrx/store";

import * as fromPatientList from "./patient-data";
import * as fromPatientView from "./patient-view";
import * as fromPatientError from "./patient-error";
import * as fromDischargedPatientList from "./patient-discharge";
import * as fromSbarList from "./sbar-data";
import * as fromRoot from "../index";

export interface patientState {
  data: fromPatientList.State;
  view: fromPatientView.State;
  dischargePatientsData: fromDischargedPatientList.DischargePatientState;
  error: fromPatientError.State;
  sbar: fromSbarList.State;
}

export interface State {
  patients: patientState;
}

export const reducer: ActionReducerMap<any, any> = {
  data: fromPatientList.reducer,
  view: fromPatientView.reducer,
  dischargePatientsData: fromDischargedPatientList.reducer,
  error: fromPatientError.reducer,
  sbar: fromSbarList.reducer,
};

/** SELECTOR setup */
export const getPatientState = createFeatureSelector<patientState>("patients");

export const getPatientsData = createSelector(
  getPatientState,
  (state: patientState) => state.data
);

export const getPatients = createSelector(
  getPatientsData,
  fromPatientList.getAllPatients
);

export const getPatView = createSelector(
  getPatientState,
  (state: patientState) => state.view
);

// a flag to indicate wheather to show discharged patients is loaded or not
// export const getPatDischarge = createSelector(
//     getPatView,
//     (state: fromPatientView.State) => (state.showDischarges)
// )

export const getPatLoading = createSelector(
  getPatView,
  (state: fromPatientView.State) => state.loading
);

// fetches all(ids, entities) discharged patients from store => patients => dischargedPatientsData
export const getDischargedPatientsData = createSelector(
  getPatientState,
  (state: patientState) => state.dischargePatientsData
);

// fetched a array of discharged patients
export const getDischargedPatients = createSelector(
  getDischargedPatientsData,
  fromDischargedPatientList.selectAllDischargedPatients
);

export const getPatientError = createSelector(
  getPatientState,
  (state: patientState) => state.error
);

// get sbar
export const getSbarData = createSelector(
  getPatientState,
  (state: patientState) => state.sbar
);
export const getSbarList = createSelector(getSbarData, fromSbarList.getAllSbar);
