import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
  ViewChild,
} from "@angular/core";
import { DomSanitizer } from "@angular/platform-browser";
import { FileObj } from "@iris/carousel";
import { FilePreviewInterface } from "@iris/file-preview-modal/models/file-preview-modal.model";
import { FilePreviewModalService } from "@iris/file-preview-modal/services/file-preview-modal.service";
import { OverlayComponent } from "@iris/overlay/components/overlay/overlay.component";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

@Component({
  selector: "cp-file-preview-modal",
  templateUrl: "./file-preview-modal.component.html",
  styleUrls: ["./file-preview-modal.component.scss"],
})
export class FilePreviewModalComponent implements OnInit, OnChanges {
  public imageTypes = [
    "jpg",
    "jpeg",
    "png",
    "gif",
    "bmp",
    "tif",
    "tiff",
    "svg",
    "raw",
    "psd",
  ];

  public filePreviewList: FileObj[] = [];

  constructor(
    private _filePreviewModalService: FilePreviewModalService,
    public domSanitizer: DomSanitizer
  ) {}

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.filePreview) {
      this.setPreviewList();
    }
  }

  public setPreviewList(): void {
    this.filePreviewList = this.filePreview.reduce((final, file) => {
      const fileNameSplit = file.fileName.split(".");
      const fileExtention = fileNameSplit[fileNameSplit?.length - 1];
      const fileType = this.imageTypes.includes(fileExtention)
        ? "image"
        : "pdf";
      let fileObj = {};
      if (fileType == "image") {
        fileObj = {
          fileType: fileType,
          imgData: file.url,
          name: file.fileName,
        };
      } else {
        const previewUrl = file.url as string;
        fileObj = {
          fileType: fileType,
          pdfUrl: this.domSanitizer.bypassSecurityTrustResourceUrl(previewUrl),
          name: file.fileName,
        };
      }
      return [...final, fileObj];
    }, []);
  }

  public unsubscribe$ = new Subject();

  @Input("filePreview") filePreview: FilePreviewInterface[] = [];

  @ViewChild("overlayComp") overlayComp: OverlayComponent;

  openAttachmentOverlay() {
    this.overlayComp.openModal();
  }

  closeAttachmentOverlay() {
    this.overlayComp.closeModal();
  }

  ngOnInit(): void {
    this.initializeListeners();
  }

  initializeListeners() {
    this._filePreviewModalService.openFile$
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(() => {
        this.openAttachmentOverlay();
      });
  }
}
