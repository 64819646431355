import {
  PatientType,
  PatientWeightHistory,
  WeightObj,
} from "../models/patient";

const WEIGHT_UNITS = {
  kg: "kg",
  gram: "g",
};

export interface CALCULATE_WEIGHT {
  weightObj: WeightObj;
  patientType: string;
  weightInGrams?: boolean;
  weight?: number;
  addUnits?: boolean;
}

export interface CALCULATE_CURRENT_WEIGHT {
  weightHistory: [PatientWeightHistory];
  weightObj: WeightObj;
  patientType: string;
  weightInGrams?: boolean;
  weight?: number;
  addUnits?: boolean;
}

export const calculateWeight = ({
  weightObj,
  patientType,
  weightInGrams = false,
  weight,
  addUnits = true,
}: CALCULATE_WEIGHT) => {
  if (!weightObj || !Object.keys(weightObj)?.length) return null;

  if (patientType == PatientType.Neonatal) {
    return weightInGrams && addUnits
      ? +(weightObj?.kg || 0) * 1000 + +weightObj?.gram + " g"
      : weight;
  }

  if (addUnits) {
    let weightString = "";
    for (const [key, value] of Object.entries(weightObj)) {
      +value > 0 && (weightString += `${+value} ${WEIGHT_UNITS[key]} `);
    }

    return weightString;
  }

  return weight;
};

export const calculateCurrentWeight = ({
  weightHistory,
  weightObj,
  patientType,
  weightInGrams = false,
  weight = null,
  addUnits = true,
}: CALCULATE_CURRENT_WEIGHT) => {
  if (weightHistory?.length) {
    return weightInGrams
      ? +weightHistory[0]["weightKg"] * 1000 + " g"
      : weightHistory[0]["weightKg"];
  }
  return calculateWeight({
    weightObj,
    patientType,
    weightInGrams,
    weight,
    addUnits,
  });
};
