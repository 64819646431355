import { Validators } from "@angular/forms";

export const mrnValidator = Validators.pattern("[^^~`!@#$%^&*?_+={[}|\"'<>,]*");

export const numberValidator = Validators.pattern(/^-?(0|[1-9]\d*)?$/);

export const numberTimeValidator = Validators.pattern(
  /^-?(0|0[0-9]|[1-9]\d*)?$/
);

export const epidNoValidator = Validators.pattern(/^\d{11}$/);

export const aadharNumberValidator = Validators.pattern(/^\d{12}$/);

export const numberWithDotValidator = Validators.pattern(/^[1-9]\d*(\.\d+)?$/);

export const pinCodeValidator = Validators.pattern(/^\d{6}$/);

export const mobileValidator = Validators.pattern(
  /^(?:(?:\+|0{0,2})91(\s*[\-]\s*)?|[0]?)?[1-9]\d{9}$/
);
