import * as fromFinalNotes from "../actions/final-notes.actions";

export interface FinalNoteState {
  tabState: boolean;
  finalNotes: [];
  draftNotes: [];
  loading: boolean;
  loaded: boolean;
}

export const initialState: FinalNoteState = {
  finalNotes: [],
  draftNotes: [],
  tabState: false,
  loading: false,
  loaded: false,
};

const noteExistCheck = (finalNotes, payload): number | null => {
  return finalNotes.findIndex((item) => item._id === payload._id);
};

export function reducer(state = initialState, action): FinalNoteState {
  switch (action.type) {
    case fromFinalNotes.LOAD_NOTE_SUCCESS: {
      let notes = action.payload;
      return { ...state, ...notes };
    }

    case fromFinalNotes.LOAD_NOTE_DRAFT_SOCKET_SUCCESS: {
      let notes = action.payload;
      state.draftNotes = [...notes] as [];
      return { ...state };
    }
    case fromFinalNotes.LOAD_NOTE_FINAL_SOCKET_SUCCESS: {
      let notes = action.payload;
      if (notes && Object.keys(notes).length > 0) {
        let index = noteExistCheck(state.finalNotes, notes);
        if (index != -1) {
          Object.assign(state.finalNotes[index], notes);
        } else {
          (state.finalNotes as any[]).push(notes);
        }
      }
      return { ...state };
    }
    case fromFinalNotes.CREATE_NOTE_SUCCESS: {
      return { ...state, loading: false, loaded: true, tabState: true };
    }
    case fromFinalNotes.CREATE_NOTE: {
      return { ...state, loading: true, loaded: false };
    }
    case fromFinalNotes.CREATE_NOTE_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
    case fromFinalNotes.NOTE_UPDATE: {
      return { ...state, loading: true, loaded: false };
    }
    case fromFinalNotes.ATTEST_NOTE: {
      return { ...state, loading: true, loaded: false };
    }

    case fromFinalNotes.NOTE_DELETE_PENDED_SUCCESS: {
      const id = action.payload;
      (state.finalNotes as any) = state.finalNotes.filter(
        (note) => note["_id"] != id
      );
      return { ...state };
    }

    case fromFinalNotes.NOTE_DELETE_SUCCESS: {
      return { ...state, tabState: true };
    }
    case fromFinalNotes.NOTE_UPDATE_SUCCESS: {
      return { ...state, loading: false, loaded: true, tabState: true };
    }
    case fromFinalNotes.NOTE_UPDATE_FAIL: {
      return { ...state, loading: false, loaded: false };
    }
    case fromFinalNotes.NOTE_TAB_REFRESH: {
      return { ...state, tabState: false };
    }
    case "[Root Actions] patient chart closed": {
      return initialState;
    }
    default:
      return { ...state };
  }
  return { ...state };
}

export const getSignedNotes = (state: FinalNoteState) => {
  let signedNote = state.finalNotes.filter((notes) => {
    return notes["content"]["pendOrSigned"] === "signed";
  });
  return signedNote;
};

export const getPendedNotes = (state: FinalNoteState) => {
  let pendedNote = state.finalNotes.filter((notes) => {
    return notes["content"]["pendOrSigned"] === "pended";
  });
  return pendedNote;
};

export const getDraftNotes = (state: FinalNoteState) => {
  // making the structure common for all type of notes
  const allDraftNotes = [];
  state.draftNotes.forEach((draftNote) => {
    let note = {};
    note["content"] = draftNote;
    allDraftNotes.push(note);
  });
  return allDraftNotes;
};

export const gettabState = (state: FinalNoteState) => {
  return state.tabState;
};

export const getloadingState = (state: FinalNoteState) => {
  return state.loading;
};

export const getloadedState = (state: FinalNoteState) => {
  return state.loaded;
};
