export const weekDays: string[] = [
  "mon",
  "tue",
  "wed",
  "thu",
  "fri",
  "sat",
  "sun",
];

export const consultationCharges = [
  "First visit",
  "Follow-up visit",
  "Re-visit",
  "Non-doctor visit",
];
